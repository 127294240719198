import React, { useState, useEffect, useRef } from "react"

import Form from "react-bootstrap/Form"
import { toast } from "react-toastify"
import Modal from "react-bootstrap/Modal"
import {
  FormRow,
  FormRowSelect,
  Pagination,
  CustumTooltip,
  ProfileAvatar,
} from "../../Common"

import { useAppContext } from "../../../context/context/appContext"

import {
  UserTable,
  UserTableCell,
  UserTableHeader,
  UserTableRow,
  UserWrapper,
} from "../../../assets/styles/Settings"
import {
  DeleteIcon,
  DriveFileRenameOutlineSharpIcon,
  FiberManualRecordRoundedIcon,
  MoreHorizRoundedIcon,
  SearchIcon,
} from "../../../assets/icons"

import { useDispatch, useSelector } from "react-redux"

import {

  getDevproReports,

}
  from "../../../redux"


const TaskReport = () => {
  const dispatch = useDispatch()
  // const {reports} = useSelector((state) => state.report) 
  const { reports, loading } = useSelector((state) => state.project)

  useEffect(() => {
    dispatch(getDevproReports()).then(() => {
      console.log("data is coming")
      console.log(reports)
    })
  }, [])

  return (
    <div className=" lg:tw-px-8    tw-px-3 ">
    <table>
      <thead>
        <tr>
          <th>User Info</th>
          <th>Projects</th>
          <th>Tasks</th>
        </tr>
      </thead>
      <tbody>
        {reports.map((report, index) => (
          <tr key={index}>
            <td>
              <p>{report.user.name}</p>
            </td>
            <td>
              {report.projects.map((project, projectIndex) => (
                <div key={projectIndex}>
                  <p> {project.title}</p>
                </div>
              ))}
            </td>
            <td>
              {report.projects.map((project, projectIndex) => (
                <div key={projectIndex}>

                  <div>
                    {project.tasks.map((task, taskIndex) => (
                      <div key={taskIndex}>
                        <p>{task.title}</p>
                        {/* Add more task details here */}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

export default TaskReport
