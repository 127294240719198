export const GET_ALL_TICKET_BEGIN = "GET_ALL_TICKET_BEGIN"
export const GET_ALL_TICKET_SUCCESS = "GET_ALL_TICKET_SUCCESS"
export const GET_ALL_TICKET_ERROR = "GET_ALL_TICKET_ERROR"

export const CREATE_TICKET_BEGIN = "CREATE_TICKET_BEGIN"
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS"
export const CREATE_TICKET_ERROR = "CREATE_TICKET_ERROR"

export const CLOSE_TICKET_BEGIN = "CLOSE_TICKET_BEGIN"
export const CLOSE_TICKET_SUCCESS = "CLOSE_TICKET_SUCCESS"
export const CLOSE_TICKET_ERROR = "CLOSE_TICKET_ERROR"
