import React from "react"
import ReactPaginate from "react-paginate"
import { PaginationWrapper } from "../../assets/styles/Common"

const Pagination = ({ handlePageClick, pageCount, forcePage }) => {
  return (
    <PaginationWrapper>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        marginPagesDisplayed={2}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        forcePage={forcePage}
      />
    </PaginationWrapper>
  )
}

export default Pagination
