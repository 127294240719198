import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openTemplateModal } from "../../../redux";
import { TestCaseWrapper } from "../../../assets/styles/TestCase";
import { AddSharpIcon, ArrowBackIcon } from "../../../assets/icons";
import TemplatesModal from "./TemplatesModal";
import AllTemplates from "./AllTemplates";

const Template = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTemplateModalOpen } = useSelector((state) => state.template);
  const [id, setId] = useState("");

  const handleBack = () => {
    navigate('/test_case');
  };

  return (
    <div className="mt-2">
      <TestCaseWrapper>
        <h5 className="card-title" style={{marginLeft: 10}}>
          <span onClick={handleBack}><ArrowBackIcon style={{ fontSize: "22px" }} /></span> Templates
        </h5>
        <TemplatesModal isTemplateModalOpen={isTemplateModalOpen} id={id} setId={setId} />
        <div className="card-title">
          <button
            className="btn btn-block add-btn"
            type="button"
            onClick={() => {
              dispatch(openTemplateModal());
              setId("");
            }}
            style={{ marginLeft: 30 }}
          >
            <AddSharpIcon style={{ color: "#fff", fontSize: "20px" }} /> Add
          </button>
        </div>
        <div className="card-title" >
          <AllTemplates setId={setId} id={id} />
        </div>
      </TestCaseWrapper>
    </div>
  );
};

export default Template;
