export const GET_ACCESS_LIST_BEGIN = "GET_ACCESS_LIST_BEGIN"
export const GET_ACCESS_LIST_SUCCESS = "GET_ACCESS_LIST_SUCCESS"
export const GET_ACCESS_LIST_ERROR = "GET_ACCESS_LIST_ERROR"

export const SIGNUP_USER_BEGIN = "SIGNUP_USER_BEGIN"
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS"
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR"

export const SET_PROFILE_PIC = "SET_PROFILE_PIC"

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"

export const UPLOAD_PROFILE_PIC_BEGIN = "UPLOAD_PROFILE_PIC_BEGIN"
export const UPLOAD_PROFILE_PIC_SUCCESS = "UPLOAD_PROFILE_PIC_SUCCESS"
export const UPLOAD_PROFILE_PIC_ERROR = "UPLOAD_PROFILE_PIC_ERROR"

export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"

export const FEED_MODAL = "FEED_MODAL"

export const SETUP_USER = "SETUP_USER"
export const LOGOUT_USER = "LOGOUT_USER"

export const SET_EDIT_PROJECT = "SET_EDIT_PROJECT"

export const LOGOUT_USER_BEGIN = "LOGOUT_USER_BEGIN"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR"

export const FILTER_TASK_ON_STATUS_UPDATE = "FILTER_TASK_ON_STATUS_UPDATE"

export const FILTER_ATTACHMENTS = "FILTER_ATTACHMENTS"

export const FILTER_WORKLOG = "FILTER_WORKLOG"

export const SET_USERS_LIST = "SET_USERS_LIST"

export const GET_TASK_BY_GOAL = "GET_TASK_BY_GOAL"

export const UPDATE_STATUS_CHANGE = "UPDATE_STATUS_CHANGE"
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS"
export const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR"

export const FILTER_TASK_BY_GOAL = "FILTER_TASK_BY_GOAL"

export const CREATE_NOTIFICATION_BEGIN = "CREATE_NOTIFICATION_BEGIN"
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS"
export const CREATE_NOTIFICATION_ERROR = "CREATE_NOTIFICATION_ERROR"

export const EDIT_PROFILE_BEGIN = "EDIT_PROFILE_BEGIN"
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS"
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR"

export const PROFILE_PIC_UPDATE = "PROFILE_PIC_UPDATE"

export const GET_USERS_LIST_BEGIN = "GET_USERS_LIST_BEGIN"
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS"
export const GET_ALL_USERS_BEGIN = "GET_ALL_USERS_BEGIN"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR"

export const DAILY_EMAIL_NOTIFICATION_BEGIN = "DAILY_EMAIL_NOTIFICATION_BEGIN"
export const DAILY_EMAIL_NOTIFICATION_SUCCESS =
  "DAILY_EMAIL_NOTIFICATION_SUCCESS"

export const GET_DAILY_EMAIL_NOTIFICATION_BEGIN =
  "GET_DAILY_EMAIL_NOTIFICATION_BEGIN"
export const GET_DAILY_EMAIL_NOTIFICATION_SUCCESS =
  "GET_DAILY_EMAIL_NOTIFICATION_SUCCESS"

export const DELETE_SINGLE_USER_ROLE = "DELETE_SINGLE_USER_ROLE"

export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS"
export const DRAG_CATEGORY = "DRAG_CATEGORY"

export const GET_ALL_QUESTIONS_BEGIN = "GET_ALL_QUESTIONS_BEGIN"
export const GET_ALL_QUESTIONS_SUCCESS = "GET_ALL_QUESTIONS_SUCCESS"
export const GET_ALL_QUESTIONS_ERROR = "GET_ALL_QUESTIONS_ERROR"

export const ADD_QUESTION_BEGIN = "ADD_QUESTION_BEGIN"
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS"
export const ADD_QUESTION_ERROR = "ADD_QUESTION_ERROR"
export const GET_ALL_FEEDBACK_BEGIN = "GET_ALL_FEEDBACK_BEGIN"
export const GET_ALL_FEEDBACK_SUCCESS = "GET_ALL_FEEDBACK_SUCCESS"
export const GET_ALL_FEEDBACK_ERROR = "GET_ALL_FEEDBACK_ERROR"

export const GET_ALL_REVIEW_BEGIN = "GET_ALL_REVIEW_BEGIN"
export const GET_ALL_REVIEW_SUCCESS = "GET_ALL_REVIEW_SUCCESS"
export const GET_ALL_REVIEW_ERROR = "GET_ALL_REVIEW_ERROR"

export const GET_ALL_REVIEWSUB_BEGIN = "GET_ALL_REVIEWSUB_BEGIN"
export const GET_ALL_REVIEWSUB_SUCCESS = "GET_ALL_REVIEWSUB_SUCCESS"
export const GET_ALL_REVIEWSUB_ERROR = "GET_ALL_REVIEWSUB_ERROR"

export const GET_ALL_FEEDREPORT_BEGIN = "GET_ALL_FEEDREPORT_BEGIN"
export const GET_ALL_FEEDREPORT_SUCCESS = "GET_ALL_FEEDREPORT_SUCCESS"
export const GET_ALL_FEEDREPORT_ERROR = "GET_ALL_FEEDREPORT_ERROR"

export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS"
export const ADD_REVIEW_BEGIN = "ADD_REVIEW_BEGIN"
export const ADD_REVIEW_ERROR = "ADD_REVIEW_ERROR"

export const SET_EDIT_QUESTION = "SET_EDIT_QUESTION"

export const EDIT_QUESTION_BEGIN = "EDIT_QUESTION_BEGIN"
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS"
export const EDIT_QUESTION_ERROR = "EDIT_QUESTION_ERROR"

export const SET_EDIT_FEEDBACK = "SET_EDIT_FEEDBACK"

export const EDIT_FEEDBACK_BEGIN = "EDIT_FEEDBACK_BEGIN"
export const EDIT_FEEDBACK_SUCCESS = "EDIT_FEEDBACK_SUCCESS"
export const EDIT_FEEDBACK_ERROR = "EDIT_FEEDBACK_ERROR"

export const SET_EDIT_FEEDBACK_TEXT = "SET_EDIT_FEEDBACK_TEXT"
export const EDIT_FEEDBACKTEXT_BEGIN = "EDIT_FEEDBACKTEXT_BEGIN"
export const EDIT_FEEDBACKTEXT_SUCCESS = "EDIT_FEEDBACKTEXT_SUCCESS"
export const EDIT_FEEDBACKTEXT_ERROR = "EDIT_FEEDBACKTEXT_ERROR"

export const OPEN_CLIENTTEXT_MODAL = "OPEN_CLIENTTEXT_MODAL"
export const OPEN_CLIENTCUSTOM_MODAL = "OPEN_CLIENTCUSTOM_MODAL"
export const OPEN_CLIENTSUB_MODAL = "OPEN_CLIENTSUB_MODAL"
export const OPEN_CLIENTMANUAL_MODAL = "OPEN_CLIENTMANUAL_MODAL"

export const GET_ALL_DETAILQUESTION_BEGIN = "GET_ALL_DETAILQUESTION_BEGIN"
export const GET_ALL_DETAILQUESTION_SUCCESS = "GET_ALL_DETAILQUESTION_SUCCESS"
export const GET_ALL_DETAILQUESTION_ERROR = "GET_ALL_DETAILQUESTION_ERROR"

export const GET_ALL_REVIEWREPORT_BEGIN = "GET_ALL_REVIEWREPORT_BEGIN"
export const GET_ALL_REVIEWREPORT_SUCCESS = "GET_ALL_REVIEWREPORT_SUCCESS"
export const GET_ALL_REVIEWREPORT_ERROR = "GET_ALL_REVIEWREPORT_ERROR"

export const GET_MY_REVIEWREPORT_BEGIN = "GET_MY_REVIEWREPORT_BEGIN"
export const GET_MY_REVIEWREPORT_SUCCESS = "GET_MY_REVIEWREPORT_SUCCESS"
export const GET_MY_REVIEWREPORT_ERROR = "GET_MY_REVIEWREPORT_ERROR"

export const PERMISSION_SINGLE_FEEDBACK = "PERMISSION_SINGLE_FEEDBACK"
export const PERMISSION_ALL_FEEDBACK = "PERMISSION_ALL_FEEDBACK"
