import React, { useState, useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
import jsImage from "../../assets/images/JavaScript-logo.png"
import txtImage from "../../assets/images/text.png"
import docImage from "../../assets/images/doc-icon.png"
import zipImage from "../../assets/images/zip.png"
import cssImage from "../../assets/images/css.png"
import htmlImage from "../../assets/images/html.jpg"
import jsonImage from "../../assets/images/json.png"
import phpImage from "../../assets/images/json.png"
import tsImage from "../../assets/images/json.png"
import fileImage from "../../assets/images/file.png"
import videoImage from "../../assets/images/video.png"
import AttachmentLoading from "./AttachmentLoading"
import { useSelector } from "react-redux"
import { useAppContext } from "../../context/context/appContext"
import { ImageGridWrapper } from "../../assets/styles/Common"
import {
  CloseOutlinedIcon,
  VerticalAlignBottomOutlinedIcon,
} from "../../assets/icons"
import {
  deleteResource,
  downloadResource,
  getResource,
} from "../../utils/s3Resources"

const Image = ({ image, worklog }) => {
  const { editingWorklog } = useSelector((state) => state.worklog)
  const { editingTask } = useSelector((state) => state.task)
  const temp = image.split(")")
  const name = temp[1]

  //console.log(image)
  // const handleDownload = async (url) => {
  //   console.log(url)
  //   const response = await getResource(url)
  //   const blob = new Blob([response.data], { type: "image/jpeg" })
  //   const imageUrl = URL.createObjectURL(blob)
  //   console.log(imageUrl)
  //   return imageUrl
  // }

  const getExtension = (key) => {
    const extension = key.split(".")
    if (extension[1] === "js") {
      return "js"
    } else if (
      extension[1] === "png" ||
      extension[1] === "jpg" ||
      extension[1] === "jpeg" ||
      extension[1] === "gif" ||
      extension[1] === "svg"
    ) {
      return "image"
      // return { id: key, src: imageUrl, url: key }
    } else if (extension[1] === "txt") {
      return "txt"
    } else if (extension[1] === "zip") {
      return "zip"
    } else if (extension[1] === "doc" || extension[1] === "docx") {
      return "doc"
    } else if (extension[1] === "html") {
      return "html"
    } else if (extension[1] === "css") {
      return "css"
    } else if (extension[1] === "json") {
      return "json"
    } else if (extension[1] === "ts" || extension[1] === "tsx") {
      return "ts"
    } else if (extension[1] === "php") {
      return "php"
    } else if (
      extension[1] === "mp4" ||
      extension[1] === "mkv" ||
      extension[1] === "mov" ||
      extension[1] === "wmv" ||
      extension[1] === "flv" ||
      extension[1] === "avi"
    ) {
      return "movie"
    } else {
      return "file"
    }
  }

  const fetchAttachment = (key) => {
    const extension = key.split(".")
    if (extension[1] === "js") {
      return jsImage
    } else if (extension[1] === "txt") {
      return txtImage
    } else if (extension[1] === "zip") {
      return zipImage
    } else if (extension[1] === "doc" || extension[1] === "docx") {
      return docImage
    } else if (extension[1] === "html") {
      return htmlImage
    } else if (extension[1] === "css") {
      return cssImage
    } else if (extension[1] === "json") {
      return jsonImage
    } else if (extension[1] === "ts" || extension[1] === "tsx") {
      return tsImage
    } else if (extension[1] === "php") {
      return phpImage
    } else if (
      extension[1] === "mp4" ||
      extension[1] === "mkv" ||
      extension[1] === "mov" ||
      extension[1] === "wmv" ||
      extension[1] === "flv" ||
      extension[1] === "avi"
    ) {
      return videoImage
    } else {
      return fileImage
    }
  }

  return (
    <div className="file-item">
      <div className="title">
        {getExtension(image) === "image" ? (
          <AttachmentLoading test={image} />
        ) : (
          <img src={fetchAttachment(image)} alt="image" className="file-img" />
        )}

        <div className="button">
          <CloseOutlinedIcon
            onClick={() => {
              worklog
                ? deleteResource({ Key: image, id: editingWorklog._id })
                : deleteResource({ Key: image, id: editingTask._id })
            }}
          />
        </div>
      </div>

      <p>
        <span> {name} </span>
        <VerticalAlignBottomOutlinedIcon
          style={{ color: "var(--blue)" }}
          onClick={() => downloadResource(image)}
        />
      </p>
    </div>
  )
}

const ImageGrid = ({ Attachments, worklog, task }) => {
  const [attachments, setAttachments] = useState([])
  const { socket } = useAppContext()

  useEffect(() => {
    setAttachments(Attachments)
  }, [])
  useEffect(() => {
    socket.on("worklogAttachment", (data) => {
      //  console.log(data)
      if (worklog) {
        setAttachments(data)
      }
    })

    socket.on("taskAttachment", (data) => {
      if (task) {
        setAttachments(data)
      }
    })
  }, [socket])
  return (
    <ImageGridWrapper>
      {attachments ? (
        <div
          className="file-list mb-4"
          style={{
            overflowX: attachments.length > 2 && "scroll",
          }}
        >
          {attachments.map((item, index) => {
            return <Image image={item} key={item.id} worklog={worklog} />
          })}
        </div>
      ) : (
        <RotatingLines
          strokeColor="grey"
          strokeWidth="3"
          animationDuration="0.75"
          width="40"
          visible={true}
        />
      )}
    </ImageGridWrapper>
  )
}

export default ImageGrid
