import { useAppContext } from "../../context/context/appContext"
import Tabs, { Tab } from "react-best-tabs"
import "react-best-tabs/dist/index.css"
import { Loading2 } from "../../components/Common"
import {
  DailyLog,
  WeeklyLog,
  UserLogs,
} from "../../components/Reports/UserReport"
import { TabsWrapper } from "../../assets/styles/Dashboard"

const Reports = () => {
  const { user } = useAppContext()

  if (!user) {
    return <Loading2 />
  }
  return (
    <TabsWrapper>
      <h5 className="card-title  lg:tw-p-8 lg:tw-pb-4  tw-p-3  tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">Reports</h5>
      <Tabs
        activeTab="1"
        className="tabss page-button-section"
        ulClassName=""
        activityClassName="bg-success"
      // onClick={(event, tab) => console.log(event, tab)}
      >
        <Tab title="My Logs" className="mr-3">
          <UserLogs />
        </Tab>
        <Tab title="Daily Log" className="mr-3">
          <DailyLog />
        </Tab>
        <Tab title="Weekly Log" className="mr-3">
          <WeeklyLog />
        </Tab>
      </Tabs>
    </TabsWrapper>
  )
}

export default Reports
