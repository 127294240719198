import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FormRow } from "../Common"
import Modal from "react-bootstrap/Modal"
import { useDispatch, useSelector } from "react-redux"
import {
  getSubjectiveQuestion,
  createFeedback,
  updateQuestion,
} from "../../redux"
import { capitalizeFirstLetter } from "../../utils/getInitialName"

const initialState = {
  answer: "",
}
function SubjectiveModal() {
  const dispatch = useDispatch()
  const [values, setValues] = useState(initialState)
  const [question, setQuestion] = useState(null)

  const { isSubjectiveModalOpen, editingQuestion, isEditingQuestion } =
    useSelector((state) => state.feedback)

  useEffect(() => {
    !editingQuestion &&
      dispatch(getSubjectiveQuestion()).then((data) => {
        setQuestion(data)
      })

    if (editingQuestion) {
      console.log(editingQuestion)
      setQuestion(editingQuestion)
    }
  }, [editingQuestion])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(values)
    if (isEditingQuestion) {
      dispatch(
        updateQuestion({
          question: values.answer,
          type: editingQuestion.type,
          options: [],
          _id: editingQuestion._id,
        })
      )
    } else {
      dispatch(
        createFeedback({ answer: values.answer, question: question._id })
      )
    }
    setValues(initialState)
  }
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const closeModal = () => {}

  return (
    <Wrapper>
      <Modal
        show={isSubjectiveModalOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditingQuestion ? "edit question" : "subjective question"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {question && (
            <div>
              <p>{capitalizeFirstLetter(question.question)}</p>
              <FormRow
                name="answer"
                type="textarea"
                labelText={isEditingQuestion ? "enter new question" : "answer"}
                value={values.answer}
                handleChange={handleChange}
                required={true}
                // placeholder={"Add answer"}
              />
              <button className="btn" onClick={handleSubmit}>
                submit
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}
const Wrapper = styled.div``
export default SubjectiveModal
