import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import Modal from "react-bootstrap/Modal"
import { useAppContext } from "../../context/context/appContext"
import { FormRow, FormRowSelect } from "../Common"
import { useSelector, useDispatch } from "react-redux"
import {
  closeProjectModal,
  createProject,
  openProjectModal,
  getAllCompany,
} from "../../redux"

const initialState = {
  title: "",
  description: "",
  createdBy: "",
  type: "scrum",
  client: "",
}

const ProjectModal = () => {
  const { user } = useAppContext()
  const { isProjectModalOpen } = useSelector((state) => state.project)
  const dispatch = useDispatch()
  const [values, setValues] = useState(initialState)
  const [allCompany, setAllCompany] = useState(null)

  useEffect(() => {
    dispatch(getAllCompany()).then((data) => setAllCompany(data))
  }, [openProjectModal])

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      createdBy: user._id,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (values.client === "" || values.client === "select") {
      toast.error("select client")
      return
    }
    await setValues({ ...values, createdBy: user._id })
    dispatch(createProject(values))
  }

  return (
    <Modal
      show={isProjectModalOpen}
      onHide={() => dispatch(closeProjectModal())}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="tw-flex tw-flex-col tw-gap-2" onSubmit={handleSubmit}>
          <FormRow
            name="title"
            type="text"
            labelText="Title"
            value={values.title}
            handleChange={handleChange}
            required={true}
            placeholder="Enter Title"
          /> 
          <FormRow
            name="description"
            type="text"
            labelText="Description"
            value={values.description}
            handleChange={handleChange}
            required={true}
            placeholder="Enter Description"
          />
 
          {allCompany && allCompany.length !== 0 && (
            <FormRowSelect
              labelText="client"
              type="text"
              name="client"
              value={values.client}
              list={["select", ...allCompany.map((i) => i.company_name)]}
              handleChange={handleChange}
            />
          )}
          <div className="textRight">
            <button className="btn add-btn" type="submit" disabled={false}>
              submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ProjectModal
