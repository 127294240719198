import authFetch from "../../../utils/interceptor";
// testItemsActions.js
import { toast } from "react-toastify";
import { 
        OPEN_TEST_ITEM_MODAL,
        CLOSE_TEST_ITEM_MODAL,
        GET_ALL_TEST_ITEMS_BEGIN,
        GET_ALL_TEST_ITEMS_SUCCESS,
        GET_ALL_TEST_ITEMS_ERROR,
        GET_TEST_ITEM_BEGIN,
        GET_TEST_ITEM_SUCCESS,
        GET_TEST_ITEM_ERROR,
        CREATE_TEST_ITEM_BEGIN,
        CREATE_TEST_ITEM_SUCCESS,
        CREATE_TEST_ITEM_ERROR,
        EDIT_TEST_ITEM_BEGIN,
        EDIT_TEST_ITEM_SUCCESS,
        EDIT_TEST_ITEM_ERROR,
        DELETE_TEST_ITEM_BEGIN,
        DELETE_TEST_ITEM_SUCCESS,
        DELETE_TEST_ITEM_ERROR,
        DELETE_TEST_ITEM_ATTACHMENT_BEGIN,
        DELETE_TEST_ITEM_ATTACHMENT_SUCCESS,
        DELETE_TEST_ITEM_ATTACHMENT_ERROR,
} from "./testItemTypes";


// Open and Close Modal
export const openTestItemModal = () => ({ type: OPEN_TEST_ITEM_MODAL });
export const closeTestItemModal = () => ({ type: CLOSE_TEST_ITEM_MODAL });

// Get All Test Items
export const getAllTestItems = (templateId) => async (dispatch) => {
  dispatch({ type: GET_ALL_TEST_ITEMS_BEGIN });
  try {
    const response = await authFetch.get(`/testTemplateItem/${templateId}`);
    dispatch({ type: GET_ALL_TEST_ITEMS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_TEST_ITEMS_ERROR, payload: error.message });
    // toast.error('Error fetching test items');
  }
};

// Get Test Item by ID
export const getTestItem = (templateId, itemId) => async (dispatch) => {
  dispatch({ type: GET_TEST_ITEM_BEGIN });
  try {
    const response = await authFetch.get(`/testTemplateItem/${templateId}/${itemId}`);
    dispatch({ type: GET_TEST_ITEM_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_TEST_ITEM_ERROR, payload: error.message });
    // toast.error('Error fetching test item');
  }
};

// Create Test Item
export const createTestItem = (templateId, item) => async (dispatch) => {
  dispatch({ type: CREATE_TEST_ITEM_BEGIN });
  try {
    await authFetch.post(`/testTemplateItem/${templateId}`, item, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: CREATE_TEST_ITEM_SUCCESS });
    dispatch(getAllTestItems(templateId));
    toast.success('Test item created successfully');
  } catch (error) {
    dispatch({ type: CREATE_TEST_ITEM_ERROR, payload: error.message });
    toast.error('Error creating test item');
  }
};

// Edit Test Item
export const editTestItem = (templateId, itemId, item) => async (dispatch) => {
  dispatch({ type: EDIT_TEST_ITEM_BEGIN });
  try {
    await authFetch.patch(`/testTemplateItem/${templateId}/${itemId}`, item);  // Note the patch method
    dispatch(getAllTestItems(templateId));
    toast.success('Test item updated successfully');
    dispatch({ type: EDIT_TEST_ITEM_SUCCESS });
  } catch (error) {
    dispatch({ type: EDIT_TEST_ITEM_ERROR, payload: error.message });
    // toast.error('Error updating test item');
  }
};

// Delete Test Item
export const deleteTestItem = (templateId, itemId) => async (dispatch) => {
  dispatch({ type: DELETE_TEST_ITEM_BEGIN });
  try {
    await authFetch.delete(`/testTemplateItem/${templateId}/${itemId}`);
    dispatch({ type: DELETE_TEST_ITEM_SUCCESS });
    dispatch(getAllTestItems(templateId));
    toast.success('Test item deleted successfully');
  } catch (error) {
    dispatch({ type: DELETE_TEST_ITEM_ERROR, payload: error.message });
    toast.error('Error deleting test item');
  }
};

// Delete Test Item Attachment
export const deleteTestItemAttachment = (templateId, itemId, attachmentKey) => async (dispatch) => {
  dispatch({ type: DELETE_TEST_ITEM_ATTACHMENT_BEGIN });
  try {
    await authFetch.delete(`/testTemplateItem/${attachmentKey}`);
    dispatch({ type: DELETE_TEST_ITEM_ATTACHMENT_SUCCESS });
    dispatch(getAllTestItems(templateId)); // Fetch updated test item after deletion
    toast.success('Attachment deleted successfully');
  } catch (error) {
    dispatch({ type: DELETE_TEST_ITEM_ATTACHMENT_ERROR, payload: error.message });
    toast.error('Error deleting attachment');
  }
};
