import { Navigate } from "react-router-dom"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { useAppContext } from "../context/context/appContext"
import Loading from "../components/Common/Loading"
const ProtectedRoute = ({ children }) => {
  const refreshToken = Cookies.get("refreshToken")
  const { getNewAccessToken } = useAppContext()
  const [showLoading, setLoading] = useState(false)

  const getNewToken = async () => {
    setLoading(true)
    await getNewAccessToken()
    setLoading(false)
  }
  useEffect(() => {
    getNewToken()
  }, [])

  if (showLoading) {
    return <Loading />
  }
  if (!refreshToken) {
    return <Navigate to="/signin" />
  }
  return children
}

export default ProtectedRoute
