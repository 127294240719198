import {
  GET_ALL_CLIENTS_BEGIN,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_ERROR,
  ADD_CLIENT_BEGIN,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_ERROR,
  OPEN_CLIENT_MODAL,
  CLOSE_CLIENT_MODAL,
  SET_EDITING_CLIENT,
  EDIT_CLIENT_BEGIN,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_ERROR,
  OPEN_ADD_USER_TO_CLIENT,
  CLOSE_ADD_USER_TO_CLIENT,
  OPEN_USER_LIST_MODAL,
  CLOSE_USER_LIST_MODAL,
  GET_CLIENT_USERS_BEGIN,
  GET_CLIENT_USERS_SUCCESS,
  GET_CLIENT_USERS_ERROR,
  ADD_USER_TO_CLIENT_BEGIN,
  ADD_USER_TO_CLIENT_SUCCESS,
  ADD_USER_TO_CLIENT_ERROR,
  SET_EDITING_CLIENT_USER,
} from "./clientTypes"

const initialState = {
  loading: false,
  clients: [],
  isEditingClient: false,
  editingClient: null,
  isClientModalOpen: false,
  isAddClientUserModalOpen: false,
  clientUsers: [],
  isUserListModalOpen: false,
  client: null,
  isEditingClientUser: false,
  editingClientUser: null,
}

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CLIENT_MODAL:
      return { ...state, isClientModalOpen: true }
    case CLOSE_CLIENT_MODAL:
      return {
        ...state,
        isClientModalOpen: false,

        editingClient: null,
        isEditingClient: false,
      }
    case ADD_CLIENT_BEGIN:
      return { ...state, loading: true }
    case ADD_CLIENT_SUCCESS:
      const clientList = [...state.clients.result, action.payload.client]
      const updatedList = { ...state.clients, result: clientList }
      return {
        ...state,
        loading: false,
        clients: updatedList,
        isClientModalOpen: false,
        editingClient: null,
        isEditingClient: false,
      }
    case ADD_CLIENT_ERROR:
      return { ...state, loading: false }
    case SET_EDITING_CLIENT:
      const clientToEdit = state.clients.result.find(
        (i) => i._id === action.payload.id
      )
      return {
        ...state,
        isClientModalOpen: true,
        editingClient: clientToEdit,
        isEditingClient: true,
      }
    case GET_ALL_CLIENTS_BEGIN:
      return { ...state, loading: true }
    case GET_ALL_CLIENTS_SUCCESS:
      return { ...state, loading: false, clients: action.payload.clients }
    case GET_ALL_CLIENTS_ERROR:
      return { ...state, loading: false }
    case EDIT_CLIENT_BEGIN:
      return { ...state }
    case EDIT_CLIENT_SUCCESS:
      const filteredClient = state.clients.result.map((i) => {
        if (i._id === action.payload.updatedClient._id) {
          return action.payload.updatedClient
        } else {
          return i
        }
      })
      const updatedclients = { ...state.clients, result: filteredClient }
      return {
        ...state,
        clients: updatedclients,
        editingClient: null,
        isEditingClient: false,
        isClientModalOpen: false,
      }
    case EDIT_CLIENT_ERROR:
      return { ...state }
    case OPEN_ADD_USER_TO_CLIENT:
      return { ...state, isAddClientUserModalOpen: true }
    case CLOSE_ADD_USER_TO_CLIENT:
      return {
        ...state,
        isAddClientUserModalOpen: false,
        isEditingClientUser: false,
        editingClientUser: null,
      }
    case OPEN_USER_LIST_MODAL:
      const client = state.clients.result.find(
        (i) => i._id === action.payload.id
      )
      return { ...state, isUserListModalOpen: true, client }
    case CLOSE_USER_LIST_MODAL:
      return { ...state, isUserListModalOpen: false, client: null }
    case GET_CLIENT_USERS_BEGIN:
      return { ...state }
    case GET_CLIENT_USERS_SUCCESS:
      return { ...state, clientUsers: action.payload.users }
    case GET_CLIENT_USERS_ERROR:
      return { ...state }
    case ADD_USER_TO_CLIENT_BEGIN:
      return { ...state }
    case ADD_USER_TO_CLIENT_SUCCESS:
      return {
        ...state,
        clientUsers: [...state.clientUsers, action.payload.user],
        isAddClientUserModalOpen: false,
      }
    case ADD_USER_TO_CLIENT_ERROR:
      return { ...state }
    case SET_EDITING_CLIENT_USER:
      const user = state.clientUsers.find((i) => i._id === action.payload.id)
      return {
        ...state,
        isEditingClientUser: true,
        editingClientUser: user,
        isAddClientUserModalOpen: true,
      }
    default:
      return { ...state }
  }
}

export default clientReducer
