import React, { useState } from "react"
import styled from "styled-components" 
import { useAppContext } from "../../../context/context/appContext" 
import { FormRowSelect } from "../../Common"
const initialState = {
  filter: "",
}
const TaskFilter = () => {
  const [dropDown, setDropDown] = useState(false)
  const [values, setValues] = useState(initialState)
  const { getTasksUser, user } = useAppContext()

  return (
    <Wrapper>
      <button onClick={() => setDropDown(!dropDown)}>filter</button>
      <div className={dropDown ? "dropdown show-dropdown" : "dropdown"}>
        <h5 onClick={() => getTasksUser(user._id)}>Assigned to me</h5>
        <br />
        <FormRowSelect
          name="priority"
          list={["high", "low", "medium", "highest", "lowest"]}
          value={values.filter}
          handleChange={(e) => setValues({ ...values, filter: e.target.value })}
        />
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  .dropdown {
    position: absolute;
    /* top: 45px; */
    /* left: 50px; */
    /* right: 0px; */
    width: auto;
    background: white;
    box-shadow: var(--shadow-5);
    padding: 0.5rem;
    text-align: center;
    visibility: hidden;
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-2);
    z-index: 4;
    border: 1px solid #dce8eb;
    transition: var(--transition);
  }
  .show-dropdown {
    visibility: visible;
    transition: var(--transition);
  }
  .dropdown-btn {
    width: 100%;
    padding: 4px;
    background: transparent;
    border-color: transparent;
    color: rgb(72 84 84);
    // font-weight: bold;
    margin-bottom: 4px;
    letter-spacing: var(--letterSpacing);
    // text-transform: capitalize;
    cursor: pointer;
    box-shadow: none;
    text-align: left;
  }
  h5 {
    cursor: pointer;
  }
`
export default TaskFilter
