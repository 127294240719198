import React from "react"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip } from "react-tooltip"
import { TooltipWrapper } from "../../assets/styles/Common"

function CustumTooltip({ id, text, children, position }) {
  return (
    <TooltipWrapper>
      <a className={id}>{children}</a>
      <Tooltip anchorSelect={`.${id}`} content={text} place={position} />
    </TooltipWrapper>
  )
}
export default CustumTooltip
