import React from "react"
import { AllClients, ClientModal } from "../../components/Clients"

const Clients = () => {
  return (
    <>
      <ClientModal />

      <div className="all-clients   ">
        <AllClients />
      </div>
    </>
  )
}

export default Clients
