// testcaseTypes.js
export const CREATE_TEMPLATE_BEGIN = 'CREATE_TEMPLATE_BEGIN';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_ERROR = 'CREATE_TEMPLATE_ERROR';

export const GET_ALL_TEMPLATES_BEGIN = 'GET_ALL_TEMPLATES_BEGIN';
export const GET_ALL_TEMPLATES_SUCCESS = 'GET_ALL_TEMPLATES_SUCCESS';
export const GET_ALL_TEMPLATES_ERROR = 'GET_ALL_TEMPLATES_ERROR';

export const GET_TEMPLATE_BEGIN = 'GET_TEMPLATE_BEGIN';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR';

export const EDIT_TEMPLATE_BEGIN = 'EDIT_TEMPLATE_BEGIN';
export const EDIT_TEMPLATE_SUCCESS = 'EDIT_TEMPLATE_SUCCESS';
export const EDIT_TEMPLATE_ERROR = 'EDIT_TEMPLATE_ERROR';

export const DELETE_TEMPLATE_BEGIN = 'DELETE_TEMPLATE_BEGIN';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';

export const OPEN_TEMPLATE_MODAL = 'OPEN_TEMPLATE_MODAL';
export const CLOSE_TEMPLATE_MODAL = 'CLOSE_TEMPLATE_MODAL';

export const SET_EDIT_TEMPLATE = 'SET_EDIT_TEMPLATE';
export const EDIT_MODAL_OPEN = 'EDIT_MODAL_OPEN';
export const EDIT_MODAL_CLOSE = 'EDIT_MODAL_CLOSE';
