import styled from "styled-components"

export const MemberProjectTitle = styled.div`
  p {
    display: flex;
    margin: 0;

    img {
      width: 40px;
      height: 40px;

      border: 1px solid lightgrey;
      border-radius: 5px;
      margin-left: 0px;
    }
    .title {
      margin-left: 15px;
      font-weight: 400;
      color: var(--primary-500-active);
    }
  }
`
export const MemberWrapper = styled.div`
  form {
    display: flex;
  }
  button {
    background: var(--primary-500-active);
    color: #fff;
    border-radius: 5px;
    height: 35px;
    width: 8%;
    font-weight: 600;
    margin: 31px 12px;
    @media (max-width: 500px) {
      margin: 27px 12px;
      width: auto;
    }
  }
  .temp {
    margin-top: -2px;
    font-weight: 500 !important;
  }
  .new-member {
    font-size: var(--font-12);
    text-transform: lowercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .title {
    display: flex;
    align-items: center;
    /* font-family: "Stylish", sans-serif; */
    font-size: var(--font-12) !important;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
      box-shadow: var(--shadow-1);
      border: 1px solid lightgrey;
      object-fit: contain;
    }
  }
  .btn-blockn {
    background: var(--primary-500-active);
    width: 70px;
    color: var(--white);
    font-size: var(--font-12);
    font-weight: 600;
    padding: 6px 10px;

    margin: 0px 10px 0px 0px;
  }
  .team-list {
    font-size: var(--font-12);
    display: flex;
    flex-wrap: wrap;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid lightgrey;
      margin-right: 10px;
    }
    h6 {
      font-size: var(--font-12);
      margin: 7px 7px;
      box-shadow: var(--shadow-1);
      padding: 10px 18px;
      display: flex;

      align-items: center;
      justify-content: space-between;
      
      @media (max-width: 1224px) {
         
      }
      @media (max-width: 800px) {
        width: 100%;
      }
      span {
        font-size: var(--font-13);
        padding: 0 4px;
        text-transform: capitalize;
      }
      p {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        font-size: var(--font-12);
        text-transform: lowercase;
        
      }
      .delete{
        &:hover{
             background-color: rgb(242 251 253) !important;
              outline: none !important;
              box-shadow: 0 0 5px 0 #efefef79;
        }
      }
      button {
        margin: 0px;
        font-size: var(--font-12);
        height: 22px;
      }
      .emll {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: lowercase;
        font-size: var(--font-12);
        font-weight: 400 !important;
        clear: both;
        @media (max-width: 1024px) {
          width: 200px;
        }
        .esll {
          font-size: var(--font-12);
          clear: both;
          text-transform: capitalize;
        }
      }
    }
    svg {
      cursor: pointer;
      font-size: 16px;
    }
  }
  .prjctd {
    p {
      font-size: var(--font-12) !important;
    }
    h5 {
      font-size: var(--font-14) !important;
    }
    ul li {
      font-size: var(--font-12) !important;
    }
  }
  h5 {
    font-size: var(--font-12) !important;
    font-weight: 500;
    display: flex;
    // justify-content: space-between;
    svg {
      margin-left: 10px;
      color: var(--grey-500);
      font-size: 15px;
    }
    i:first-child {
      color: var(--primary-500);
    }
  }
  p {
    font-size: var(--font-12);
    margin-left: 2px;
  }
  .ptitle {
    // text-transform: capitalize;
    width: 100%;
    padding: 0.375rem 0.5rem;
    border-radius: var(--borderRadius);
    background-color: rgb(249, 249, 249);
    border: 1px solid rgb(249, 249, 249);
    font-size: var(--font-12);
    svg {
      margin-right: 10px;
    }
  }
  ul li {
    font-size: var(--font-12);
  }
  /* .card {
    background: aliceblue;
    padding: 25px;
    box-shadow: var(--shadow-1);
    border: none;
  } */
  .displayDate {
    position: absolute;
    right: 0;

    margin: -14px 20px;
    p {
      font-size: var(--font-12);
      // font-style: italic;
      color: var(--primary-500);
      margin-right: 15px;
    }
  }
  .project-tlte {
    position: sticky;
    top: 0px;
    background: aliceblue;
    display: flex;
    padding: 12px 30px 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    z-index: 1;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom: 1px solid var(--grey-50);
    p {
      font-size: 22px;
      display: flex;
      color: var(--primary-500);
      margin: 0 0 6px;

      img {
        border: 1px solid lightgrey;
        margin-right: 10px;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .ql-editor {
    min-height: 180px;
    max-height: 250px;
    overflow-y: scroll;
  }

  .ql-picker,
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: 1px solid var(--grey-200);
    font-size: var(--font-12);
  }

  .ql-snow .ql-editor img {
    max-width: 100%;
    width: 40px;
    margin: 2px;
    object-fit: contain;
    height: 40px;
  }
  .add-member {
    input {
      height: 34px;
      width: 25%;
      padding: 0.375rem 0.75rem;
      border-radius: var(--borderRadius);
      background: var(--white);
      border: 1px solid var(--grey-200);
    }
    @media (max-width: 1248px) {
      .form-row {
        width: 200px;
      }
    }
    @media (max-width: 600px) {
      .form-row {
        width: 100%;
      }
      input {
        width: 60%;
      }
      @media (max-width: 400px) {
        .form-row {
          width: 150px;
        }
      }
    }
  }
`
export const GoalWrapper = styled.div`
  position: absolute;
  background: #fff;
  right: 0;
  width: 300px;
  top: 0px;
  z-index: 1000;
  padding: 20px;
  box-shadow: var(--shadow-1);
  height: 100%;
  //background: #97ed26;
  .goal-content {
    height: 540px;
    overflow-y: scroll;
  }
  h6 {
    cursor: pointer;
    text-transform: capitalize;
    font-size: 10px;
    text-align: right;
  }
  .goal-heading {
    p {
      margin: 0;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      .date-group {
        time {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    svg {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
    }
  }
  .textRight {
    margin: 0 0 10px 0;
  }
  .borde {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }
  .date-group {
    font-size: 12px;
    display: flex;
    margin-top: 4px;
    p {
      margin-top: 0px;
      font-size: 12px;
    }
    time {
      font-size: 11px;
      font-weight: 400;
    }
  }
  .single-goal {
    margin: 10px 0;
    padding: 10px;
    background: #fdfdfd;
    /* border: 1px solid aliceblue; */
    border-radius: 3px;
    box-shadow: var(--shadow-1);
    p {
      font-size: 13px;
      margin: 0;
    }

    .title {
      display: flex;
      justify-content: space-between;
      input {
        height: 15px;
        width: 15px;
      }
      input:focus {
        outline: none !important;
        border: none !important;
      }
      p {
        // text-transform: capitalize;
        font-weight: 500;
      }
    }
  }

  .progress-bar-rect-wrap-container h2 {
    font-size: 12px !important;
  }
`
export const NotificationWrapper = styled.div`
  overflow: auto;
  p {
    margin: 0;
  }

  .check-Form {
    input {
      height: 14px;
      width: 14px;
      text-align: center;
    }
    input:focus {
      outline: none !important;
      border: none !important;
    }
    button {
      background: var(--primary-300);
      color: rgb(255, 255, 255);
      border-radius: 5px;

      width: 78%;
      font-weight: 600;
      margin: 0px 0px 0px 15px;
      height: 30px;
      font-size: var(--font-12);
      padding: 0px;
      margin-top: 6px;
      text-transform: lowercase;
    }
    h2 {
      font-size: var(--font-12);
      font-weight: 400;
      margin-top: 6px;
      text-transform: lowercase;
    }
  }
  .heading {
    display: flex;
    justify-content: flex-end;
    //margin-left: 500px;
  }
  h2 {
    font-size: var(--font-14);
    font-weight: 500;
    margin-top: 20px;
    svg {
      margin-top: 0px;
      margin-right: 10px;
    }
  }

  .heading {
    h2 {
      font-size: var(--font-14);
      margin-left: 20px;
      margin-right: 19px;
    }
  }

  .caption-row {
    background-color: var(--grey-50) !important;
  }
  b {
    font-weight: 500 !important;
  }
  input {
    margin-top: 5px;
  }
`
export const DetailWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(235, 247, 245, 0.8);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 999;
  /* opacity: 0; */
  transition: var(--transition);

  .project-details {
    top: 50%;
    position: absolute;
    left: 50%;
    width: 70vw;
    transform: translate(-50%, -50%);
    //  background: rgb(255, 255, 255);
    //padding: 50px 35px;
    //box-shadow: var(--shadow-1);
    height: 550px;

    border-radius: 1rem;
    @media (max-width: 1280px) {
      height: 500px;
      width: 90vw;
    }
    @media (max-width: 1024px) {
      height: 500px;
      width: 90vw;
    }

    .row {
      margin: 3rem 1rem;
      overflow-y: scroll;
      height: 400px;
    }
  }

  .close {
    width: 25px;
    height: 25px;

    cursor: pointer;

    svg {
      font-size: 18px;
      color: var(--grey-500);
      /* margin: -13px -13px;
      margin-bottom: 20px; */
      @media (max-width: 992px) {
        font-size: 20px;
      }
    }
  }

  .modal {
    width: 90% !important;
    margin: 15px auto;
  }
`
export const TaskWrapper = styled.div`
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(235, 247, 245, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: var(--transition);
    z-index: -1;
  }
  .asstm {
    font-size: var(--font-12);
  }

  .lowest-priority .icon {
    color: blue;
  }
  
  .low-priority .icon {
    color: green;
  }
  
  .medium-priority .icon {
    color: orange;
  }
  
  .high-priority .icon {
    color: red;
  }
  
  .highest-priority .icon {
    color: purple;
  }

  .lowerCase select {
    text-transform: lowercase !important;
  }
  .placeTaken {
    width: 100%;
    padding: 10px 10px 10px 12px;
    border-radius: var(--borderRadius);
    // background-color: rgb(249, 249, 249);
    border: 1px solid var(--grey-200);
    font-size: var(--font-12);
    display: flex;
    p {
      font-size: var(--font-12) !important;
      margin: 0;
    }
    svg {
      margin-right: 10px;
      font-size: var(--font-18);
    }
  }
  .Upper {
    svg {
      font-size: 15px;
      color: var(--white);
      margin-left: 0px;
    }
  }
  svg {
    font-size: 16px;
    color: rgb(72 152 179);
    margin-left: 10px;
  }
  .assign {
    width: 100%;
    margin-right: 8px;
    height: 38px;
    border: 1px solid var(--grey-200);
    border-radius: var(--borderRadius);
    padding: 0.2rem 0.75rem;
    display: flex;
    margin-bottom: 9px;

    h5 {
      font-size: var(--font-12);
      margin: 6px 9px;
      text-transform: lowercase;
      /* width: 150px; */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      border: 1px solid lightgrey;
    }
  }
  .modal-header,
  .modal-body {
    border-bottom: 1px solid lightgrey;
  }

  .modal-footer {
    padding: 0px 3rem;
  }

  .ast {
    margin-bottom: 8px;
  }
  .modal-body {
    height: 654px;
    overflow-y: scroll;
    //  padding: 15px;
    overflow-x: hidden;
  }
  .isOpen {
    opacity: 1;
    z-index: 999;
  }

  .modal-header {
    background-color: aliceblue;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
  .modal-content {
    background: white;
    width: 60vw;

    // padding: 3rem;
    border-radius: 1rem;
    position: relative;
    height: 550px;
    .heading {
      display: flex;
      justify-content: center;
      padding: 15px 3rem;
      width: 100%;
      h3 {
        margin: 0;
        padding: 0;
        color: var(--primary-500);
        margin: 5px 0 6px;
        font-size: var(--font-16);
        .task-title {
          font-size: var(--font-18);
          text-align: center;
        }
        @media (max-width: 500px) {
          .task-title {
            display: none;
          }
        }
      }
    }
    @media (max-width: 1580px) {
      height: 500px;
      width: 70vw;
    }
    @media (max-width: 1280px) {
      height: 500px;
      width: 70vw;
    }
    @media (max-width: 1100px) {
      height: 450px;
      width: 70vw;
    }
    @media (max-width: 1024px) {
      height: 450px;
      width: 70vw;
    }
  }
  .btnw {
    color: #fff;
    background-color: var(--primary-500-active);
  }
  .avatard {
    display: flex;
    h5 {
      text-transform: lowercase;
      margin-left: 10px;
      font-size: var(--font-13);
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 8px;
      font-family: "Space Grotesk", sans-serif;
      @media (max-width: 1280px) {
        width: 125px;
      }
      @media (max-width: 1024px) {
        width: 100px;
      }
    }
    img {
      margin-left: -4px !important;
      border: 1px solid lightgrey;
    }
  }

  .modal-content p {
    font-size: 1.5rem;
    text-transform: none;
  }
  .close-icon {
    font-size: var(--font-20);
    color: var(--grey-500);
    position: absolute;
    right: 12px;
    cursor: pointer;
    @media (max-width: 500px) {
      font-size: var(--font-20);
    }
  }
  h3,
  h6 {
    font-weight: 600;
    font-size: var(--font-12) !important;
    letter-spacing: 1px;
  }
  .btn-block {
    background: var(--primary-500-active);
    width: 100px;
    margin-top: 35px;
    margin-bottom: 20px;
    color: var(--white);
    font-size: var(--font-14);
    font-weight: 600;
  }

  .btn-blockn {
    background: var(--primary-500-active);
    width: 70px;
    color: var(--white);
    font-size: var(--font-14);
    font-weight: 600;
    border-radius: 5px;
    margin: 0px 10px 0px 0px;
    padding: 4px;
  }

  .btn-cancel {
    background: var(--primary-500-active);
    width: 100px;
    margin-top: 35px;
    margin-bottom: 20px;
    color: var(--white);
    margin-right: 20px;
    font-size: var(--font-14);
    font-weight: 600;
  }
  .btn-block svg {
    font-size: var(--font-20);
  }

  .dropzone p {
    color: grey !important;
    font-size:14px
  }
  .input-zone {
    width: 100%;
  }
  .ql-editor {
    min-height: 180px;
    max-height: 250px;
    overflow-y: scroll;
  }

  .quill {
    p {
      font-size: var(--font-12);
    }
  }
  .ql-picker,
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: 1px solid var(--grey-200);
    font-size: var(--font-12);
  }

  .ql-snow .ql-editor img {
    max-width: 100%;
    width: 40px;
    margin: 2px;
    object-fit: contain;
    height: 40px;
  }
  .content,
  .status {
    display: flex;
    justify-content: space-between;
  }

  .content .form-row {
    width: 50%;
    margin-right: 12px;
  }

  .status .form-row {
    display: flex;
    width: 50%;
    margin-right: 12px;
  }

  .status .form-input {
    height: 20px;
    width: 20px;
    margin-left: 12px;
  }

  .color {
    box-shadow: var(--shadow-5);
  }
  .bussiness-value {
    // width: 85% !important;
    margin-right: 8px;
    border: 1px solid var(--grey-200);
    border-radius: var(--borderRadius);
    padding: 10px 10px 10px 12px;
  }
  .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
    font-size: var(--font-14);
  }
  .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active {
    color: var(--primary-500-active);
  }

  .bg-success,
  .rb-tabs .rb-tabs-header .rb-tabs-active-item {
    background: var(--primary-500-active) !important;
  }

  .rb-tabs-content {
    margin: 20px 0;
  }

  .rb-tabs-content-item p {
    font-size: var(--font-14);
    margin-top: 20px;
  }

  label {
    font-size: var(--font-12);
    display: flex;
    justify-content: start;
    margin-bottom: 0.2rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);

    i:first-child {
      color: var(--grey-500);
      margin-left: 7px;
      font-size: var(--font-16);
      margin-top: 2px;
    }
  }
  select {
    font-size: var(--font-12);
    // margin-top: 5px;
    margin-bottom: 0.5rem !important;
    border-radius: var(--borderRadius);
    margin-bottom: 12px;
     padding: 10px 10px 10px 12px !important;
     border:1px solid var(--grey-200);
  }

  select[name="status"] {
    text-transform: capitalize;
  }

  select[name="priority"] {
    text-transform: capitalize;
  }

  .btn-groupp {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* margin-bottom: -25px; */
    // margin-top: 20px;

    .btn {
      border-radius: 5px;
      background-color: var(--primary-500-active);
      font-size: var(--font-14);
      font-weight: 500 !important;
      text-align: center;
      color: #fff;
      margin: 0 10px 0 0;
    }
  }
  .tab-content {
    .btn {
      margin-bottom: 10px;
      margin-bottom: 10px;
      /* right: 40px;
      position: absolute; */
    }
    p {
      font-size: var(--font-14);
    }
  }
  .share-icon {
    /* margin-bottom: 35px; */
    // margin-top: 40px;
    svg {
      margin: 8px;

      font-size: var(--font-20);
      margin-left: 20px;
      cursor: pointer;
      color: grey;
    }
    i:first-child {
      color: var(--primary-500);
    }
  }

  textarea {
    width: 100%;
    border: 1px solid var(--grey-200);
    border-radius: var(--borderRadius);
    padding: 0.375rem 0.75rem;
    margin-bottom: 10px;
  }

  .css-13cymwt-control {
    width: 100%;
    border-radius: var(--borderRadius) !important;
    background: var(--white);
    border: 1px solid var(--grey-200) !important;
  }

  .css-qbdosj-Input input:focus {
    outline: none !important;
    border: none !important;
  }
  .css-t3ipsp-control {
    outline: 1px solid #1577b3 !important;
    border: 1px solid #1577b3 !important;
    border-radius: 5px;
    box-shadow: none !important;
  }

  .css-1nmdiq5-menu {
    cursor: pointer !important;
  }

  .assignTo {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 12px;
    border-radius: var(--borderRadius);
    background: var(--white);
    border: 1px solid var(--grey-200);
    cursor: pointer;
    margin-top: 2px;
    height: 40px;
    svg {
      margin-top: 8px;
    }
  }

  .assign {
    img {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 992px) {
    select,
    input {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    .modal-content {
      width: 90vw;
      /* padding: 1rem; */
      height: 540px;
    }

    .modal-content .heading {
      padding: 7px 1rem;
    }
    .task-content {
      padding: 2rem 1rem 0px;
    }
    .share-icon svg {
      font-size: var(--font-14);
    }
    .btn-groupp {
      /* position: relative; */
      right: 0px;
      margin-bottom: 0px;
      width: 100%;
    }

    .share-icon {
      margin-bottom: 0px;
      margin-top: 12px;
    }

    .btn-block,
    .btn-cancel {
      margin-top: 10px;
    }

    .rb-tabs button,
    .btn-groupp button {
      font-size: var(--font-14);
    }
    .cont {
      margin-top: 15px;
    }
  }
`
export const WorklogWrapper = styled.div`
  .single-worklog {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  .img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid lightgrey;
  }
  .table-sc {
    height: 300px;
    overflow-y: scroll;
  }

  .responsiveTable td .tdBefore {
    font-size: 12px !important;
  }

  .edit-btn,
  .del-btn {
    background: none;
    color: var(--primary-500);
    font-weight: 600 !important;
  }
  .del-btn {
    color: red;
  }
  p {
    margin: 0 !important;
    font-size: 12px !important;
  }
  .worklog-description {
    font-size: 12px !important;
    // text-transform: capitalize;
    word-break: break-all;
  }
  .worklog-description img {
    width: 30px;
    height: 30px;
  }
  .action {
    svg {
      cursor: pointer;
      margin: 5px;
      font-size: 18px;
      color: var(--primary-500);
    }
    i:last-child {
      font-size: 20px;
    }
  }
  .date-w {
    width: 100px;
  }

  .profilepics {
    display: flex;
    padding-left: 22px;
    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
  }
  .textLeft {
    text-align: left;
  }
  tr td:first-child {
    text-align: left;
    font-size: 12px;
    display: table-cell;
  }
  .upperCase {
    p {
      word-break: normal !important;
    }
  }

  .d-flex {
    justify-content: center;
  }
`

export const WorklogModalWrapper = styled.div`
  // padding: 0 20px;

  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* display: flex; */
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: var(--transition);
    z-index: -1;
    .form .btn {
      color: #fff;
      margin-top: 20px;
    }
    .modal-content {
      border-radius: 1rem;
      width: 35vw;
      @media (max-width: 1024px) {
        width: 60vw;
      }
      @media (max-width: 600px) {
        width: 80vw;
      }
    }

    .btn {
      border-radius: 50px;
      color: var(--primary-500);
      font-size: 18px;
      font-weight: 600 !important;
      // padding: 10px 20px;
      text-align: center;
      color: #fff;
      margin: 10px;
    }

    .btn:hover {
      color: inherit;
      background: inherit;
    }
    .form {
      overflow-y: scroll;
      position: relative;
    }
    @media (max-width: 993px) {
      .form {
        padding: 2rem 1rem;
        margin: 8rem auto;
        overflow-y: scroll;
      }
    }
  }
  .expl {
    font-size: 11px;
    position: absolute;
    margin-top: -9px;
  }
  .isOpen {
    opacity: 1;
    z-index: 999;
  }
  h6 {
    margin: 8px;
  }
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid grey;
  }
  .close-icon {
    font-size: 20px;
    color: grey;
    /* position: absolute;
    right: 9px;
    top: 9px; */
    cursor: pointer;
  }
  .btn { 
    margin-right: 5px;
  }
  .ql-editor {
    min-height: 180px;
    max-height: 250px;
    overflow-y: scroll;
  }
  .quill {
    p {
      font-size: 14px;
    }
  }
  .ql-picker,
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: 1px solid var(--grey-200);
    font-size: 12px;
  }

  .ql-snow .ql-editor img {
    max-width: 100%;
    width: 40px;
    margin: 2px;
    object-fit: contain;
    height: 40px;
  }

  .react-datetime-picker__wrapper {
    border-radius: 4px;
    border: 1px solid var(--grey-200);
  
    background: #fff;
  }

  .react-datetime-picker__inputGroup__day,
  .react-datetime-picker__inputGroup__minute,
  .react-datetime-picker__inputGroup__hour {
    width: 22px !important;
  }
  .react-datetime-picker__inputGroup__month {
    width: 14px !important;
  }
  .react-datetime-picker__inputGroup__year {
    width: 48px !important;
  }
`
