import {
  OPEN_WORKLOG_MODAL,
  CLOSE_WORKLOG_MODAL,
  CREATE_WORKLOG_BEGIN,
  CREATE_WORKLOG_SUCCESS,
  CREATE_WORKLOG_ERROR,
  GET_ALL_WORKLOG_TASK_BEGIN,
  GET_ALL_WORKLOG_TASK_SUCCESS,
  GET_ALL_WORKLOG_TASK_ERROR,
  GET_SINGLE_WORKLOG_BEGIN,
  GET_SINGLE_WORKLOG_SUCCESS,
  GET_SINGLE_WORKLOG_ERROR,
  SET_EDITING_WORKLOG_BEGIN,
  SET_EDITING_WORKLOG_SUCCESS,
  SET_EDITING_WORKLOG_ERROR,
  UPDATE_WORKLOG_BEGIN,
  UPDATE_WORKLOG_SUCCESS,
  UPDATE_WORKLOG_ERROR,
  DELETE_WORKLOG_BEGIN,
  DELETE_WORKLOG_SUCCESS,
  DELETE_WORKLOG_ERROR,
  GET_ALL_WORKLOG_BEGIN,
  GET_ALL_WORKLOG_SUCCESS,
  GET_ALL_WORKLOG_ERROR,
  GET_DAILY_LOGS_BEGIN,
  GET_DAILY_LOGS_SUCCESS,
  GET_DAILY_LOGS_ERROR,
  GET_WEEKLY_LOGS_BEGIN,
  GET_WEEKLY_LOGS_SUCCESS,
  GET_WEEKLY_LOGS_ERROR,
  GET_ALL_WORKLOG_USER_BEGIN,
  GET_ALL_WORKLOG_USER_SUCCESS,
  GET_ALL_WORKLOG_USER_ERROR,
  GET_ATTENDANCE_REPORT,
} from "./worklogTypes"

const initialState = {
  loading: false,
  worklogs: [],
  allLogs: [],
  isWorklogModalOpen: false,
  editingWorklog: null,
  isEditingWorklog: false,
  dailyLogs: [],
  weeklyLogs: [],
  users: [],
  attendance: [],
}

const worklogReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_WORKLOG_MODAL:
      return { ...state, isWorklogModalOpen: true }
    case CLOSE_WORKLOG_MODAL:
      return {
        ...state,
        isWorklogModalOpen: false,
        isEditingWorklog: false,
        editingWorklog: null,
      }
    case CREATE_WORKLOG_BEGIN:
      return { ...state, loading: false }
    case CREATE_WORKLOG_SUCCESS:
      return {
        ...state,
        worklogs: [...state.worklogs, action.payload.worklog],
        loading: false,
      }
    case CREATE_WORKLOG_ERROR:
      return { ...state, loading: false }
    case GET_ALL_WORKLOG_TASK_BEGIN:
      return { ...state, loading: true }
    case GET_ALL_WORKLOG_TASK_SUCCESS:
      return { ...state, worklogs: action.payload.worklogs, loading: false }
    case GET_ALL_WORKLOG_TASK_ERROR:
      return { ...state, loading: false }
    case GET_SINGLE_WORKLOG_BEGIN:
      return { ...state }
    case GET_SINGLE_WORKLOG_SUCCESS:
      return { ...state }
    case GET_SINGLE_WORKLOG_ERROR:
      return { ...state }
    case SET_EDITING_WORKLOG_BEGIN:
      return { ...state }
    case SET_EDITING_WORKLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        isEditingWorklog: true,
        isWorklogModalOpen: true,
        editingWorklog: action.payload.worklog,
      }
    case SET_EDITING_WORKLOG_ERROR:
      return { ...state, loading: false }
    case UPDATE_WORKLOG_BEGIN:
      return { ...state }
    case UPDATE_WORKLOG_SUCCESS:
      const worklogs = state.worklogs.map((i) => {
        if (i._id === action.payload.worklog._id) {
          return action.payload.worklog
        } else {
          return i
        }
      })
      return { ...state, worklogs }
    case UPDATE_WORKLOG_ERROR:
      return { ...state }
    case DELETE_WORKLOG_BEGIN:
      return { ...state }
    case DELETE_WORKLOG_SUCCESS:
      const worklog = state.worklogs.filter((i) => i._id !== action.payload.id)
      return { ...state, worklogs: worklog }
    case DELETE_WORKLOG_ERROR:
      return { ...state }
    case GET_ALL_WORKLOG_BEGIN:
      return { ...state, loading: true }
    case GET_ALL_WORKLOG_SUCCESS:
      return { ...state, worklogs: action.payload.logs, loading: false }
    case GET_ALL_WORKLOG_ERROR:
      return { ...state, loading: false }
    case GET_DAILY_LOGS_BEGIN:
      return { ...state, loading: true }
    case GET_DAILY_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        dailyLogs: action.payload.worklogs,
      }
    case GET_DAILY_LOGS_ERROR:
      return { ...state, loading: false }
    case GET_WEEKLY_LOGS_BEGIN:
      return { ...state, loading: true }
    case GET_WEEKLY_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        weeklyLogs: action.payload.worklogs,
      }
    case GET_WEEKLY_LOGS_ERROR:
      return { ...state, loading: false }
    case GET_ALL_WORKLOG_USER_BEGIN:
      return { ...state }
    case GET_ALL_WORKLOG_USER_SUCCESS:
      return { ...state, allLogs: action.payload.logs }
    case GET_ALL_WORKLOG_USER_ERROR:
      return { ...state }

    case GET_ATTENDANCE_REPORT:
      return {
        ...state,
        attendance: action.payload.workReport,
      }

    default:
      return { ...state }
  }
}

export default worklogReducer
