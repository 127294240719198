import {
  CREATE_NOTIFICATION_BEGIN,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_ERROR,
  DAILY_EMAIL_NOTIFICATION_BEGIN,
  DAILY_EMAIL_NOTIFICATION_SUCCESS,
  GET_DAILY_EMAIL_NOTIFICATION_BEGIN,
  GET_DAILY_EMAIL_NOTIFICATION_SUCCESS,
} from "./notificationTypes"
import authFetch from "../../utils/interceptor"
import { toast } from "react-toastify"

export const updateNotification = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NOTIFICATION_BEGIN })
    try {
      const response = await authFetch.patch(
        "/notification/${values.projectId}",
        values
      )
      dispatch({
        type: CREATE_NOTIFICATION_SUCCESS,
        payload: { notification: response.data.notification },
      })
      toast.success("Notification updated")
    } catch (error) {
      toast.error("Error in creating notification")
    }
  }
}

export const getAllNotification = () => {
  return async (dispatch) => {
    const response = await authFetch.get("/notification")
    return response.data.notifications
  }
}

export const getNotificationByProject = (projectId) => {
  return async (dispatch) => {
    const response = await authFetch.get(`/notification/${projectId}`)
    return response.data.notifications
  }
}

export const dailyEmailNotification = (values) => {
  return async (dispatch) => {
    dispatch({ type: DAILY_EMAIL_NOTIFICATION_BEGIN })
    try {
      const response = await authFetch.post(
        "/notification/dailyEmailNotification",
        values
      )
      dispatch({
        type: DAILY_EMAIL_NOTIFICATION_SUCCESS,
        payload: { notification: response.data.notification },
      })
      toast.success("Daily email updated notification")
    } catch (error) {
      toast.error("Error in creating notification")
    }
  }
}

export const getDailyEmailNotification = (values) => {
  return async (dispatch) => {
    dispatch({ type: GET_DAILY_EMAIL_NOTIFICATION_BEGIN })

    try {
      const response = await authFetch.post(
        "/notification/getDailyEmailNotification",
        values
      )
      dispatch({
        type: GET_DAILY_EMAIL_NOTIFICATION_SUCCESS,
        payload: { notification: response.data.notification },
      })
      return response.data.notification
    } catch (error) {
      toast.error("Error in creating notification")
    }
  }
}
