import React from "react"
import Modal from "react-bootstrap/Modal"
import { ConfirmBoxWrapper } from "../../assets/styles/Common"

const ConfirmationBox = ({
  open,
  setConfirm,
  setOpenConfirmation,
  handleDelete,
  name,
}) => {
  return (
    <Modal
      show={open}
      onHide={() => {
        setOpenConfirmation(false)
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ConfirmBoxWrapper>
          <p>Are you sure want to delete?</p>
          <button
            className="btn add-btn"
            type="submit"
            onClick={() => setOpenConfirmation(false)}
          >
            No
          </button>
          <button
            className="btn add-btn"
            type="submit"
            onClick={() => {
              handleDelete(name)
              setConfirm(true)
            }}
          >
            Yes
          </button>
        </ConfirmBoxWrapper>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmationBox
