import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import defaultImage from "../../assets/images/mobile.png"
import { useAppContext } from "../../context/context/appContext"
import {
  Avatar,
  CustumTooltip,
  Loading,
  ProfileAvatar,
  ProjectIcon,
} from "../../components/Common"
import { ProjectModal, ProjectDetails } from "../../components/Projects"
import Modal from "react-bootstrap/Modal"
import { capitalizeFirstLetter } from "../../utils/getInitialName"
import SubjectiveModal from "../../components/Question/SubjectiveModal"
import MultipleModal from "../../components/Question/MultipleModal"
import { useDispatch, useSelector } from "react-redux"
import { getAllClients } from "../../redux"
import {
  ProjectConfirmationsWrapper,
  ProjectHeadersWrapper,
  ProjectListViewWrapper,
  ProjectWrapper,
} from "../../assets/styles/Dashboard"
import {
  AddSharpIcon,
  AppsRoundedIcon,
  ArchiveOutlinedIcon,
  DriveFileRenameOutlineSharpIcon,
  EditNoteOutlinedIcon,
  MenuRoundedIcon,
  MoreHorizRoundedIcon,
  UnarchiveOutlinedIcon,
} from "../../assets/icons"
import {
  openProjectModal,
  getAllProjects,
  selectProject,
  setEditProject,
  openEditModal,
  archiveProject,
  getTasksByProject,
  openFeedbackModal,
} from "../../redux"
import * as Dialog from "@radix-ui/react-dialog"

const ViewAllProjects = () => {
  const dispatch = useDispatch()
  const {
    projects,
    loading,
    selectedProject,
    editingProject,
    editProjectModalOpen,
  } = useSelector((state) => state.project)

  const { socket, user, getUserName, accessList } = useAppContext()

  const [confirmArchive, setConfirmArchive] = useState(false)
  const [client, setClient] = useState(false)
  const [projectId, setProjectId] = useState()
  const [open, setOpen] = useState()
  const [listView, setListView] = useState(false)
  const [cardView, setCardView] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [slide, setSlide] = useState(false)

  useEffect(() => {
    console.log(user);
    dispatch(getAllProjects(user.role, user._id))
    // getUsersList()

    //  dispatch(openFeedbackModal())
    setCardView(true)
    setListView(false)
    getUserName().then((data) => setUsersList(data))
  }, [])

  useEffect(() => {
    if (client) {
      dispatch(getAllClients(1, 10, ""))
    }
  }, [client])

  useEffect(() => {
    socket.on("newProject", (data) => {
      dispatch(getAllProjects(user.role, user._id))
    })
    socket.on("newList", async (data) => {
      console.log(data)
      await dispatch(getAllProjects(user.role, user._id))

      dispatch(selectProject(data))
      dispatch(getTasksByProject(data))
    })
    // socket.on("projectPic", async (data) => {
    //   await getAllProjects()
    //   const name = data.split("/")

    //   setOpenEditProject(true)
    //   setEditProject(name[1])
    //   closeProjectModal()
    // })
  }, [socket])

  if (loading) {
    return <Loading center />
  }

  const handleOpenDropdown = (id) => {
    setProjectId(id)
    setOpen(!open)
  }

  const random = () => Math.floor(Math.random() * 255)

  return (
    <>
      <h5 className="card-title  lg:tw-p-8 lg:tw-pb-4 tw-p-3 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">
        Projects{" "}
        <div className="tw-flex " style={{ alignItems: "center" }}>
          <CustumTooltip
            text={"Card view"}
            children={
              <AppsRoundedIcon
                onClick={() => {
                  setCardView(true)
                  setListView(false)
                }}
                style={{ color: cardView && "var(--blue)" }}
                className="grey tw-mr-5 hover:tw-bg-slate-200 tw-cursor-pointer tw-rounded"
              />
            }
            position={"bottom"}
            id={`card-tooltip`}
          />
          <CustumTooltip
            text={"List view"}
            children={
              <MenuRoundedIcon
                onClick={() => {
                  setCardView(false)
                  setListView(true)
                }}
                style={{ color: listView && "var(--blue)", fontSize: "25px" }}
                className="grey  hover:tw-bg-slate-200 tw-cursor-pointer tw-rounded"
              />
            }
            position={"bottom"}
            id={`list-tooltip`}
          />
        </div>{" "}
      </h5>
      <SubjectiveModal />
      <MultipleModal />
      <ProjectWrapper>
        {/* add project button for admin only */}
        {console.log(accessList)}
        <div className="head-txt  lg:tw-pl-8  tw-pl-3">
          {accessList.includes("CREATE_PROJECT") && (
            <button
              className="btn btn-block add-btn mb-0"
              type="submit"
              onClick={() => {
                dispatch(openProjectModal())
                setClient(true)
              }}
            >
              <AddSharpIcon style={{ color: "#fff", fontSize: "20px" }} /> Add
              Project
            </button>
          )}
        </div>

        {/* show details to add project or no project is assigned */}
        {projects.length === 0 &&
          (user.role !== "developer" ? (
            <p style={{ marginTop: "40px" }} className="  lg:tw-px-8 lg:tw-mt-4 tw-px-3  ">
              No projects to display, create one by clicking on 'Add Project'
            </p>
          ) : (
            <p className="  lg:tw-px-8 lg:tw-mt-4 tw-px-3  ">
              You have not been assigned a project yet. Please reach out to your
              project manager.
            </p>
          ))}

        <div className="row all-projects  lg:tw-px-8 lg:tw-mt-4 tw-px-3    tw-h-[72vh] tw-m-0">
          {cardView &&
            projects &&
            projects.map((project) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 mb-4"
                  key={project._id}
                  onClick={() => {
                    dispatch(selectProject(project._id))
                  }}
                >
                  <div
                    className="container"
                    style={{
                      borderTop: `4px solid rgb(${random()}, ${random()}, ${random()},.6)`,
                    }}
                  >
                    <ProjectHeadersWrapper>
                      {project.isArchived === false ? (
                        <Link
                          to={"/board"}
                          onClick={() => {
                            dispatch(setEditProject(project._id))
                          }}
                          style={{ width: "100%" }}
                        >
                          <div className="headers">
                            <div className="main-icon">
                              <ProjectIcon id={project._id} size={"s"} />
                            </div>
                            <div className="info">
                              <h5>{project.title}</h5>
                              <p className="content-center ">
                                {capitalizeFirstLetter(project.description)}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <div className="headers">
                          <div className="main-icon">
                            <ProjectIcon id={project._id} size={"s"} />
                          </div>
                          <div className="info">
                            <h5>{project.title}</h5>
                            <p className="content-center mb-2">
                              {capitalizeFirstLetter(project.description)}
                            </p>
                          </div>
                        </div>
                      )}
                      {accessList.includes("UPDATE_PROJECT") ? (
                        <MoreHorizRoundedIcon
                          onClick={() => handleOpenDropdown(project._id)}
                        />
                      ) : null}
                      {open && projectId === project._id && (
                        <div
                          className="dropdown-menu" //jyoti
                          aria-labelledby="dropdownMenu2"
                        >
                          {!project.isArchived && (
                            <div
                              className="dropdown-item"
                              onClick={() => {
                                dispatch(openEditModal())
                                dispatch(setEditProject(project._id))

                                setOpen(false)
                              }}
                            >
                              <DriveFileRenameOutlineSharpIcon />
                              Edit
                            </div>
                          )}
                          <div
                            className="dropdown-item"
                            onClick={(e) => {
                              setConfirmArchive(true)
                              setOpen(false)
                            }}
                          >
                            {!project.isArchived ? (
                              <>
                                <ArchiveOutlinedIcon />
                                Archive
                              </>
                            ) : (
                              <>
                                <UnarchiveOutlinedIcon />
                                UnArchive
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </ProjectHeadersWrapper>

                    {/* Archive Confirmation start */}

                    <Modal
                      show={confirmArchive && projectId === project._id}
                      onHide={() => {
                        setConfirmArchive(false)
                      }}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm archivation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ProjectConfirmationsWrapper>
                          <p>
                            Are you sure do you want to{" "}
                            {project.isArchived === false
                              ? " archive "
                              : "unarchive"}{" "}
                            this project?
                          </p>
                          <button
                            className="btn add-btn"
                            type="submit"
                            onClick={() => setConfirmArchive(false)}
                          >
                            No
                          </button>
                          <button
                            className="btn add-btn"
                            type="submit"
                            onClick={() => {
                              dispatch(archiveProject(project._id))
                              setConfirmArchive(false)
                            }}
                          >
                            Yes
                          </button>
                        </ProjectConfirmationsWrapper>
                      </Modal.Body>
                    </Modal>

                    {/* Archive Confirmation end */}

                    <div className="footers">
                      <div>
                        {project.isArchived && <ArchiveOutlinedIcon />}{" "}
                      </div>
                      <div
                        className={`avatar-image upperCase  ${slide ? "expanded" : "collapsed"
                          }`}
                        onMouseLeave={() => setSlide(false)}
                      >
                        {slide && projectId === project._id
                          ? project.team.map((teamMember) => {
                            const user = usersList.find(
                              (user) => user.email === teamMember.email
                            )
                            const name = user ? user.name : null
                            const id = teamMember.id
                            return (
                              <CustumTooltip
                                key={id}
                                text={
                                  <p
                                    style={{
                                      textTransform: "capitalize",
                                      margin: 0,
                                    }}
                                  >
                                    {name}
                                  </p>
                                }
                                children={
                                  <ProfileAvatar id={id} size={"s"} />
                                }
                                position={"bottom"}
                                id={`project-tooltip-${id}`}
                              />
                            )
                          })
                          : project.team.slice(0, 2).map((teamMember) => {
                            const user = usersList.find(
                              (user) => user.email === teamMember.email
                            )
                            const name = user ? user.name : null
                            const id = teamMember.id
                            return (
                              <CustumTooltip
                                key={id}
                                text={
                                  <p
                                    style={{
                                      textTransform: "capitalize",
                                      margin: 0,
                                    }}
                                  >
                                    {name}
                                  </p>
                                }
                                children={
                                  <ProfileAvatar id={id} size={"s"} />
                                }
                                position={"bottom"}
                                id={`project-tooltip-${id}`}
                              />
                            )
                          })}

                        {!slide && project.team.length > 2 ? (
                          <span
                            onMouseEnter={() => {
                              setSlide(true)
                              setProjectId(project._id)
                            }}
                          >
                            +{project.team.length - 2}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          {listView && (
            <ProjectListViewWrapper>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="textLeft">Description</th>
                    <th>Team</th>
                    {user.role !== "developer" && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {listView &&
                    projects &&
                    projects.map((project) => {
                      return (
                        <>
                          <tr key={project._id}>
                            <td className="name">
                              <ProjectIcon id={project._id} size={"s"} />
                              <Link
                                to={"/board"}
                                onClick={() =>
                                  dispatch(setEditProject(project._id))
                                }
                              >
                                <h5 style={{ fontSize: "12px" }}>
                                  {project.title}
                                </h5>
                              </Link>
                            </td>

                            <td className="description">
                              <p className="content-center mb-2 textLeft">
                                {capitalizeFirstLetter(project.description)}
                              </p>
                            </td>
                            <td className="image">
                              <div
                                className={`avatar-image upperCase  ${slide ? "expanded" : "collapsed"
                                  }`}
                                onMouseLeave={() => setSlide(false)}
                              >
                                {slide && projectId === project._id
                                  ? project.team.map((teamMember) => {
                                    const user = usersList.find(
                                      (user) =>
                                        user.email === teamMember.email
                                    )
                                    const name = user ? user.name : null
                                    const id = teamMember.id
                                    return (
                                      <CustumTooltip
                                        key={id}
                                        text={
                                          <p
                                            style={{
                                              textTransform: "capitalize",
                                              margin: 0,
                                            }}
                                          >
                                            {name}
                                          </p>
                                        }
                                        children={
                                          <ProfileAvatar id={id} size={"s"} />
                                        }
                                        position={"bottom"}
                                        id={`project-tooltip-${id}`}
                                      />
                                    )
                                  })
                                  : project.team
                                    .slice(0, 2)
                                    .map((teamMember) => {
                                      const user = usersList.find(
                                        (user) =>
                                          user.email === teamMember.email
                                      )
                                      const name = user ? user.name : null
                                      const id = teamMember.id
                                      return (
                                        <CustumTooltip
                                          key={id}
                                          text={
                                            <p
                                              style={{
                                                textTransform: "capitalize",
                                                margin: 0,
                                              }}
                                            >
                                              {name}
                                            </p>
                                          }
                                          children={
                                            <ProfileAvatar
                                              id={id}
                                              size={"s"}
                                            />
                                          }
                                          position={"bottom"}
                                          id={`project-tooltip-${id}`}
                                        />
                                      )
                                    })}

                                {!slide && project.team.length > 2 ? (
                                  <span
                                    onMouseEnter={() => {
                                      setSlide(true)
                                      setProjectId(project._id)
                                    }}
                                  >
                                    +{project.team.length - 2}
                                  </span>
                                ) : null}
                              </div>
                            </td>
                            {user.role !== "developer" && (
                              <td>
                                {
                                  <MoreHorizRoundedIcon
                                    onClick={() =>
                                      handleOpenDropdown(project._id)
                                    }
                                  />
                                }
                                {open && projectId === project._id && (
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenu2"
                                  >
                                    {!project.isArchived && (
                                      <div
                                        className="dropdown-item"
                                        onClick={() => {
                                          // setProject(project)

                                          dispatch(setEditProject(project._id))

                                          setOpen(false)
                                        }}
                                      >
                                        {user.role === "admin" && (
                                          <DriveFileRenameOutlineSharpIcon />
                                        )}
                                        {user.assignedRoles.includes(
                                          "projectManager"
                                        ) && (
                                            <DriveFileRenameOutlineSharpIcon />
                                          )}
                                        Edit
                                      </div>
                                    )}
                                    <div
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        setConfirmArchive(true)
                                        setOpen(false)
                                      }}
                                    >
                                      {!project.isArchived ? (
                                        <>
                                          <ArchiveOutlinedIcon />
                                          Archive
                                        </>
                                      ) : (
                                        <>
                                          <UnarchiveOutlinedIcon />
                                          UnArchive
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </td>
                            )}
                          </tr>
                        </>
                      )
                    })}
                </tbody>
              </table>
            </ProjectListViewWrapper>
          )}
        </div>

        <ProjectModal />
        {editProjectModalOpen && <ProjectDetails project={editingProject} />}
      </ProjectWrapper>
    </>
  )
}

export default ViewAllProjects
