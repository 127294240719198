import styled from "styled-components"

export const FeedbacksFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: var(--shadow-1);
  padding: 40px;

  border-radius: 5px;
  .feedform {
    padding: 20px;
    width: 40vw;
    background-color: #fff;
  }
  button {
    border-radius: 5px;
  }
  p {
    text-align: center !important;
    margin: 10px 0;
  }

  .app-container {
    display: flex;
    flex-direction: column;
  }
  .size {
    font-size: 18px;
  }
  .custom-size {
    margin-left: "40px";
  }
`