import React from "react"
import { WorkReportWrapper } from "../../../assets/styles/WorkReportWrapper"
import Calendar from "./Calender"
const Attendance = () => {
  return (
    <WorkReportWrapper>
      <div className="heading mb-6">
        {/* <h5 className="card-title"></h5>
        <div>
          <button className="btn add-btn mx-4">Download</button>
          <button className="btn add-btn  ">Share</button>
        </div> */}
      </div>

      <Calendar />
    </WorkReportWrapper>
  )
}

export default Attendance
