import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router"
import moment from "moment"
import { useAppContext } from "../../context/context/appContext"
import { FormRow, ProfileAvatar } from "../../components/Common"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { PhoneNumberUtil } from "google-libphonenumber"
import { ProfileView } from "../../assets/styles/Dashboard"
import {
  CloseOutlinedIcon,
  CloseRoundedIcon,
  ContactMailIcon,
  DoneRoundedIcon,
  DriveFileRenameOutlineSharpIcon,
  LanOutlinedIcon,
  PortraitOutlinedIcon,
} from "../../assets/icons"
import styled from "styled-components"

const Profile = () => {
  const { user, setShowLogout, editProfile, updateProfilePic } = useAppContext()

  const initialState = {
    _id: user._id,
    name: user.name,
    email: user.email,
    contact_number: user.contact_number,
    country: user.country,
    address: user.address,
    role: user.role,
    education_qualification: user.education_qualification,
    work_experience: user.work_experience,
    skills: user.skills,
    job_title: user.job_title,
    department: user.department,
    reporting_manager: user.reporting_manager,
    joining_date: user.joining_date,
    salary_details: user.salary_details,
    emergency_contact_details: user.emergency_contact_details,
  }
  const navigate = useNavigate()
  // const [showDropZone, setShowDropzon] = useState(false)
  const [values, setValues] = useState(initialState)
  const [openPersonalInfo, setOpenPersonalInfo] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState("US")
  const [nameOpen, setNameOpen] = useState(false)
  const [contactOpen, setContactOpen] = useState(false)
  const [eduOpen, setEduOpen] = useState(false)
  const [addressOpen, setAddressOpen] = useState(false)

  const fileInputRef = useRef(null)

  const handleImageClick = () => {
    fileInputRef.current.click()
  }

  const handleFileInputChange = async (event) => {
    const selectedFile = event.target.files[0]
    const form = new FormData()
    form.append("id", user._id)
    form.append("files", selectedFile)
    await updateProfilePic(form)
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleEdit = async (e) => {
    e.preventDefault()
    // if (validator.isMobilePhone(values.contact_number)) {
    //   toast.error("enter valid phone number")
    //   return
    // }

    const result = editProfile(values)
    if (result) {
      navigate("/profile")
    }
    setAddressOpen(false)
    setContactOpen(false)
    setEduOpen(false)
    setNameOpen(false)
  }

  const handleContactChange = (e) => {
    setValues({ ...values, ["contact_number"]: e })
    validatePhoneNumber(e, selectedCountry)
  }

  const handleCountryChange = (country) => {
    setSelectedCountry(country)
    setValues({ ...values, ["country"]: country })

    validatePhoneNumber(values.contact_number, country)
  }

  const validatePhoneNumber = (input, country = selectedCountry) => {
    try {
      // Create an instance of PhoneNumberUtil
      const phoneUtil = PhoneNumberUtil.getInstance()

      // Parse the phone number
      const parsedNumber = phoneUtil.parseAndKeepRawInput(input, country)

      // Check if the phone number is valid for the selected country
      const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(
        parsedNumber,
        country
      )

      setIsValid(isValidPhoneNumber)
    } catch (error) {
      // Handle any errors during phone number validation
      setIsValid(false)
    }
  }

  return (
    <div className="tw-w-full   lg:tw-px-8 lg:tw-mt-4 tw-px-3  ">
      <div onClick={() => setShowLogout(false)} className="tw-w-full">
        <div className="tw-w-full">
          <p className="card-title tw-p-0 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-[13px] tw-flex tw-justify-between tw-items-center">
            Profile
          </p>
          <div className="tw-m-4 tw-flex  tw-h-[60vh] tw-overflow-y-scroll  md:tw-flex-row tw-flex-col tw-w-full">
            {/* first div */}
            <div className="lg:tw-w-1/2 md:tw-w-1/2 tw-w-full  ">

              {/* profile picture */}
              <Prodile>
                <div className="tw-flex tw-flex-col tw-gap-3 tw-shadow tw-items-center tw-px-3 tw-py-4 tw-justify-center tw-mb-4 tw-mr-4">
                  <ProfileAvatar id={user._id} size="l" />
                  <div className="tw-self-start">
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      style={{ display: "none" }}
                    />
                    <DriveFileRenameOutlineSharpIcon className="tw-text-[var(--primary-500)]"
                      onClick={handleImageClick}
                    />
                  </div>
                  <h5 className="tw-ml-[-26px] tw-text-center">

                    {values.name} <h6 className="lowerCase ">{values.email} </h6>
                    {/* <h6 className="">

                  <span> {values.role}</span>

                </h6>{" "} */}
                  </h5>
                </div>


              </Prodile>

              <div className=" tw-mr-4  tw-px-3 tw-py-4 ">
                <p className="tw-text-[18px]  tw-font-semibold">About</p>
                <hr className="tw-w-full" />
                <div className="">
                  <div className="  tw-flex tw-flex-col tw-cursor-pointer">
                    <p className="tw-text-[14px] tw-mb-2">Name</p>
                    {nameOpen && (
                      <span className="tw-self-end tw-text-[13px] tw-absolute">
                        <CloseOutlinedIcon onClick={() => setNameOpen(false)} className="tw-text-red-500" />
                        <DoneRoundedIcon onClick={(e) => handleEdit(e)} className="tw-text-green-400" />
                      </span>
                    )}
                    <h6>
                      {!nameOpen ? (
                        <p className="tw-text-[14px] tw-text-gray-800 tw-mb-[0.5rem]" onClick={() => setNameOpen(true)}>
                          {values.name ? values.name : "Add Name"}
                        </p>
                      ) : (
                        <FormRow
                          type="text"
                          name="name"
                          value={values.name}
                          handleChange={handleChange}
                          placeholder={"Add Name"}
                        />
                      )}
                    </h6>
                  </div>


                  <div className="  tw-flex tw-flex-col tw-cursor-pointer">
                    <p className="tw-text-[14px] tw-mb-2">Education Qualification</p>
                    {eduOpen && (
                      <span className="tw-self-end tw-absolute">
                        <CloseOutlinedIcon onClick={() => setEduOpen(false)} className="tw-text-red-500" />
                        <DoneRoundedIcon onClick={(e) => handleEdit(e)} className="tw-text-green-400" />
                      </span>
                    )}
                    <h6>
                      {!eduOpen ? (
                        <p className="tw-text-gray-800 tw-text-[13px] tw-mb-[0.5rem]" onClick={() => setEduOpen(true)}>
                          {values.education_qualification
                            ? values.education_qualification
                            : "+ Add Education Qualification"}
                        </p>
                      ) : (
                        <FormRow
                          type="text"
                          name="education_qualification"
                          value={values.education_qualification}
                          handleChange={handleChange}
                          placeholder={"Add Education Qualification"}
                        />
                      )}
                    </h6>
                  </div>


                  <div className=" tw-flex tw-flex-col tw-cursor-pointer">
                    <p className="tw-text-[14px] tw-mb-2">Contact Number</p>
                    {contactOpen && (
                      <span className="tw-self-end tw-absolute">
                        <CloseOutlinedIcon className="tw-text-red-500"
                          onClick={() => setContactOpen(false)}
                        />
                        <DoneRoundedIcon onClick={(e) => handleEdit(e)} className="tw-text-green-500" />
                      </span>
                    )}
                    <h6>
                      {!contactOpen ? (
                        <p
                          className="tw-text-gray-800 tw-text-[13px] tw-mb-[0.5rem]"
                          onClick={() => setContactOpen(true)}
                        >
                          {values.contact_number
                            ? values.contact_number
                            : "+ Add Education Qualification"}
                        </p>
                      ) : (
                        <>
                          <PhoneInput
                            international
                            defaultCountry={values.country || selectedCountry}
                            value={values.contact_number}
                            onChange={handleContactChange}
                            country={values.country}
                            onCountryChange={handleCountryChange}
                          />
                          {isValid ? null : (
                            <span className="validatePError">
                              invalid phone number.
                            </span>
                          )}
                        </>
                      )}
                    </h6>
                  </div>


                  <div className=" tw-flex tw-flex-col tw-cursor-pointer">
                    <p className="tw-text-[14px] tw-mb-2">
                      {/* <ContactMailIcon /> */}
                      Address{" "}
                    </p>
                    {addressOpen && (
                      <span className="tw-self-end tw-absolute">
                        <CloseOutlinedIcon className="tw-text-red-500"
                          onClick={() => setAddressOpen(false)}
                        />
                        <DoneRoundedIcon onClick={(e) => handleEdit(e)} className="tw-text-green-400" />
                      </span>
                    )}
                    <h6>
                      {!addressOpen ? (
                        <p
                          className="tw-text-gray-800 tw-text-[13px] tw-mb-[0.5rem]"
                          onClick={() => setAddressOpen(true)}
                        >
                          {values.address ? values.address : "+ Add Address"}
                        </p>
                      ) : (
                        <FormRow
                          type="text"
                          name="address"
                          value={values.address}
                          handleChange={handleChange}
                          placeholder={"Add Address"}
                        />
                      )}
                    </h6>
                  </div>

                </div>
              </div>
            </div>


            {/* second div */}
            <div
              className="   lg:tw-w-1/2  md:tw-w-1/2 tw-w-full tw-px-3"
              style={{ borderLeft: "2px solid #14689f" }}
            >
              <div className=" tw-p-3">
                <p className="tw-text-[18px] tw-flex tw-justify-between tw-font-semibold ">
                  Work Details <div
                    className="tw-text-blue-500 tw-text-[13px] tw-text-right tw-cursor-pointer"
                    onClick={() => setOpenPersonalInfo(!openPersonalInfo)}
                  >
                    {!openPersonalInfo ? "Edit" : "Cancel"}
                  </div>
                </p>
                <hr className="tw-w-full" />
                <div className="tw-flex tw-flex-col tw-gap-2">
                  {!openPersonalInfo ? (
                    <>
                      <label className="  tw-text-[14px] ">Department</label>
                      <p className="tw-text-gray-800 tw-text-[13px] tw-mb-[0.5rem] ">
                        {values.department ? values.department : "Add Department"}
                      </p>
                      <label className="tw-text-[14px]">
                        Work Experience
                      </label>
                      <p className="tw-text-gray-800 tw-text-[13px] tw-mb-[0.5rem] ">
                        {values.work_experience
                          ? values.work_experience
                          : "Add Experience"}
                      </p>
                      <label className="tw-text-[14px]  ">Skills</label>
                      <p className="tw-text-gray-800 tw-text-[13px]  tw-mb-[0.5rem]">
                        {values.skills ? values.skills : "Add Skills"}
                      </p>
                      <label className="tw-text-[14px] ">Job Title</label>
                      <p className="tw-text-gray-800 tw-text-[13px]  tw-mb-[0.5rem]">
                        {values.job_title ? values.job_title : "Add Job Title"}
                      </p>
                      <label className="tw-text-[14px] ">
                        Reporting Manager
                      </label>
                      <p className="tw-text-gray-800 tw-text-[13px] tw-mb-[0.5rem]">
                        {values.reporting_manager
                          ? values.reporting_manager
                          : "Add Reporting Manager"}
                      </p>
                      <label className="tw-text-[14px] ">
                        Joining Date
                      </label>
                      <p className="tw-text-gray-800 tw-text-[13px] tw-mb-[0.5rem] ">
                        {values.joining_date
                          ? moment(values.joining_date).format("DD/MM/YYYY")
                          : "Add Joining Date"}
                      </p>
                    </>
                  ) : (
                    <>
                      <FormRow
                        type="text"
                        name="department"
                        labelText="Department"
                        value={values.department}
                        handleChange={handleChange}
                        placeholder={"Add Department"}
                      />

                      <FormRow
                        type="text"
                        name="work_experience"
                        labelText="Work Experience"
                        value={values.work_experience}
                        handleChange={handleChange}
                        placeholder={"Add Work Experience"}
                      />
                      <FormRow
                        type="text"
                        name="skills"
                        labelText="skills"
                        value={values.skills}
                        handleChange={handleChange}
                        placeholder={"Add Skills"}
                      />

                      <FormRow
                        type="text"
                        name="job_title"
                        labelText="Job Title"
                        value={values.job_title}
                        handleChange={handleChange}
                        placeholder={"Add Job Title"}
                      />
                      <FormRow
                        type="text"
                        name="reporting_manager"
                        labelText="Reporting Manager"
                        value={values.reporting_manager}
                        handleChange={handleChange}
                        placeholder={"Add Reporting Manager"}
                      />
                      <FormRow
                        type="date"
                        name="joining_date"
                        labelText="Joining Date"
                        value={values.joining_date}
                        handleChange={handleChange}
                        placeholder={"Add Joining Date"}
                      />
                      <div className="edit">
                        <button type="submit" className="rounded  tw-bg-[--primary-500] tw-text-white hover:tw-bg-[--primary-500-active]" onClick={(e) => handleEdit(e)}>
                          Update
                        </button>
                      </div>
                    </>
                  )}
                </div>

              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile


const Prodile = styled.div`
position:relative;
  img{
    height:200px;
    width:200px;
  }
  svg{
    position:absolute;
    right:36%;
    top:60%;
  }
`