// codeReviewReducer.js
import {
    CREATE_CODE_REVIEW_BEGIN,
    CREATE_CODE_REVIEW_SUCCESS,
    CREATE_CODE_REVIEW_ERROR,
    GET_CODE_REVIEWS_BEGIN,
    GET_CODE_REVIEWS_SUCCESS,
    GET_CODE_REVIEWS_ERROR,
    EDIT_CODE_REVIEW_BEGIN,
    EDIT_CODE_REVIEW_SUCCESS,
    EDIT_CODE_REVIEW_ERROR,
    DELETE_CODE_REVIEW_BEGIN,
    DELETE_CODE_REVIEW_SUCCESS,
    DELETE_CODE_REVIEW_ERROR,
    GET_CODE_REVIEW_BY_ID_BEGIN,
    GET_CODE_REVIEW_BY_ID_SUCCESS,
    GET_CODE_REVIEW_BY_ID_ERROR,
    OPEN_CODE_REVIEW_MODAL,
    CLOSE_CODE_REVIEW_MODAL,
    SET_IS_REVIEW_ID,
    SET_EDIT_TASK_FOR_CODE_REVIEW,
    DELETE_CODE_REVIEW_ATTACHMENT_REQUEST,
    DELETE_CODE_REVIEW_ATTACHMENT_SUCCESS,
    DELETE_CODE_REVIEW_ATTACHMENT_FAILURE,
    STATUS_CHECK_REQUEST,
    STATUS_CHECK_SUCCESS,
    STATUS_CHECK_FAILURE,
} from './codeReviewTypes';

const initialState = {
    isReviewId: null,
    codeReviews: [],
    editingTaskForCodeReview: null,
    selectedCodeReview: null,
    isCodeReviewModalOpen: false,
    loading: false,
    loading2: false,
    error: null,
    status: 1, // Default to 1 as per your requirements
};

const codeReviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_CODE_REVIEW_MODAL:
            return { ...state, isCodeReviewModalOpen: true };
        case CLOSE_CODE_REVIEW_MODAL:
            return { ...state, isCodeReviewModalOpen: false };
        case SET_IS_REVIEW_ID:
            return {
                ...state,
                isReviewId: action.payload,
            };
        case SET_EDIT_TASK_FOR_CODE_REVIEW:
            return {
                ...state,
                editingTaskForCodeReview: action.payload.codeReviewTask,
            }
        case GET_CODE_REVIEWS_BEGIN:
            return { ...state, loading: true, error: null };
        case CREATE_CODE_REVIEW_BEGIN:
            return { ...state, loading: true, error: null };
        case EDIT_CODE_REVIEW_BEGIN:
            return { ...state, loading: true, error: null };
        case DELETE_CODE_REVIEW_BEGIN:
            return { ...state, loading: true, error: null };
        case GET_CODE_REVIEW_BY_ID_BEGIN:
            return { ...state, loading2: true, error: null };

        case GET_CODE_REVIEW_BY_ID_SUCCESS:
            return { ...state, loading2: false, selectedCodeReview: action.payload };

        case GET_CODE_REVIEW_BY_ID_ERROR:
            return { ...state, loading2: false, error: action.payload };
        case GET_CODE_REVIEWS_SUCCESS:
            return { ...state, loading: false, codeReviews: action.payload };

        case CREATE_CODE_REVIEW_SUCCESS:
            return { ...state, loading: false, codeReviews: [...state.codeReviews, action.payload] };

        case EDIT_CODE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                codeReviews: state.codeReviews.map((review) =>
                    review._id === action.payload._id ? action.payload : review
                ),
            };

        case DELETE_CODE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                codeReviews: state.codeReviews.filter((review) => review._id !== action.payload),
            };

        case GET_CODE_REVIEWS_ERROR:
        case CREATE_CODE_REVIEW_ERROR:
        case EDIT_CODE_REVIEW_ERROR:
        case DELETE_CODE_REVIEW_ERROR:
            return { ...state, loading: false, error: action.payload };
        case DELETE_CODE_REVIEW_ATTACHMENT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            };

        case DELETE_CODE_REVIEW_ATTACHMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };

        case DELETE_CODE_REVIEW_ATTACHMENT_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload, // Error message
            };

        case STATUS_CHECK_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case STATUS_CHECK_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action.payload, // The status result from the API
            };
        case STATUS_CHECK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload, // Error message from the API call
            };

        default:
            return state;
    }
};

export default codeReviewReducer;
