import React from "react"
import Tabs, { Tab } from "react-best-tabs"
import SubjectiveReview from "../../components/Question/review/SubjectiveReview"
import MultipleReview from "../../components/Question/review/MultipleReview"
import { TabsWrapper } from "../../assets/styles/Dashboard"

const FeedbackReport = () => {
  return (
    <>
      <h5 className="card-title tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center lg:tw-p-8  tw-p-3">Feedback Report
      </h5>
      <TabsWrapper  >
        <Tabs
          activeTab="1"
          className="tabss mb-3"
          ulClassName=""
          activityClassName="bg-success"
        // onClick={(event, tab) => console.log(event, tab)}
        >
          <Tab title="Subjective" className="mr-3">
            <SubjectiveReview />
          </Tab>
          <Tab title="Multiple" className="mr-3">
            <MultipleReview />
          </Tab>
        </Tabs>
      </TabsWrapper>
    </>
  )
}

export default FeedbackReport
