import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Tabs, { Tab } from "react-best-tabs"
import { openQuestionModal } from "../../redux"
import Subjective from "./Subjective"
import Multiple from "./Multiple"
import { TabsWrapper } from "../../assets/styles/Dashboard"
import SubjectiveModal from "./SubjectiveModal"
import MultipleModal from "./MultipleModal"

const AllClients = () => {
  const dispatch = useDispatch()

  return (
    <>
      <TabsWrapper>
        <SubjectiveModal />
        <MultipleModal />
        <h5 className="card-title lg:tw-px-8  tw-px-3  tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm  tw-flex tw-justify-between tw-items-center">Question
          <div className="task-heading d-flex ">
            <button
              className="btn btn-block add-btn "
              type="submit"
              onClick={() => dispatch(openQuestionModal())}
              style={{marginBottom:"0"}}
            >
              + Add Question
            </button>
          </div>
        </h5>
        {/* table to display all questions */}
        {/* make two tabs multiple and subjective with filters */}
        <div className="question-tabs" >
          <Tabs
            activeTab="1"
            className="tabss mb-3"
            ulClassName=""
            activityClassName="bg-success"
          // onClick={(event, tab) => console.log(event, tab)}
          >
            <Tab title="Subjective" className="mr-3 lg:tw-p-8 lg:tw-pb-3 tw-p-3 ">
              <Subjective />
            </Tab>
            <Tab title="Multiple" className="mr-3 lg:tw-p-8 lg:tw-pb-3 tw-p-3 ">
              <Multiple />
            </Tab>
          </Tabs>
        </div>
      </TabsWrapper>
    </>
  )
}

export default AllClients

