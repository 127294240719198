import styled from "styled-components"

export const SharedWrapper = styled.div`
  background: #fdfdfd;

  .dashboard {
    display: grid;
    grid-template-columns: 1fr;
  }

  .dashboard-page {
    // width: 90vw;
    margin: 0 auto;
    // padding: 2rem 0;
  }

  @media (min-width: 992px) {
    .dashboard {
      grid-template-columns: auto 1fr;
    }

    .dashboard-page {
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      padding-bottom:100px;
    }
  }
`
export const TabsWrapper = styled.div`
 .rb-tabs-header {
  margin:0 32px;
 }
  .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active {
    
    color: var(--primary-500-active);
    padding: 10px;
    /* background: var(--bg-card); */
    display: block;
    background: aliceblue;
  }

  .bg-success,
  .rb-tabs .rb-tabs-header .rb-tabs-active-item {
    background: var(--primary-500-active) !important;
  }

  .rb-tabs-content {
    margin: 20px 0;
  }

  .rb-tabs-item {
    text-transform: capitalize;
    font-size: var(--font-15);
  }
`
// export const ProfileView = styled.div`
//   .containers {
//     min-height: calc(100vh - 56px);
//     //overflow: auto;
//     height: 100vh;

//     @media (max-width: 800px) {
//       // padding: 3rem 2rem 8rem 2rem;
//     }
//   }

//   .leftBox {
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     h6 {
//       font-size: 12px;
//       font-weight: 400;
//     }

//     p {
//       margin: 0;
//     }

//     .info {
//       display: flex;
//       justify-content: space-between;

//       svg {
//         font-size: 18px !important;
//         margin-left: 5px;
//         margin-right: 0 !important;
//       }

//       span {
//         font-weight: 600;
//       }
//     }

//     .userinfo {
//       width: 100%;
//       text-align: left;
//       // border-top: 2px solid lightgrey;
//       margin-top: 30px;

//       h6 {
//         font-size: 12px;
//         text-transform: capitalize;
//         margin-bottom: 10px;
//       }

//       h5 {
//         font-size: 12px;
//         color: grey;

//         svg {
//           font-size: 20px;
//           margin-right: 2px;
//         }
//       }
//     }
//   }

//   .name {
//     // background: var(--primary-500-active);
//     display: block;
//     align-items: stretch;
//     box-sizing: content-box;
//     cursor: inherit;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     outline: none;
//     overflow: hidden;
//     position: static;
//     transform: translateZ(0px);
//     transition: transform 200ms ease 0s, opacity 200ms ease 0s;
//     box-shadow: 0 0 0 2px var(--ds-surface-overlay, #ffffff);
//     border: none;

//     span {
//       text-align: center;
//       color: #fff;
//       font-size: 30px;
//     }

//     .upload {
//       position: absolute;

//       color: rgb(255, 255, 255);
//       border-radius: 50%;
//       width: 100%;
//       height: 50px;
//     }

//     img {
//       height: 200px;
//       width: 200px;
//       border-radius: 50%;
//       margin-left: 0px;
//     }
//   }

//   .ptitle {
//     // text-transform: capitalize;
//     width: 100%;
//     padding: 0.475rem 0.1rem;
//     border-radius: var(--borderRadius);
//     background-color: #fff;
//     border: 1px solid #fff;
//     margin-bottom: 0.5rem;
//     font-size: var(--font-12);
//   }

//   .patitle {
//     padding: 0;
//     margin: 0;
//     text-align: center;
//     color: var(--primary-500-active);
//     font-weight: 800 !important;
//   }

//   .wrkhd {
//     font-size: 14px;
//     text-align: left;
//     justify-content: start;
//     display: flex;
//     align-items: flex-start;
//     width: 100%;
//     font-weight: 800;
//   }

//   .prdf {
//     background: var(--grey-50);
//     padding: 15px 30px;
//     border-radius: 5px;
//   }

//   .form-input {
//     padding: 0.475rem 0.5rem;
//   }

//   .edit {
//     text-align: right;
//     font-size: 12px;
//     color: var(--primary-500-active);
//     cursor: pointer;

//     button {
//       font-size: 12px;
//       background: var(--primary-500);
//       color: #fff;
//       margin-top: 20px;
//       padding: 3px 5px;
//       border-radius: 5px;
//     }
//   }

//   .form-label {
//     margin-bottom: 0.5rem !important;
//     margin-top: 8px;
//   }

//   .PhoneInput {
//     margin-top: 0px;
//   }
// `
export const BoardWrapper = styled.div`
 
  .dropdown-menu {
    display: block !important;
    right: 22px;
    margin-top: 100px;
  }
 
  transition: var(--transition);

  a {
    text-decoration: none;
  }

  h3 {
    font-size: 1rem;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .navbar {
    width: 100%;
    background-color: #f1f7ee;
    height: 10vh;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .form__input {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input {
    margin: 0 5px;
    width: 50%;
    padding: 10px 15px;
  }

  .addTodoBtn {
    width: 150px;
    padding: 10px;
    cursor: pointer;
    background-color: #367e18;
    color: #fff;
    border: none;
    outline: none;
    height: 43px;
  }

  .tasktitle {
    // text-transform: capitalize;
    font-size: var(--font-12);
    margin-right: 15px;
    text-align: left !important;
    word-break: break-word;
  }

  .container {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .completed__wrapper h3 {
    padding: 10px 0px 10px 8px;
    margin: 0 5px;
    border-radius: 3px;
    margin-bottom: -5px;
    // box-shadow: var(--shadow-1);
    border: 1px solid rgb(235, 243, 249);
    display: flex;
    position: sticky;
    top: 0;
    background: white;

    p {
      margin: 4px 0 0;
      font-size: var(--font-12);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .count {
      margin: 0;
      margin-top: 3px;
    }
  }

  .completed__wrapper,
  .ongoing__wrapper,
  .pending__wrapper {
    /* width: 32%; */
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    // padding: 5px;
    min-width: 250px;
    // width: 300px !important;
    font-size: var(--font-14);

    @media (min-width: 1400px) {
      flex: 0 0 auto;
      width: calc(25% - 8px) !important;
    }
  }

  .ongoing__wrapper > h3,
  .pending__wrapper > h3,
  .completed__wrapper > h3 {
    // text-align: center;
    text-transform: capitalize;
    margin-left: 5px;
  }

  .pending__items {
    background-color: #fff;
 border:0px solid gray;
    .d-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    p {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
    }

    .my-4 {
      margin-bottom: 0 !important;
      margin-top: 10px !important;
    }
  }

  .ongoing__items {
    background-color: #d2daff;
  }

  .completed__items {
    background-color: #7fb77e;
  }

  .pending__container,
  .ongoing__container,
  .completed__container {
    width: 100%;
    min-height: 55vh;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .pending__items,
  .ongoing__items,
  .completed__items {
    width: 100%;
    margin-bottom: 8px;
    padding: 10px;
    border-radius: 5px;
  }

  .comment {
    text-align: right;
    font-size: 12px;
    cursor: pointer;
    color: rgb(85, 85, 199);
  }

  .comment:hover {
    text-decoration: underline;
  }

  .comments__container {
    padding: 20px;
  }

  .comment__form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .comment__form > label {
    margin-bottom: 15px;
  }

  .comment__form textarea {
    width: 80%;
    padding: 15px;
    margin-bottom: 15px;
  }

  .commentBtn {
    padding: 10px;
    width: 200px;
    background-color: #367e18;
    outline: none;
    border: none;
    color: #fff;
    height: 45px;
    cursor: pointer;
  }

  .comments__section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .login__form {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login__form > label {
    margin-bottom: 15px;
  }

  .login__form > input {
    width: 70%;
    padding: 10px 15px;
    margin-bottom: 15px;
  }

  .login__form > button {
    background-color: #367e18;
    color: #fff;
    padding: 15px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    outline: none;
    width: 200px;
  }

  .serch {
    .icon {
      span {
        height: 37.3px;
      }
    }
  }

  .box {
    background: var(--white);
    margin: 5px 5px;
    padding: 5px;
    height: auto;
    /* min-height: 400px; */
    // box-shadow: var(--shadow-2);
    border-top: 2px solid #ebf3f9;
    border-radius: 4px;
  }

  @media (max-width: 1024px) {
    // .completed__wrapper {
    //   width: 45%;
    // }
  }

  @media (max-width: 1284px) {
    // .completed__wrapper {
    //   width: 45%;
    // }
  }

  @media (max-width: 550px) {
    // .completed__wrapper {
    //   width: 95%;
    // }
    // .box {
    //   width: 300px;
    // }
  }

  @media (max-width: 992px) {
    .row {
      flex-direction: column-reverse;
    }

    .bor-left {
      border-left: none !important;
      margin-bottom: 30px;
    }
  }

  .addtask {
    background: none;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
    float: left;
  }

  .addtask svg {
    // padding: 5px;
    //  margin-bottom: 15px;
    width: 100%;
    font-size: var(--font-14);
    //color: var(--primary-500);
    // color: #fff;
    // border-radius: 35px;
  }

  .add-task {
    position: absolute;
    top: 45px;
    width: auto;
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-2);
    z-index: 999;
    border: 1px solid rgb(220, 232, 235);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--white);
  }

  .add-task form {
    padding: 20px;
    width: 400px;
  }

  .task-count {
    font-size: 8px;
    margin-left: 8px;
    background: var(--primary-500) !important;
    color: rgb(255, 255, 255);
    padding: 5px 5px;
    border-radius: 50%;
    width: 16px;
    display: inline-block;
    height: 16px;
    font-weight: 400;
  }

  .task-container {
    display: flex;
    overflow-y:scroll;
    position: relative;  
    padding: 0 32px;
  }

  .avatar-image {
    display: flex;
    flex-direction: row-reverse;

    img {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      margin-left: -10px;
    }

    span {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid var(--grey-400);
      margin-right: 5px;
      text-align: center;

      padding: 5px;
      font-size: 12px;
      background: #fff;
    }
  }

  .btn-newlist {
    font-size: 12px !important;

    transition: all 0.2s;
    border: 1px solid inherit;
    background: var(--primary-500-active) !important;
    color: #fff !important;
    font-weight: 600 !important;

    @media (max-width: 600px) {
      font-size: 12px !important;
    }

    &:hover {
      background: var(--primary-500) !important;
      color: #fff !important;
    }
  }

  .btn-newlist:hover,
  .btn-newlist:focus {
    border: 0px solid inherit;
    outline: none;
  }

  .goal-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .task-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    p {
      margin: 0;

      text-transform: capitalize;
    }
  }

  .bor-left {
    border-left: 2px solid var(--primary-50);
  }
`
export const ActivityWrapper = styled.div`
padding:32px;
  overflow: auto;

  .capital {
    text-transform: capitalize;
    display: table-cell;

    img {
      width: 30px;
      height: 30px;
      margin-left: 5px;
    }

    .name {
      dispaly: flex;
      flex-direction: column;
      margin-left: 5px;

      p {
        word-break: normal !important;
      }
    }

    p {
      margin: 0;
      word-break: none !important;
    }
  }

  .textLeft {
    margin: 8px 0;
  }
`
/**Project css */
//working on adding project
export const ProjectHeadersWrapper = styled.div`
  position: relative;
  // border-bottom: 1px solid var(--grey-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h5 {
    letter-spacing: 0;
    font-size: 18px;
    // font-weight:400;
  }

  .headers {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }

  svg {
    position: absolute;
    right: 0px;
    top: -5px;
    font-size: 20px;
    color: #8f93a0;
    opacity: 0;
    /* Hide the SVG */
    transition: opacity 0.3s ease;
    /* Add a smooth transition for opacity */
  }

  /* Show the SVG on hover */
  &:hover {
    svg {
      opacity: 1;
      /* Show the SVG on hover */
    }
  }
`
export const ProjectWrapper = styled.div`
padding-bottom:40px;
  .popup-modal {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9999;
    width: 400px;
    background: #fff;
    bottom: 0;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    height: 40px;

    button {
      width: auto !important;
    }
  }

  .green {
    path {
      stroke: #00ff00;
      /* Green color for percentage >= 75 */
    }
  }

  .orange {
    path {
      stroke: #ffa500;
      /* Orange color for percentage >= 50 */
    }
  }

  .red {
    path {
      stroke: #ff0000;
      /* Red color for percentage < 50 */
    }
  }

  .feed {
    text-align: center;
  }

  .btn-block add-btn {
    width: 30px;
  }

  .button-container {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .button-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .dropdown-menu {
    display: block;
    right: 0;
    top: 35px;
    border: none;
    font-weight: 400;
    color: #212529;
    cursor: pointer;
    position: absolute;
    width: 50%;
    background: #fff; 
    border-radius: 5px;

    svg {
      font-size: var(--font-16);
      position: relative;
      top: 0;
      right: 0;
      margin-right: 10px;
      opacity: 1;

      &:hover {
        color: #333;
      }
    }

    .dropdown-item {
      padding: 8px 10px !important;
      font-weight: 500;
      font-size: 12px;
      color: gray;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  a {
    color: inherit !important;
    text-decoration: none !important;
  }

  /* .project-container {
    display: flex;
    flex-wrap: wrap;
  } */

  .head-txt input {
    height: 35px;
    margin-left: 10px !important;

    @media (max-width: 576px) {
      width: 95%;
      margin-top: 15px;
    }
  }

  .head-txt {
    justify-content: space-between;
    display: flex;

    svg {
      font-size: 25px;
      vertical-align: middle;
      color: grey;
    }
  }

  .container {
    background: #fff;
    // height: 140px;
    // min-height: 140px;
    // max-height: 190px;
    height: 300px;
    transition: all 0.2s;
    border-radius: 5px;
    // box-shadow: 0px 0px 50px rgba(42, 89, 152, 0.15);
    padding: 30px 20px;
    border: 1px solid #fff;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .container:hover {
    border: 1px solid rgb(223 237 246);
  }

  .main-icon {
    display: grid;
    place-items: center;
    border-radius: var(--borderRadius);
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-500);
    // margin-right: 1.8rem;

    img {
      width: 80px;
      height: 80px;
      margin-left: 0px !important;
      border-radius: 50% !important;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }

  .img {
    width: 60px;
    height: 60px;
  }

  .info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      margin-bottom: 1rem;
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: var(--font-14);
      color: #374151;
      text-align: center;

      @media (max-width: 500px) {
        font-size: var(--font-14);
      }
    }

    p {
      margin: 0;
      text-align: center !important;
      // text-transform: capitalize;
      color: #0f2740;
      // letter-spacing: var(--letterSpacing);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      font-size: var(--font-12);
      font-weight: 500;
      line-height: 1.2;
      height: 30px;
      //   word-break: break-all !important;
    }
  }

  .progress-bar {
    margin-top: 12px;
  }

  .pending {
    background: #fcefc7;
    color: #e9b949;
  }

  .interview {
    background: #e0e8f9;
    color: #647acb;
  }

  .declined {
    color: #d66a6a;
    background: #ffeeee;
  }

  .content {
    padding: 1rem 1rem;
  }

  .content-center {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;

    line-height: 1.5;

    p {
      font-size: var(--font-15);
    }

    @media (min-width: 576px) {
      // grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 992px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 1120px) {
      // grid-template-columns: 1fr 1fr;
    }
  }

  .avatar-image {
    display: flex;
    flex-direction: row-reverse;

    // width: 220px;
    img {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      margin-left: -18px;
      border: 1px solid lightgrey;
      cursor: pointer;
    }

    img:hover {
      z-index: 1;
    }

    span {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid var(--grey-400);
      margin-right: 12px;
      text-align: center;

      padding: 6px;
      font-size: var(--font-12);
      background: #fff;
      margin-top: 3px;
    }
  }

  .status {
    border-radius: var(--borderRadius);
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
    text-align: center;
    width: 100px;
    height: 30px;
  }

  footer {
    margin-top: 1rem;
  }

  .edit-btn,
  .delete-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
  }

  .edit-btn {
    color: var(--green-dark);
    background: var(--green-light);
    margin-right: 0.5rem;
  }

  .delete-btn {
    color: var(--red-dark);
    background: var(--red-light);
  }

  &:hover .actions {
    visibility: visible;
  }

  @media (min-width: 1120px) {
    // width: 50%;
  }

  .footers {
    display: flex;
    justify-content: space-between;
    // padding: 0px 16px;
    align-items: center;
    margin-top: 25px;

    button {
      font-size: var(--font-11);
      background: rgba(255, 255, 255, 0.3);
      color: #185e5c;
      font-weight: 600;
      border-radius: 8px;
      padding: 2px 10px;
      box-shadow: var(--shadow-3);
      transition: all 0.2s;

      &:hover {
        background: rgba(5, 63, 63, 0.3);
        color: var(--white);
      }
    }

    svg {
      font-size: 18px;
      color: var(--grey-300);
      font-weight: 600;

      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        box-shadow: var(--shadow-2);
      }
    }
  }
`
export const ProjectConfirmationsWrapper = styled.div`
  text-align: center;

  .btn {
    color: var(--main-color);
    font-size: var(--font-14);
    font-weight: 600;

    text-align: center;
    color: #fff;
    margin: 10px;
  }

  .btn:hover {
    color: inherit;
    background: inherit;
  }

  p {
    color: #878787;
    font-size: var(--font-14);
  }
`
export const ProjectListViewWrapper = styled.div`
  overflow: auto;

  .image {
    width: 150px;

    .avatar-image {
      justify-content: center;
    }

    .avatar-image img {
      height: 35px;
      width: 35px;
      border: 1px solid lightgrey;
    }
  }

  h5 {
    margin: 0;
  }

  svg {
    font-size: 20px;
    color: grey;
  }

  .textLeft {
    margin-top: 0;
  }

  .name {
    align-items: center;
    width: 200px;

    img {
      height: 40px;
      width: 40px;
      border-radius: 5px;
      border: 1px solid lightgrey;
      margin-right: 8px;
      margin-left: 0px;
    }
  }

  .dropdown-menu {
    display: block;
    right: 65px;
    top: auto;

    svg {
      margin-top: 0;
    }
  }
`
export const SearchInside = styled.div`
  width: fit-content;

  .searches {
    display: flex;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 3px 12px;
    align-items: center;
  }

  input {
    border: none;
    outline: none;
    padding: 0 !important;
    &:focus {
      outline: none !important;
      border: 0px solid #1577b3 !important;
      border-radius: 0px;
    }
  }

  svg {
    color: lightgrey;
    font-size: 20px;
  }

  .form-row {
    margin-bottom: 0 !important;
  }

  .form-label {
    display: none !important;
  }

  .search-input::placeholder {
    color: #999;
    /* Change the color of the placeholder text as needed */
  }
`
