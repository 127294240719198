import styled from "styled-components"

export const ClientWrapper = styled.article`
  // margin-top: 30px;
  background: var(--white);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 1fr;

  .responsiveTable td .tdBefore {
    font-size: 14px;
  }

  table {
    box-shadow: var(--shadow-2);
  }
  svg {
    font-size: 16px;
    color: grey;
  }

  .main-icon {
    width: 25px;
    height: 25px;
    display: grid;
    place-items: center;
    background: var(--primary-500);
    border-radius: var(--borderRadius);
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    margin-right: 1rem;
    margin-top: 8px;
  }
  .main-icon img {
    width: 25px;
    height: 25px;
  }

  .dropdown-menu {
    display: block;
    right: 80px;
  }

  .dropdown-item .text {
    font-size: 12px;
  }
  hr {
    margin: 30px 0;
  }
  .status {
    text-align: right;
    color: var(--grey-400);
  }

  .actives {
    color: rgb(79, 190, 121) !important;
  }

  .pending {
    background: #fcefc7;
    color: #e9b949;
  }
  .interview {
    background: #e0e8f9;
    color: #647acb;
  }
  .declined {
    color: #d66a6a;
    background: #ffeeee;
  }
  .content {
    padding: 1rem 1.5rem;
  }
  h4 {
    cursor: pointer;
    svg {
      color: grey;
    }
  }
  .content-center {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    @media (min-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 1120px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .status {
    /* border-radius: var(--borderRadius);
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
    text-align: center;
    width: 100px;
    height: 30px; */
  }
  footer {
    margin-top: 1rem;
  }
  .edit-btn,
  .delete-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
    text-align: center;
    justify-content: center;
    padding-bottom: 30px !important;
  }
  .edit-btn {
    color: var(--green-dark);
    background: var(--green-light);
    margin-right: 1.5rem;
  }
  .delete-btn {
    color: var(--red-dark);
    background: var(--red-light);
  }
  &:hover .actions {
    visibility: visible;
  }
  @media (max-width: 1120px) {
    /* width: 50%; */
    table thead th,
    table tbody td {
      padding: 6px;
    }
    .responsiveTable tbody tr {
      margin-bottom: 10px;
      border: 1px solid #dce8eb;
    }
    table {
      box-shadow: none;
    }
  }

  @media (max-width: 640px) {
    .responsiveTable tbody tr {
      box-shadow: var(--shadow-2);
    }

    .dropdown-menu {
      top: -111px;
    }
  }

  .paginationed {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    position: relative;
    button {
      border: 2px solid var(--primary-500);
      background: var(--primary-500);
      border-radius: 2px;
      color: #fff;
      margin-left: 2px;
      height: 37px;
    }
    input {
      width: 50px;
      height: 37px;
    }
  }

  .serch {
    .icon {
      span {
        height: 37.3px;
      }
    }
  }
`
