// codeReviewTypes.js
export const OPEN_CODE_REVIEW_MODAL = 'OPEN_CODE_REVIEW_MODAL';
export const CLOSE_CODE_REVIEW_MODAL = 'CLOSE_CODE_REVIEW_MODAL';

export const CREATE_CODE_REVIEW_BEGIN = 'CREATE_CODE_REVIEW_BEGIN';
export const CREATE_CODE_REVIEW_SUCCESS = 'CREATE_CODE_REVIEW_SUCCESS';
export const CREATE_CODE_REVIEW_ERROR = 'CREATE_CODE_REVIEW_ERROR';

export const GET_CODE_REVIEWS_BEGIN = 'GET_CODE_REVIEWS_BEGIN';
export const GET_CODE_REVIEWS_SUCCESS = 'GET_CODE_REVIEWS_SUCCESS';
export const GET_CODE_REVIEWS_ERROR = 'GET_CODE_REVIEWS_ERROR';

export const SET_IS_REVIEW_ID = "SET_IS_REVIEW_ID";
export const SET_EDIT_TASK_FOR_CODE_REVIEW= "SET_EDIT_TASK_FOR_CODE_REVIEW";
 
export const GET_CODE_REVIEW_BY_ID_BEGIN = 'GET_CODE_REVIEW_BY_ID_BEGIN';
export const GET_CODE_REVIEW_BY_ID_SUCCESS = 'GET_CODE_REVIEW_BY_ID_SUCCESS';
export const GET_CODE_REVIEW_BY_ID_ERROR = 'GET_CODE_REVIEW_BY_ID_ERROR';

export const EDIT_CODE_REVIEW_BEGIN = 'EDIT_CODE_REVIEW_BEGIN';
export const EDIT_CODE_REVIEW_SUCCESS = 'EDIT_CODE_REVIEW_SUCCESS';
export const EDIT_CODE_REVIEW_ERROR = 'EDIT_CODE_REVIEW_ERROR';

export const DELETE_CODE_REVIEW_BEGIN = 'DELETE_CODE_REVIEW_BEGIN';
export const DELETE_CODE_REVIEW_SUCCESS = 'DELETE_CODE_REVIEW_SUCCESS';
export const DELETE_CODE_REVIEW_ERROR = 'DELETE_CODE_REVIEW_ERROR';

export const DELETE_CODE_REVIEW_ATTACHMENT_REQUEST = "DELETE_CODE_REVIEW_ATTACHMENT_REQUEST";
export const DELETE_CODE_REVIEW_ATTACHMENT_SUCCESS = "DELETE_CODE_REVIEW_ATTACHMENT_SUCCESS";
export const DELETE_CODE_REVIEW_ATTACHMENT_FAILURE = "DELETE_CODE_REVIEW_ATTACHMENT_FAILURE";

export const STATUS_CHECK_REQUEST = 'STATUS_CHECK_REQUEST';
export const STATUS_CHECK_SUCCESS = 'STATUS_CHECK_SUCCESS';
export const STATUS_CHECK_FAILURE = 'STATUS_CHECK_FAILURE';



