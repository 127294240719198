const getMinutes = (value) => {
  const regex = /(\d+)(m|h|d|w)/g
  let match = regex.exec(value)
  let minutes = 0
  while (match != null) {
    const num = parseInt(match[1])
    const unit = match[2]
    if (unit === "m") {
      minutes += num
    } else if (unit === "h") {
      minutes += num * 60
    } else if (unit === "d") {
      minutes += num * 60 * 24
    } else if (unit === "w") {
      minutes += num * 60 * 24 * 7
    }
    match = regex.exec(value)
  }
  return minutes
}

export default getMinutes
