import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FormRow, FormRowSelect } from "../Common"
import { useDispatch } from "react-redux"
import { getActivities } from "../../redux"
import moment from "moment"

const initialState = {
  startDate: moment(Date.now()).format("YYYY-MM-DD"),
  endDate: moment(Date.now()).format("YYYY-MM-DD"),
  resourceType: "task",
  action: "created",
}
const ActivityFilters = () => {
  const [values, setValues] = useState(initialState)
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(values)
    dispatch(getActivities(values))
  }
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    dispatch(getActivities(values))
  }, [])
  return (
    <Wrapper>
      {/* <p>Search activity by date</p> */}
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-2">
          <FormRow
            name="startDate"
            value={values.startDate}
            type="date"
            handleChange={handleChange}
            required={true}
            labelText={"start Date"}
          />
        </div>
        <div className="col-2">
          <FormRow
            name="endDate"
            value={values.endDate}
            type="date"
            handleChange={handleChange}
            required={true}
            labelText={"end date"}
          />
        </div>
        <div className="col-2">
          <FormRowSelect className="col-2"
            name="resourceType"
            labelText={"resource type"}
            value={values.resourceType}
            handleChange={handleChange}
            list={[
              "task",
              "worklog",
              "Project",
              "goal",
              "client",
              "notification",
              "permission",
              "user",
            ]}
          />
        </div>
        <div className="col-2">
          <FormRowSelect
            name="action"
            labelText={"action"}
            value={values.action}
            handleChange={handleChange}
            list={["created", "updated", "deleted"]}
          />
        </div>
        <button className="btn col-2">apply</button>
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  form {
    display: flex;
  }
  input {
    display: inline-block;
  }
  button {
    background: var(--primary-500);
    color: #fff;
    // margin-bottom: 15px;
    // margin-left: 20px;
    height: fit-content;
    margin-top: 21px;
  }
  span {
    margin-left: 20px;
  }
`
export default ActivityFilters
