import React, { useState, useEffect } from "react"
import { useAppContext } from "../../context/context/appContext"
import { Loading2 } from "../Common"
import SingleNotification from "./SingleNotification"
import { NotificationWrapper } from "../../assets/styles/Project"
import { useSelector, useDispatch } from "react-redux"
import { getNotificationByProject } from "../../redux"

const Notification = ({ list, project }) => {
  const { socket, getAdminList, getClientList } = useAppContext()
  const dispatch = useDispatch()
  const { selectedProject } = useSelector((state) => state.project)

  const [admins, setAdmins] = useState(null)
  const [clients, setClients] = useState([])
  const [notifications, setNotifications] = useState(null)

  useEffect(() => {
    getAdminList().then((data) => {
      setAdmins(data)
    })
    // console.log(admins, clients)
    getClientList(project).then((data) => {
      data.map((item) => {
        if (item._id === project._id) {
          return setClients(item.userDetails)
        }
      })
    })
    dispatch(getNotificationByProject(selectedProject._id)).then((data) => {
      setNotifications(data)
    })
  }, [])

  useEffect(() => {
    socket.on("teamMember", async (data) => {
      project.team = data
    })
  }, [socket])

  if (!admins) {
    return <Loading2 />
  }
  if (!notifications) {
    return <Loading2 />
  }

  return (
    <NotificationWrapper>
      <hr />

      {
        <h2>
          <i class="bi bi-bell-fill"></i>
          Notifications
        </h2>
      }
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Worklog</th>
            <th>Task</th>
            <th>Daily</th>
            <th>Weekly</th>
          </tr>
        </thead>

        <tbody>
          <tr className="caption-row">
            <td colSpan="5">
              <b>Team</b>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          {selectedProject.team.map((user, i) => {
            return (
              <SingleNotification
                user={user}
                notification={notifications.find((i) => i.email === user.email)}
                key={i}
              />
            )
          })}
          <tr className="caption-row">
            <td colSpan="5">
              <b>Admin</b>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          {admins &&
            admins.map((admin, i) => {
              return (
                <SingleNotification
                  user={admin}
                  notification={notifications.find(
                    (i) => i.email === admin.email
                  )}
                  key={i}
                />
              )
            })}
          <tr className="caption-row">
            <td colSpan="5">
              <b>Client</b>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          {clients &&
            clients.map((client, i) => {
              return (
                <SingleNotification
                  user={client}
                  notification={notifications.find(
                    (i) => i.email === client.email
                  )}
                  key={i}
                />
              )
            })}
        </tbody>
      </table>
      {/* <button>save</button> */}
    </NotificationWrapper>
  )
}

export default Notification
