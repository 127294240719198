import React, { useEffect } from "react"
import moment from "moment"
import parse from "html-react-parser"
import { CustumTooltip, ProfileAvatar, Loading2 } from "../Common"
import { useAppContext } from "../../context/context/appContext"
import { capitalizeDescription } from "../../utils/getInitialName"
import { getWorklogTask, setEditingWorklog, deleteWorklog } from "../../redux"
import { useSelector, useDispatch } from "react-redux"
import { WorklogWrapper } from "../../assets/styles/Project"
import { DeleteIcon, DriveFileRenameOutlineSharpIcon } from "../../assets/icons"

const Worklog = () => {
  const { editingTask, user, accessList } = useAppContext()
  const { worklogs, loading } = useSelector((state) => state.worklog)
  const dispatch = useDispatch()

  useEffect(() => {
    if (editingTask) {
      dispatch(getWorklogTask(editingTask._id))
    }
  }, [])

  if (!worklogs || worklogs.length === 0) {
    return <p>No work log to display. Please create one.</p>
  }
  if (loading) {
    return <Loading2 />
  }
  const editWorklog = (id) => {
    dispatch(setEditingWorklog(id))
  }

  function convertMinutesToHoursAndMinutes(minutes) {
    const days = Math.floor(minutes / (60 * 24))
    const remainingHours = Math.floor((minutes % (60 * 24)) / 60)
    const remainingMinutes = minutes % 60

    if (days > 0) {
      return `${days}d ${remainingHours}h ${remainingMinutes}m`
    } else {
      return `${remainingHours}h ${remainingMinutes}m`
    }
  }
  return (
    <WorklogWrapper>
      {/* <h3>work logs</h3> */}
      <br />
      <div className={worklogs.length > 4 && "table-sc"}>
        <table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th className="textLeft">Description</th>
              <th className="date-w">Start Date</th>
              <th className="date-w">Time Spent</th>
              <th className="textCenter">Action</th>
            </tr>
          </thead>
          <tbody>
            {worklogs.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="profilepics upperCase">
                    <p style={{ display: "flex", alignItems: "center" }}>
                      {<ProfileAvatar id={item.userId} size={"s"} />}
                      {item.name}
                    </p>
                  </td>
                  <td className="textLeft">
                    <div className="worklog-description">
                      {parse(capitalizeDescription(item.workDescription))}
                    </div>
                  </td>
                  <td className="date-w">
                    {moment(item.dateStarted).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {item.timeSpent > 0
                      ? convertMinutesToHoursAndMinutes(item.timeSpent)
                      : "0 h"}
                  </td>
                  <td className="action textCenter">
                    <div className="d-flex">
                      <CustumTooltip
                        text={"Edit worklog"}
                        children={
                          <DriveFileRenameOutlineSharpIcon
                            onClick={() => {
                              editWorklog(item._id)
                            }}
                          />
                        }
                        position={"bottom"}
                        id={`edit-tooltip`}
                      />
                      {accessList.includes("DELETE_WORKLOG") && (
                        <CustumTooltip
                          text={"Delete worklog"}
                          children={
                            <DeleteIcon
                              style={{
                                fontSize: "18px",
                                color: "rgb(203, 48, 69)",
                                marginTop: "3px",
                              }}
                              onClick={() => dispatch(deleteWorklog(item._id))}
                            />
                          }
                          position={"bottom"}
                          id={`delete-tooltip`}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </WorklogWrapper>
  )
}

export default Worklog
