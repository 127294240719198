import styled from "styled-components"

export const PermissionWrapper = styled.div`
  .h {
    display: none;
  }

  .g {
    /* display: block; */
    transition: all 0.2s;
  }
  .search-by-role {
    display: flex;
    width: 100%;
    background: var(--white);
    border-radius: var(--borderRadius);
 
    transition: var(--transition);
    /* max-width: var(--fixed-width); */
    margin-bottom: 20px;
    .btn-block {
      margin-left: 20px;
      width: auto;
    }
    label {
      margin-top: 5px;
    }
  }
  h2 {
    font-size: var(--font-12);
    margin: 20px 0px 2px;
    background-color: #edf5fd;
    padding: 10px;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    font-weight: 500;
    border: 1px solid #edf5fd;
    svg {
      margin-right: 10px;
    }
  }
  p {
    margin: 0;
    font-size: var(--font-14);
  }
  .permission {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background: #fafafa;
    border: 1px solid #fafafa;
    padding: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    p {
      font-size: 12px !important;
    }
    @media (max-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
  .permission-list {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    p {
      font-size: var(--font-12);
    }
  }
  input {
    height: 15px;
    width: 15px;
    margin-right: 20px;
    cursor: pointer;
  }

  .form-row {
    display: flex;
    width: 35%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .form-label {
    width: 15%;
  }
  .from-select {
    width: 40%;
  }
`
export const RoleWrapper = styled.div`
   
      padding: 0 32px;
    overflow-y: scroll;
    height: 70vh;
  .form {
    display: flex;
    max-width: var(--fixed-width);
    width: 100%;
    background: var(--white);
    border-radius: var(--borderRadius);
    box-shadow: none !important;
    padding: 1rem;
    transition: var(--transition);
    margin: 0;
    margin-bottom: 20px;

    button {
      margin: 33.2px 10px;
      height: 35px;
    }
  }
  .role-list {
    display: flex;
    flex-direction: column;
    max-width: var(--fixed-width);
    // box-shadow: var(--shadow-2);
    //  padding: 1rem;
    margin-top: 20px;
    p {
      margin: 0;
      font-size: var(--font-16);
    }
    label {
      font-size: var(--font-18);
    }
  }
  input {
    text-transform: capitalize;
  }
  .userRole {
    display: flex;
    justify-content: space-between;
  }
  .custom-modal {
    position: fixed;
    opacity: 1;
    display: block;

    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    top: 8rem;
  }

  .modal-dialog-centered {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    min-height: auto;
    /* background-color: #fff; */
    padding: 20px;
  }
  .btn-add-card {
    border-radius: 5px;
    background-color: var(--primary-500-active);
    font-size: var(--font-14);
    font-weight: 500 !important;
    text-align: center;
    color: #fff;

    svg {
      color: #fff;
    }
  }
  .modal-header {
    background-color: aliceblue;
    button {
      background-color: none;
    }
  }

  svg {
    cursor: pointer;
    font-size: var(--font-16);
    margin: 0 5px;
    color: #333;
  }
`

export const UserWrapper = styled.div`
  margin-top: 30px;
  height:70vh;
  overflow-y:scroll;
  // display: flex;
  justify-content: center;
  align-items: center;
  select[name="role"] {
    text-transform: capitalize;
  }
  .form {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 40px;
    max-width: 100%;
    width: 100%;
  }
  .actives {
    position: absolute;
    font-size: 12px;

    bottom: 10px;
    left: 30px;
    svg {
      color: #01bf01;
      font-size: 11px;
    }
  }

  .status {
    position: absolute;
    font-size: 12px;

    bottom: 10px;
    left: 30px;
    svg {
      font-size: 11px;
      color: var(--grey-300);
    }
  }
  .lowerCase {
    font-size: var(--font-11);
  }
  td:first-child {
    text-transform: capitalize;
    display: flex;
    border: none;
    position: relative;
    img {
      width: 30px;
      height: 30px;
      margin: 0;
    }
    p {
      margin: 0px 0px 0px 10px;
      font-size: var(--font-12);
      display: flex;
      flex-direction: column;
    }
  }
  p {
    word-break: normal !important;
  }
  td:last-child {
    text-align: center;
  }

  td::nth-child(3) {
    text-align: center;
  }
  .listst ul {
    padding: 0 0 0 10px;
    --icon-space: 1em;
    list-style: none;
    margin-bottom: 0;
    li {
      text-transform: capitalize;
      display: flex;
      position: relative;
      svg {
        font-size: 15px;
        position: absolute;
        right: 0;
      }
    }
    li::marker {
      color: grey;
    }
    li:before {
      content: "⚬";
      font-family: FontAwesome;
      display: inline-block;
      margin-left: calc(var(--icon-space) * -1);
      width: var(--icon-space);
      font-size: 14px;
      color: grey;
    }
  }

  .dropdown-menu {
    display: block;
    right: 40px;
  }
  .text {
    padding: 18px;
    cursor: pointer;
    svg {
      font-size: 14px;
    }
  }
  .paginationed {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    position: relative;
    button {
      border: 2px solid var(--primary-500-active);
      background: var(--primary-500-active);
      border-radius: 2px;
      color: #fff;
      margin-left: 2px;
      height: 37px;
    }
    input {
      width: 50px;
      height: 37px;
    }
  }
  .search-users {
    margin-left: -1px;
    margin-bottom: 5px;
    padding: 5px;
  }
  .serch {
    input {
      margin-top: -5px;
    }
    .icon {
      span {
        margin-top: 2px;
      }
    }
  }
`

export const UserTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  // margin-top: 40px;
  box-shadow: var(--shadow-1);
`

export const UserTableHeader = styled.th`
  padding: 10px;
  // text-align: left;
  padding: 8px;
  font-size: var(--font-12);
  width: 10px;
  text-transform: uppercase;
`

export const UserTableRow = styled.tr`
  background-color: rgb(249, 249, 249);
`

export const UserTableCell = styled.td`
  padding: 10px;
  font-size: var(--font-12);

  svg {
    font-size: 28px;
    color: var(--grey-500);
  }
`
