import styled from "styled-components"

export const DsWrapper = styled.div`
height:100vh;
  display: none;
  @media (min-width: 1100px) {
    display: block;
    transition: var(--transition);
    .sidebar-container {
      background:#f7f9fc;
      min-height: 100%;
      height: 100%;
      width: 230px;
      margin-left: -250px;
      transition: var(--transition);
      border-right: 1px solid var(--grey-200);
    }
    .content {
      position: sticky;
      top: 0;
    }
    .show-sidebar {
      margin-left: 0;
    }

    .nav-links {
   padding:1rem .5rem ;
      display: flex;
      flex-direction: column;
    }
    .nav-link {
      display: flex;
      align-items: center;
      color:  var(--grey-600) !important;
      padding: 0.5rem 0;
      padding-left: 1rem;
      font-size: 15px;
      margin: 2px 5px;
      text-transform: capitalize;
      transition: var(--transition);
      border-radius: 5px;   background:#f7f9fc;
      font-weight:500;
      // letter-spacing:0.4px;
    }
    .nav-link:hover {
      background:#c7e6ff;
      // color: var(--white) !important;
    }
    
    .icon {
      font-size: 1rem;
      margin-right: 1rem;
      display: grid;
      place-items: center;
    }
    .active {
      background:#c7e6ff; !important;
    color:  var(--primary-500-active) !important;
    }
  }
`

export const DsHeader = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #fff;
  padding:8px
`

export const DsSideMenu = styled.div`
  background-color: rgb(247, 249, 252);
  color: #fff;
  min-height: 100vh;
  height: 100%;
  border-right: 1px solid var(--grey-200);
  transition: var(--transition);
  width: 100px;
  .nav-links {
    align-items: center;
  }
  .nav-link {
    display: flex;
    align-items: center;
    color: var(--white);
    text-transform: capitalize;
    transition: var(--transition);
    padding: 0.5rem 0px 0.5rem 1rem;
  }
  .nav-link:hover {
    color: var(--primary-500-active);
  rgb(199, 230, 255)
  }
  .nav-link:hover .icon {
    color: var(--primary-500-active);
  }
  .icon {
    font-size: 1.2rem;
    display: grid;
    place-items: center;
    transition: var(--transition);
  }
  .active {
    color: var(--primary-500-active);
     background:  rgb(247, 249, 252);
    font-weight: 600;
  }
  .active .icon {
    color: var(--primary-500-active);
  }
  .companylogo {
    // background: #fff;
    padding: 0px 10px;

    // border-bottom: 1px solid var(--primary-500-active);
    img {
      width: 85px;
    }
  }
`
export const NaveBar = styled.nav`
  position: sticky;
  width:100%;
  top: 0;
  height: 65px;
  // border-bottom: 1px solid var(--grey-200);
  background:#f7f9fc;
  z-index: 999;
`

export const NavWrapper = styled.nav`
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);    
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  // background: #fdfdfd;
  z-index: 1;
  @media (max-width: 1124px) {
    // position: fixed;
    width: 100%;
  }
  .logo {
    display: flex;
    align-items: center;
    width: 100px;
  }
  .nav-center {
    display: flex;
    width: 90vw;
    align-items: center;
    justify-content: space-between;
    button {
      
      .toggle-btn {
        &:hover {
          background: #f7f9fc !important;

          color: var(--primary-500) !important;
        }
      }
    }
  }
  .toggle-btn { 
    border: none;
    font-size: 4rem !important;
    color: var(--primary-500-active);
    cursor: pointer;
    display: flex;
    align-items: center;
    background:inherit;
    box-shadow: none; 
    margin-left:-15px;
  }

  .btn-container {
    position: relative;
    cursor: pointer;
    padding:0 !important;
    span {
      width: 25px;
      height: 25px;
      background: var(--primary-500-active);
      padding: 8px;
      border-radius: 50%;
      font-size: 15px;
      align-items: center;
      display: flex;
      color: #fff;
    }
    .isactive {
      font-size: 10px !important;
      position: absolute !important;
      right: 0 !important;
      top: 22px;
    }
  }
  .btn-container svg:nth-child(1) {
    font-size: 25px;
    color: var(--grey-400);
    margin-right: 20px;
    cursor: pointer;
  }
  .btn-container svg:nth-child(2) {
    font-size: 30px;
    color: var(--primary-500);
    cursor: pointer;
  }
  .btn-container img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 1px solid #dee2e6;
    cursor: pointer;
    margin-left: 0;
  }
  .btn {
    display: flex;
    align-items: center;
    color: var(--white);
    background: var(--primary-500);
    justify-content: center;
    gap: 0 0.5rem;
    position: relative;
    box-shadow: var(--shadow-2);
  }
  .dropdown {
    position: absolute;
    top: 45px;
    /* left: 50px; */
    right: 0px;
    // width: 250px;
    background: white;
    box-shadow: var(--shadow-5);
    padding: 1rem;
    text-align: center;
    visibility: hidden;
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-2);
    z-index: 4;
    border: 1px solid #dce8eb;
    // transition: var(--transition);
    font-size: 12px;
  }
  .show-dropdown {
    visibility: visible;
    transition: var(--transition);
  }
  .dropdown-btn {
    width: 100%;
    padding: 4px;
    background: transparent;
    border-color: transparent;
    color: rgb(72 84 84);
    font-size: 12px !important;
    cursor: pointer;
    box-shadow: none;
    text-align: left;
    font-weight: normal !important;
    svg {
      font-size: 18px !important;
    }
  }
  .logo-text {
    display: none;
    margin: 0;
  }
  .right-txt {
    display: flex;
  }
  .right-txt input {
    // margin-right: 20px;
    border: none;
    outline: none;
    height: 30px;
    padding: 0px 0px 2px 9px;
    border-radius: 3px;
  }
  .right-txt input:focus {
    border: none;
    outline: none !important;
  }
  .bortop {
    border-top: 1px solid lightgrey;
  }

  @media (min-width: 1100px) {
    .nav-center {
      width: 95%;
    }
    .logo {
      display: none;
    }
    .logo-text {
      display: block;
    }
  }
  @media (max-width: 850px) {
    .toggle-btn {
      margin-left: 0;
    }
  }
  .textLeft {
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .acc {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 0px;
  }
  .dflex {
    display: flex;
    align-items: center;
    img {
      margin-right: 14px;
      // width: 42px;
    }
  }
  hr {
    margin: 0.6rem 0;
  }
  .lowerCase {
    font-weight: 500 !important;
  }
`
export const NavProjectName = styled.div`
  display: flex;

  padding-left: 0.6rem;
  p {
    // box-shadow: var(--shadow-3);
    display: flex;
    align-items: center;
    align-content: center;
    /* background: linear-gradient(
      348deg,
      rgba(170, 211, 213, 1) 0%,
      rgba(231, 241, 241, 1) 0%,
      rgba(163, 226, 226, 1) 100%
    ); */

    width: 100%;
    padding: 7px;
    margin: 0px;
    img {
      height: 30px;
      width: 30px;
      // border: 1px solid #a8d6e1;
      object-fit: cover;
    }
    span {
      font-size: 25px;
      margin-left: 5px;
      text-transform: capitalize; 
      color: var(--primary-500-active);
    }
  }

  @media (max-width: 992px) {
    // display: none;
    padding-left: 0rem;
    p {
      width: 100%;
      padding: 0px;
      span {
        font-size: 20px;
      }
    }
    span {
      margin-left: 18px !important;
    }
  }
`
export const WMenu = styled.div`
  .toolttp {
    display: none !important;
  }
  .tooltipp {
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: var(--shadow-1) !important;
    z-index: 2000 !important;
    font-size: 12px !important;
    .react-tooltip__place-bottom {
      display: none !important;
    }
  }
`
export const SmWrapper = styled.div`
  @media (min-width: 1100px) {
    display: none;
  }
  .sidebar-container {
    position: fixed;
    inset: 0;
    background: var(--grey-50);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;100
    transition: var(--transition);
  }
  .show-sidebar {
    z-index: 1000;
    opacity: 1;
  }
  .content { 
    width: var(--fluid-width);
    height: 95vh;
    border-radius: var(--borderRadius);
    padding: 2rem 2rem;
    position: relative;
    //  display: flex;
    //  align-items: center;
    flex-direction: column;
  }
  .close-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background: transparent;
    border-color: transparent;
    font-size: 2rem;
    color: var(--red-dark);
    cursor: pointer;
  }
  .nav-links {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
  }
  .nav-link {
    display: flex;
    align-items: center;
    color: var(--grey-500)  !important;
    padding: 1rem 0;
    text-transform: capitalize;
    
    transition: var(--transition);
  }
  .nav-link:hover {
    color: var(--primary-500);
    background: var(--white);
  }
  .nav-link:hover .icon {
    color: var(--primary-500);
  }
  .icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    display: grid;
    place-items: center;
    transition: var(--transition);
  }
  .active {
    color: var(--primary-500) !important;
    background: var(--white);
    font-weight:500;
  }
  .active .icon {
    color: var(--primary-500);
  }
`

export const SmHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--primary-500);
  justify-content: space-between;
  font-size: 20px;
  border-bottom: 1px solid grey;
  align-items: baseline;
  svg {
    cursor: pointer;
  }
`
