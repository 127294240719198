import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { useSelector, useDispatch } from "react-redux"
import {
  closeManageGoalModal,
  openGoalModal,
  setEditingGoal,
  deleteGoal,
} from "../../redux"
import moment from "moment"
import {
  capitalizeDescription,
  capitalizeFirstLetter,
} from "../../utils/getInitialName"
import GoalModal from "./GoalModal"
import { ManageWrapper } from "../../assets/styles/Goals"
import { DeleteIcon, DriveFileRenameOutlineSharpIcon } from "../../assets/icons"

const ManageGoalModal = () => {
  const { goals, isManageGoalModalOpen } = useSelector((state) => state.goal)
  const [open, setOpen] = useState(false)
  const [id, setId] = useState()
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(closeManageGoalModal())
  }

  const handleDelete = (id) => {
    setOpen(true)
    setId(id)
  }

  return (
    <Modal
      show={isManageGoalModalOpen}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>manage Goals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ManageWrapper>
          <button
            className="btn mb-4 mt-4"
            onClick={() => dispatch(openGoalModal())}
          >
            Add goal
          </button>
          <GoalModal />
          <div style={{ overflow: "auto" }}>
            <table className="mt-2">
              <thead>
                <tr>
                  <th className="textLeft">Title</th>
                  <th className="textLeft">Description</th>
                  <th className="textLeft">Start Date</th>
                  <th className="textLeft">End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {goals.map((i) => {
                  return (
                    <tr key={i._id}>
                      <td className="textLeft">
                        {capitalizeFirstLetter(i.title)}
                      </td>
                      <td className="textLeft">
                        {capitalizeDescription(i.description)}
                      </td>
                      <td className="textLeft">
                        {moment(i.startDate).format("DD/MM/YYYY")}
                      </td>
                      <td className="textLeft">
                        {moment(i.endDate).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        <DriveFileRenameOutlineSharpIcon
                          onClick={() => dispatch(setEditingGoal(i._id))}
                          style={{
                            fontSize: "15px",
                            color: "#58a3d3",
                          }}
                          className="mx-2"
                        />
                        <DeleteIcon
                          style={{
                            fontSize: "15px",
                            color: "rgb(203 48 69)",
                          }}
                          onClick={() => handleDelete(i._id)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </ManageWrapper>
        <Modal
          show={open}
          onHide={() => {
            setOpen(false)
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="textCenter mt-0">
            <ManageWrapper>
              <p className="mb-4 cent">Are you sure want to delete?</p>
              <button
                className="btn add-btn"
                type="submit"
                onClick={() => setOpen(false)}
              >
                No
              </button>
              <button
                className="btn add-btn"
                type="submit"
                onClick={() => {
                  dispatch(deleteGoal(id))
                  setOpen(false)
                }}
              >
                Yes
              </button>
            </ManageWrapper>
          </Modal.Body>
        </Modal>
      </Modal.Body>
    </Modal>
  )
}

export default ManageGoalModal
