import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { FormRow, FormRowSelect, Loading } from "../../Common";
import FormTextArea from "../../Common/FormTextArea";
import { Dropzone } from "../../Common";
import { DeleteIcon } from "../../../assets/icons";
import { createTestItemsDetailsTemplate, getSingleTestCasesById, getTestCase, updateTestCaseDetails } from "../../../redux";
import { deleteAttachmentForTestCase, deleteRemarkAttachment, getAllTestCasesStatus } from "../../../redux/testcase/tests/testCaseAction";

const TestDetailsModal = ({ templateId, testCaseId, isTestDetailsModalOpen, setIsTestDetailsModalOpen, id, setId, setIsAction }) => {
    const dispatch = useDispatch();
    // const { selectedTestItem, loading } = useSelector((state) => state.testItems);
    const { selectedSingleTestCase, loading2, loading } = useSelector((state) => state.testCase);

    const [values, setValues] = useState({
        title: "",
        status: "Pending",
        userId: "",
        startDate: "",
        description: "",
        steps: "",
        sampleData: "",
        remarks: "",
    });

    const [imageArray, setImageArray] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    useEffect(() => {
        if (id) {
            dispatch(getSingleTestCasesById(id));
        } else {
            setValues({
                title: "",
                status: "Pending",
                userId: "",
                startDate: "",
                description: "",
                steps: "",
                sampleData: "",
                remarks: "",
            });
            setImageArray([]);
            setId("");
        }
    }, [id, dispatch]);

    useEffect(() => {

        if (selectedSingleTestCase && id) {
            setValues({
                title: selectedSingleTestCase?.title,
                status: selectedSingleTestCase?.status,
                userId: selectedSingleTestCase?.userId,
                startDate: selectedSingleTestCase?.startDate,
                description: selectedSingleTestCase?.description,
                steps: selectedSingleTestCase?.steps,
                sampleData: selectedSingleTestCase?.sampleData,
                remarks: selectedSingleTestCase?.remarks,
            });
            // Populate imageArray with URLs of existing attachments
            if (selectedSingleTestCase?.attachments) {
                const attachmentURLs = selectedSingleTestCase?.attachments?.map((attachment) => {
                    // Convert base64 string to a Uint8Array
                    const buffer = Uint8Array.from(atob(attachment.content), (c) => c.charCodeAt(0));
                    const blob = new Blob([buffer], { type: attachment.contentType });  // Create a Blob
                    const imageUrl = URL.createObjectURL(blob);  // Generate an object URL for the Blob
                    return { key: attachment.key, filename: attachment.filename, url: imageUrl };
                });
                setImageArray(attachmentURLs);
            }
        }
    }, [selectedSingleTestCase]);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append("title", values.title);
        formData.append("status_id", values.status);
        formData.append("userId", values.userId);
        formData.append("startDate", values.startDate);
        formData.append("description", values.description);
        formData.append("steps", values.steps);
        formData.append("sampleData", values.sampleData);
        formData.append("remarks", values.remarks);

        imageArray.forEach((file, index) => {
            formData.append(`attachment`, file);
        });

        if (id) {
            dispatch(updateTestCaseDetails(id, formData, testCaseId));
        } else {

            dispatch(createTestItemsDetailsTemplate(testCaseId, formData, testCaseId));
        }

        dispatch(getAllTestCasesStatus(testCaseId)); // Uncomment when using Redux for update
        setValues({
            title: "",
            status: "Pending",
            userId: "",
            startDate: "",
            description: "",
            steps: "",
            sampleData: "",
            remarks: "",
        });
        setIsAction(false)
        // dispatch(closeTestItemModal());
        setId("");
        setImageArray([]);
        setIsTestDetailsModalOpen(false)
    };

    const onDrop = (acceptedFiles) => {
        setImageArray([...imageArray, ...acceptedFiles]);
    };

    const handleRemoveAttachment = (index, attachmentKey) => {

        if (!attachmentKey) {
            const updatedArray = imageArray.filter((_, indx) => indx !== index);
            setImageArray(updatedArray);
        } else {
            const parts = attachmentKey.split('/');
            const keyId = parts[1];
            // Find the index of the attachment to remove
            const attachmentIndex = imageArray.findIndex((attachment) => attachment.key === attachmentKey);

            if (attachmentIndex !== -1) {
                // Update the local state to remove the attachment from the UI
                const updatedArray = imageArray.filter((_, i) => i !== attachmentIndex);
                setImageArray(updatedArray);

                // Dispatch the delete action to remove the attachment from the backend
                dispatch(deleteAttachmentForTestCase(keyId, id));

            }
        }
    };

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        <Modal
            show={isTestDetailsModalOpen}
            onHide={() => {
                // dispatch(closeTestItemModal());
                setId("");
                setIsTestDetailsModalOpen(false)
                setValues({
                    title: "",
                    status: "Pending",
                    userId: "",
                    startDate: "",
                    description: "",
                    steps: "",
                    sampleData: "",
                    remarks: "",
                });
                setImageArray([]);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {loading2 ? <Loading /> : <></>}

            <Modal.Header closeButton>
                <Modal.Title>{id ? "Edit Test Item" : "Create Test Item"}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="">
                    <form className="row" onSubmit={handleSubmit}>
                        <FormRow
                            labelText="Title"
                            name="title"
                            value={values.title}
                            handleChange={handleChange}
                        />
                        <FormTextArea
                            labelText="Description"
                            name="description"
                            value={values.description}
                            handleChange={handleChange}
                        />
                        <FormTextArea
                            labelText="Steps"
                            name="steps"
                            value={values.steps}
                            handleChange={handleChange}
                        />
                        <FormTextArea
                            labelText="Sample Data"
                            name="sampleData"
                            value={values.sampleData}
                            handleChange={handleChange}
                            placeholder="Enter sample data..."
                        />
                        <label htmlFor="attachment">Attachments</label>
                        <Dropzone onDrop={onDrop} loading={false} />
                        <div
                            className="attachmentContainer"
                            style={{ display: "flex", overflowX: "auto", position: "relative" }}
                        >
                            {imageArray.map((image, index) => (
                                <div
                                    key={index}
                                    className=""
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        display: 'flex',
                                        justifyItems: 'center',
                                        alignItems: "center",
                                        margin: 8,
                                        background: "#EBF3F9",
                                        borderRadius: 4,
                                        position: "relative",
                                        transition: "all 0.3s ease"
                                    }}
                                >
                                    <span
                                        className="tw-cursor-pointer "
                                        onClick={() =>
                                            window.open(
                                                image instanceof File ? URL.createObjectURL(image) : image.url,
                                                "_blank"
                                            )
                                        }
                                        style={{ background: '#EBF3F9', marginLeft: 8, marginRight: 8 }}
                                    >
                                        {
                                            image instanceof File
                                                ? image.name
                                                : image.filename // Use Base64 directly if not a File
                                        }
                                    </span>
                                    <DeleteIcon
                                        style={{
                                            position: "absolute",
                                            right: 4,
                                            color: "rgb(203, 48, 69)",
                                            cursor: "pointer",
                                            opacity: hoveredIndex === index ? 1 : 0,
                                            transition: "opacity 0.3s ease",
                                        }}
                                        onClick={() => handleRemoveAttachment(index, image.key)}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="textRight mt-2">
                            <button className="btn add-btn" type="submit" disabled={loading2}>
                                {id ? "Update" : "Submit"}
                            </button>
                            <button
                                type="button"
                                className="btn add-btn"
                                disabled={loading2}
                                onClick={() => {
                                    setId("");
                                    setIsTestDetailsModalOpen(false)
                                    setValues({
                                        title: "",
                                        status: "Pending",
                                        userId: "",
                                        startDate: "",
                                        description: "",
                                        steps: "",
                                        sampleData: "",
                                        remarks: "",
                                    });
                                    setIsAction(false)
                                    setImageArray([]);
                                }}
                            >
                                {"Cancel"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TestDetailsModal;
