import React from "react"
import styled from "styled-components"
import moment from "moment"
import parse from "html-react-parser"
import { FilterContainer } from "./index"

import { ProfileAvatar } from "../../Common"
import {
  capitalizeDescription,
  capitalizeFirstLetter,
  getInitials,
} from "../../../utils/getInitialName"
import { useSelector } from "react-redux"
import PagesIcon from "@mui/icons-material/Pages"

const UserLogs = () => {
  const { allLogs, loading } = useSelector((state) => state.worklog)

  if (loading) {
    return <p>loading</p>
  }
  // if (!logs) {
  //   return <h4>loading</h4>
  // }
  let totalHours = 0
  let totalMinutes = 0

  if (allLogs) {
    for (let i = 0; i < allLogs.length; i++) {
      const { timeSpent } = allLogs[i]
      totalHours += Math.floor(timeSpent / 60)
      totalMinutes += timeSpent % 60
    }
  }

  totalHours += Math.floor(totalMinutes / 60)
  totalMinutes = totalMinutes % 60

  return (
    <Wrapper>
      <FilterContainer />
      {/* {logs && (
        <div className="total-time">
          Total Time: {totalHours} hours {totalMinutes} minutes
        </div>
      )}
      <div style={{ marginTop: "65px" }}></div> */}
      <div className=" tableresponse tw-h-[55vh] tw-overflow-y-scroll">
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Description</th>
              <th>task</th>
              <th>project</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {allLogs &&
              allLogs.map((log) => {
                console.log(log)
                return (
                  <tr key={log._id}>
                    <td className=" img-content">
                      <div className="d-flex">
                        <ProfileAvatar id={log.userId} size="s" />
                        <span>{log.name}</span>
                      </div>
                    </td>
                    <td style={{ wordBreak: "break-all" }}>
                      {parse(capitalizeDescription(log.workDescription))}
                    </td>
                    <td className="textLeft">
                      <p>
                        {log.result &&
                          capitalizeFirstLetter(log.result[0].title)}
                      </p>
                      <span className="f-12 grey">
                        {log.result && log.result[0].key && (
                          <>
                            <PagesIcon fontSize="15" />
                            <span className="Upper">
                              {log.p && getInitials(log.p[0].title)}-
                              {log.result[0].key}
                            </span>
                          </>
                        )}
                      </span>
                    </td>
                    <td>{log.p[0].title}</td>
                    <td> {moment(log.dateStarted).format("DD/MM/YYYY")}</td>
                    <td>
                      {" "}
                      {log.timeSpent > 0
                        ? `${Math.floor(log.timeSpent / 60)}h ${
                            log.timeSpent % 60
                          }m`
                        : "0 h"}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </Wrapper>
  )
}

const Description = styled.div`
  margin-left: 20px;
  @media (max-width: 992px) {
    margin-top: 15px !important;
    margin-left: 0;
  }
`

const Wrapper = styled.div`
  .row {
    box-shadow: var(--shadow-1) !important;
    margin: 15px 0;
    padding: 15px 12px;
    border-left: 4px solid green !important;
    p {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  p {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
  }
  img {
    height: 60px;
    width: 60px;
  }
  .time-spent {
    font-size: 12px;
    font-style: italic;
    color: grey;
  }

  .right-content {
    text-align: end;
    span {
      margin-right: 10px;
    }
  }
  .img-content {
    padding-left: 20px;
    display: table-cell;
  }
  .right-content img,
  .img-content img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid lightgrey;
  }

  .img-content span {
    margin-left: 10px;
  }
  .dsk-view {
    display: block;
  }
  .mb-view {
    display: none;
  }

  @media (max-width: 992px) {
    .dsk-view {
      display: none;
    }
    .mb-view {
      display: block;
    }

    .date-format {
      display: flex;
      margin-top: 12px !important;
      font-size: 12px !important;
      .time-spent {
        margin-left: 5px;
      }
    }
    .img-content {
      display: flex;
      span {
        font-size: 14px;
      }
    }
    .status {
      margin-left: 20px;
      color: orange;
    }
    .rhgtc {
      border-top: 1px solid var(--grey-100);
    }
  }

  .total-time {
    background: #edf5fd;
    font-size: var(--font-12);
    right: 10px;
    padding: 5px;
  }
  table {
    td {
      text-align: left;
    }
    th {
      text-align: left;
    }
  }
`
export default UserLogs
