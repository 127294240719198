import {
  CREATE_QUESTION_BEGIN,
  CREATE_QUESTION_SUBJECTIVE_SUCCESS,
  CREATE_QUESTION_MULTIPLE_SUCCESS,
  CREATE_QUESTION_ERROR,
  GET_ALL_QUESTION_BEGIN,
  GET_ALL_QUESTION_SUBJECTIVE_SUCCESS,
  GET_ALL_QUESTION_MULTIPLE_SUCCESS,
  GET_ALL_QUESTION_ERROR,
  UPDATE_QUESTION_BEGIN,
  UPDATE_SUBJECTIVE_QUESTION_SUCCESS,
  UPDATE_MULTIPLE_QUESTION_SUCCESS,
  UPDATE_QUESTION_ERROR,
  GET_SINGLE_QUESTION_BEGIN,
  GET_SINGLE_QUESTION_SUCCESS,
  GET_SINGLE_QUESTION_ERROR,
  DELETE_QUESTION_BEGIN,
  DELETE_SUBJECTIVE_QUESTION_SUCCESS,
  DELETE_MULTIPLE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,
  OPEN_QUESTION_MODAL,
  CLOSE_QUESTION_MODAL,
  SET_EDIT_MULTIPLE,
  SET_EDIT_SUBJECTIVE,
  CREATE_FEEDBACK_BEGIN,
  CREATE_FEEDBACK_SUCCESS,
  CREATE_FEEDBACK_ERROR,
  OPEN_SUBJECTIVE_MODAL,
  OPEN_MULTIPLE_MODAL,
} from "./feedbackTypes"

const initialState = {
  loading: false,
  questions: [],
  subjective: [],
  multiple: [],
  isAddQuestionModalOpen: false,
  isSubjectiveModalOpen: false,
  isMultipleModalOpen: false,
  editingQuestion: null,
  isEditingQuestion: false,
}

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_QUESTION_MODAL:
      return { ...state, isAddQuestionModalOpen: true }
    case CLOSE_QUESTION_MODAL:
      return {
        ...state,
        isSubjectiveModalOpen: false,
        isMultipleModalOpen: false,
        editingQuestion: null,
        isEditingQuestion: false,
        isAddQuestionModalOpen: false,
      }
    case CREATE_QUESTION_BEGIN:
      return { ...state }
    case CREATE_QUESTION_SUBJECTIVE_SUCCESS:
      return {
        ...state,
        isAddQuestionModalOpen: false,
        editingQuestion: null,
        isEditingQuestion: false,
        subjective: [...state.subjective, action.payload.question],
      }
    case CREATE_QUESTION_MULTIPLE_SUCCESS:
      return {
        ...state,
        isAddQuestionModalOpen: false,
        editingQuestion: null,
        isEditingQuestion: false,
        multiple: [...state.multiple, action.payload.question],
      }
    case CREATE_QUESTION_ERROR:
      return { ...state }
    case GET_ALL_QUESTION_BEGIN:
      return { ...state }
    case GET_ALL_QUESTION_SUBJECTIVE_SUCCESS:
      return { ...state, subjective: action.payload.questions }
    case GET_ALL_QUESTION_MULTIPLE_SUCCESS:
      return { ...state, multiple: action.payload.questions }
    case GET_ALL_QUESTION_ERROR:
      return { ...state }
    case UPDATE_QUESTION_BEGIN:
      return { ...state, loading: true }
    case UPDATE_SUBJECTIVE_QUESTION_SUCCESS:
      console.log(action.payload)
      const filteredSubjectiveQuestions = state.subjective.map((i) => {
        if (i._id === action.payload.updatedQuestion._id) {
          return action.payload.updatedQuestion
        } else {
          return i
        }
      })
      return {
        ...state,
        subjective: filteredSubjectiveQuestions,
        isSubjectiveModalOpen: false,
        editingQuestion: null,
        isEditingQuestion: false,
      }
    case UPDATE_MULTIPLE_QUESTION_SUCCESS:
      console.log(action.payload)
      const filteredObjectiveQuestions = state.multiple.map((i) => {
        if (i._id === action.payload.updatedQuestion._id) {
          return action.payload.updatedQuestion
        } else {
          return i
        }
      })
      return {
        ...state,
        subjective: filteredObjectiveQuestions,
        isMultipleModalOpen: false,
        editingQuestion: null,
        isEditingQuestion: false,
      }
    case UPDATE_QUESTION_ERROR:
      return { ...state }
    case GET_SINGLE_QUESTION_BEGIN:
      return { ...state }
    case GET_SINGLE_QUESTION_SUCCESS:
      return { ...state }
    case GET_SINGLE_QUESTION_ERROR:
      return { ...state }
    case DELETE_QUESTION_BEGIN:
      return { ...state }
    case DELETE_SUBJECTIVE_QUESTION_SUCCESS:
      const filterSub = state.subjective.filter(
        (i) => i._id !== action.payload.deletedQuestion._id
      )
      return { ...state, subjective: filterSub }
    case DELETE_MULTIPLE_QUESTION_SUCCESS:
      const filterMul = state.multiple.filter(
        (i) => i._id !== action.payload.deletedQuestion._id
      )
      return { ...state, multiple: filterMul }
    case DELETE_QUESTION_ERROR:
      return { ...state }
    case SET_EDIT_SUBJECTIVE:
      const question = state.subjective.find((i) => i._id === action.payload.id)
      return {
        ...state,
        editingQuestion: question,
        isSubjectiveModalOpen: true,
        isEditingQuestion: true,
      }
    case SET_EDIT_MULTIPLE:
      const multipleQuestion = state.multiple.find(
        (i) => i._id === action.payload.id
      )
      return {
        ...state,
        editingQuestion: multipleQuestion,
        isMultipleModalOpen: true,
        isEditingQuestion: true,
      }
    case CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        isAddQuestionModalOpen: false,
        isSubjectiveModalOpen: false,
        isMultipleModalOpen: false,
        editingQuestion: null,
        isEditingQuestion: false,
      }
    case OPEN_SUBJECTIVE_MODAL:
      return { ...state, isSubjectiveModalOpen: true }
    case OPEN_MULTIPLE_MODAL:
      return { ...state, isMultipleModalOpen: true }
    default:
      return { ...state }
  }
}

export default feedbackReducer
