import React, { useState,useEffect } from "react"
import { FormRow,Attachments, Dropzone, } from "../Common"
import styled from "styled-components"

import { raiseTicket } from "../../redux"
import { putticketResource } from "../../utils/s3Resources"
import { getAllTicket, closeTicket } from "../../redux"
import { useDispatch, useSelector } from "react-redux"
import { capitalizeFirstLetter } from "../../utils/getInitialName"

const initialState = {
  description: "",
  attach: "",
}
const RaiseTicket = () => {
  const dispatch = useDispatch()

  const { ticket } = useSelector((state) => state.ticket)

  useEffect(() => {
    dispatch(getAllTicket())
  }, [])

  const [values, setValues] = useState(initialState)
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const [isLoadingFile, setIsLoadingFile] = useState(false)
  const onDrop = async (acceptedFiles) => {
    for (let index = 0; index < acceptedFiles.length; index++) {
      setIsLoadingFile(true)

      const form = new FormData()
      form.append("files", acceptedFiles[index])
      await putticketResource(form)

      setIsLoadingFile(false)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(raiseTicket(values))
    setValues(initialState)
  }
  return (
    <Wrapper>
      <h5 className="card-title tw-p-0 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">Raise Ticket</h5>
      <FormRow
        name="description"
        type={"textarea"}
        labelText={"description"}
        value={values.description}
        handleChange={handleChange}
        className="description"
      />

                  {/* <Dropzone
                    onDrop={(files) => onDrop(files)}
                    loading={isLoadingFile}
                   />
                  <Attachments
                    attachments={values.attach}                    
                  /> */}


      <button className="btn add-btn tw-mt-2" onClick={handleSubmit}>
        submit
      </button> 
      <hr/>
      <section   >
        <h5 className="card-title tw-p-0 tw-font-semibold lg:tw-text-xl tw-mt-5 sm:tw-text-[20px] tw-text-sm tw-flex tw-justify-between tw-items-center">Tickets</h5>
        {/* {console.log(ticket)} */}
        {ticket &&
          ticket.length > 0 &&
          ticket.map((i) => {
            return (
              <div className="ticket">
                <p>{capitalizeFirstLetter(i.description)}</p>
                <div className="dfgfdc">
                  <p className="user"><i>- {i.userName[0].name}</i></p>
                  <button
                    className="btn add-btn"
                    onClick={() => dispatch(closeTicket(i._id))}
                  >
                    close ticket
                  </button>
                </div>
              </div>
            )
          })}
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
 padding: 32px;
  .ticket{
        background: aliceblue;
    padding: 10px;
    p{
      font-size:14px;
    }
    .dfgfdc{
      display:flex;
      justify-content:space-between;
    }
    
  }
`
export default RaiseTicket
