import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useAppContext } from "../../../context/context/appContext"
import { FormRow, FormRowSelect } from "../../Common"
import moment from "moment"
import { getWorklogUser } from "../../../redux"
import { useDispatch, useSelector } from "react-redux"

const initialState = {
  project: "all",
  projectId: "all",
  taskId: "all",
  task: "all",
  startDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: moment(new Date()).format("YYYY-MM-DD"),
  // startDate: prev30Days,
  // toDate: today,
}

const FilterContainer = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState(initialState)
  const { user } = useAppContext()
  const { projects } = useSelector((state) => state.project)
  const { tasks } = useSelector((state) => state.task)

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleProjectChange = (e) => {
    if (e.target.value !== "all") {
      const project = projects.find((item) => {
        if (item.title === e.target.value) {
          return item
        }
      })
      setValues({
        ...values,
        project: e.target.value,
        projectId: project._id,
      })
    } else
      setValues({
        ...values,
        project: e.target.value,
        projectId: e.target.value,
      })
  }
  const handleTaskChange = (e) => {
    const task = tasks.find((item) => {
      if (item.title === e.target.value) {
        return item
      }
    })
    setValues({ ...values, task: e.target.value, taskId: task._id })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(getWorklogUser({ id: user._id, filters: values }))
  }

  useEffect(() => {
    console.log(values)
    dispatch(getWorklogUser({ id: user._id, filters: values }))
  }, [])

  // console.log(projects, tasks)
  return (
    <Wrapper>
      <form onSubmit={handleSubmit} className="">
        <div className="row">
          <div className="col-lg-2  col-xs-4 filter-option">
            <FormRowSelect
              labelText="select project"
              name="project"
              value={values.project}
              handleChange={handleProjectChange}
              list={["all", ...projects.map((project) => project.title)]}
            />
          </div>

          <div className="col-lg-2  col-xs-4 filter-option">
            <FormRowSelect
              labelText="select task"
              name="task"
              value={values.task}
              handleChange={handleTaskChange}
              list={["all", ...tasks.map((task) => task.title)]}
            />
          </div>

          <div className="col-lg-2  col-xs-4 filter-option">
            <FormRow
              type="date"
              labelText="to"
              name="startDate"
              value={values.startDate}
              handleChange={handleChange}
              placeholder="Enter Start Date"
            />
          </div>
          <div className="col-lg-2  col-xs-4 filter-option">
            <FormRow
              type="date"
              labelText="from"
              name="toDate"
              value={values.toDate}
              handleChange={handleChange}
              placeholder="Enter End Date"
            />
          </div>
          <div className="col-lg-2  col-xs-4 filter-option">
            <button>Apply</button>
          </div>
        </div>
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  // background: aliceblue;
  border-radius: 10px;
  // padding: 10px 20px;

  svg {
    font-size: 20px;
    background: var(--primary-500);
    color: var(--white);
    padding: 2px;
    width: 35px;
    display: inline-block;
    height: 30px;

    margin-top: -12px;
  }

  form {
    .row {
      box-shadow: none !important;
      border-left: none !important;
      width: 100%;
      label {
        color: #1c093c;
        font-size: 12px;
      }
    }
    button {
      background: var(--primary-500);
      color: #fff;
      border-radius: 5px;
      height: 35px;
      width: 50%;
      font-weight: 600;
      margin-top: 22px;
      @media (max-width: 500px) {
        margin-top: 10px;
        width: 100%;
      }
    }
    select {
      option {
        text-transform: capitalize;
      }
    }
    select,
    input {
      cursor: pointer;
    }
    label {
      margin-bottom: 0;
    }
  }

  .dropdown-menu {
    display: block;
  }
`

export default FilterContainer
/*** 
 * <div class="dropdown filters">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-mdb-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setProjectOpen(!projectOpen)}
              >
                Select Project
              </button>
              {projectOpen && (
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {projects && projects.length > 0 ? (
                    projects.map((project, i) => {
                      return (
                        <li key={project._id}>
                          <div class="form-check dropdown-item">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value={values.project}
                              onChange={handleProjectChange}
                              id="project"
                              name="project"
                            />
                            <label class="form-check-label" htmlFor="project">
                              {project.title}
                            </label>
                          </div>
                        </li>
                      )
                    })
                  ) : (
                    <li>
                      <div class="form-check dropdown-item">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value={"all"}
                          onChange={handleProjectChange}
                          id="project"
                          name="project"
                        />
                        <label class="form-check-label" htmlFor="project">
                          All
                        </label>
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </div> */
