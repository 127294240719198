import {
  CLOSE_TICKET_BEGIN,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_ERROR,
  CREATE_TICKET_BEGIN,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_ERROR,
  GET_ALL_TICKET_BEGIN,
  GET_ALL_TICKET_SUCCESS,
  GET_ALL_TICKET_ERROR,
} from "./ticketTypes"

const initialState = {
  ticket: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TICKET_BEGIN:
      return { ...state }
    case GET_ALL_TICKET_SUCCESS:
      return { ...state, ticket: action.payload.tickets }
    case GET_ALL_TICKET_ERROR:
      return { ...state }
    case CREATE_TICKET_BEGIN:
      return { ...state }
    case CREATE_TICKET_SUCCESS:
      return { ...state }
    case CREATE_TICKET_ERROR:
      return { ...state }
    case CLOSE_TICKET_BEGIN:
      return { ...state }
    case CLOSE_TICKET_SUCCESS:
      const filteredTickets = state.ticket.filter(
        (i) => i._id !== action.payload.updatedTicket._id
      )
      return { ...state, ticket: filteredTickets }
    case CLOSE_TICKET_ERROR:
      return { ...state }
    default:
      return { ...state }
  }
}

export default reducer
