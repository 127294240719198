import {
  GET_ALL_TASKS_BEGIN,
  GET_ALL_TASKS_SUCCESS,
  GET_ALL_TASKS_ERROR,
  GET_TASK_USER_BEGIN,
  GET_TASK_USER_SUCCESS,
  CREATE_TASK_BEGIN,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,
  UPDATE_TASKS_BEGIN,
  UPDATE_TASKS_SUCCESS,
  UPDATE_TASKS_ERROR,
  OPEN_TASK_MODAL,
  CLOSE_TASK_MODAL,
  SET_EDITING_TASK,
  NEW_TASK_CREATED,
  FILTER_TASK_ON_STATUS_UPDATE,
  UPDATE_TASK_SOCKET,
  GET_TASK_BY_GOAL,
  ARCHIVE_TASK,
  GET_TASK_WITHOUT_GOAL_SUCCESS,
} from "./taskTypes"

const initialState = {
  loading: false,
  tasks: [],
  editingTask: null,
  isTaskEditing: false,
  isTaskModalOpen: true,
}

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TASK_BEGIN:
      return { ...state }
    case CREATE_TASK_SUCCESS:
      return { ...state }
    case CREATE_TASK_ERROR:
      return { ...state }
    case NEW_TASK_CREATED:
      const taskAlreadyExist = state.tasks.find(
        (i) => i._id === action.payload.task._id
      )
      if (
        action.payload.task.project === action.payload.project._id &&
        !taskAlreadyExist
      ) {
        return { ...state, tasks: [...state.tasks, action.payload.task] }
      } else {
        return { ...state }
      }
    case GET_ALL_TASKS_BEGIN:
      return { ...state, loading: true }
    case GET_ALL_TASKS_SUCCESS:
      return { ...state, loading: false, tasks: action.payload.tasks }
    case GET_ALL_TASKS_ERROR:
      return { ...state, loading: false }
    case OPEN_TASK_MODAL:
      return { ...state }
    case CLOSE_TASK_MODAL:
      return { ...state, isTaskModalOpen: false, editingTask: null }
    case SET_EDITING_TASK:
      return {
        ...state,
        isTaskEditing: true,
        editingTask: action.payload.task,
        isTaskModalOpen: true,
      }
    case FILTER_TASK_ON_STATUS_UPDATE:
      const tasks = state.tasks.filter((task) => {
        if (task._id === action.payload.id) {
          task.status = action.payload.destination
        }
        return task
      })
      return { ...state, tasks }
    case UPDATE_TASK_SOCKET:
      if (state.editingTask) {
        if (state.editingTask._id === action.payload.id) {
          const key = Object.keys(action.payload.updatedField)

          const b = key[0]
          const a = {
            ...state.editingTask,
            [b]: action.payload.updatedField[b],
          }

          return { ...state, editingTask: a }
        }
      }

      return { ...state }
    case GET_TASK_BY_GOAL:
      return { ...state, tasks: action.payload.tasks }
    case ARCHIVE_TASK:
      const filteredTasks = state.tasks.filter(
        (i) => i._id !== action.payload.id
      )
      return { ...state, tasks: filteredTasks }
    case GET_TASK_WITHOUT_GOAL_SUCCESS:
      return { ...state, tasks: action.payload.tasks }
    default:
      return { ...state }
  }
}

export default taskReducer
