export const CREATE_TASK_BEGIN = "CREATE_TASK_BEGIN"
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS"
export const CREATE_TASK_ERROR = "CREATE_TASK_ERROR"
export const GET_ALL_TASKS_BEGIN = "GET_ALL_TASKS_BEGIN"
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS"
export const GET_ALL_TASKS_ERROR = "GET_ALL_TASKS_ERROR"
export const GET_TASK_USER_BEGIN = "GET_TASK_USER_BEGIN"
export const GET_TASK_USER_SUCCESS = "GET_TASK_USER_SUCCESS"
export const UPDATE_TASKS_BEGIN = "UPDATE_TASKS_BEGIN"
export const UPDATE_TASKS_SUCCESS = "UPDATE_TASKS_SUCCESS"
export const UPDATE_TASKS_ERROR = "UPDATE_TASKS_ERROR"
export const OPEN_TASK_MODAL = "OPEN_TASK_MODAL"
export const CLOSE_TASK_MODAL = "CLOSE_TASK_MODAL"
export const SET_EDITING_TASK = "SET_EDITING_TASK"

export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS"
export const GET_TASK_BY_GOAL = "GET_TASK_BY_GOAL"

export const NEW_TASK_CREATED = "NEW_TASK_CREATED"
export const FILTER_TASK_ON_STATUS_UPDATE = "FILTER_TASK_ON_STATUS_UPDATE"
export const UPDATE_TASK_SOCKET = "UPDATE_TASK_SOCKET"

export const ARCHIVE_TASK = "ARCHIVE_TASK"
export const UNARCHIVE_TASAK = "UNARCHIVE_TASAK"

export const GET_TASK_WITHOUT_GOAL_BEGIN = "GET_TASK_WITHOUT_GOAL_BEGIN"
export const GET_TASK_WITHOUT_GOAL_SUCCESS = "GET_TASK_WITHOUT_GOAL_SUCCESS"
export const GET_TASK_WITHOUT_GOAL_ERROR = "GET_TASK_WITHOUT_GOAL_ERROR"
