import React, { useRef } from "react"
import styled from "styled-components"
import { useState, useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
// import defaultImg from "../assets/images/mobile.png"
import { useAppContext } from "../../context/context/appContext"
import cache from "../../utils/imageCache"
import { ProfileAvatarWrapper } from "../../assets/styles/Common"
import { getProfilePic } from "../../utils/s3Resources"

const ProfileAvatar = ({ id, size }) => {
  const { profilepic, socket } = useAppContext()
  const [image, setImage] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    handleCache(id, size)
  }, [id])

  const handleCache = async (id, size) => {
    const imageBuffer = cache.get(id)

    if (imageBuffer) {
      const blob = new Blob([imageBuffer], { type: "image/jpeg" })
      const imageUrl = URL.createObjectURL(blob)

      setImage(imageUrl)
    } else {
      const response = await getProfilePic(id, size)

      cache.set(id, response?.data)
      const blob = new Blob([response?.data], { type: "image/jpeg" })
      const imageUrl = URL.createObjectURL(blob)

      await setImage(imageUrl)
    }
  }

  useEffect(() => {
    socket.on("profilePicUpdate", async (data) => {
      if (data.id === id) {
        console.log(data)
        cache.delete(data.id)
        const response = await getProfilePic(data.id, size)

        cache.set(id, response.data)
        const blob = new Blob([response.data], { type: "image/jpeg" })
        const imageUrl = URL.createObjectURL(blob)

        await setImage(imageUrl)
      }
    })
  }, [socket])

  return (
    <ProfileAvatarWrapper>
      {isLoading ? (
        <img src={image} alt="n" onerror="this.src='https://spng.pinpng.com/pngs/s/80-805308_placeholder-person-hd-png-download.png'" />
      ) : (
        <RotatingLines
          strokeColor="grey"
          strokeWidth="3"
          animationDuration="0.75"
          width="30"
          visible={true}
        />
      )}
    </ProfileAvatarWrapper>
  )
}

export default ProfileAvatar
