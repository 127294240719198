import {
  HomeIcon,
  ViewKanbanIcon,
  PeopleAltIcon,
  SettingsIcon,
  TrendingUpIcon,
  InsightsIcon,
  LibraryBooksIcon,
  FeedIcon,
  BugReportIcon,
} from "../assets/icons"
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket"
const links = [
  {
    id: 0,
    text: "Home",
    path: "/",
    icon: <HomeIcon />,
  },

  {
    id: 1,
    text: "Client",
    path: "/",
    icon: <ViewKanbanIcon />,
  },

  {
    id: 3,
    text: "Board",
    path: "board",
    icon: <i className="bi bi-clipboard2-data"></i>,
  },
  {
    id: 6,
    text: "Reports",
    path: "reports",
    icon: <i className="bi bi-graph-up-arrow"></i>,
  },
]

export const userLinks = [
  {
    id: 0,
    text: "Home",
    path: "/",
    icon: <HomeIcon />,
  },

  {
    id: 1,
    text: "Board",
    path: "/board",
    icon: <ViewKanbanIcon />,
  },
  {
    id: 5,
    text: "Tests",
    path: "/test_case",
    icon: <BugReportIcon />,
  },
  {
    id: 2,
    text: "Report",
    path: "/report",
    icon: <TrendingUpIcon />,
  },
  {
    id: 3,
    text: "raise ticket",
    path: "/raise_ticket",
    icon: <AirplaneTicketIcon />,
  },
]

export const adminLinks = [
  {
    id: 0,
    text: "Home",
    path: "/",
    icon: <HomeIcon />,
  },
  {
    id: 1,
    text: "Board",
    path: "/board",
    icon: <ViewKanbanIcon />,
  },
  {
    id: 9,
    text: "Tests",
    path: "/test_case",
    icon: <BugReportIcon />,
  },
  {
    id: 2,
    text: "Clients",
    path: "/clients",
    icon: <PeopleAltIcon />,
  },

  {
    id: 4,
    text: "Report",
    path: "/admin_report",
    icon: <TrendingUpIcon />,
  },
  {
    id: 5,
    text: "Settings",
    path: "/settings",
    icon: <SettingsIcon />,
  },
  {
    id: 6,
    text: "activity",
    path: "/activity",
    icon: <InsightsIcon />,
  },
  {
    id: 7,
    text: "Post Question",
    path: "/question_answer",
    icon: <LibraryBooksIcon />,
  },

  {
    id: 8,
    text: "Feedback Report",
    path: "/feedback_report",
    icon: <FeedIcon />,
  },
  {
    id: 3,
    text: "all tickets",
    path: "/all_Ticket",
    icon: <AirplaneTicketIcon />,
  },
]

export default links
