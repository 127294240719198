import React from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const DescriptionBox = ({ handleChangeDescription, value }) => {
  return (
    <ReactQuill
      theme="snow"
      placeholder="Write description "
      modules={DescriptionBox.modules}
      formats={DescriptionBox.formats}
      value={value}
      onChange={handleChangeDescription}
      name="description"
    />
  )
}

export default DescriptionBox

DescriptionBox.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    [{ font: [] }],
    [{ size: ["small", "large"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
    ["code-block"],
  ],
}

DescriptionBox.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "code-block",
]
