import React, { useState, useEffect, useRef } from "react"
import { verifyEmailOTP } from "../../services/repository"
import { useNavigate } from "react-router-dom"
import OtpInput from "react-otp-input"
import Lottie from "react-lottie"
import robotAnimation from "../../animation/74569-two-factor-authentication.json"
import { toast } from "react-toastify"
import { Logo } from "../Common"
import { useAppContext } from "../../context/context/appContext"
import setCookie from "../../utils/setCookie"
import { OTPWrapper, OTPHeader } from "../../assets/styles/Auth"
import { useDispatch } from "react-redux"
import { openFeedbackModal } from "../../redux"

const VerifyOTP = (props) => {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState("")
  const [message, setMessage] = useState("")
  const { setAccessToken, setRefreshToken, setupUser } = useAppContext()

  const navigate = useNavigate()
  const otpInputRef = useRef(null)
  const submitButtonRef = useRef(null)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault() // Prevent form submission on Enter key
        handleVerifyOtp()
      }
    }

    const otpInput = otpInputRef.current
    if (otpInput) {
      otpInput.addEventListener("keydown", handleKeyDown)
    }

    return () => {
      if (otpInput) {
        otpInput.removeEventListener("keydown", handleKeyDown)
      }
    }
  }, [])

  const handleVerifyOtp = async () => {
    if (otp === "") {
      alert("Please enter OTP")
      return
    }
    if (isNaN(otp)) {
      toast.error("OTP is incorrect")
      return
    }
    try {
      const result = await verifyEmailOTP(props.email, otp)

      if (result.data.status === 400) {
        toast.error("OTP is incorrect")
        return
      }

      if (result.data.status === true) {
        localStorage.setItem("User", JSON.stringify(result.data.user))
        console.log("userdata")
        console.log(result.data.user)
        setupUser(result.data.user)
        const { accessToken, refreshToken } = result.data
        console.log(accessToken)
        setCookie("accessToken", accessToken, 8000, "/")
        setCookie("refreshToken", refreshToken, 864000, "/")
        await setAccessToken(accessToken)
        await setRefreshToken(refreshToken)
        if (result.data.user.role !== 'admin' && result.data.user.clientId.length === 0) {
          dispatch(openFeedbackModal())
        }

        navigate("/")
      } else {
        navigate("/signup")
      }
    } catch (error) { }
  }

  useEffect(() => {
    const submitButton = submitButtonRef.current
    if (submitButton) {
      submitButton.focus()
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    handleVerifyOtp()
  }

  return (
    <div className="">
      <div className="container main signin">
        <div className="row">
          <Logo />
          <div className="col-md-6 left col-xs-12">
            <div className="input-box ">
              <OTPHeader>
                Enter OTP
                <br />
                <p>Check your email to verify</p>
              </OTPHeader>
              <OTPWrapper>
                <form onSubmit={handleSubmit}>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => (
                      <input ref={otpInputRef} {...props} />
                    )}
                    shouldAutoFocus={true}
                  />
                  <span>{message === "" ? null : message}</span>
                  <div className="form-group mb-3 text-center">
                    <button
                      ref={submitButtonRef}
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={otp.length === 6 ? false : true}
                      style={{ margin: "20px 0", width: "50%" }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </OTPWrapper>
            </div>
          </div>
          <div className="col-md-6 side-image">
            <Lottie
              options={{
                animationData: robotAnimation,
                loop: true,
                autoplay: true,
              }}
              // height={200}
              width="100%" //https://media.giphy.com/media/eltkEbq0Zl4aGQHisf/giphy.gif
            />
            {/* <img
              src="https://media.giphy.com/media/14bdkEoNrpRk8TqUFC/giphy.gif"
              alt=""
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyOTP
