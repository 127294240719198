import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { FormRow } from "../Common"
import { useAppContext } from "../../context/context/appContext"
import { editProject } from "../../redux"
import { useDispatch, useSelector } from "react-redux"

const initialState = {
  title: "",
  status: "none",
}
const NewListModal = ({ list, setTaskList }) => {
  const { selectedProject } = useSelector((state) => state.project)
  const dispatch = useDispatch()

  const [values, setValues] = useState(initialState)

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(
      editProject({
        ...selectedProject,
        // statusCategories: selectedProject.statusCategories.push(values.title),
        statusCategories: [...selectedProject.statusCategories, values.title],
      })
    )
    setTaskList(false)
  }

  return (
    <Modal
      show={true}
      onHide={() => setTaskList(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create New List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="" onSubmit={handleSubmit}>
          <FormRow
            type="text"
            name="title"
            labelText="Title"
            value={values.title}
            handleChange={handleChange}
            placeholder="Enter Title"
          />

          <div className="textRight">
            <button className="btn" type="submit">
              submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default NewListModal
