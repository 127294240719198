import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddSharpIcon, ArrowBackIcon, DeleteIcon, DriveFileRenameOutlineSharpIcon, FiberManualRecordRoundedIcon, MoreHorizRoundedIcon, SearchIcon } from "../../assets/icons";
import { TestCaseWrapper } from "../../assets/styles/TestCase";
import { Loading2 } from "../Common";
import TestDetailsModal from "./TestDetails/TestDetailsModal";
import { deleteTestCaseDetails, getAllTestCaseDetailsItems, setDateRange } from "../../redux";
import DeleteConfirmationModal from "../Common/DeleteConfirmationModal";
import { useAppContext } from "../../context/context/appContext";
import moment from "moment";
import styled from "styled-components";
import TestsRemarkModal from "./TestDetails/TestsRemarkModal";
import { getAllTestCasesStatus } from "../../redux/testcase/tests/testCaseAction";

const initialDateState = {
  startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

const TestCaseDetails = () => {
  const dispatch = useDispatch();
  const {
    getUsersList,
    getAllUsers,
    usersSearchByName,
    deleteSingleRole,
  } = useAppContext()
  const { testCaseDetailsItem, statusCount, loading } = useSelector((state) => state.testCase);
  const { testCaseItems } = testCaseDetailsItem || {};
  const { user, } = useAppContext()
  const navigate = useNavigate();
  const location = useLocation();
  const { testCaseId, templateId, testCaseName } = location.state || {};
  const [testCaseIds, setTestCaseId] = useState(testCaseId)
  const [templateIds, setTemplateId] = useState(templateId)

  const [id, setId] = useState("");
  const [isTestDetailsModalOpen, setIsTestDetailsModalOpen] = useState(false);
  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isTestCaseDelete, setIsTestCaseDelete] = useState(false);

  const [dateRanges, setDateRanges] = useState(initialDateState);
  const [allUsers, setAllUsers] = useState([]);
  

  const handleBack = () => {
    navigate("/test_case");
  };

  useEffect(() => {
    dispatch(setDateRange(dateRanges))
    dispatch(getAllTestCasesStatus({caseId: testCaseId}))
    dispatch(getAllTestCaseDetailsItems(testCaseId));
  }, [dispatch, isTestCaseDelete]);

  const getAllUser =async () => {
      const users = await getUsersList()
      setAllUsers(users)
      return users;
  }

  useEffect(() => {
    dispatch(getAllTestCaseDetailsItems(testCaseId));
    dispatch(getAllTestCasesStatus({caseId: testCaseId}))
    getAllUser();

  }, [testCaseId, id, isRemarkModalOpen]);

  useEffect(() => {
    if (testCaseId) {
      setTestCaseId(testCaseId)
      setTemplateId(templateId)
    }
  }, [testCaseId, templateId]);

  const handleEditClick = (testCaseDetailsId) => {
    // navigate(`/test_case/details/edit`, { state: { testCaseDetailsId, testCaseId, templateId } });
    setIsTestDetailsModalOpen(true);
    setId(testCaseDetailsId);
  };

  const handleEditRemarkClick = (testCaseDetailsId) => {
    // navigate(`/test_case/details/edit`, { state: { testCaseDetailsId, testCaseId, templateId } });
    setIsRemarkModalOpen(true);
    setId(testCaseDetailsId);
  };

  const handleAction = (actionId) => {
    setIsAction(!isAction);
    setId(actionId);
  };

  //All about delete functions
  const handleDeleteClick = (testCaseId) => {
    setId(testCaseId);
    setIsTestCaseDelete(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteTestCaseDetails(id, testCaseId));
    setIsTestCaseDelete(false);
    setId("");
    setIsAction(false)
  };

  const handleDeleteClose = () => {
    setId("");
    setIsTestCaseDelete(false);
  };
  
  return (
    <TestCaseWrapper>
      <div className="card-title">
        <TestDetailsModal templateId={templateIds} testCaseId={testCaseId} isTestDetailsModalOpen={isTestDetailsModalOpen} setIsTestDetailsModalOpen={setIsTestDetailsModalOpen} id={id} setId={setId} isAction={isAction} setIsAction={setIsAction} />
        <TestsRemarkModal getAllUser={getAllUser} templateId={templateIds} testCaseId={testCaseId} isTestDetailsModalOpen={isRemarkModalOpen} setIsTestDetailsModalOpen={setIsRemarkModalOpen} id={id} setId={setId} />
        <DeleteConfirmationModal show={isTestCaseDelete} handleClose={handleDeleteClose} handleConfirm={handleDeleteConfirm} text={"Are you sure you want to delete this Test Case"} />
        {loading ? <Loading2 /> : <></>}
        <Wrapper>
          <h5 className="mt-2" style={{ marginLeft: 10 }}>
            <span onClick={handleBack}>
              <ArrowBackIcon style={{ fontSize: 20 }} />
            </span>{" "}
            {testCaseName}
          </h5>
          <div className="gap-4 testCaseHeader">
            <div className="rangeContainer">
              <button
                className="btn add-btn mt-2"
                type="submit"
                onClick={() => {
                  // dispatch(openTestItemModal());
                  setIsTestDetailsModalOpen(true);
                  setId("");
                }}
              >
                <AddSharpIcon style={{ color: "#fff", fontSize: "20px", }} /> Add
              </button>
            </div>
            {/* test cases filters date range  wise */}
            <div className="status">
              <span className="badgesContainer" style={{ position: 'relative', color: '#374151' }}>
                Pending <span className="badges" style={{ color: 'gray' }}>{statusCount?.Pending}</span>
              </span>
              <span className="badgesContainer" style={{ position: 'relative', color: '#374151' }}>
                Pass <span className="badges" style={{ color: '#0b9f5c', }}>{statusCount?.Pass}</span>
              </span>
              <span className="badgesContainer" style={{ position: 'relative', color: '#374151' }}>
                Fail <span className="badges" style={{ color: '#e80707', }}>{statusCount?.Fail}</span>
              </span>
            </div>
          </div>
          <div className="mt-4">
            <div className="tableresponse">
              <div className='table-wrapper'>
                <table>
                  <thead>
                    <tr>
                      <th className='textLeft'>#</th>
                      <th className='textLeft'>Title</th>
                      <th className='textLeft'>Description</th>
                      <th className="textLeft">User</th>
                      <th className="textLeft">Start Date</th>
                      <th className="">Status</th>
                      <th className="textRight" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testCaseItems && testCaseItems.length > 0 ? (
                      testCaseItems.map((testItems, i) => (
                        <tr key={testItems._id}>
                          <td style={{ width: "20px" }}>{i + 1}</td>
                          <td className="" style={{ textAlign: "left" }}>
                            <span onClick={() => handleEditRemarkClick(testItems._id)} className="testcase-link">{testItems.title}</span>
                          </td>
                          <td style={{ textAlign: 'left', }}>{testItems.description}</td>
                          <td style={{ textAlign: 'left', width: 'auto' }}>
                            {testItems.status_id != 1 ? allUsers && allUsers
                              .filter((user) => user.value === testItems.user_id)
                              .map((user) => user.label) // Extract the `label` of the matched user
                              : ''
                            }
                          </td>                         
                           {/* <td style={{ textAlign: "left", width: 'auto' }}>{formatDate(testItems.tested_on)}</td> */}
                          <td className="textLeft" style={{ width: "auto" }}>
                            {testItems.status_id != 1 ? testItems.tested_on ? moment(testItems.tested_on).format("DD/MM/YYYY") : '' : ""}
                          </td>
                          <td>
                            <span>
                              <FiberManualRecordRoundedIcon
                                style={{ color: testItems.status_id == 2 ? "#0b9f5c" : testItems.status_id == 3 ? "red" : "gray" }}
                              />
                            </span>
                          </td>
                          <td style={{ textAlign: 'end', }}>
                            <h4 onClick={(e) => handleAction(testItems._id)}>
                              <MoreHorizRoundedIcon
                                style={{ fontSize: "25px" }}
                              />
                            </h4>
                            {id === testItems._id && isAction ? (
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenu2"
                              >
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleEditClick(testItems._id)}
                                >
                                  <DriveFileRenameOutlineSharpIcon />
                                  <span className="text"> Edit </span>
                                </button>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => { handleDeleteClick(testItems._id) }}
                                >
                                  <DeleteIcon />
                                  <span className="text"> Delete </span>
                                </button>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">No test cases found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </TestCaseWrapper>
  );
};

const Wrapper = styled.div`
  .testCaseHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    padding-right: 30px;    
  }
  .rangeContainer {
    display: flex;
    justify-content: justify-center;
    align-items: center; 
    gap: 10px;
  }
  .status {
    display: flex;
    ${'' /* gap: 1px; */}
  }
  .badgesContainer {
    position: relative;
    font-size: 10px;
    width: 50px;
    text-align: center;
    border-radius: 14px;
    padding: 1px 0px;
    font-weight: 800;
  }
  .badges {
    position: absolute;
    bottom: 9px;
    right: 5px;
    text-align: center;
    ${'' /* color: '#374151'; */}
    color: 'black';
    padding: 6px;
    border-radius: 50%;
    width: 40px;
    font-size: 18px;
    font-weight: 800;
  }
`;
export default TestCaseDetails;
