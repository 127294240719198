import styled from "styled-components"

export const AvatarWrapper = styled.div` 
display: flex;
flex-direction: row-reverse;

img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: -10px;
  background: #ebf3f9;
}
`
export const BreadCrumbWrapper = styled.div` 
text-transform: capitalize;
font-size: 13px;
padding: 20px 40px 0px;

a {
  text-decoration: none !important;
  color: inherit !important;
}

.crumb a {
  background: aliceblue;
  border-radius: 5px;
  padding: 2px;
  font-size: 13px;
  line-height: 1.2;
  margin-left: 5px;
  text-transform: capitalize;
}

.crumb:after {
  content: "/";
  margin-left: 5px;
}

.crumb:last-child:after {
  content: "";
}

.sdf {
  display: flex;
}

@media (max-width: 1100px) {
  .sdf {
    display: flex;
    margin-top: 60px;
    margin-left: -15px;
  }
}

.themeSwitch {
  position: absolute;
  float: right;
  right: 18px;
  top: 20px;
  z-index: 1000;

  @media (max-width: 992px) {
    top: 80px;
  }
}

.form-switch .form-check-input {
  visibility: hidden;
}
`
export const CircleLoadingWrapper = styled.div` 
top: 50%;
outline: none;
position: absolute;
left: 50%;
transform: translate(-50%, -50%) !important;
border-radius:50%;
box-shadow: var(--shadow-1);
z-index: 999;

.loader {
  margin: auto;
  border: 8px solid var(--primary-500);
  border-radius: 50%;
  border-top: 7px solid lightgrey;
  width: 100px;
  height: 100px;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
`
export const ConfirmBoxWrapper = styled.div` 
text-align: center !important;

.btn {
  color: var(--main-color);
  font-size: var(--font-14);
  font-weight: 600;
  text-align: center;
  color: #fff;
  margin: 10px;
}

.btn:hover {
  color: inherit;
  background: inherit;
}

p {
  color: #878787;
  font-size: var(--font-14);
}
`
export const TooltipWrapper = styled.div` 
.tooltip {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: var(--shadow-1) !important;
  z-index: 2200 !important;
  font-size: 12px !important;
  /* width: 200px !important; */
}
`
export const DropzoneWrapper = styled.div` 
.dropzone {
  text-align: center;
  padding: 16px 8px 8px 8px;
  border: 1px solid var(--grey-200);
  margin: auto;
  transition: all 0.2s;
  cursor: pointer;
  z-index: 2;
  border-radius: 5px;
}

.dropzone-content svg {
  font-size: 40px;
  transition: all 0.2s;
  color: var(--primary-500);
  display: none;
}

@keyframes bounce {

  20%,
  50%,
  80%,
  to {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  70% {
    transform: translateY(-15px);
  }
}

.dropzone:hover {
  svg {
    animation: bounce 1s;
  }

  // background-color: #cbd5e0;
  border: 1px solid var(--primary-500);
}

p {
  text-align: center !important;
}

@media (max-width: 500px) {
  p {
    font-size: 1rem !important;
  }
}
`
export const ImageGridWrapper = styled.div` 
// height:100px;

.file-list {
  display: flex !important;
  align-items: center;
  width: auto;
  margin: 20px 0px;
  flex-wrap: nowrap;
}

.file-item img {
  height: 100px;
  width: 150px;
  padding-right: 10px;
  object-fit: contain;
  border-radius: 1px;
  border: none;
}

.file-item {
  button {
    svg {
      position: absolute;
      top: -3px;
      height: 25px !important;
      right: -2px;
    }
  }
}

.file-item {
  .title {
    button {
      svg {
        position: absolute;
        top: -3px;
        height: 25px !important;
        right: -2px;
      }
    }
  }
}

.file-item {
  .title {
    svg {
      height: 100px;
    }
  }
}

.file-item {
  border: 1px solid rgb(220 228 241);
  margin: 5px;
  padding: 5px;
  width: 200px;
  height: 180px;
  border-radius: 5px;
  box-shadow: var(--shadow-1);

  svg {
    height: 20px;
    width: 25px;
    cursor: pointer;
    color: var(--primary-500);
  }

  svg:last-child {
    color: #992323;
    // position: absolute;
    top: -3px;
    height: 25px !important;
    right: -2px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    background:none;
  }

  .title {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .title span {
    font-size: 15px;
  }

  p {
    margin: 0;
    font-size: 14px !important;
    margin-top: 10px;
    border-top: 1px solid var(--grey-50);
    display: flex;
    justify-content: space-between;
    padding: 5px;
    text-align: left !important;

    span {
      white-space: normal;
      display: -webkit-box;
      max-width: 160px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 993px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 70%;
  }
}
`
export const Loading2Wrapper = styled.div` 
width: 200px;
/* adjust the size as needed */
height: 200px;
perspective: 1000px;
display: flex;
justify-content: center;
align-items: center;
top: 50%;
outline: none;
position: absolute;
left: 50%;
transform: translate(-50%, -50%) !important;
z-index: 999;

.loader {
  width: 20%;
  height: 20%;
  border-radius: 50%;
  border: 10px solid transparent;
  border-color: var(--primary-500-active);
  /* control the color here */
  animation: rotateLoader 8s infinite linear;
  transform-style: preserve-3d;
  transform-origin: center center;
  /* Set the transform origin to the center */
  /* Additional 3D effects */
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.loader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom right,
      rgba(255, 255, 255, 0.5),
      transparent);
  pointer-events: none;
  transform-origin: center center;
}

.loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top left,
      rgba(255, 255, 255, 0.5),
      transparent);
  pointer-events: none;
  transform-origin: center center;
}

@keyframes rotateLoader {
  0% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateY(-20%) scale(1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  25% {
    transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg) translateY(20%) scale(1.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  50% {
    transform: rotateX(360deg) rotateY(720deg) rotateZ(720deg) translateY(-20%) scale(1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  75% {
    transform: rotateX(540deg) rotateY(1080deg) rotateZ(1080deg) translateY(20%) scale(1.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: rotateX(720deg) rotateY(1440deg) rotateZ(1440deg) translateY(-20%) scale(1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
}
`
export const PaginationWrapper = styled.div` 
.pagination .active>.page-link,
.page-link.active {
  background: var(--primary-500) !important;
  color: #fff !important;
}

.pagination .page-link {
  color: var(--primary-500);
  border: 1px solid var(--primary-500);
}

.pagination .page-link.disabled,
.disabled>.page-link {
  background: #fff !important;
  color: grey !important;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  border-top-left-radius: none !important;
  border-bottom-left-radius: none !important;
}
`
export const ProfileAvatarWrapper = styled.div`
display: flex;
flex-direction: row-reverse;

img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-left: -10px;
  background: #ebf3f9;
}
`
export const SearchWrapper = styled.div` 
margin-right: 20px;

.selector {
  width: 100%;
}

h6 {
  font-size: 0.95rem;
}

#select {
  width: 100%;
  padding: 5px 10px;
  height: 35px;
  margin-bottom: 6px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--grey-200);
  cursor: pointer;
}

#select input:focus {
  outline: none !important;
  border: none !important;
}

#select svg {
  font-size: 20px;
  color: var(--grey-400);
}

#list {
  width: 45%;
  border-radius: 6px;
  border: 1px solid var(--grey-200);
  overflow: hidden;
  position: absolute;
  padding: 5px;
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: auto;
  max-height: 300px;
}

#list-mb {
  top: 48px;
  position: absolute;
  width: 100%;
  left: 0;
  height: 300px;
  background: #fff;
}

.options {
  width: 22.5%;
  padding: 5px 10px 5px 10px;
  list-style: none;
  cursor: pointer;
  box-sizing: border-box;
}

ul li:hover {
  background: var(--grey-50);
  width: 100%;
}

.search {
  display: none;
}

.search-popup {
  position: fixed;
  inset: 0px;
  background: rgb(21 98 106 / 40%);
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  left: 0;
  top: 0;
  transition: var(--transition);
  padding: 20px;
}

.search-heading {
  display: flex;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 10px;
  height: 45px;
}

#select input {
  border: none !important;
  outline: none !important;
}

@media (max-width: 520px) {
  .selector {
    display: none;
  }

  .search {
    display: block;
  }

  .search svg {
    font-size: 23px;
    color: var(--grey-400);
    cursor: pointer;
    margin-top: 5px;
  }
}
`