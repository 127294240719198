import React, { useState } from "react"
import { FormRow } from "../Common"
import { getGoalsReport } from "../../redux"
import { useDispatch } from "react-redux"
import moment from "moment"
import * as XLSX from "xlsx"

import { capitalizeFirstLetter } from "../../utils/getInitialName"
import { ReportWrapper } from "../../assets/styles/Goals"

const initialState = {
  startDate:  moment().startOf('month').format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
}
const GoalsReport = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState(initialState)
  const [result, setResult] = useState(null)
  const handleChange = (e) => {
    console.log(e.target.value)
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleGenerate = (e) => {
    e.preventDefault()

    dispatch(getGoalsReport(values.startDate, values.endDate)).then((data) => {
      setResult(data.result)
    })
  }
  
  function downloadgoalsTableAsCSV() {
    const tableData = []
    if (!result)
      return;

    result.forEach((p) => {

      const projectTitle = `Project: ${capitalizeFirstLetter(p.project)}`

      const taskHighlights = p.goals.map((r, index) => {
        return `${index + 1}. ${capitalizeFirstLetter(r)}`
      })

      tableData.push({ Project: projectTitle })

      taskHighlights.forEach((highlight) => {
        tableData.push({ "TASK HIGHLIGHT": highlight })
      })

      tableData.push({
        TEAM: "Team",
        "Duration(Day)": "Duration(Day)",
        "Duration(Hours)": "Duration(Hours)",
      })

     p.users.map((user) => {
        tableData.push({
          TEAM: user.name,
          "Duration(Day)": Math.ceil(user.spent / 60 / 8),
          "Duration(Hours)": `${Math.floor(user.spent / 60)}h ${user.spent % 60}m`,
        })
      })
      // Add a blank row after each team's data
      tableData.push({})
      tableData.push({})
      tableData.push({})

    })

    const xlsxData = convertToXLSX(tableData)

    const url = window.URL.createObjectURL(xlsxData)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `temp.xlsx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function convertToXLSX(data) {
    const header = Object.keys(data[0])
    const rows = data.map((row) => Object.values(row))
    const xlsxData = [header, ...rows]
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Goals")
    const xlsxBuffer = XLSX.write(workbook, { type: "array", bookType: "xlsx" })
    return new Blob([xlsxBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  }


  return (
    <ReportWrapper>
      <form>
        <FormRow
          type="date"
          name="startDate"
          labelText={"From"}
          value={values.startDate}
          handleChange={handleChange}
        />
        <FormRow
          type="date"
          name="endDate"
          labelText={"To"}
          value={values.endDate}
          handleChange={handleChange}
        />
        <div className="form-row">
          <button className="btn add-btn" onClick={handleGenerate}>
            Generate
          </button>
        </div>
        <div className="form-row">
          <button onClick={downloadgoalsTableAsCSV}>Download</button>
        </div>
      </form>

      <br />
      <section>
        {result &&
          result.map((p) => {

            return (
              <div style={{ boxShadow: "var(--shadow-1)" }}>
                <h4 className="proj mt-4 mb-0 f-16">
                  Project: {capitalizeFirstLetter(p.project)}
                </h4>
                <div className="proj mb-4 pt-4 px-8 pb-4">
                  <ol>
                    {p.goals.map((goal) => {
                      return (
                        <li className="f-12">
                          {" "}
                          {capitalizeFirstLetter(goal)}
                        </li>
                      )
                    })}
                  </ol>


                  <table id="goals-table">
                    <tr>
                      <th>Team</th>
                      <th>Duration(Day)</th>
                      <th>Duration(Hours)</th>
                    </tr>

                    {p.users.map((user) => {
                      return (
                        <tr>
                          <td>{user.name}</td>
                          <td>{Math.ceil(user.spent / 60 / 8)}</td>
                          <td>
                            {user.spent / 60}h {user.spent % 60}m
                          </td>
                        </tr>
                      )
                    }
                    )}
                  </table>

                </div>
              </div>
            )

          })}
      </section>
    </ReportWrapper>
  )
}

export default GoalsReport
