/**for user name or company name like if name is Jhon Doe then it show JD  */
export function getInitials(name) {
  var names = name.split(" ")
  var initials = ""

  for (var i = 0; i < Math.min(2, names.length); i++) {
    initials += names[i].charAt(0)
  }

  return initials
}
/**for sentence case of title */
export const capitalizeFirstLetter = (text) => {
  return text && text.charAt(0).toUpperCase() + text.slice(1)
}
/**for sentence case of description */
export const capitalizeDescription = (htmlContent) => {
  const wrapper = document.createElement("div")
  wrapper.innerHTML = htmlContent

  const textNodes = []
  const collectTextNodes = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node)
    } else {
      node.childNodes.forEach(collectTextNodes)
    }
  }
  collectTextNodes(wrapper)

  textNodes.forEach((textNode) => {
    textNode.textContent = textNode.textContent.replace(
      /(^\w|\.\s\w)/g,
      (match) => match.toUpperCase()
    )
  })

  return wrapper.innerHTML
}
