// codeReviewActions.js
import { toast } from "react-toastify";
import authFetch from "../../utils/interceptor";
import {
    CREATE_CODE_REVIEW_BEGIN,
    CREATE_CODE_REVIEW_SUCCESS,
    CREATE_CODE_REVIEW_ERROR,
    GET_CODE_REVIEWS_BEGIN,
    GET_CODE_REVIEWS_SUCCESS,
    GET_CODE_REVIEWS_ERROR,
    EDIT_CODE_REVIEW_BEGIN,
    EDIT_CODE_REVIEW_SUCCESS,
    EDIT_CODE_REVIEW_ERROR,
    DELETE_CODE_REVIEW_BEGIN,
    DELETE_CODE_REVIEW_SUCCESS,
    DELETE_CODE_REVIEW_ERROR,
    GET_CODE_REVIEW_BY_ID_BEGIN,
    GET_CODE_REVIEW_BY_ID_SUCCESS,
    GET_CODE_REVIEW_BY_ID_ERROR,
    OPEN_CODE_REVIEW_MODAL,
    CLOSE_CODE_REVIEW_MODAL,
    SET_IS_REVIEW_ID,
    DELETE_CODE_REVIEW_ATTACHMENT_REQUEST,
    DELETE_CODE_REVIEW_ATTACHMENT_SUCCESS,
    DELETE_CODE_REVIEW_ATTACHMENT_FAILURE,
    STATUS_CHECK_REQUEST,
    STATUS_CHECK_SUCCESS,
    STATUS_CHECK_FAILURE,
} from './codeReviewTypes';


// Open and Close Code Review Modal
export const openCodeReviewModal = () => {
    return (dispatch) => {
        dispatch({ type: OPEN_CODE_REVIEW_MODAL });
    };
};

export const closeCodeReviewModal = () => {
    return (dispatch) => {
        dispatch({ type: CLOSE_CODE_REVIEW_MODAL });
    };
};

export const setIsReviewId = (id) => {
    return {
      type: SET_IS_REVIEW_ID,
      payload: id,
    };
  };

// Get all code reviews for a task
export const getCodeReviews = (taskId) => async (dispatch) => {
    dispatch({ type: GET_CODE_REVIEWS_BEGIN });
    try {
        const response = await authFetch.get(`/codeReview/${taskId}`);
        dispatch({ type: GET_CODE_REVIEWS_SUCCESS, payload: response.data?.data });
        dispatch(checkStatus(taskId))
    } catch (error) {
        dispatch({ type: GET_CODE_REVIEWS_ERROR, payload: error.message });
        toast.error('Error fetching code reviews');
    }
};

// Create a code review
export const createCodeReview = (taskId, reviewData) => async (dispatch) => {
    dispatch({ type: CREATE_CODE_REVIEW_BEGIN });
    try {
        const response = await authFetch.post(`/codeReview/${taskId}`, reviewData);
        dispatch({ type: CREATE_CODE_REVIEW_SUCCESS, payload: response.data });
        dispatch(getCodeReviews(taskId));

        toast.success('Code review added successfully');
    } catch (error) {
        dispatch({ type: CREATE_CODE_REVIEW_ERROR, payload: error.message });
        toast.error('Error adding code review');
    }
};

// Get a code review by ID
export const getCodeReviewById = (reviewId) => async (dispatch) => {
    dispatch({ type: GET_CODE_REVIEW_BY_ID_BEGIN });
    try {
        const response = await authFetch.get(`/codeReview/getReview/${reviewId}`);
        dispatch({ type: GET_CODE_REVIEW_BY_ID_SUCCESS, payload: response.data?.data });
    } catch (error) {
        dispatch({ type: GET_CODE_REVIEW_BY_ID_ERROR, payload: error.message });
        toast.error('Error fetching code review');
    }
};

// Edit a code review
export const editCodeReview = (taskId, reviewId, reviewData) => async (dispatch) => {
    dispatch({ type: EDIT_CODE_REVIEW_BEGIN });
    try {
        const response = await authFetch.patch(`/codeReview/${reviewId}`, reviewData);
        dispatch({ type: EDIT_CODE_REVIEW_SUCCESS, payload: response.data?.data });
        dispatch(getCodeReviews(taskId));
        toast.success('Code review updated successfully');
    } catch (error) {
        dispatch({ type: EDIT_CODE_REVIEW_ERROR, payload: error.message });
        toast.error('Error updating code review');
    }
};

// Delete a code review
export const deleteCodeReview = (taskId, reviewId) => async (dispatch) => {
    dispatch({ type: DELETE_CODE_REVIEW_BEGIN });
    try {
        await authFetch.delete(`/codeReview/${reviewId}`);
        dispatch({ type: DELETE_CODE_REVIEW_SUCCESS, payload: reviewId });
        dispatch(getCodeReviews(taskId));
        toast.success('Code review deleted successfully');
    } catch (error) {
        dispatch({ type: DELETE_CODE_REVIEW_ERROR, payload: error.message });
        toast.error('Error deleting code review');
    }
};

// Action creator for deleting a code review attachment
export const deleteCodeReviewAttachment = (attachmentKey) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_CODE_REVIEW_ATTACHMENT_REQUEST });
  
      // Call your API to delete the attachment
      await authFetch.delete(`/codeReview/attachment/${attachmentKey}`);
      dispatch({ type: DELETE_CODE_REVIEW_ATTACHMENT_SUCCESS });
      toast.success('Code review attachment deleted successfully');
    } catch (error) {
      dispatch({ type: DELETE_CODE_REVIEW_ATTACHMENT_FAILURE, payload: error.message });
      toast.error('Error deleting attachment');
    }
  };

  // Action creator for status check
export const checkStatus = (taskId) => async (dispatch) => {
    try {
      dispatch({ type: STATUS_CHECK_REQUEST });
  
      const { data } = await authFetch.get(`/codeReview/checkStatus/${taskId}`);
  
      dispatch({
        type: STATUS_CHECK_SUCCESS,
        payload: data.status, // Assuming `data.status` contains the status result
      });
    } catch (error) {
      dispatch({
        type: STATUS_CHECK_FAILURE,
        payload: error.message,
      });
    }
  };