// testItemsReducer.js
import {
    OPEN_TEST_ITEM_MODAL,
    CLOSE_TEST_ITEM_MODAL,
    GET_ALL_TEST_ITEMS_BEGIN,
    GET_ALL_TEST_ITEMS_SUCCESS,
    GET_ALL_TEST_ITEMS_ERROR,
    GET_TEST_ITEM_BEGIN,
    GET_TEST_ITEM_SUCCESS,
    GET_TEST_ITEM_ERROR,
    CREATE_TEST_ITEM_BEGIN,
    CREATE_TEST_ITEM_SUCCESS,
    CREATE_TEST_ITEM_ERROR,
    EDIT_TEST_ITEM_BEGIN,
    EDIT_TEST_ITEM_SUCCESS,
    EDIT_TEST_ITEM_ERROR,
    DELETE_TEST_ITEM_BEGIN,
    DELETE_TEST_ITEM_SUCCESS,
    DELETE_TEST_ITEM_ERROR,
    SET_EDIT_TEST_ITEM,
    EDIT_TEST_ITEM_MODAL_OPEN,
    EDIT_TEST_ITEM_MODAL_CLOSE,
    DELETE_TEST_ITEM_ATTACHMENT_BEGIN,
    DELETE_TEST_ITEM_ATTACHMENT_SUCCESS,
    DELETE_TEST_ITEM_ATTACHMENT_ERROR,
  } from "./testItemTypes";
  
  const initialState = {
    testItems: [],
    loading: false,
    error: null,
    editingTestItem: null,
    isEditingTestItem: false,
    selectedTestItem: null,
    isTestItemModalOpen: false,
    editTestItemModalOpen: false,
  };
  
  const testItemsReducer = (state = initialState, action) => {
    switch (action.type) {
      case OPEN_TEST_ITEM_MODAL:
        return { ...state, isTestItemModalOpen: true };
      case CLOSE_TEST_ITEM_MODAL:
        return { ...state, isTestItemModalOpen: false };
      case GET_ALL_TEST_ITEMS_BEGIN:
        return { ...state, loading: true, error: null };
      case GET_TEST_ITEM_BEGIN:
        return { ...state, loading: true, error: null };
      case CREATE_TEST_ITEM_BEGIN:
        return { ...state, loading: true, error: null };
      case EDIT_TEST_ITEM_BEGIN:
        return { ...state, loading: true, error: null };
      case DELETE_TEST_ITEM_BEGIN:
        return { ...state, loading: true, error: null };
      case GET_ALL_TEST_ITEMS_SUCCESS:
        return { ...state, loading: false, testItems: action.payload };
      case GET_TEST_ITEM_SUCCESS:
        return { ...state, loading: false, selectedTestItem: action.payload };
      case CREATE_TEST_ITEM_SUCCESS:
        return { ...state, loading: false, isTestItemModalOpen: false };
      case EDIT_TEST_ITEM_SUCCESS:
        return {
          ...state,
          loading: false,
          editTestItemModalOpen: false,
          testItems: state.testItems.map((item) =>
            item._id === action.payload._id ? action.payload : item
          ),
        };
      case DELETE_TEST_ITEM_SUCCESS:
        return {
          ...state,
          loading: false,
          testItems: state.testItems.filter((item) => item._id !== action.payload),
        };
        case DELETE_TEST_ITEM_ATTACHMENT_BEGIN:
        return { ...state, loading: true, error: null };
        case DELETE_TEST_ITEM_ATTACHMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          testItems: action.payload,
        };
        case DELETE_TEST_ITEM_ATTACHMENT_ERROR:
        return { ...state, loading: false, error: action.payload };
      case GET_ALL_TEST_ITEMS_ERROR:
      case GET_TEST_ITEM_ERROR:
      case CREATE_TEST_ITEM_ERROR:
      case EDIT_TEST_ITEM_ERROR:
      case DELETE_TEST_ITEM_ERROR:
        return { ...state, loading: false, error: action.payload };
      case SET_EDIT_TEST_ITEM:
        const editingTestItem = state.testItems.find(
          (item) => item._id === action.payload.id
        );
        return {
          ...state,
          editingTestItem: editingTestItem,
          isEditingTestItem: true,
        };
      case EDIT_TEST_ITEM_MODAL_OPEN:
        return { ...state, editTestItemModalOpen: true };
      case EDIT_TEST_ITEM_MODAL_CLOSE:
        return {
          ...state,
          editTestItemModalOpen: false,
          editingTestItem: null,
          isEditingTestItem: false,
        };
        
      default:
        return state;
    }
  };
  
  export default testItemsReducer;
  