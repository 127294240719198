// testCaseActions.js
import { toast } from "react-toastify";
import {
  OPEN_TEST_CASE_MODAL,
  CLOSE_TEST_CASE_MODAL,
  GET_ALL_TEST_CASES_BEGIN,
  GET_ALL_TEST_CASES_SUCCESS,
  GET_ALL_TEST_CASES_ERROR,
  GET_TEST_CASE_BEGIN,
  GET_TEST_CASE_SUCCESS,
  GET_TEST_CASE_ERROR,
  CREATE_TEST_CASE_BEGIN,
  CREATE_TEST_CASE_SUCCESS,
  CREATE_TEST_CASE_ERROR,
  EDIT_TEST_CASE_BEGIN,
  EDIT_TEST_CASE_SUCCESS,
  EDIT_TEST_CASE_ERROR,
  DELETE_TEST_CASE_BEGIN,
  DELETE_TEST_CASE_SUCCESS,
  DELETE_TEST_CASE_ERROR,
  SET_TEMPLATE_ID,
  CLEAR_TEMPLATE_ID,
  CREATE_TEST_CASE_DETAILS_SUCCESS,
  CREATE_TEST_CASE_DETAILS_BEGIN,
  GET_ALL_TEST_CASE_DETAILS_ITEMS_BEGIN,
  GET_ALL_TEST_CASE_DETAILS_ITEMS_SUCCESS,
  GET_ALL_TEST_CASE_DETAILS_ITEMS_ERROR,
  DELETE_TEST_CASE_DETAILS_BEGIN,
  DELETE_TEST_CASE_DETAILS_SUCCESS,
  DELETE_TEST_CASE_DETAILS_ERROR,
  GET_TEST_CASE_DETAILS_BEGIN,
  GET_TEST_CASE_DETAILS_ERROR,
  GET_TEST_CASE_DETAILS_SUCCESS,
  UPDATE_TEST_CASE_DETAILS_REQUEST,
    UPDATE_TEST_CASE_DETAILS_SUCCESS,
    UPDATE_TEST_CASE_DETAILS_FAILURE,
    UPDATE_TEST_CASE_REMARKS_REQUEST,
    UPDATE_TEST_CASE_REMARKS_SUCCESS,
    UPDATE_TEST_CASE_REMARKS_FAILURE,
    CREATE_COPY_TEMPLATE_ITEM_BEGIN,
    CREATE_COPY_TEMPLATE_ITEM_SUCCESS,
    CREATE_COPY_TEMPLATE_ITEM_ERROR,
    GET_TEST_CASE_BY_ID_BEGIN,
    GET_TEST_CASE_BY_ID_ERROR,
    GET_TEST_CASE_BY_ID_SUCCESS,
    SET_DATE_RANGE,
    CLEAR_DATE_RANGE,
    COUNT_TEST_CASE_ITEMS_BEGIN,
    COUNT_TEST_CASE_ITEMS_SUCCESS,
    DELETE_REMARK_ATTACHMENT_BEGIN,
    DELETE_REMARK_ATTACHMENT_ERROR,
    DELETE_REMARK_ATTACHMENT_SUCCESS,
    DELETE_TEST_CASE_ATTACHMENT_SUCCESS,
    DELETE_TEST_CASE_ATTACHMENT_ERROR,
    DELETE_TEST_CASE_ATTACHMENT_BEGIN,
} from "./testCaseTypes";
import authFetch from "../../../utils/interceptor";

// Open and Close Modal
export const openTestCaseModal = () => ({ type: OPEN_TEST_CASE_MODAL });
export const closeTestCaseModal = () => ({ type: CLOSE_TEST_CASE_MODAL });

export const setTemplateId = (templateId) => ({ type: SET_TEMPLATE_ID, payload: templateId });
export const resetTemplateId = () => ({ type: CLEAR_TEMPLATE_ID });

export const setDateRange = (dateRange) => ({ type: SET_DATE_RANGE, payload: dateRange });
export const resetDateRange = () => ({ type: CLEAR_DATE_RANGE });

//------------------------------------------------------------------ All Tests api----------------------------------------------------------------------------//

// Get All Test Cases
export const getAllTestCases = (dateRange) => async (dispatch) => {
  dispatch({ type: GET_ALL_TEST_CASES_BEGIN });
  try {
    const response = await authFetch.post(`/testCase/dateRange`, dateRange);
    dispatch({ type: GET_ALL_TEST_CASES_SUCCESS, payload: response.data?.data });
  } catch (error) {
    dispatch({ type: GET_ALL_TEST_CASES_ERROR, payload: error.message });
  }
};

// Get Test Case by ID
export const getTestCase = (caseId) => async (dispatch) => {
  dispatch({ type: GET_TEST_CASE_BEGIN });
  try {
    const response = await authFetch.get(`/testCase/${caseId}`);
    dispatch({ type: GET_TEST_CASE_SUCCESS, payload: response.data?.data });
  } catch (error) {
    dispatch({ type: GET_TEST_CASE_ERROR, payload: error.message });
  }
};

// Create Test Case
export const createTestCase = (testCase, dateRange) => async (dispatch) => {
  dispatch({ type: CREATE_TEST_CASE_BEGIN });
  try {
    await authFetch.post(`/testCase`, testCase);
    dispatch({ type: CREATE_TEST_CASE_SUCCESS });
    dispatch(getAllTestCases(dateRange));
    toast.success('Test case created successfully');
  } catch (error) {
    dispatch({ type: CREATE_TEST_CASE_ERROR, payload: error.message });
    toast.error('Error creating test case');
  }
};

// Edit Test Case
export const editTestCase = (caseId, testCase, dateRange) => async (dispatch) => {
  dispatch({ type: EDIT_TEST_CASE_BEGIN });
  try {
    await authFetch.patch(`/testCase/${caseId}`, testCase);
    dispatch(getAllTestCases(dateRange));
    toast.success('Test case updated successfully');
    dispatch({ type: EDIT_TEST_CASE_SUCCESS });
  } catch (error) {
    dispatch({ type: EDIT_TEST_CASE_ERROR, payload: error.message });
    // toast.error('Error updating test case');
  }
};

// Delete Test Case
export const deleteTestCase = (caseId, dateRange) => async (dispatch) => {
  dispatch({ type: DELETE_TEST_CASE_BEGIN });
  try {
    await authFetch.delete(`/testCase/${caseId}`);
    dispatch({ type: DELETE_TEST_CASE_SUCCESS });
    dispatch(getAllTestCases(dateRange));
    toast.success('Test case deleted successfully');
  } catch (error) {
    dispatch({ type: DELETE_TEST_CASE_ERROR, payload: error.message });
    toast.error('Error deleting test case');
  }
};

//------------------------------------------------------------------ All Tests cases api----------------------------------------------------------------------------//
// copy template and test items
export const copyTemplateItemsToTestCaseItems = (templateId, testCaseId) => async (dispatch) => {
  dispatch({ type: CREATE_COPY_TEMPLATE_ITEM_BEGIN });
  try {
    const response = await authFetch.get(`/testCaseItem/copy/${templateId}/${testCaseId}`);
    dispatch({ type: CREATE_COPY_TEMPLATE_ITEM_SUCCESS, payload: response.data?.data });
    // dispatch(getAllTestCaseDetailsItems(testCaseId, templateId));
    // toast.success('Test case created successfully');
  } catch (error) {
    dispatch({ type: CREATE_COPY_TEMPLATE_ITEM_ERROR, payload: error.message });
    // toast.error('Error creating test case');
  }
};
  
//Test Details Items
export const createTestItemsDetailsTemplate = (caseId, testCase, testCaseId) => async (dispatch) => {
  dispatch({ type: CREATE_TEST_CASE_DETAILS_BEGIN });
  try {
    await authFetch.post(`/testCaseItem/${caseId}`, testCase);
    dispatch({ type: CREATE_TEST_CASE_DETAILS_SUCCESS });   
    dispatch(getAllTestCaseDetailsItems(testCaseId));
    // dispatch(getAllTestCasesStatus(testCaseId));
    toast.success('Test case created successfully');
  } catch (error) {
    dispatch({ type: CREATE_TEST_CASE_ERROR, payload: error.message });
    toast.error('Error creating test case');
  }
};

export const getTestCaseDetailsById = (caseId, templateId) => async (dispatch) => {
  dispatch({ type: GET_TEST_CASE_DETAILS_BEGIN });
  try {
    const response = await authFetch.post(`/testCaseItem/test_case/${caseId}`);
    dispatch({ type: GET_TEST_CASE_DETAILS_SUCCESS, payload: response.data?.data });
  } catch (error) {
    dispatch({ type: GET_TEST_CASE_DETAILS_ERROR, payload: error.message });
  }
};

// Get Single Tests Cases by ID
export const getSingleTestCasesById = (caseId) => async (dispatch) => {
  dispatch({ type: GET_TEST_CASE_BY_ID_BEGIN });
  try {
    const response = await authFetch.post(`/testCaseItem/test_case/${caseId}`);
    dispatch({ type: GET_TEST_CASE_BY_ID_SUCCESS, payload: response.data?.data });
  } catch (error) {
    dispatch({ type: GET_TEST_CASE_BY_ID_ERROR, payload: error.message });
  }
};

// Get All Test Cases Items along with copy data.
export const getAllTestCaseDetailsItems = (testCaseId) => async (dispatch) => {
  dispatch({ type: GET_ALL_TEST_CASE_DETAILS_ITEMS_BEGIN });
  try {
    const response = await authFetch.get(`/testCaseItem/${testCaseId}`);
    dispatch({ type: GET_ALL_TEST_CASE_DETAILS_ITEMS_SUCCESS, payload: response.data?.data });
    dispatch(getAllTestCasesStatus({caseId: testCaseId}));
  } catch (error) {
    dispatch({ type: GET_ALL_TEST_CASE_DETAILS_ITEMS_ERROR, payload: error.message });
  }
};

export const getAllTestCasesStatus = (caseId) => async (dispatch) => {
  dispatch({ type: COUNT_TEST_CASE_ITEMS_BEGIN });
  try { 
    const response = await authFetch.post(`/testCaseItem/statusCount`, caseId);
    dispatch({ type: COUNT_TEST_CASE_ITEMS_SUCCESS, payload: response.data?.data });
  } catch (error) {
    dispatch({ type: COUNT_TEST_CASE_ITEMS_SUCCESS, payload: error.message });
  }
};

export const updateTestCaseDetails = (id, testCase, testCaseId) => async (dispatch) => {
  dispatch({ type: DELETE_TEST_CASE_DETAILS_BEGIN });
  try {
    const response = await authFetch.patch(`/testCaseItem/${id}`, testCase);
    dispatch({ type: UPDATE_TEST_CASE_DETAILS_SUCCESS, payload: response.data });
    dispatch(getAllTestCaseDetailsItems(testCaseId));
    toast.success('Test case details updated successfully');
  } catch (error) {
    dispatch({ type: UPDATE_TEST_CASE_DETAILS_FAILURE, payload: error.message });
    toast.error('Error updating test case details');
  }
};

export const deleteTestCaseDetails = (id, testCaseId) => async (dispatch) => {
  dispatch({ type: DELETE_TEST_CASE_DETAILS_BEGIN });
  try {
    await authFetch.delete(`/testCaseItem/${id}`);
    dispatch({ type: DELETE_TEST_CASE_DETAILS_SUCCESS });
    dispatch(getAllTestCaseDetailsItems(testCaseId));  // or getAllTestCaseDetailsItems if applicable
    // dispatch(getAllTestCasesStatus(testCaseId));  // get all status count
    toast.success('Test case details deleted successfully');
  } catch (error) {
    dispatch({ type: DELETE_TEST_CASE_DETAILS_ERROR, payload: error.message });
    toast.error('Error deleting test case details');
  }
};

export const updateTestCaseRemarks = (id, testCase, testCaseId) => async (dispatch) => {
  dispatch({ type: UPDATE_TEST_CASE_REMARKS_REQUEST });
  try {
    const response = await authFetch.patch(`/testCaseItem/updateRemarks/${id}`, testCase);
    dispatch({ type: UPDATE_TEST_CASE_REMARKS_SUCCESS, payload: response.data });
    dispatch(getAllTestCasesStatus({caseId: testCaseId}));
    dispatch(getAllTestCaseDetailsItems(testCaseId));
    toast.success('Test case remarks updated successfully');
  } catch (error) {
    dispatch({ type: UPDATE_TEST_CASE_REMARKS_FAILURE, payload: error.message });
    toast.error('Error updating test case remarks');
  }
};

//delete remark attachments.
export const deleteRemarkAttachment = (remarkId, attachmentKey, testCaseId) => async (dispatch) => {
  dispatch({ type: DELETE_REMARK_ATTACHMENT_BEGIN });
  try {
    const response = await authFetch.delete(`/testCaseItem/updateRemarks/${testCaseId}/${remarkId}/${attachmentKey}`);
    dispatch({ type: DELETE_REMARK_ATTACHMENT_SUCCESS, payload: response.data });
    dispatch(getAllTestCasesStatus({ caseId: testCaseId }));
    toast.success('Attachment deleted successfully');
  } catch (error) {
    dispatch({ type: DELETE_REMARK_ATTACHMENT_ERROR, payload: error.message });
    toast.error('Error deleting attachment');
  }
};

export const deleteAttachmentForTestCase = (attachmentKey, testCaseId) => async (dispatch) => {
  dispatch({ type: DELETE_TEST_CASE_ATTACHMENT_BEGIN });
  try {
    const response = await authFetch.delete(`/testCaseItem/tests/${attachmentKey}`);
    dispatch({ type: DELETE_TEST_CASE_ATTACHMENT_SUCCESS, payload: response.data });
    dispatch(getAllTestCasesStatus({ caseId: testCaseId }));
    toast.success('Attachment deleted successfully');
  } catch (error) {
    dispatch({ type: DELETE_TEST_CASE_ATTACHMENT_ERROR, payload: error.message });
    toast.error('Error deleting attachment');
  }
};


// Status Api



