import React from "react"
import { useSelector } from "react-redux"
import { capitalizeFirstLetter } from "../../utils/getInitialName"
import moment from "moment"
import { ProfileAvatar } from "../../components/Common"
import ActivityFilters from "../../components/activity/ActivityFilters"
import { ActivityWrapper } from "../../assets/styles/Dashboard"

const Activity = () => {
  const { activity } = useSelector((state) => state.activity)

  return (
    <ActivityWrapper>
      <h5 className="card-title tw-p-0 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">Activity</h5>
      <div className="activity-filter">
        <ActivityFilters />
      </div>
      <div className="activity-table">
        <table className="activity">
          <thead>
            <tr>
              <th className="textLeft">Name</th>
              <th className="textLeft">Resource</th>
              <th className="textLeft">Action</th>
              <th className="textLeft">Timestamp</th>
              <th className="textLeft">Details</th>
            </tr>
          </thead>
          <tbody>
            {activity &&
              activity.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="textLeft capital">
                      <div className="d-flex">
                        <ProfileAvatar id={i.userId} size="s" />
                        <div className="name">
                          <p> {i.user[0].name}</p>
                          <p>
                            <span className="lowerCase"> {i.user[0].email}</span>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="textLeft">
                      {capitalizeFirstLetter(i.resourceType)}
                    </td>
                    <td className="textLeft">{i.action}</td>
                    <td className="textLeft" style={{ width: "150px" }}>
                      {moment(i.timeStamp).format("MM-DD-YYYY h:mm a")}
                    </td>
                    <td className="textLeft">
                      {capitalizeFirstLetter(i.details)}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </ActivityWrapper>
  )
}

export default Activity
