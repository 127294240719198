import { DELETE_TEST_ITEM_ATTACHMENT_BEGIN } from "../testItems/testItemTypes";
import {
  OPEN_TEST_CASE_MODAL,
  CLOSE_TEST_CASE_MODAL,
  GET_ALL_TEST_CASES_BEGIN,
  GET_ALL_TEST_CASES_SUCCESS,
  GET_ALL_TEST_CASES_ERROR,
  GET_TEST_CASE_BEGIN,
  GET_TEST_CASE_SUCCESS,
  GET_TEST_CASE_ERROR,
  CREATE_TEST_CASE_BEGIN,
  CREATE_TEST_CASE_SUCCESS,
  CREATE_TEST_CASE_ERROR,
  EDIT_TEST_CASE_BEGIN,
  EDIT_TEST_CASE_SUCCESS,
  EDIT_TEST_CASE_ERROR,
  DELETE_TEST_CASE_BEGIN,
  DELETE_TEST_CASE_SUCCESS,
  DELETE_TEST_CASE_ERROR,
  SET_EDIT_TEST_CASE,
  EDIT_TEST_CASE_MODAL_OPEN,
  EDIT_TEST_CASE_MODAL_CLOSE,
  SET_TEMPLATE_ID,
  CLEAR_TEMPLATE_ID,
  CREATE_TEST_CASE_DETAILS_BEGIN,
  CREATE_TEST_CASE_DETAILS_SUCCESS,
  CREATE_TEST_CASE_DETAILS_ERROR,
  GET_ALL_TEST_CASE_DETAILS_ITEMS_BEGIN,
  GET_ALL_TEST_CASE_DETAILS_ITEMS_SUCCESS,
  GET_ALL_TEST_CASE_DETAILS_ITEMS_ERROR,
  DELETE_TEST_CASE_DETAILS_BEGIN,
  DELETE_TEST_CASE_DETAILS_SUCCESS,
  DELETE_TEST_CASE_DETAILS_ERROR,
  GET_TEST_CASE_DETAILS_BEGIN,
  GET_TEST_CASE_DETAILS_ERROR,
  GET_TEST_CASE_DETAILS_SUCCESS,
  UPDATE_TEST_CASE_DETAILS_REQUEST,
  UPDATE_TEST_CASE_DETAILS_SUCCESS,
  UPDATE_TEST_CASE_DETAILS_FAILURE,
  UPDATE_TEST_CASE_REMARKS_REQUEST,
  UPDATE_TEST_CASE_REMARKS_SUCCESS,
  UPDATE_TEST_CASE_REMARKS_FAILURE,
  CREATE_COPY_TEMPLATE_ITEM_SUCCESS,
  CREATE_COPY_TEMPLATE_ITEM_BEGIN,
  CREATE_COPY_TEMPLATE_ITEM_ERROR,
  GET_TEST_CASE_BY_ID_SUCCESS,
  GET_TEST_CASE_BY_ID_BEGIN,
  GET_TEST_CASE_BY_ID_ERROR,
  SET_DATE_RANGE,
  CLEAR_DATE_RANGE,
  COUNT_TEST_CASE_ITEMS_BEGIN,
  COUNT_TEST_CASE_ITEMS_SUCCESS,
  COUNT_TEST_CASE_ITEMS_ERROR,
  DELETE_REMARK_ATTACHMENT_BEGIN,
  DELETE_REMARK_ATTACHMENT_SUCCESS,
  DELETE_REMARK_ATTACHMENT_ERROR,
  DELETE_TEST_CASE_ATTACHMENT_SUCCESS,
  DELETE_TEST_CASE_ATTACHMENT_ERROR,
} from "./testCaseTypes";

// Initial state for the test case reducer
const initialState = {
  testCases: [], // List of all test cases
  testCaseDetailsItems: [], // List of test case details items
  templateTestItems: [], // List of template test item 
  loading: false, // Loading state for async operations
  loading2: false, // Loading state for async operations
  error: null, // Error message if any operation fails
  editingTestCase: null, // The test case being edited
  isEditingTestCase: false, // Flag to indicate if a test case is being edited
  selectedTestCase: null, // The selected test case
  selectedSingleTestCase: null, // The selected test case
  selectedTestCaseDetails: null, // The selected test case details by ID
  remarksData: null, // The selected test case details by ID
  templateId: null, // The ID of the selected template
  isTestCaseModalOpen: false, // Flag to indicate if the test case modal is open
  isTestCaseDetailsModalOpen: false, // Flag to indicate if the test case details modal is open
  editTestCaseModalOpen: false, // Flag to indicate if the edit test case modal is open
  dateRange: null,
  countStatus: {}
};

// Reducer function to manage the state of test cases
const testCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    // Open the test case modal
    case OPEN_TEST_CASE_MODAL:
      return { ...state, isTestCaseModalOpen: true };

    // Close the test case modal
    case CLOSE_TEST_CASE_MODAL:
      return { ...state, isTestCaseModalOpen: false };

    // Begin fetching all test cases
    case GET_ALL_TEST_CASES_BEGIN:
      return { ...state, loading: true, error: null };

    // Begin fetching a single test case by ID
    case GET_TEST_CASE_BEGIN:
      return { ...state, loading: true, error: null };

    // Begin creating a new test case
    case CREATE_TEST_CASE_BEGIN:
      return { ...state, loading: true, error: null };

    // Begin creating test case details
    case CREATE_TEST_CASE_DETAILS_BEGIN:
      return { ...state, loading: true, error: null };

    case CREATE_COPY_TEMPLATE_ITEM_BEGIN:
      return { ...state, loading: true, error: null };
    // Begin editing a test case
    case EDIT_TEST_CASE_BEGIN:
      return { ...state, loading: true, error: null };

    // Begin deleting a test case
    case DELETE_TEST_CASE_BEGIN:
      return { ...state, loading: true, error: null };

    // Set the selected template ID
    case SET_TEMPLATE_ID:
      return { ...state, templateId: action.payload };

    // Clear the selected template ID
    case CLEAR_TEMPLATE_ID:
      return { ...state, templateId: null };

      case SET_DATE_RANGE:
        return { ...state, dateRange: action.payload };
        case CLEAR_DATE_RANGE:
          return { ...state, dateRange: null };
    // Successfully fetched all test cases
    case GET_ALL_TEST_CASES_SUCCESS:
      return { ...state, loading: false, testCases: action.payload };

    // Successfully fetched a single test case by ID
    case GET_TEST_CASE_SUCCESS:
      return { ...state, loading: false, selectedTestCase: action.payload.testCase };

    // Successfully created a new test case
    case CREATE_TEST_CASE_SUCCESS:
      return { ...state, loading: false, isTestCaseModalOpen: false };

    // Successfully created test case details
    case CREATE_TEST_CASE_DETAILS_SUCCESS:
      return { ...state, loading: false, isTestCaseDetailsModalOpen: false };
    case CREATE_COPY_TEMPLATE_ITEM_SUCCESS:
      return { ...state, loading: false, isTestCaseDetailsModalOpen: false };


    // Successfully edited a test case
    case EDIT_TEST_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        editTestCaseModalOpen: false,
        testCases: state.testCases.map((testCase) =>
          testCase._id === action.payload._id ? action.payload : testCase
        ),
      };

    // Successfully deleted a test case
    case DELETE_TEST_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        testCases: state.testCases.filter(
          (testCase) => testCase._id !== action.payload
        ),
      };

    // Error fetching all test cases
    case GET_ALL_TEST_CASES_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Error fetching a single test case by ID
    case GET_TEST_CASE_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Error creating a new test case
    case CREATE_TEST_CASE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case CREATE_COPY_TEMPLATE_ITEM_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Error creating test case details
    case CREATE_TEST_CASE_DETAILS_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Error editing a test case
    case EDIT_TEST_CASE_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Error deleting a test case
    case DELETE_TEST_CASE_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Set the test case to be edited
    case SET_EDIT_TEST_CASE:
      const editingTestCase = state.testCases.find(
        (testCase) => testCase._id === action.payload.id
      );
      return {
        ...state,
        editingTestCase: editingTestCase,
        isEditingTestCase: true,
      };

    // Open the edit test case modal
    case EDIT_TEST_CASE_MODAL_OPEN:
      return { ...state, editTestCaseModalOpen: true };

    // Close the edit test case modal and reset editing state
    case EDIT_TEST_CASE_MODAL_CLOSE:
      return {
        ...state,
        editTestCaseModalOpen: false,
        editingTestCase: null,
        isEditingTestCase: false,
      };

    // Begin fetching all test case details items
    case GET_ALL_TEST_CASE_DETAILS_ITEMS_BEGIN:
      return { ...state, loading: true, error: null };

    // Successfully fetched all test case details items
    case GET_ALL_TEST_CASE_DETAILS_ITEMS_SUCCESS:
      return { ...state, loading: false, testCaseDetailsItem: action.payload };

    // Error fetching all test case details items
    case GET_ALL_TEST_CASE_DETAILS_ITEMS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CREATE_COPY_TEMPLATE_ITEM_SUCCESS:
      return { ...state, loading: false, isTestCaseDetailsModalOpen: false, testCaseDetailsItem: action.payload };

    // Begin fetching test case details by ID
    case GET_TEST_CASE_DETAILS_BEGIN:
      return { ...state, loading2: true, error: null };

    // Successfully fetched test case details by ID
    case GET_TEST_CASE_DETAILS_SUCCESS:
      return { ...state, loading2: false, selectedTestCaseDetails: action.payload };

    // Error fetching test case details by ID
    case GET_TEST_CASE_DETAILS_ERROR:
      return { ...state, loading2: false, error: action.payload };

    // Begin deleting test case details item
    case DELETE_TEST_CASE_DETAILS_BEGIN:
      return { ...state, loading: true, error: null };

    // Successfully deleted a test case details item
    case DELETE_TEST_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        testCaseDetailsItem: state.testCaseDetailsItems.filter(
          (item) => item._id !== action.payload
        ),
      };

    // Error deleting test case details item
    case DELETE_TEST_CASE_DETAILS_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Begin updating test case details
    case UPDATE_TEST_CASE_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_TEST_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        testCaseDetailsItem: state.testCaseDetailsItems.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case UPDATE_TEST_CASE_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_TEST_CASE_REMARKS_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_TEST_CASE_REMARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        remarksData: action.payload,
      };
      case DELETE_REMARK_ATTACHMENT_BEGIN:
        return { ...state, loading: true, error: null };
      case DELETE_REMARK_ATTACHMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          remarksData: action.payload,
        };
      case DELETE_REMARK_ATTACHMENT_ERROR:
        return { ...state, loading: false, error: action.payload };
      case DELETE_TEST_ITEM_ATTACHMENT_BEGIN:
        return { ...state, loading: true, error: null };
      case DELETE_TEST_CASE_ATTACHMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          remarksData: action.payload,
        };
      case DELETE_TEST_CASE_ATTACHMENT_ERROR:
        return { ...state, loading: false, error: action.payload };

    case UPDATE_TEST_CASE_REMARKS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_TEST_CASE_BY_ID_BEGIN:
      return { ...state, loading2: true, error: null };
    case GET_TEST_CASE_BY_ID_SUCCESS:
      return { ...state, loading2: false, selectedSingleTestCase: action.payload };
    case GET_TEST_CASE_BY_ID_ERROR:
      return { ...state, loading2: false, error: action.payload };
      case COUNT_TEST_CASE_ITEMS_BEGIN:
      return {...state, loading: true, error: null };
      case COUNT_TEST_CASE_ITEMS_SUCCESS:
        return {...state, loading: false, statusCount:action.payload}
        case COUNT_TEST_CASE_ITEMS_ERROR:
          return {...state, loading: false, error:action.payload}
    // Return the current state by default
    default:
      return state;
  }
};

export default testCaseReducer;
