import styled from "styled-components"

export const SignInWrapper = styled.div`
  // background: var(--linear-gradiant);
  background-image: url(/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 20px 0 20px;
  .form-group {
    p {
      color: red !important;
      font-size: 12px;
    }
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  .signin .row {
    width: 700px;
    height: 450px;
    background: rgba(255, 255, 255, 0.6);
    border: none;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    @media (max-width: 500px) {
      height: auto;
      /* padding: 70px 14px; */
      width: 100%;
    }
  }

  header {
    text-align: center;
    color: var(--primary-btn);
    font-size: 1.9rem;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ddedeb0a;
    position: relative;
    margin-bottom: 25px;
  }

  .side-image {
    // background-image: url(https://img.freepik.com/premium-vector/secure-email-otp-authentication-verification-method_258153-468.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    opacity: 0.9;
    border-radius: 0px 10px 10px 0px;
  }
  input {
    padding: 10px 8px;
  }
  button {
    color: #fff;
    background: var(--primary-500) !important;
    border-color: var(--primary-500);
    transition: all 0.3s;
    font-weight: 500 !important;
  }

  button:hover {
    box-shadow: 1px 2px 3px #5e7e9f;
    background: var(--primary-500) !important;
    color: #fff !important;
    border-color: inherit;
  }

  .form-check-input {
    cursor: pointer;
    margin-top: 6px;
  }

  .btmq {
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    a {
      color: var(--primary-500) !important;
      font-size: 13px;
    }
  }

  .companylogo {
    text-align: center;
    margin-top: 20px;
  }

  .form-check-label {
    font-size: 14px;
  }
  img {
    position: absolute;
    margin-left: -60px;
    @media (max-width: 993px) {
      position: relative;
      margin: 0;
    }
  }

  .robot {
    width: 100px;
    height: 100px;
    background: #cc333f; /* control the color here */
    border-radius: 50%;
    position: relative;
    animation: robotAnimation 2.5s infinite linear;
  }

  .robot:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background: #666; /* control the color here */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: blinkEyes 2.5s infinite;
  }

  @keyframes robotAnimation {
    0% {
      transform: rotate(0deg) translateY(-10%);
    }
    25% {
      transform: rotate(10deg) translateY(0%);
    }
    50% {
      transform: rotate(0deg) translateY(-10%);
    }
    75% {
      transform: rotate(-10deg) translateY(0%);
    }
    100% {
      transform: rotate(0deg) translateY(-10%);
    }
  }

  @keyframes blinkEyes {
    0%,
    100% {
      height: 20px;
    }
    50% {
      height: 5px;
    }
  }

  @media (max-width: 767px) {
    .side-image {
      display: none;
    }
    .left {
      margin-bottom: 60px;
    }
  }
`
export const SignUpWrapper = styled.div`
  background-image: url(/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  /* margin: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    margin: 20 275px;
    width: 50vw;
    max-width: max-content;
    background: rgba(255, 255, 255, 0.6);

    @media (max-width: 766px) {
      width: 90vw;

      padding: 3rem 2.5rem;
    }
  }
  h3 {
    text-align: center;
    color: var(--primary-btn);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .btn {
    cursor: pointer;
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-2);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
    font-weight: 500 !important;
    color: rgb(255, 255, 255);
    width: 30%;
    color: rgb(255, 255, 255);
    border-color: var(--primary-500);
    transition: all 0.3s ease 0s;
    background: var(--primary-500) !important;
    font-weight: 500 !important;
  }

  .btn:hover {
    box-shadow: 1px 2px 3px #5e7e9f;
    background: var(--primary-500-active) !important;
    color: #fff !important;
    border-color: inherit;
  }

  .PhoneInput input {
    height: 35px;
    min-width: 0;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--borderRadius);
    background: var(--white);
    border: 1px solid var(--grey-200);
  }

  .PhoneInput .PhoneInputCountry {
    border: 1px solid grey;
    padding: 3px;
    border-radius: var(--borderRadius);
    background: var(--white);
    border: 1px solid var(--grey-200);
  }
  .bttn {
    text-align: center;
    margin-top: 30px;
  }

  .btmq {
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    a {
      color: var(--primary-500) !important;
      font-size: 14px;
    }
  }
`
export const OTPHeader = styled.div`
  text-align: center;
  color: var(--primary-btn);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  p {
    font-size: 1rem;
    /* margin-top: 1rem; */
    color: var(--primary-btn) !important;
    display: flex;
    justify-content: center;
  }
`
export const OTPWrapper = styled.div`
  .csdk {
    padding: 20px 40px;
    color: #fff;
    border-radius: 50%;
    button {
      font-size: 18px !important;
    }
    .modal-content {
      padding: 20px 40px;
      width: 500px;
    }
  }
  .popup-modal {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9999;
    width: 400px;

    bottom: 0;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    height: 40px;
    button {
      width: auto !important;
    }
  }
  input {
    width: 35px !important;
    height: 35px;
    margin: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;

    @media (max-width: 500px) {
      margin: 5px;
    }
  }
  span {
    font-size: 13px;
    font-style: italic;
  }
  button {
    margin: 20px 0px;
    width: 50%;
  }
`
export const ConfirmWrapper = styled.div`
  background-image: url(/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 20px 0 20px;
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  /* margin: 0; */
  display: flex;
  align-items: center;
  justify-content: center;

  .textCenter {
    text-align: center !important;
  }
  img {
    height: 50%;
    width: 50%;
  }
`
