export const GET_ALL_PROJECTS_BEGIN = "GET_ALL_PROJECTS_BEGIN"
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS"
export const GET_ALL_PROJECTS_ERROR = "GET_ALL_PROJECTS_ERROR"

export const GET_ALL_PROJECTS_USER_BEGIN = "GET_ALL_PROJECTS_USER_BEGIN"
export const GET_ALL_PROJECTS_USER_SUCCESS = "GET_ALL_PROJECTS_USER_SUCCESS"
export const GET_ALL_PROJECTS_USER_ERROR = "GET_ALL_PROJECTS_USER_ERROR"

export const OPEN_PROJECT_MODAL = "OPEN_PROJECT_MODAL"
export const CLOSE_PROJECT_MODAL = "CLOSE_PROJECT_MODAL"

export const SET_EDIT_PROJECT = "SET_EDIT_PROJECT"

export const CREATE_PROJECT_BEGIN = "CREATE_PROJECT_BEGIN"
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS"
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR"

export const EDIT_PROJECT_BEGIN = "EDIT_PROJECT_BEGIN"
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS"
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR"

export const SELECT_PROJECT = "SELECT_PROJECT"

export const UPDATE_PROJECT = "UPDATE_PROJECT"

export const UPDATE_PROJECT_PIC = "UPDATE_PROJECT_PIC"

export const EDIT_MODAL_OPEN = "EDIT_MODAL_OPEN"
export const EDIT_MODAL_CLOSE = "EDIT_MODAL_CLOSE"

export const DRAG_CATEGORY = "DRAG_CATEGORY"

export const GET_ALL_DEVPRO_BEGIN = "GET_ALL_DEVPRO_BEGIN"
export const GET_ALL_DEVPRO_SUCCESS = "GET_ALL_DEVPRO_SUCCESS"
export const GET_ALL_DEVPRO_ERROR = "GET_ALL_DEVPRO_ERROR"
