import React, { useEffect } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { getAllQuestion, setEditMultiple } from "../../redux"
import ApiIcon from "@mui/icons-material/Api"
import { capitalizeFirstLetter } from "../../utils/getInitialName"
import {
  DeleteIcon,
  DriveFileRenameOutlineSharpIcon,
  EditNoteOutlinedIcon,
} from "../../assets/icons"
import { FiberManualRecordRoundedIcon } from "../../assets/icons"
import QuestionFilter from "./QuestionFilter"

const Multiple = () => {
  const dispatch = useDispatch()
  const { multiple } = useSelector((state) => state.feedback)

  if (multiple.length < 1) {
    return (
      <p className="tw-flex tw-flex-col tw-gap-2 lg:tw-px-8   tw-px-3 " >
       <span className="tw-bg-gray-100 tw-px-[5px] tw-py-3 tw-italic">  No questions to display add one by clicking on add question</span>
        <QuestionFilter multiple={true} />
      </p>
    )
  }
  return (
    <Wrapper>
      <QuestionFilter multiple={true} />
      {multiple.length > 0 &&
        multiple.map((i) => {
          return (
            <div>
              <h6>
                <ApiIcon fontSize="15px" style={{ marginRight: "10px" }} />
                {capitalizeFirstLetter(i.question)}
                <DriveFileRenameOutlineSharpIcon
                  className="edit"
                  onClick={() => dispatch(setEditMultiple(i._id))}
                />
                <DeleteIcon className="del" />
              </h6>
              <div className="options">
                {i.options.map((o, i) => {
                  return (
                    <p key={i}>
                      <FiberManualRecordRoundedIcon
                        style={{ marginRight: "3px", fontSize: "5px" }}
                      />{" "}
                      {o}
                    </p>
                  )
                })}
              </div>
            </div>
          )
        })}
      <section></section>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  .options {
    display: flex;
    margin-bottom: 20px;
    margin-top: -8px;
    background: #fbfeff;
    flex-wrap: wrap;
    padding: 20px 0;
    box-shadow: var(--shadow-1);
  }

  h6 {
    font-size: 14px;
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 5px;
    padding: 11px 10px;
    box-shadow: var(--shadow-1);
    color: rgb(19 49 54);
  }

  p {
    margin: 0 30px;
    text-transform: capitalize;
    font-size: 12px;
  }

  .edit {
    margin-left: 20px;
    color: var(--blue);
    font-size: 18px;
  }

  .del {
    margin-left: 10px;
    color: rgb(203, 48, 69);
    font-size: 18px;
  }
`
export default Multiple
