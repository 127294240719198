import React from "react"
import error from "../assets/images/404.png"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { HomeIcon } from "../assets/icons"

const Error = () => {
  return (
    <Wrapper>
      <img src={error} alt="" className="center" />

      <div className="btnn">
        Page not found
        <br />
        <Link to="/">
          <button className="btn ">
            back to home <HomeIcon />
          </button>
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  .btnn {
    text-align: center;
  }
  button {
    box-shadow: none;
    font-size: 14px;
    color: var(--primary-500);
    text-transform: lowercase;
  }
  a {
    color: var(--primary-500);
  }
`

export default Error
