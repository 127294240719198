import React, { useRef } from "react"
import styled from "styled-components"
import { useState, useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
// import defaultImg from "../assets/images/mobile.png"
import { useAppContext } from "../../context/context/appContext"
import cache from "../../utils/imageCache"
import { useDispatch } from "react-redux"
import { getProjectPic } from "../../redux"

const ProjectIcon = ({ id, size }) => {
  const dispatch = useDispatch()
  const { profilepic, socket } = useAppContext()
  const [image, setImage] = useState(null)
  const [isLoading, setLoading] = useState(true)

  //   const handleDownload = async (id, size) => {
  //     setLoading(false)
  //     const response = await getProfilePic(id, size)
  //     const blob = new Blob([response.data], { type: "image/jpeg" })
  //     const imageUrl = URL.createObjectURL(blob)
  //     console.log(imageUrl)
  //     await setImage(imageUrl)
  //     setLoading(true)
  //   }
  useEffect(() => {
    // handleDownload(id, size)
  }, [profilepic])

  useEffect(() => {
    handleCache(id, size)
  }, [])

  const handleCache = async (id, size) => {
    const imageBuffer = cache.get(id)

    if (imageBuffer) {
      const blob = new Blob([imageBuffer], { type: "image/jpeg" })
      const imageUrl = URL.createObjectURL(blob)

      setImage(imageUrl)
    } else {
      const response = await dispatch(getProjectPic(id, size))

      cache.set(id, response.data)
      const blob = new Blob([response.data], { type: "image/jpeg" })
      const imageUrl = URL.createObjectURL(blob)

      await setImage(imageUrl)
    }
  }
  useEffect(() => {
    socket.on("projectPicUpdate", async (data) => {
      if (data.id === id) {
        cache.delete(data.id)
        const response = await dispatch(getProjectPic(id, size))

        cache.set(id, response.data)
        const blob = new Blob([response.data], { type: "image/jpeg" })
        const imageUrl = URL.createObjectURL(blob)

        await setImage(imageUrl)
      }
    })
  }, [socket])
  return (
    <Wrapper>
      {isLoading ? (
        <img src={image} alt="n" />
      ) : (
        <RotatingLines
          strokeColor="grey"
          strokeWidth="3"
          animationDuration="0.75"
          width="30"
          visible={true}
        />
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-left: -10px;
    background: #ebf3f9;
  }
`

export default ProjectIcon
