import React, { useEffect, useState } from "react"
import Moment from "react-moment"
import { useAppContext } from "../../context/context/appContext"
import { capitalizeFirstLetter } from "../../utils/getInitialName"
import { useSelector, useDispatch } from "react-redux"
import { getGoalsProject, openManageGoalModal } from "../../redux"
import ManageGoalModal from "./ManageGoalModal"
import { GoalWrapper } from "../../assets/styles/Goals"
import { CloseOutlinedIcon } from "../../assets/icons"
import { getTasksByGoal, getTasksByProject } from "../../redux"

const GoalContainer = ({ setOpenPopupGoal }) => {
  const { user, socket, accessList } = useAppContext()
  const dispatch = useDispatch()
  const { loading, goals } = useSelector((state) => state.goal)
  const { selectedProject } = useSelector((state) => state.project)

  const [filterList, setFilterList] = useState([])

  useEffect(() => {
    dispatch(getGoalsProject({ project: selectedProject._id }))
  }, [selectedProject])

  useEffect(() => {
    socket.on("newGoal", (data) => {
      console.log(data)
      // getGoalsProject({ project: selectedProject._id })
    })
    socket.on("goalUpdated", (data) => {
      console.log(data)
      // getGoalsProject({ project: selectedProject._id })
    })
  }, [socket])
  useEffect(() => {
    if (filterList.length !== 0) {
      dispatch(getTasksByGoal(filterList))
    } else {
      dispatch(getGoalsProject({ project: selectedProject._id }))
    }
  }, [filterList])
  const minDate = Math.min(
    ...goals.map((element) => {
      return new Date(element.startDate)
    })
  )
  const maxDate = Math.max(
    ...goals.map((element) => {
      return new Date(element.endDate)
    })
  )

  const handleClear = () => {
    dispatch(getGoalsProject({ project: selectedProject._id }))
    setFilterList([])
    dispatch(getTasksByProject(selectedProject._id))
  }

  return (
    <GoalWrapper>
      <div className="goal-heading">
        <p>
          Goals ({goals.length}){" "}
          {goals.length !== 0 && (
            <div className="date-group">
              <p>{<Moment format="DD/MM/YYYY">{minDate}</Moment>}</p>
              <p>&nbsp;&nbsp;-&nbsp;&nbsp;</p>
              <p>{<Moment format="DD/MM/YYYY">{maxDate}</Moment>}</p>
            </div>
          )}
        </p>
        <CloseOutlinedIcon onClick={() => setOpenPopupGoal(false)} />
      </div>
      <hr />

      <div className="textRight dflex">
        {accessList.includes("CREATE_GOAL") && (
          <button
            type="button"
            className="btn btn-newlist  "
            onClick={() => dispatch(openManageGoalModal())}
          >
            Manage Goals
          </button>
        )}
        <button
          type="button"
          className="btn btn-newlist  "
          onClick={handleClear}
        >
          clear filters
        </button>
      </div>

      {goals.length === 0 ? (
        <p style={{ fontSize: "12px" }}>No goals </p>
      ) : (
        goals.map((item) => {
          return (
            <div className="single-goal" key={item._id}>
              <div className="title">
                <p>{capitalizeFirstLetter(item.title)}</p>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setFilterList([...filterList, item._id])
                    }
                    if (e.target.checked === false) {
                      setFilterList(filterList.filter((i) => i !== item._id))
                    }
                  }}
                  checked={filterList.includes(item._id)}
                />
              </div>
              <div className="date-group">
                <Moment format="DD/MM/YYYY">{item.startDate}</Moment>
                &nbsp;&nbsp;-&nbsp;&nbsp;
                <Moment format="DD/MM/YYYY">{item.endDate}</Moment>
              </div>

              {/* <ProgressBar
                width="100%"
                height="10px"
                rect
                fontColor="gray"
                percentage="70"
                rectPadding="1px"
                rectBorderRadius="20px"
                trackPathColor="transparent"
                bgColor="#333333"
                trackBorderColor="grey"
                marginTop="15px"
                defColor={{
                  fair: "orangered",
                  good: "yellow",
                  excellent: "green",
                  poor: "red",
                }}
              /> */}
            </div>
          )
        })
      )}
      <ManageGoalModal />
    </GoalWrapper>
  )
}

export default GoalContainer
