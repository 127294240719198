export const OPEN_WORKLOG_MODAL = "OPEN_WORKLOG_MODAL"
export const CLOSE_WORKLOG_MODAL = "CLOSE_WORKLOG_MODAL"

export const CREATE_WORKLOG_BEGIN = "CREATE_WORKLOG_BEGIN"
export const CREATE_WORKLOG_SUCCESS = "CREATE_WORKLOG_SUCCESS"
export const CREATE_WORKLOG_ERROR = "CREATE_WORKLOG_ERROR"

export const GET_ALL_WORKLOG_TASK_BEGIN = "GET_ALL_WORKLOG_TASK_BEGIN"
export const GET_ALL_WORKLOG_TASK_SUCCESS = "GET_ALL_WORKLOG_TASK_SUCCESS"
export const GET_ALL_WORKLOG_TASK_ERROR = "GET_ALL_WORKLOG_TASK_ERROR"

export const GET_SINGLE_WORKLOG_BEGIN = "GET_SINGLE_WORKLOG_BEGIN"
export const GET_SINGLE_WORKLOG_SUCCESS = "GET_SINGLE_WORKLOG_SUCCESS"
export const GET_SINGLE_WORKLOG_ERROR = "GET_SINGLE_WORKLOG_ERROR"

export const SET_EDITING_WORKLOG_BEGIN = "SET_EDITING_WORKLOG_BEGIN"
export const SET_EDITING_WORKLOG_SUCCESS = "SET_EDITING_WORKLOG_SUCCESS"
export const SET_EDITING_WORKLOG_ERROR = "SET_EDITING_WORKLOG_ERROR"

export const UPDATE_WORKLOG_BEGIN = "UPDATE_WORKLOG_BEGIN"
export const UPDATE_WORKLOG_SUCCESS = "UPDATE_WORKLOG_SUCCESS"
export const UPDATE_WORKLOG_ERROR = "UPDATE_WORKLOG_ERROR"

export const DELETE_WORKLOG_BEGIN = "DELETE_WORKLOG_BEGIN"
export const DELETE_WORKLOG_SUCCESS = "DELETE_WORKLOG_SUCCESS"
export const DELETE_WORKLOG_ERROR = "DELETE_WORKLOG_ERROR"

// for admin
export const GET_ALL_WORKLOG_BEGIN = "GET_ALL_WORKLOG_BEGIN"
export const GET_ALL_WORKLOG_SUCCESS = "GET_ALL_WORKLOG_SUCCESS"
export const GET_ALL_WORKLOG_ERROR = "GET_ALL_WORKLOG_ERROR"

export const GET_DAILY_LOGS_BEGIN = "GET_DAILY_LOGS_BEGIN"
export const GET_DAILY_LOGS_SUCCESS = "GET_DAILY_LOGS_SUCCESS"
export const GET_DAILY_LOGS_ERROR = "GET_DAILY_LOGS_ERROR"

export const GET_WEEKLY_LOGS_BEGIN = "GET_WEEKLY_LOGS_BEGIN"
export const GET_WEEKLY_LOGS_SUCCESS = "GET_WEEKLY_LOGS_SUCCESS"
export const GET_WEEKLY_LOGS_ERROR = "GET_WEEKLY_LOGS_ERROR"

// for user
export const GET_ALL_WORKLOG_USER_BEGIN = "GET_ALL_WORKLOG_USER_BEGIN"
export const GET_ALL_WORKLOG_USER_SUCCESS = "GET_ALL_WORKLOG_USER_SUCCESS"
export const GET_ALL_WORKLOG_USER_ERROR = "GET_ALL_WORKLOG_USER_ERROR"

export const GET_ATTENDANCE_REPORT = "GET_ATTENDANCE_REPORT"
