import React, { useRef } from 'react';

function ScrollableContainer() {
  const containerRef = useRef(null);

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 20;
    }
  };

  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 20;
    }
  };

  return (
    <div>
      <div
        id="container"
        ref={containerRef}
        style={{ width: '100%', height: '100px', border: '1px solid #ccc', overflowX: 'scroll' }}
      >
        <div id="content" style={{ width: '100%', backgroundColor: '#ccc' }}>
          <div className="d-flex">
            <div style={{ width: '350px' }}> Click the buttons to slide horizontally!</div>
            <div style={{ width: '350px' }}> Click the buttons to slide horizontally!</div>
            <div style={{ width: '350px' }}> Click the buttons to slide horizontally!</div>
            <div style={{ width: '350px' }}> Click the buttons to slide horizontally!</div>
            <div style={{ width: '350px' }}> Click the buttons to slide horizontally!</div>
            <div style={{ width: '350px' }}> Click the buttons to slide horizontally!</div>
            <div style={{ width: '350px' }}> Click the buttons to slide horizontally!</div>
          </div>
        </div>
      </div>
      <button id="slideLeft" type="button" onClick={handleScrollLeft}>
        Slide left
      </button>
      <button id="slideRight" type="button" onClick={handleScrollRight}>
        Slide right
      </button>
    </div>
  );
}

export default ScrollableContainer;
