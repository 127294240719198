import React, { useEffect, useState } from "react"
import { RotatingLines } from "react-loader-spinner"
import { getResource } from "../../utils/s3Resources"

const AttachmentLoading = ({ test }) => {
  const [image, setImage] = useState(null)

  const handleDownload = async (url) => {
    const response = await getResource(url)
    const blob = new Blob([response.data], { type: "image/jpeg" })
    const imageUrl = URL.createObjectURL(blob)
    setImage(imageUrl)
    return imageUrl
  }
  useEffect(() => {
    handleDownload(test)
  }, [])
  if (!image) {
    return (
      <RotatingLines
        strokeColor="grey"
        strokeWidth="3"
        animationDuration="0.75"
        width="60"
        height="80"
        visible={true}
      />
    )
  }
  return <img src={image} alt="image" />
}

export default AttachmentLoading
