import React, { useState, useEffect, useRef } from "react"
import { FormRow, Pagination } from "../Common"
import { AllUser } from "../Users"
import { useAppContext } from "../../context/context/appContext"
import { useDispatch, useSelector } from "react-redux"
import {
  openClientModal,
  setEditingClient,
  getAllClients,
  openUserListModal,
} from "../../redux"
import { ClientWrapper } from "../../assets/styles/Client"
import {
  AccountCircleRoundedIcon,
  AddSharpIcon,
  DriveFileRenameOutlineSharpIcon,
  FiberManualRecordRoundedIcon,
  MoreHorizRoundedIcon,
  SearchIcon,
} from "../../assets/icons"
import { SearchInside } from "../../assets/styles/Dashboard"
const AllClients = () => {
  const dispatch = useDispatch()
  const { clients, isClientModalOpen } = useSelector((state) => state.client)
  const [actionOpen, setActionOpen] = useState(false)

  const [userOpen, setUserOpen] = useState(false)
  const [id, setId] = useState()

  const [limit, setLimit] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")

  const currentPage = useRef(1)

  const [isActive, setIsActive] = useState(false);


  const getPagination = async () => {
    await dispatch(getAllClients(currentPage.current, limit, searchQuery, isActive))
    setPageCount(clients?.data?.pageCount) // Update pageCount from API response
  }

  useEffect(() => {
    currentPage.current = 1
    //getAllClients(currentPage.current, limit, searchQuery);
    getPagination()
  }, [limit, searchQuery, isActive, isClientModalOpen])

  // make the loading component later
  // if (loading) {
  //   return <CircleLoading />
  // }

  const handleIsActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const handleAction = (e, value) => {
    setId(value)
    setActionOpen(!actionOpen)
  }

  const handleUsers = () => {
    setUserOpen(!userOpen)
    setActionOpen(false)
  }

  const editClient = (value) => {
    setActionOpen(false)
    dispatch(setEditingClient(value))
  }

  const handlePageClick = (e) => {
    currentPage.current = e.selected + 1
    getPagination()
  }

  const changeLimit = () => {
    currentPage.current = 1
    getPagination()
  }

  return (
    <>
      <ClientWrapper>
        <h5 className="card-title  lg:tw-p-8 lg:tw-pb-4  tw-p-3  tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">
          Clients   <SearchInside >
            <div className="searches ">

              <SearchIcon className=" " />

              <FormRow
                name="companyName"
                type="text"
                placeholder="Search by company name"
                value={searchQuery}
                handleChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

          </SearchInside></h5>
        <div className="d-flex align-items-center  gap-4 lg:tw-px-8 tw-px-3 ">
          <div className="tw-w-36">
          <button
            className="btn add-btn"
            type="submit"
            onClick={() => dispatch(openClientModal())}
          >
            <AddSharpIcon style={{ color: "#fff" }} /> Add Client
          </button>
          </div>
          <div className="d-flex gap-2 tw-w-full">
            <input
              type='checkbox'
              value={isActive}
              name={'isActive'}
              checked={isActive}  // Set checked to false, so checkbox is not checked by default
              onChange={handleIsActiveChange}
              className="tw-w-6"
            />
            <label htmlFor={'isActive'} className="tw-w-full" style={{fontSize: 14,}}>
              {"Include Inactive"}
            </label>
            </div>
          </div>

        <div className="tableresponse  lg:tw-p-8  tw-p-3   tw-h-[72vh] tw-overflow-y-scroll"  >
          <table>
            <thead>
              <tr>
                <th>Client</th>
                <th className="textLeft">Website</th>
                <th>Contact Number</th>
                <th className="textLeft">Address</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {clients && clients.result && clients.result.length > 0 ? (
                clients.result
                  .filter((client) =>
                    client.company_name
                      .toLowerCase()
                      .includes(searchQuery.toLocaleLowerCase())
                  )
                  .map((client) => {
                    return (
                      <tr key={client._id}>
                        <td cl>
                          <span className="main-icon">
                            {/* {getInitials(client.company_name)} */}
                            <img
                              src="https://secure.gravatar.com/avatar/28859fdf97e611b1434dec99bdb5554d?s=48&d=identicon"
                              alt="logo"
                            />
                          </span>
                          <div className="flexColumn">
                            <span className="textC">{client.company_name}</span>
                            <span className="lowerCase f-11">
                              {client.email}
                            </span>
                          </div>
                        </td>
                        {/* <td className="textC">{client.company_name}</td> */}
                        <td className="textLeft">{client.website}</td>
                        {/* <td className="lowerCase">{client.email}</td> */}
                        <td>{client.contact_number}</td>
                        <td
                          className="textLeft"
                          style={{ textTransform: "capitalize" }}
                        >
                          {client.address}
                        </td>
                        <td>
                          <span
                            className={client.is_active ? "actives" : "status"}
                          >
                            <FiberManualRecordRoundedIcon
                              style={{
                                color: client.is_active
                                  ? "rgb(79, 190, 121)"
                                  : "var(--grey-400)",
                              }}
                            />
                          </span>
                        </td>
                        <td>
                          <h4 onClick={(e) => handleAction(e, client._id)}>
                            <MoreHorizRoundedIcon
                              style={{ fontSize: "25px" }}
                            />
                          </h4>
                          {id === client._id && actionOpen ? (
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu2"
                            >
                              <button
                                className="dropdown-item clints"
                                type="button"
                                onClick={() =>
                                  dispatch(openUserListModal(client._id))
                                }
                              >
                                <AccountCircleRoundedIcon />
                                <span className="text"> Users </span>
                              </button>
                              <button
                                className="dropdown-item clints"
                                type="button"
                                onClick={() =>{ 
                                  editClient(client._id)
                                  setIsActive(false)
                                }}

                                
                              >
                                <DriveFileRenameOutlineSharpIcon />
                                <span className="text"> Edit </span>
                              </button>
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    )
                  })
              ) : (
                <p className="f-12">No results found!</p>
              )}
            </tbody>
          </table>
        </div>
        {clients && clients.totalUser > 10 && clients.pageCount && (
          <div className="paginationed">
            <div>
              {/* Total Records : {clients.totalUser} */}
              <input
                type="number"
                placeholder="Limit"
                onChange={(e) => setLimit(e.target.value)}
              />
              <button onClick={changeLimit}>set row</button>
            </div>

            <Pagination
              handlePageClick={handlePageClick}
              pageCount={clients.pageCount}
              forcePage={currentPage.current - 1}
            />
          </div>
        )}

        <AllUser />
      </ClientWrapper>
      <div
        className={`${actionOpen && "pg-blur"}  `}
        onClick={() => setActionOpen(false)}
      ></div>
    </>
  )
}

export default AllClients
