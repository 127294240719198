import React from "react"
import { useAppContext } from "../../context/context/appContext"
import { Logo } from "../Common"
import NavLinks from "./NavLinks"
import { SmHeader, SmWrapper } from "../../assets/styles/Layouts"
import { CloseOutlinedIcon } from "../../assets/icons"

const SmallSidebar = () => {
  const { showSidebar, toggleSidebar } = useAppContext()
  return (
    <SmWrapper>
      <div
        className={
          showSidebar ? "sidebar-container show-sidebar" : "sidebar-container"
        }
      >
        <div className="content">
          <SmHeader>
            <Logo />
            <CloseOutlinedIcon onClick={toggleSidebar} />
          </SmHeader>

          <NavLinks toggleSidebar={toggleSidebar} />
        </div>
      </div>
    </SmWrapper>
  )
}

export default SmallSidebar
