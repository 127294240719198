import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closeTemplateModal, createTemplate, editTemplate, getAllTemplates, getTemplate } from "../../../redux";
import { FormRow } from "../../Common";
import FormTextArea from "../../Common/FormTextArea";


const TemplatesModal = ({ isTemplateModalOpen, id, setId }) => {
  const dispatch = useDispatch();
  const { selectedTemplate, loading } = useSelector((state) => state.template);
  const [values, setValues] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(getTemplate(id));
    } else {
      setValues({
        name: "",
        description: "",
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (selectedTemplate && id) {
      setValues({
        name: selectedTemplate.name || "",
        description: selectedTemplate.description || "",
      });
    } else {
      setValues({
        name: "",
        description: "",
      });
    }
  }, [selectedTemplate]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      dispatch(editTemplate(id, values));
    } else {
      dispatch(createTemplate(values));
    }
    setValues({
      name: "",
      description: "",
    });
    dispatch(getAllTemplates());
    dispatch(closeTemplateModal());
    setId("");
  };

  return (
    <Modal
      show={isTemplateModalOpen}
      onHide={() => {
        dispatch(closeTemplateModal());
        setId("");
        setValues({
        name: "",
        description: "",
      });
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{id ? "Edit Template" : "Create Template"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <FormRow
            name="name"
            type="text"
            labelText="Name"
            value={values.name}
            handleChange={handleChange}
            required={true}
            placeholder="Enter Name"
          />
          <FormTextArea
            name="description"
            labelText="Description"
            value={values.description}
            handleChange={handleChange}
            required={true}
            placeholder="Enter Description"
          />
          <div className="textRight">
            <button className="btn add-btn" type="submit" disabled={loading}>
              {id ? "Update" : "Submit"}
            </button>
            <button className="btn add-btn" disabled={loading} onClick={() => {
              dispatch(closeTemplateModal());
              setValues({
                name: "",
                description: "",
              })
              setId("")
            }}>
              {"Cancel"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TemplatesModal;
