import { socket, user } from "../context/context/appContext"
import store from "../redux/store"
import {
  newTaskCreated,
  updatedProject,
  filterTaskOnStatusChange,
  updateTaskSocket,
} from "../redux"

export const connectWithWebSocket = () => {
  socket.on("newTask", (data) => {
    store.dispatch(newTaskCreated(data))
  })

  socket.on("mySocket", (data) => {
    console.log(data)
    const User = JSON.parse(user)
    socket.emit("showOnline", {
      socketId: data,
      user: { _id: User._id, email: User.email },
    })
  })

  socket.on("projectUpdated", (data) => {
    //  store.dispatch({
    //    type: UPDATE_PROJECT,
    //    payload: { id: data.id, updatedField: data.updatedField },
    //  })
    store.dispatch(updatedProject(data.id, data.updatedField))
  })

  socket.on("tasks", ({ id, destination }) => {
    // const item = tasks.filter((item) => item._id === id)
    // console.log(destination)
    store.dispatch(filterTaskOnStatusChange(id, destination))
  })

  socket.on("taskUpdated", (data) => {
    updateTaskSocket(data.id, data.updatedField)
  })
}
