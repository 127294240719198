import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { useAppContext } from "../../context/context/appContext"
import { userLinks, adminLinks } from "../../utils/links"

const NavLinks = ({ toggleSidebar }) => {
  const { user } = useAppContext()
  if (!user) {
    return <h4>loading</h4>
  }
  return (
    <Wrapper>
      <div className="nav-links">
        {user.role === "admin"
          ? adminLinks.map((link) => {
              const { text, path, id, icon } = link
              return (
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  key={id}
                  onClick={toggleSidebar}
                >
                  <span className="icon">{icon}</span>
                  {text}
                </NavLink>
              )
            })
          : userLinks.map((link) => {
              const { text, path, id, icon } = link
              return (
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  key={id}
                  onClick={toggleSidebar}
                >
                  <span className="icon">{icon}</span>
                  {text}
                </NavLink>
              )
            })}
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  img {
    width: 50px;
  }
`

export default NavLinks
