// testItemsTypes.js
export const CREATE_TEST_ITEM_BEGIN = 'CREATE_TEST_ITEM_BEGIN';
export const CREATE_TEST_ITEM_SUCCESS = 'CREATE_TEST_ITEM_SUCCESS';
export const CREATE_TEST_ITEM_ERROR = 'CREATE_TEST_ITEM_ERROR';

export const GET_ALL_TEST_ITEMS_BEGIN = 'GET_ALL_TEST_ITEMS_BEGIN';
export const GET_ALL_TEST_ITEMS_SUCCESS = 'GET_ALL_TEST_ITEMS_SUCCESS';
export const GET_ALL_TEST_ITEMS_ERROR = 'GET_ALL_TEST_ITEMS_ERROR';

export const GET_TEST_ITEM_BEGIN = 'GET_TEST_ITEM_BEGIN';
export const GET_TEST_ITEM_SUCCESS = 'GET_TEST_ITEM_SUCCESS';
export const GET_TEST_ITEM_ERROR = 'GET_TEST_ITEM_ERROR';

export const EDIT_TEST_ITEM_BEGIN = 'EDIT_TEST_ITEM_BEGIN';
export const EDIT_TEST_ITEM_SUCCESS = 'EDIT_TEST_ITEM_SUCCESS';
export const EDIT_TEST_ITEM_ERROR = 'EDIT_TEST_ITEM_ERROR';

export const DELETE_TEST_ITEM_BEGIN = 'DELETE_TEST_ITEM_BEGIN';
export const DELETE_TEST_ITEM_SUCCESS = 'DELETE_TEST_ITEM_SUCCESS';
export const DELETE_TEST_ITEM_ERROR = 'DELETE_TEST_ITEM_ERROR';

export const OPEN_TEST_ITEM_MODAL = 'OPEN_TEST_ITEM_MODAL';
export const CLOSE_TEST_ITEM_MODAL = 'CLOSE_TEST_ITEM_MODAL';

export const SET_EDIT_TEST_ITEM = 'SET_EDIT_TEST_ITEM';
export const EDIT_TEST_ITEM_MODAL_OPEN = 'EDIT_TEST_ITEM_MODAL_OPEN';
export const EDIT_TEST_ITEM_MODAL_CLOSE = 'EDIT_TEST_ITEM_MODAL_CLOSE';

export const DELETE_TEST_ITEM_ATTACHMENT_BEGIN = 'DELETE_TEST_ITEM_ATTACHMENT_BEGIN';
export const DELETE_TEST_ITEM_ATTACHMENT_SUCCESS = 'DELETE_TEST_ITEM_ATTACHMENT_SUCCESS';
export const DELETE_TEST_ITEM_ATTACHMENT_ERROR = 'DELETE_TEST_ITEM_ATTACHMENT_ERROR';



