import React, { useEffect, useState } from "react"
import { Loading2 } from "../../components/Common"
import { BigSidebar, SmallSidebar, Navbar } from "../../components/Layouts"
import { Outlet } from "react-router-dom"
import { useAppContext } from "../../context/context/appContext"
import { SharedWrapper } from "../../assets/styles/Dashboard"
import { connectWithWebSocket } from "../../utils/wssConnection"
import "../../styles/theme.css"
const SharedLayout = () => {
  const {
    getNewAccessToken,
    refreshToken,
    user,
    setShowLogout,
    socket,
    getAccessList,
  } = useAppContext()
  const [theme, setTheme] = useState("light")
  useEffect(() => {
    const intervalId = setInterval(() => {
      getNewAccessToken()
    }, 830000)
    return () => clearInterval(intervalId)
  }, [refreshToken])
  useEffect(() => {
    const user = localStorage.getItem("User")
    // setupUser(JSON.parse(user))
    // console.log(JSON.parse(user))
    const temp = JSON.parse(user)
    getAccessList()
  }, [])
  useEffect(() => {
    connectWithWebSocket()
  }, [socket])

  if (!user) {
    return <Loading2 />
  }

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark")
    } else {
      setTheme("light")
    }
  }
  return (
    <SharedWrapper>
      <main className="dashboard">
        <SmallSidebar />
        <BigSidebar />
        <div>
          <Navbar theme={theme} toggleTheme={toggleTheme} />

          <div
            className={`dashboard-page  ${theme} `}
            onClick={() => setShowLogout(false)}
          >
            <div className="pb-[100px]">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </SharedWrapper>
  )
}

export default SharedLayout
