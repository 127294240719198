import {
  GET_GOALS_PROJECT_BEGIN,
  GET_GOALS_PROJECT_SUCCESS,
  GET_GOALS_PROJECT_ERROR,
  CREATE_GOAL_BEGIN,
  CREATE_GOAL_SUCCESS,
  CREATE_GOAL_ERROR,
  UPDATE_GOAL_BEGIN,
  UPDATE_GOAL_SUCCESS,
  UPDATE_GOAL_ERROR,
  GET_SINGLE_GOAL_BEGIN,
  GET_SINGLE_GOAL_SUCCESS,
  GET_SINGLE_GOAL_ERROR,
  OPEN_GOAL_MODAL,
  CLOSE_GOAL_MODAL,
  SET_EDITING_GOAL_BEGIN,
  SET_EDITING_GOAL_SUCCESS,
  SET_EDITING_GOAL_ERROR,
  DELETE_GOAL_BEGIN,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_ERROR,
  OPEN_MANAGE_GOAL_MODAL,
  CLOSE_MANAGE_GOAL_MODAL,
  GET_GOALS_REPORT_BEGIN,
  GET_GOALS_REPORT_SUCCESS,
  GET_GOALS_REPORT_ERROR,
} from "./goalTypes"
import { toast } from "react-toastify"

import authFetch from "../../utils/interceptor"

export const createGoal = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_GOAL_BEGIN })
    try {
      const response = await authFetch.post("/goal", values)
      dispatch({
        type: CREATE_GOAL_SUCCESS,
        payload: { goal: response.data.goal },
      })
      toast.success("Goal created successfully")
    } catch (error) {
      dispatch({ type: CREATE_GOAL_ERROR })
      toast.error("Error creating goal try later")
    }
  }
}

export const getGoalsProject = (values) => {
  return async (dispatch) => {
    dispatch({ type: GET_GOALS_PROJECT_BEGIN })
    try {
      const response = await authFetch.get(
        `/goal/project/${values.project}`,
        values
      )
      dispatch({
        type: GET_GOALS_PROJECT_SUCCESS,
        payload: { goals: response.data.goals },
      })
    } catch (error) {
      dispatch({ type: GET_GOALS_PROJECT_ERROR })
    }
  }
}

export const updateGoal = (values) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_GOAL_BEGIN })
    try {
      const response = await authFetch.patch(`/goal/${values._id}`, values)
      dispatch({
        type: UPDATE_GOAL_SUCCESS,
        payload: { updatedGoal: response.data.updatedGoal },
      })
      toast.success("Goal updated successfully")
    } catch (error) {
      dispatch({ type: UPDATE_GOAL_ERROR })
      toast.error("Error updating goal")
    }
  }
}

export const getSingleGoal = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_SINGLE_GOAL_BEGIN })
    try {
      const response = authFetch.get(`/goal/${id}`)
      dispatch({
        type: GET_SINGLE_GOAL_SUCCESS,
        payload: { goal: response.data.goal },
      })
    } catch (error) {
      dispatch({ type: GET_SINGLE_GOAL_ERROR })
      toast.error("error getting goal")
    }
  }
}

export const openGoalModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_GOAL_MODAL })
  }
}
export const closeGoalModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_GOAL_MODAL })
  }
}

export const setEditingGoal = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_EDITING_GOAL_BEGIN })
    try {
      const response = await authFetch.get(`/goal/${id}`)

      dispatch({
        type: SET_EDITING_GOAL_SUCCESS,
        payload: { goal: response.data.goal },
      })
    } catch (error) {
      dispatch({ type: SET_EDITING_GOAL_ERROR })
    }
  }
}

export const deleteGoal = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_GOAL_BEGIN })
    try {
      const response = await authFetch.delete(`/goal/${id}`)

      dispatch({
        type: DELETE_GOAL_SUCCESS,
        payload: { deletedGoal: response.data.deletedGoal },
      })
    } catch (error) {
      console.log(error)
      dispatch({ type: DELETE_GOAL_ERROR })
    }
  }
}

export const openManageGoalModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_MANAGE_GOAL_MODAL })
  }
}
export const closeManageGoalModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_MANAGE_GOAL_MODAL })
  }
}

export const getGoalsReport = (startDate, endDate) => {
  return async (dispatch) => {
    dispatch({ type: GET_GOALS_REPORT_BEGIN })
    try {
      let sd = new Date(startDate)
      let ed = new Date(endDate)
      sd = sd.toISOString()
      ed = ed.toISOString()
      const response = await authFetch.get(`/goal/${sd}/${ed}`)
      console.log(response)
      dispatch({ type: GET_GOALS_REPORT_SUCCESS })
      return response.data
    } catch (error) {
      dispatch({ type: GET_GOALS_REPORT_ERROR })
    }
  }
}
