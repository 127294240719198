import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import LogFilters from "./LogFilters"
import tableToCSV from "../../../utils/tableToCsv"
import {
  capitalizeFirstLetter,
  getInitials,
} from "../../../utils/getInitialName"
import PagesIcon from "@mui/icons-material/Pages"
import { useSelector, useDispatch } from "react-redux"
import { getDailyLogs } from "../../../redux"

const DailyLog = () => {
  const dispatch = useDispatch()
  const { users, dailyLogs, loading } = useSelector((state) => state.worklog)
  const [collapse, setCollapse] = useState({})

  useEffect(() => {
    dispatch(getDailyLogs())
  }, [])

  const handleCollapse = (key) => {
    setCollapse((prevCollapse) => ({
      ...prevCollapse,
      [key]: !prevCollapse[key] || false,
    }))
  }

  const totalSpentByUser = users.map((u) => {
    let count = 0
    const totalTime = dailyLogs.reduce((acc, cur) => {
      if (cur.userId === u._id) {
        count += Number(cur.timeSpent)
      }
      return count
    }, 0)
    return { userId: u._id, totalTimeSpent: totalTime / 60, name: u.name }
  })

  let totalHours = 0
  let totalMinutes = 0

  if (totalSpentByUser) {
    for (let i = 0; i < totalSpentByUser.length; i++) {
      const { totalTimeSpent } = totalSpentByUser[i]
      const hours = Math.floor(totalTimeSpent)
      const minutes = Math.round((totalTimeSpent - hours) * 60)

      totalHours += hours
      totalMinutes += minutes
    }

    totalHours += Math.floor(totalMinutes / 60)
    totalMinutes = totalMinutes % 60
  }

  const downloadTableAsCSV = () => {
    tableToCSV("log-table", "dailylog")
  }

  return (
    <Wrapper>
      <LogFilters daily={true} />
      <div className="download-btn">
        {dailyLogs && (
          <span className="total-time">
            Total Time: ({Math.floor(totalHours)}h {Math.floor(totalMinutes)}m)
          </span>
        )}
        <button
          className={totalHours !== 0 ? "btn-show" : "btn-disable"}
          disabled={totalHours === 0}
          onClick={downloadTableAsCSV}
        >
          Download
        </button>
      </div>
         <div className="tw-h-[72vh] tw-overflow-y-scroll">
            <div className="tableresponse">
          <table id="log-table" >
            <table>
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>Name</th>
                  <th style={{ width: "200px" }} className="textLeft">
                    Project
                  </th>
                  <th style={{ width: "300px" }} className="textLeft">
                    Task
                  </th>
                  <th style={{ width: "150px" }}>Date</th>
                  <th style={{ width: "150px" }}>Time Spent</th>
                </tr>
              </thead>
            </table>

            {users.map((user) => {
              const userLogs = dailyLogs.filter((log) => log.userId === user._id)

              return (
                <React.Fragment key={`${user._id}-fragment`}>
                  {totalSpentByUser.map((item) => {
                    if (item.userId === user._id) {
                      return (
                        <h4
                          key={`${user._id}-header`}
                          className="text-start name-h"
                          onClick={() => handleCollapse(user._id)}
                        >
                          {item.name}:
                          <span className="timespet">
                            {" "}
                            ({Math.floor(item.totalTimeSpent)}h{" "}
                            {Math.round((item.totalTimeSpent % 1) * 60)}m)
                          </span>
                        </h4>
                      )
                    }
                    return null
                  })}
                  <table key={`${user._id}-table`}>
                    <tbody>
                      {userLogs.map((log) => (
                        <tr
                          key={log._id}
                          className={`collapseTable ${
                            collapse[user._id] ? "h" : "g"
                          }`}
                          style={{
                            background:
                              log.totalTimeSpent < 8 ? "rgb(235 155 155)" : "white",
                          }}
                        >
                          <td
                            style={{
                              width: "100px",
                              backgroundColor:
                                log.totalTimeSpent < 8
                                  ? "rgb(235 155 155)"
                                  : "white",
                            }}
                          ></td>
                          <td
                            style={{
                              width: "200px",
                              backgroundColor:
                                log.totalTimeSpent < 8
                                  ? "rgb(235 155 155)"
                                  : "white",
                              textTransform: "capitalize",
                            }}
                            className="textLeft"
                          >
                            {log.p[0].title}
                          </td>
                          <td
                            style={{
                              width: "300px",
                              backgroundColor:
                                log.totalTimeSpent < 8
                                  ? "rgb(235 155 155)"
                                  : "white",
                            }}
                            className="textLeft"
                          >
                            {" "}
                            <p>
                              {log.result &&
                                capitalizeFirstLetter(log.result[0].title)}
                            </p>
                            <span className="f-12 grey">
                              {log.result[0].key && (
                                <>
                                  <PagesIcon fontSize="15" />
                                  <span className="Upper">
                                    {log.p && getInitials(log.p[0].title)}-
                                    {log.result[0].key}
                                  </span>
                                </>
                              )}
                            </span>
                          </td>
                          <td
                            style={{
                              width: "150px",
                              background:
                                log.totalTimeSpent < 8
                                  ? "rgb(235 155 155)"
                                  : "white",
                            }}
                          >
                            {moment(log.dateStarted).format("DD/MM/YYYY")}
                          </td>
                          <td
                            style={{
                              width: "150px",
                              backgroundColor:
                                log.totalTimeSpent < 8
                                  ? "rgb(235 155 155)"
                                  : "white",
                            }}
                          >
                            {Math.floor(log.timeSpent / 60)}h{" "}
                            {Math.floor(log.timeSpent % 60)}m
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              )
            })}
          </table>
          </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .redf {
    background-color: "red";
  }
  .h {
    display: none;
  }

  .g {
    /* display: block; */
    transition: all 0.5s;
  }

  .download-btn {
    text-align: right;
    margin: 20px 32px;
    button {
      background: var(--primary-500);
      color: #fff;
      border-radius: 5px;
      padding: 3px 10px;
      margin-left: 20px;
      font-weight: 500 !important;
    }
    .btn-show {
      background: var(--primary-500);
    }
    .btn-disable {
      cursor: not-allowed;
      pointer-events: none;

      /*Button disabled - CSS color class*/
      color: #fff;
      background-color: lightgrey;
    }
  }
  .name-h {
    font-size: var(--font-12);
    text-transform: capitalize;
    background: var(--grey-50) !important;
    color: #333 !important;
    border: 1px solid var(--grey-50);
    border: 1px solid var(--grey-50);
    margin-top: 15px;
    margin-bottom: -2px;
    padding: 10px 10px 9px;

    cursor: pointer;

    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    svg {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  #log-table {
    margin-top: 20px;
  }
  .total-time {
    background: #edf5fd;
    font-size: var(--font-12);
    right: 30px;
    padding: 5px;
  }
  table {
    tr td:last-child {
      text-align: center;
    }
    tr td:first-child {
      display: table-cell;
      width: 150px;
    }
  }

  .name-h.collapsed .chevron.down {
    transform: rotate(0deg);
    transition: all 0.2s;
  }

  .name-h.collapsed .chevron.up {
    transform: rotate(180deg);
    transition: all 0.2s;
  }

  .name-h {
    cursor: pointer;
    transition: all 0.2s;
  }

  .collapsible-content {
    max-height: 0;
    transition: all 0.2s;
    overflow: hidden;
  }

  .timespet {
    text-transform: lowercase !important;
  }

  .project {
  }
  .task {
  }
  .datee {
  }
  .textLeft {
    margin: 0;
  }
  p {
    margin: 0;
  }
`
export default DailyLog
