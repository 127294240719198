import React, { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import convertToXlsx from "../../../utils/xlsxConvert"
import LogFilters from "./LogFilters"
import {
  capitalizeFirstLetter,
  getInitials,
} from "../../../utils/getInitialName"
import PagesIcon from "@mui/icons-material/Pages"
import { useSelector, useDispatch } from "react-redux"
import { getWeeklyLogs } from "../../../redux"
import { ArrowDropDownIcon, ArrowRightIcon } from "../../../assets/icons"

const WeeklyLog = () => {
  const [projects, setProjects] = useState(null)
  const [collapse, setCollapse] = useState([])
  const dispatch = useDispatch()
  const { users, weeklyLogs, loading } = useSelector((state) => state.worklog)

  useEffect(() => {
    dispatch(getWeeklyLogs()).then((data) => {
      setProjects(data.project)
    })
  }, [])

  const handleCollapse = (key) => {
    if (key in collapse) {
      setCollapse({ ...collapse, [key]: !collapse[key] })
    } else {
      setCollapse({ ...collapse, [key]: true })
    }
  }

  let totalhour = 0
  if (weeklyLogs) {
    for (let i = 0; i < weeklyLogs.length; i++) {
      let a = Number(weeklyLogs[i].timeSpent / 60)
      totalhour = totalhour + a
    }
  }

  const totalSpentByUser = users.map((u) => {
    let count = 0
    const totalTime = weeklyLogs.reduce((acc, cur) => {
      if (cur.userId === u._id) {
        count += Number(cur.timeSpent)
      }
      return count
    }, 0)
    return { userId: u._id, totalTimeSpent: totalTime / 60, email: u.email }
  })

  let totalHours = 0
  let totalMinutes = 0
  // console.log(weeklyLogs);
  if (weeklyLogs) {
    for (let i = 0; i < weeklyLogs.length; i++) {
      const { timeSpent } = weeklyLogs[i]
      totalHours += Math.floor(timeSpent / 60)
      totalMinutes += timeSpent % 60
    }
    totalHours += Math.floor(totalMinutes / 60)
    totalMinutes = totalMinutes % 60
  }

  if (loading) {
    return <p>loading</p>
  }

  return (
    <Wrapper>
      <LogFilters weekly={true} />
      <div className="download-btn">
        {weeklyLogs && (
          <span className="total-time">
            Total Time: {totalHours}h {totalMinutes}m
          </span>
        )}
        <button
          onClick={() => convertToXlsx({ weeklyLogs, projects }, "weeklog")}
        >
          Download
        </button>
      </div>
      <div className="weekly ">
        <div className="tableresponse">
          <table id="lo-table">
            <thead>
              <tr>
                <th style={{ width: "150px" }}>Name</th>
                <th style={{ width: "200px" }} className="textLeft">
                  Task Name
                </th>

                <th style={{ width: "100px" }}>Date</th>
                <th style={{ width: "100px" }}>Time Spent</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="log-box">
          {projects &&
            projects.map((p, index) => {
              return (
                <>
                  <h4
                    className="project-name"
                    onClick={() => handleCollapse(p.title)}
                    key={index}
                  >
                    {collapse[p.title] ? (
                      <ArrowRightIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}{" "}
                    {p.title}
                  </h4>
                  <div className="collapseTable">
                    <div className="tableresponse">
                      <table
                        id="lo-table"
                        className={collapse[p.title] ? "h" : "g"}
                      >
                        <tbody className="body">
                          {weeklyLogs.map((log, i) => {
                            const hours = Math.floor(log.timeSpent / 60)
                            const minutes = log.timeSpent % 60

                            if (log.p[0].title === p.title) {
                              return (
                                <tr key={i}>
                                  {/* <td style={{width:"5px"}}>  <input type="checkbox" /> </td> */}
                                  <td
                                    style={{
                                      textTransform: "capitalize",
                                      width: "150px",
                                    }}
                                    className="word-break"
                                  >
                                    {log.name}
                                  </td>
                                  <td
                                    style={{
                                      width: "200px",
                                    }}
                                    className="textLeft word-break"
                                  >
                                    {" "}
                                    <p>
                                      {log.result &&
                                        capitalizeFirstLetter(
                                          log.result[0].title
                                        )}
                                    </p>
                                    <span className="f-12 grey">
                                      {log.result[0].key && (
                                        <>
                                          <PagesIcon fontSize="15" />
                                          <span className="Upper">
                                            {log.p &&
                                              getInitials(log.p[0].title)}
                                            -{log.result[0].key}
                                          </span>
                                        </>
                                      )}
                                    </span>
                                  </td>
                                  <td style={{ width: "100px" }}>
                                    {moment(log.dateStarted).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td style={{ width: "100px" }}>
                                    {`${hours}h ${minutes}m`}
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )
            })}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .h {
    display: none;
  }

  .g {
    /* display: block; */
    transition: all 0.2s;
  }

  #lo-table {
    margin-bottom: 20px;
    // margin-top: -8px;
  }
  .log-box {
    height: 70vh;
    overflow-y: scroll;
    margin-top: -12px;
    padding: 0 32px;
  }
  h4 {
    font-size: var(--font-12);
  }

  .project-name {
    background: var(--grey-50);
    cursor: pointer;
    color: #333;
    padding: 8px 10px 7px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border: 1px solid var(--grey-50);
    svg {
      font-size: var(--font-18);
    }
  }
  .download-btn {
    text-align: right;
    margin: 20px 32px;
    button {
      background: var(--primary-500);
      color: #fff;
      border-radius: 5px;
      padding: 3px 10px;
      margin-left: 20px;
      font-weight: 500 !important;
    }
  }
  #log-table {
    margin-top: 20px;
  }
  .total-time {
    background: #edf5fd;
    font-size: var(--font-12);
    right: 30px;
    padding: 5px;
  }
  table {
    input[type="checkbox"] {
      margin: 5px 8px;
    }
    tr td:first-child {
      display: table-cell;
    }
  }
  p {
    margin: 0;
  }
`
export default WeeklyLog
