import React, { useEffect, useState } from "react"
import { PROJECT, TASK, WORKLOG, GOAL, CLIENT, USERS, TEMPLATE, TEST_ITEM, TEST_CASE, CODE_REVIEW } from "../../actions"
import { Loading, FormRowSelect } from "../Common"
import { useAppContext } from "../../context/context/appContext"
import { PermissionWrapper } from "../../assets/styles/Settings"
import { AddSharpIcon } from "../../assets/icons"
import {
  getAllPermissionName,
  getPermissionByRole,
  updatePermissionAction,
  getUsersByRole,
} from "../../redux"
import { useDispatch } from "react-redux"

const initialState = {
  value: false,
  category: "",
  role: "",
  action: "",
}

const Permissions = () => {
  const dispatch = useDispatch()
  const { socket } = useAppContext()
  const [permissions, setPermissions] = useState(null)
  const [roles, setRoles] = useState(null)
  const [role, setRole] = useState("")
  const [users, setUsers] = useState(null)
  const [values, setValues] = useState(initialState)
  const [collapse, setCollapse] = useState({
    project: false,
    task: false,
    goal: false,
    worklog: false,
    client: false,
    users: false,
    template:false,
    testcase:false,
    testItems:false
  })

  useEffect(() => {
    dispatch(getAllPermissionName()).then((data) => setRoles(data))
  }, [])
  useEffect(() => {
    socket.on("newPermission", (data) => {
      dispatch(getAllPermissionName()).then((data) => setRoles(data))
    })
    socket.on("permissionDeleted", (data) => {
      dispatch(getAllPermissionName()).then((data) => setRoles(data))
      setPermissions(null)
      setUsers(null)
    })
  }, [socket])

  useEffect(() => {
    if (role !== "" && role !== "select") {
      dispatch(getPermissionByRole(role)).then((data) => setPermissions(data))
      dispatch(getUsersByRole({ role: role })).then((data) => setUsers(data))
    }
  }, [role])

  useEffect(() => {
    dispatch(updatePermissionAction(values)).then((data) => {
      setPermissions(data)
    })
  }, [values])

  const handleChange = (e) => {
    setRole(e.target.value)
  }

  const handleCollapse = (key) => {
    if (key in collapse) {
      setCollapse({ ...collapse, [key]: !collapse[key] })
    } else {
      setCollapse({ ...collapse, [key]: true })
    }
  }

  const projectAction = Object.keys(PROJECT)
  const taskAction = Object.keys(TASK)
  const worklogAction = Object.keys(WORKLOG)
  const goalAction = Object.keys(GOAL)
  const clientAction = Object.keys(CLIENT)
  const userAction = Object.keys(USERS)
  const testTemplateAction = Object.keys(TEMPLATE)
  const testItemAction = Object.keys(TEST_ITEM)
  const testCaseAction = Object.keys(TEST_CASE)
  const codeReviewAction = Object.keys(CODE_REVIEW)
  // if (loading) {
  //   return <Loading />
  // }
  // console.log(collapse)

  return (
    <PermissionWrapper>
      <form className="search-by-role  lg:tw-px-8   tw-px-3 ">
        {roles && (
          <FormRowSelect
            handleChange={handleChange}
            labelText={"Role"}
            value={role}
            name="role"
            list={["select", ...roles.map((p) => p.name)]}
          />
        )}
      </form>
      {/* {users &&
        users.map((item) => {
          return <h6>{item.email}</h6>
        })} */}
      <div className="lg:tw-px-8 lg:tw-pb-8 tw-px-3   tw-h-[72vh] tw-overflow-y-scroll">
        <h2 onClick={() => handleCollapse(["project"])}>
          <AddSharpIcon />
          project
        </h2>
        <div className={`${collapse["project"] ? "h  " : "g permission"} `}>
          {permissions ? (
            projectAction.map((item) => {
              const temp = permissions.permission.find(
                (i) => i.category === "PROJECT"
              )
              return (
                <div className="permission-list">
                  <input
                    type="checkbox"
                    checked={temp && temp.actions[item]}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        value: e.target.checked,
                        category: "PROJECT",
                        role: role,
                        action: item,
                      })
                    }}
                  />
                  <p>{item}</p>
                </div>
              )
            })
          ) : (
            <p>select role to give permission</p>
          )}
        </div>
        <h2 onClick={() => handleCollapse(["task"])}>
          <AddSharpIcon />
          task
        </h2>
        <div className={`${collapse["task"] ? "h  " : "g permission "} `}>
          {permissions ? (
            taskAction.map((item) => {
              const temp = permissions.permission.find(
                (i) => i.category === "TASK"
              )
              return (
                <div className="permission-list">
                  <input
                    type="checkbox"
                    checked={temp && temp.actions[item]}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        value: e.target.checked,
                        category: "TASK",
                        role: role,
                        action: item,
                      })
                    }
                  />
                  <p>{item}</p>
                </div>
              )
            })
          ) : (
            <p>select role to give permission</p>
          )}
        </div>
        <h2 onClick={() => handleCollapse(["worklog"])}>
          <AddSharpIcon />
          worklog
        </h2>
        <div className={`${collapse["worklog"] ? "h  " : "g permission"} `}>
          {permissions ? (
            worklogAction.map((item) => {
              const temp = permissions.permission.find(
                (i) => i.category === "WORKLOG"
              )
              return (
                <div className="permission-list">
                  <input
                    type="checkbox"
                    checked={temp && temp.actions[item]}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        value: e.target.checked,
                        category: "WORKLOG",
                        role: role,
                        action: item,
                      })
                    }
                  />
                  <p>{item}</p>
                </div>
              )
            })
          ) : (
            <p>select role to give permission</p>
          )}
        </div>
        <h2 onClick={() => handleCollapse(["goal"])}>
          <AddSharpIcon />
          goal
        </h2>
        <div className={`${collapse["goal"] ? "h  " : "g permission"} `}>
          {permissions ? (
            goalAction.map((item) => {
              const temp = permissions.permission.find(
                (i) => i.category === "GOAL"
              )
              return (
                <div className="permission-list">
                  <input
                    type="checkbox"
                    checked={temp && temp.actions[item]}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        value: e.target.checked,
                        category: "GOAL",
                        role: role,
                        action: item,
                      })
                    }
                  />
                  <p>{item}</p>
                </div>
              )
            })
          ) : (
            <p>select role to give permission</p>
          )}
        </div>
        <h2 onClick={() => handleCollapse(["client"])}>
          <AddSharpIcon />
          Client
        </h2>
        <div className={`${collapse["client"] ? "h  " : "g permission"} `}>
          {permissions ? (
            clientAction.map((item) => {
              const temp = permissions.permission.find(
                (i) => i.category === "CLIENT"
              )
              return (
                <div className="permission-list">
                  <input
                    type="checkbox"
                    checked={temp && temp.actions[item]}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        value: e.target.checked,
                        category: "CLIENT",
                        role: role,
                        action: item,
                      })
                    }
                  />
                  <p>{item}</p>
                </div>
              )
            })
          ) : (
            <p>select role to give permission</p>
          )}
        </div>
        <h2 onClick={() => handleCollapse(["users"])}>
          <AddSharpIcon />
          users
        </h2>
        <div className={`${collapse["users"] ? "h  " : "g permission"} `}>
          {permissions ? (
            userAction.map((item) => {
              const temp = permissions.permission.find(
                (i) => i.category === "USER"
              )
              return (
                <div className="permission-list">
                  <input
                    type="checkbox"
                    checked={temp && temp.actions[item]}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        value: e.target.checked,
                        category: "USER",
                        role: role,
                        action: item,
                      })
                    }
                  />
                  <p>{item}</p>
                </div>
              )
            })
          ) : (
            <p>select role to give permission</p>
          )}
        </div>

        <h2 onClick={() => handleCollapse(["codeReview"])}>
          <AddSharpIcon />
          Code Review
        </h2>
        <div className={`${collapse["codeReview"] ? "h  " : "g permission"} `}>
          {permissions ? (
            codeReviewAction.map((item) => {
              const temp = permissions.permission.find(
                (i) => i.category === "CODE_REVIEW"
              )
              return (
                <div className="permission-list">
                  <input
                    type="checkbox"
                    checked={temp && temp.actions[item]}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        value: e.target.checked,
                        category: "CODE_REVIEW",
                        role: role,
                        action: item,
                      })
                    }
                  />
                  <p>{item}</p>
                </div>
              )
            })
          ) : (
            <p>select role to give permission</p>
          )}
        </div>
        <h2 onClick={() => handleCollapse(["template"])}>
        <AddSharpIcon />
        Template
      </h2>
      <div className={`${collapse["template"] ? "h  " : "g permission"} `}>
        {permissions ? (
          testTemplateAction.map((item) => {
            const temp = permissions.permission.find(
              (i) => i.category === "TEMPLATE"
            )
            return (
              <div className="permission-list">
                <input
                  type="checkbox"
                  checked={temp && temp.actions[item]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      value: e.target.checked,
                      category: "TEMPLATE",
                      role: role,
                      action: item,
                    })
                  }
                />
                <p>{item}</p>
              </div>
            )
          })
        ) : (
          <p>select role to give permission</p>
        )}
      </div>
      <h2 onClick={() => handleCollapse(["testItems"])}>
        <AddSharpIcon />
        Test Items
      </h2>
      <div className={`${collapse["testItems"] ? "h  " : "g permission"} `}>
        {permissions ? (
          testItemAction.map((item) => {
            const temp = permissions.permission.find(
              (i) => i.category === "TEST_ITEM"
            )
            return (
              <div className="permission-list">
                <input
                  type="checkbox"
                  checked={temp && temp.actions[item]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      value: e.target.checked,
                      category: "TEST_ITEM",
                      role: role,
                      action: item,
                    })
                  }
                />
                <p>{item}</p>
              </div>
            )
          })
        ) : (
          <p>select role to give permission</p>
        )}
      </div>
      <h2 onClick={() => handleCollapse(["testCase"])}>
        <AddSharpIcon />
        Test Case
      </h2>
      <div className={`${collapse["testCase"] ? "h  " : "g permission"} `}>
        {permissions ? (
          testCaseAction.map((item) => {
            const temp = permissions.permission.find(
              (i) => i.category === "TEST_CASE"
            )
            return (
              <div className="permission-list">
                <input
                  type="checkbox"
                  checked={temp && temp.actions[item]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      value: e.target.checked,
                      category: "TEST_CASE",
                      role: role,
                      action: item,
                    })
                  }
                />
                <p>{item}</p>
              </div>
            )
          })
        ) : (
          <p>select role to give permission</p>
        )}
      </div>
      </div>
    </PermissionWrapper>
  )
}

export default Permissions
