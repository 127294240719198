import { toast } from "react-toastify"

import authFetch from "../../utils/interceptor"

import {
  OPEN_CLIENT_MODAL,
  CLOSE_CLIENT_MODAL,
  GET_ALL_CLIENTS_BEGIN,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_ERROR,
  ADD_CLIENT_BEGIN,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_ERROR,
  SET_EDITING_CLIENT,
  EDIT_CLIENT_BEGIN,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_ERROR,
  ADD_USER_TO_CLIENT_BEGIN,
  ADD_USER_TO_CLIENT_SUCCESS,
  ADD_USER_TO_CLIENT_ERROR,
  OPEN_ADD_USER_TO_CLIENT,
  CLOSE_ADD_USER_TO_CLIENT,
  OPEN_USER_LIST_MODAL,
  CLOSE_USER_LIST_MODAL,
  GET_CLIENT_USERS_BEGIN,
  GET_CLIENT_USERS_SUCCESS,
  GET_CLIENT_USERS_ERROR,
  SET_EDITING_CLIENT_USER,
  EDIT_CLIENT_USER_BEGIN,
  EDIT_CLIENT_USER_SUCCESS,
  EDIT_CLIENT_USER_ERROR,
} from "./clientTypes"

export const addClient = (values) => {
  return async (dispatch) => {
    dispatch({ type: ADD_CLIENT_BEGIN })
    try {
      const response = await authFetch.post(`/add_client`, values)
      console.log(response)

      dispatch({
        type: ADD_CLIENT_SUCCESS,
        payload: { client: response.data.result },
      })
      toast.success("client added successfully")
    } catch (error) {
      toast.error(error.data.msg)
      console.log(error)
      dispatch({
        type: ADD_CLIENT_ERROR,
        payload: {
          err: error.data,
        },
      })
    }
  }
}

export const openClientModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_CLIENT_MODAL })
  }
}
export const closeClientModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_CLIENT_MODAL })
  }
}

export const setEditingClient = (id) => {
  return (dispatch) => {
    dispatch({ type: SET_EDITING_CLIENT, payload: { id } })
  }
}

export const getAllClients = (page, limit, search, isActive) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_CLIENTS_BEGIN })
    try {
      const response = await authFetch.get(
        `/display_client?page=${page}&limit=${limit}&search=${search}&includeInactive=${isActive}`
      )

      dispatch({
        type: GET_ALL_CLIENTS_SUCCESS,
        payload: {
          clients: response.data.data,
        },
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_CLIENTS_ERROR,
        payload: {
          err: error,
        },
      })
    }
  }
}

export const updateClientActivation = (clientId, isActive) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.patch(`/client/activation/${clientId}`, {
        "is_active": isActive,
      })
      if(response.status == 200) {
        toast.success("Client activation status updated successfully")  
      }
    } catch (error) {
      toast.error(error.response.data.error)
    }
  }
}

export const editClient = (values) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_CLIENT_BEGIN })
    try {
      const response = await authFetch.post(`/edit_client`, values)
      // console.log(response)
      dispatch({
        type: EDIT_CLIENT_SUCCESS,
        payload: { updatedClient: response.data.updatedClient },
      })
    } catch (error) {
      console.log(error)
      dispatch({ type: EDIT_CLIENT_ERROR })
    }
  }
}

export const updateClientUserActivation = (userId, isActive) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.put(`/user/activation/${userId}`, {
        isActive,
      })
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.response.data.error)
    }
  }
}

export const openAddUserToClientModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_ADD_USER_TO_CLIENT })
  }
}
export const closeAddUserToClientModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_ADD_USER_TO_CLIENT })
  }
}

export const addUserToClient = (values) => {
  return async (dispatch) => {
    dispatch({ type: ADD_USER_TO_CLIENT_BEGIN })
    try {
      const response = await authFetch.post("/add_user", values)
      if (response.data.updatedUser) {
        dispatch({
          type: ADD_USER_TO_CLIENT_SUCCESS,
          payload: { user: response.data.updatedUser },
        })
      } else {
        dispatch({
          type: ADD_USER_TO_CLIENT_SUCCESS,
          payload: { user: response.data.user },
        })
      }
    } catch (error) {
      toast.error("User already linked")
      console.log(error)
      dispatch({ type: ADD_USER_TO_CLIENT_ERROR })
    }
  }
}

export const openUserListModal = (id) => {
  return (dispatch) => {
    dispatch({ type: OPEN_USER_LIST_MODAL, payload: { id } })
  }
}
export const closeUserListModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_USER_LIST_MODAL })
  }
}

export const getClientUsers = (id, isActive) => {
  return async (dispatch) => {
    dispatch({ type: GET_CLIENT_USERS_BEGIN })
    try {
      const response = await authFetch.post(`/display_user?includeInactive=${isActive}`, { clientId: id })
      console.log(response)
      dispatch({
        type: GET_CLIENT_USERS_SUCCESS,
        payload: { users: response.data.users },
      })
    } catch (error) {
      dispatch({ type: GET_CLIENT_USERS_ERROR })
    }
  }
}

export const setEditingClientUser = (id) => {
  return (dispatch) => {
    dispatch({ type: SET_EDITING_CLIENT_USER, payload: { id } })
  }
}

export const getAllCompany = () => {
  return async (dispatch) => {
    try {
      const response = await authFetch.get("/companyName")
      return response.data.client
    } catch (error) {
      toast.error("Error fetching company name")
    }
  }
}
