import React from "react"
import NavLinks from "./NavLinks"
import { Logo } from "../Common"
import SidebarMenu from "./SidebarMenu"
import { useAppContext } from "../../context/context/appContext"
import { DsHeader, DsSideMenu, DsWrapper } from "../../assets/styles/Layouts"

const BigSidebar = () => {
  const { showSidebar } = useAppContext()
  return (
    <DsWrapper>
      {showSidebar ? (
        <>
          <DsSideMenu>
            <div className="content">
              <Logo />

              <SidebarMenu />
            </div>
          </DsSideMenu>
        </>
      ) : (
        <>
          <div className={"sidebar-container show-sidebar tw-h-full"}>
            <div className="">
              <DsHeader>
                <Logo />
              </DsHeader>

              <NavLinks />
            </div>
          </div>
        </>
      )}
    </DsWrapper>
  )
}

export default BigSidebar
