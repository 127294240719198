import React from "react"
import { useState, useEffect } from "react"
import { useAppContext } from "../../context/context/appContext"
import { useSelector } from "react-redux"
import { updateNotification } from "../../redux"
import { useDispatch } from "react-redux"

const SingleNotification = ({ user, notification }) => {
  // const { updateNotification } = useAppContext()
  const dispatch = useDispatch()

  const { selectedProject } = useSelector((state) => state.project)

  const [worklog, setWorklog] = useState(false)
  const [task, setTask] = useState(false)
  const [daily, setDaily] = useState(false)
  const [weekly, setWeekly] = useState(false)
  useEffect(() => {
    if (notification) {
      notification.worklog && setWorklog(notification.worklog)
      notification.task && setTask(notification.task)
      notification.daily && setDaily(notification.daily)
      notification.weekly && setWeekly(notification.weekly)
    }
  }, [])

  return (
    <tr>
      <td>
        <p
          style={{
            display: "inline-block",
            fontWeight: "400",
            textTransform: "lowercase",
          }}
        >
          {user.email}
        </p>
      </td>
      <td>
        {" "}
        <input
          type="checkbox"
          checked={worklog}
          onChange={(e) => {
            setWorklog(e.target.checked)
            dispatch(
              updateNotification({
                userId: user.id || user._id,
                email: user.email,
                projectId: selectedProject._id,
                worklog: e.target.checked,
              })
            )
          }}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={task}
          onChange={(e) => {
            setTask(e.target.checked)
            dispatch(
              updateNotification({
                userId: user.id || user._id,
                email: user.email,
                projectId: selectedProject._id,
                task: e.target.checked,
              })
            )
          }}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={daily}
          onChange={(e) => {
            setDaily(e.target.checked)
            dispatch(
              updateNotification({
                userId: user.id || user._id,
                email: user.email,
                projectId: selectedProject._id,
                daily: e.target.checked,
              })
            )
          }}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={weekly}
          onChange={(e) => {
            setWeekly(e.target.checked)
            dispatch(
              updateNotification({
                userId: user.id || user._id,
                email: user.email,
                projectId: selectedProject._id,
                weekly: e.target.checked,
              })
            )
          }}
        />
      </td>
    </tr>
  )
}

export default SingleNotification
