import React, { useState, useEffect, useRef } from "react"
import OtpInput from "react-otp-input"
import { useAppContext } from "../../context/context/appContext"
import { OTPWrapper, OTPHeader } from "../../assets/styles/Auth"
import { FormRow } from "../Common"
import { FeedbacksFormWrapper } from "../../assets/styles/feedForm"
import Feedbackforms from "./Feedbackforms"
import FeedCustomforms from "./FeedCustomforms"

function Feedsforms() {
  const [showModal, setShowModal] = useState(false)
  const [showotherModal, setShowotherModal] = useState(false)

  const [customModal, setCustomodal] = useState(true)
  const [manualModal, setManualModal] = useState(true)

  const handleButtonmanualClick = () => {
    setShowModal(true)
    setCustomodal(false)
  }

  const handleButtoncustomClick = () => {
    setShowotherModal(true)
    setManualModal(false)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      {!showModal && !showotherModal && (
        <FeedbacksFormWrapper>
          <div className="app-container">
            {customModal && (
              <button className="add-btn" onClick={handleButtoncustomClick}>
                <span className="size">Subjective Feedback</span>
              </button>
            )}
            <p>or</p>
            {manualModal && (
              <button className="add-btn" onClick={handleButtonmanualClick}>
                <span className="size">Objective Feedback</span>
              </button>
            )}
          </div>
        </FeedbacksFormWrapper>
      )}
      {showModal && <Feedbackforms openModal={() => setShowModal(true)} />}
      {showotherModal && (
        <FeedCustomforms openModal={() => setShowotherModal(true)} />
      )}
    </>
  )
}

export default Feedsforms
