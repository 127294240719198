import React, { useState } from "react"
import VerifyOTP from "./VerifyOTP"
import { sendEmailOTP } from "../../services/repository"
import validator from "validator"
import Lottie from "react-lottie"
import robotAnimation from "../../animation/139721-robot.json"
import { Logo } from "../Common"
import { Link } from "react-router-dom"
import { SignInWrapper } from "../../assets/styles/Auth"

const SignIn = () => {
  const [email, setEmail] = useState("")
  const [display, setDisplay] = useState(false)
  const [message, setMessage] = useState(false)
  const [isEmail, setIsEmail] = useState(true)
  const [showMsg, setShowMsg] = useState()

  const handleSendOTP = async (e) => {
    e.preventDefault()
    if (validator.isEmail(email)) {
      const result = await sendEmailOTP(email)

      setShowMsg(result.data.message)
      if (result.data.status) {
        setMessage(true)
      }
    } else {
      alert("Please all fields")
    }
  }

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      setIsEmail(true)
    } else {
      setIsEmail(false)
    }
  }

  return (
    <SignInWrapper>
      <div className="container main signin">
        {message === true ? (
          <VerifyOTP email={email} />
        ) : (
          <div className="row">
            <Logo />
            <div className="col-md-6 left">
              <div className="input-box tw-w-[85%]" >
               
                <header className="text-center">Login</header>
                <form>
                  <div className="form-group mb-3">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      onFocus={() => setDisplay(false)}
                      required
                      autoComplete="on"
                      onKeyUp={validateEmail}
                    />
                    {isEmail ? null : <p>please enter valid email</p>}
                  </div>

                  <div className="form-group mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block  " style={{width:"100%"}}
                      onClick={(e) =>
                        (email !== "" && email !== null && email) !== undefined
                          ? handleSendOTP(e)
                          : setDisplay(true)
                      }
                    >
                      Submit
                    </button>
                    {showMsg}
                  </div>
                </form>
                <div className="btmq  " >
                  not a user ?<Link to="/signup"> click here to Sign up</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 side-image">
              <Lottie
                options={{
                  animationData: robotAnimation,
                  loop: true,
                  autoplay: true,
                }}
                width="100%"
              />
              {/* <img
                src="https://media.giphy.com/media/eltkEbq0Zl4aGQHisf/giphy.gif"
                alt=""
              /> */}
            </div>
          </div>
        )}
      </div>
    </SignInWrapper>
  )
}

export default SignIn
