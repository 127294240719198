import { LRUCache } from "lru-cache"

const cacheOptions = {
  max: 150,
  maxAge: 1000 * 60 * 60,
}

const cache = new LRUCache(cacheOptions)
// console.log(cache)

export default cache
