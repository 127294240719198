import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useAppContext } from "../../../context/context/appContext"
import { Loading } from "../../Common"
import tableToCSV from "../../../utils/tableToCsv"
import { capitalizeFirstLetter } from "../../../utils/getInitialName"
import { useDispatch } from "react-redux"
import { weeklyWorklog } from "../../../redux"

const WeeklyLog = () => {
  const dispatch = useDispatch()
  const { user } = useAppContext()
  const [weeklyLog, setWeeklyLog] = useState(null)

  useEffect(() => {
    dispatch(weeklyWorklog(user._id)).then((data) => setWeeklyLog(data))
  }, [])

  if (!weeklyLog) {
    return <Loading />
  }

  let totalHours = 0
  let totalMinutes = 0

  if (weeklyLog) {
    for (let i = 0; i < weeklyLog.length; i++) {
      const { timeSpent } = weeklyLog[i]
      totalHours += Math.floor(timeSpent / 60)
      totalMinutes += timeSpent % 60
    }
  }

  totalHours += Math.floor(totalMinutes / 60)
  totalMinutes = totalMinutes % 60

  return (
    <Wrapper>
      <div className="download-btn">
        {weeklyLog && (
          <span className="total-time">
            Total Time: {totalHours}h {totalMinutes}m
          </span>
        )}
        <button onClick={() => tableToCSV("log-table", "weeklylog.csv")}>
          Download
        </button>
      </div>
      <div className=" tableresponse tw-h-[55vh] tw-overflow-y-scroll">
        <table id="log-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Project</th>
              <th>Task</th>
              <th>Time Spent</th>
            </tr>
          </thead>
          <tbody>
            {weeklyLog.map((log) => {
              console.log(log)
              return (
                <tr>
                  <td>{log.name}</td>
                  <td>{capitalizeFirstLetter(log.p[0].title)}</td>
                  <td>{capitalizeFirstLetter(log.task[0].title)}</td>
                  <td>
                    {log.timeSpent > 0
                      ? `${Math.floor(log.timeSpent / 60)}h ${
                          log.timeSpent % 60
                        }m`
                      : "0 h"}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 20px;
  .download-btn {
    text-align: right;
    margin: 20px 32px;
    button {
      background: var(--primary-500);
      color: #fff;
      border-radius: 5px;
      padding: 3px 10px;
      margin-left: 20px;
    }
  }
  #log-table {
    margin-top: 20px;
  }
  .total-time {
    background: #edf5fd;
    font-size: var(--font-12);
    right: 45px;
    padding: 5px;
  }
  table {
    td {
      text-align: left;
    }
    th {
      text-align: left;
    }
  }
`

export default WeeklyLog
