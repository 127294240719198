import {
  SELECT_PROJECT,
  OPEN_PROJECT_MODAL,
  CLOSE_PROJECT_MODAL,
  CREATE_PROJECT_BEGIN,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  GET_ALL_PROJECTS_BEGIN,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_ERROR,
  GET_ALL_PROJECTS_USER_BEGIN,
  GET_ALL_PROJECTS_USER_SUCCESS,
  GET_ALL_PROJECTS_USER_ERROR,
  SET_EDIT_PROJECT,
  EDIT_PROJECT_BEGIN,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_ERROR,
  UPDATE_PROJECT_PIC,
  EDIT_MODAL_OPEN,
  EDIT_MODAL_CLOSE,
  UPDATE_PROJECT,
  DRAG_CATEGORY,
  GET_ALL_DEVPRO_BEGIN,
  GET_ALL_DEVPRO_SUCCESS,
  GET_ALL_DEVPRO_ERROR,
} from "./projectTypes"
import authFetch from "../../utils/interceptor"
import { toast } from "react-toastify"

export const createProject = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_PROJECT_BEGIN })
    try {
      const response = await authFetch.post("/project", values)

      toast.success("Project created successfully")
      dispatch({ type: CREATE_PROJECT_SUCCESS })
      // closeProjectModal()
    } catch (error) {
      dispatch({
        type: CREATE_PROJECT_ERROR,
        payload: { msg: error.response.data },
      })
      toast.error(error.response.data.msg)
    }
  }
}

export const selectProject = (id) => {
  return (dispatch) => {
    dispatch({ type: SELECT_PROJECT, payload: { id } })
  }
}

export const openProjectModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_PROJECT_MODAL })
  }
}
export const closeProjectModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_PROJECT_MODAL })
  }
}
export const getDevproReports = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_DEVPRO_BEGIN })
    try {      
      const response = await authFetch.get("/project/devreport")
      console.log(response)

    
      console.log("all reports are here")  

      console.log(response.data)   
      const usersData = response.data.map(item => item.user); // Extract user data
      const projectsData = response.data.map(item => item.projects)
    
      console.log(usersData)  
      console.log(projectsData)  
    
      dispatch({
        type: GET_ALL_DEVPRO_SUCCESS,
        payload: { 

          report: response.data,         
         
        
        },
      })

    } catch (error) {
      // add toast here
      dispatch({
        type: GET_ALL_DEVPRO_ERROR,
        payload: { err: error.response.data },
      })
    }
  }  
}
export const openEditModal = () => {
  return (dispatch) => {
    dispatch({ type: EDIT_MODAL_OPEN })
  }
}
export const closeEditModal = () => {
  return (dispatch) => {
    dispatch({ type: EDIT_MODAL_CLOSE })
  }
}

export const updatedProject = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PROJECT, payload: { id, data } })
  }
}

export const getAllProjects = (role, userId) => {
  // get role here
  return async (dispatch) => {
    dispatch({ type: GET_ALL_PROJECTS_BEGIN })
    let res
    try {
      if (role === "admin") {
        res = await authFetch.get(`/project`)
      } else {
        res = await authFetch.post("/project/user", {
          userId,
        })
      }

      const { projects } = res.data

      dispatch({ type: GET_ALL_PROJECTS_SUCCESS, payload: { projects } })
    } catch (error) {
      dispatch({
        type: GET_ALL_PROJECTS_ERROR,
        payload: { err: error.message },
      })
    }
  }
}

export const setEditProject = (id) => {
  return (dispatch) => {
    dispatch({ type: SET_EDIT_PROJECT, payload: { id } })
  }
}

export const editProject = (values) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_PROJECT_BEGIN })
    try {
      const response = await authFetch.patch(`/project/${values._id}`, values)
      toast.success("Project updated successfully")
      dispatch({ type: EDIT_PROJECT_SUCCESS })
    } catch (error) {
      toast.error(error.response.data.msg)

      dispatch({ type: EDIT_PROJECT_ERROR })
    }
  }
}

export const getProjectPic = (id, size) => {
  return async (dispatch) => {
    //  console.log(id, size)
    try {
      const response = await authFetch.get(`/resource/project/${size}/${id}`, {
        responseType: "arraybuffer",
      })
      //    console.log(response)
      return response
    } catch (error) {
      //    console.log(error)
      toast.error("cannot get profile pic")
    }
  }
}
export const updateProjectPic = (file) => {
  return async (dispatch) => {
    const response = await authFetch.post("/resource/updateProjectPic", file)
    if (response.data.msg === "project pic updated successfully") {
      toast.success("project pic updated successfully")
    }
    dispatch({
      type: UPDATE_PROJECT_PIC,
      payload: { Key: response.data.Key },
    })
  }
}

export const archiveProject = (id) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.patch(`/project/archive/${id}`)
      if (response.data.project.isArchived === true) {
        toast.success("Project archived successfully")
      } else {
        toast.success("Project unarchived successfully")
      }
    } catch (error) {
      //   console.log(error)
      toast.error("Error archived project")
    }
  }
}

export const updateCategory = (
  selectedProject,
  categorys,
  category,
  action
) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.post("/project/managelist", {
        selectedProject,
        categorys,
        category,
        action,
      })

      if (response) {
        if (action === "delete") {
          toast.warning(response.data.msg)
        }
      }
    } catch (error) {
      toast.error("Error updating category")
    }
  }
}

export const dragCategory = (
  id,
  sourceIndex,
  destinationIndex,
  selectedProject
) => {
  return async (dispatch) => {
    try {
      // const { selectedProject } = state
      const { title, description, createdBy, statusCategories } =
        selectedProject

      if (
        !title ||
        !description ||
        !createdBy ||
        !statusCategories ||
        !Array.isArray(statusCategories)
      ) {
        throw new Error("Invalid request payload")
      }

      // Create a copy of the statusCategories array
      const updatedStatusCategories = Array.from(statusCategories)

      // Reorder the categories in the updatedStatusCategories array
      const [removed] = updatedStatusCategories.splice(sourceIndex, 1)
      updatedStatusCategories.splice(destinationIndex, 0, removed)

      // Send the updated statusCategories array in the request body
      await authFetch.patch(`/project/${id}`, {
        title,
        description,
        createdBy,
        statusCategories: updatedStatusCategories,
      })

      // toast.success("Project updated successfully")

      dispatch({
        type: DRAG_CATEGORY,
        payload: { sourceIndex, destinationIndex },
      })
    } catch (error) {
      toast.error(error.message)
    }
  }
}
