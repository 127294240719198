import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { getAllQuestion, getFeedbackSubjective } from "../../../redux"
import { capitalizeFirstLetter } from "../../../utils/getInitialName"
import { FiberManualRecordRoundedIcon } from "../../../assets/icons"

const MultipleReview = () => {
  const dispatch = useDispatch()
  const { multiple } = useSelector((state) => state.feedback)
  const [answers, setAnswers] = useState(null)
  const [active, setActive] = useState(false)
  // there will be two sections one list of question on left and then answers in right
  const handleGetFeedback = (id) => {
    dispatch(getFeedbackSubjective(id)).then((data) => {
      console.log("data of feedback", data)
      const answers = data.map((i) => i.answer)
      let counts = {}

      for (let i = 0; i < answers.length; i++) {
        let currentString = answers[i]

        if (counts[currentString]) {
          counts[currentString]++
        } else {
          counts[currentString] = 1
        }
      }
      console.log(counts)
      setAnswers(counts)
    })
    setActive(id)
  }
  useEffect(() => {
    dispatch(getAllQuestion({ multiple: true }))
  }, [])

  return (
    <Wrapper>
      <Wrapper>
        <div className="row lg:tw-px-8 tw-px-3 ">
          <div className="col-6">
            <div className="question">
              <h6>Question</h6>
              {multiple &&
                multiple.length > 0 &&
                multiple.map((q) => {
                  return (
                    <div
                      className="single-question"
                      onClick={() => handleGetFeedback(q._id)}
                      style={{
                        background:
                          active === q._id ? "rgba(151, 219, 249, 0.5)" : "",
                      }}
                    >
                      {capitalizeFirstLetter(q.question)}
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="col-6">
            <h6 className="question">Answer</h6>
            <div className="answer">
              {answers
                ? Object.keys(answers).map((i) => {
                    return (
                      <div className="single-answer">
                        <FiberManualRecordRoundedIcon
                          style={{ marginRight: "5px", fontSize: "5px" }}
                        />
                        {i}: {"   "}
                        {answers[i]} times
                      </div>
                    )
                  })
                : "Select Question"}
            </div>
          </div>
        </div>
      </Wrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 40px;
  .question-list {
    border: 1px solid grey;
  }

  .question {
    margin-top: -12px;
  }

  .answer {
    background: aliceblue;
    padding: 5px 20px;
    border: 1px solid aliceblue;
    border-radius: 5px;
    font-size: 12px;
    margin-top: 15px;
  }

  .single-question {
    box-shadow: var(--shadow-1);
    padding: 8px;
    margin: 15px 0px;
    background: aliceblue;
    border: 2px solid aliceblue;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    border-left: 2px solid var(--blue);
    &:hover {
      background: rgba(151, 219, 249, 0.37298669467787116);
    }
  }

  .single-answer {
    display: flex;
    margin: 10px 0px;
    align-items: center;
  }
`
export default MultipleReview
