export const CREATE_QUESTION_BEGIN = "CREATE_QUESTION_BEGIN"
export const CREATE_QUESTION_SUBJECTIVE_SUCCESS =
  "CREATE_QUESTION_SUBJECTIVE_SUCCESS"
export const CREATE_QUESTION_MULTIPLE_SUCCESS =
  "CREATE_QUESTION_MULTIPLE_SUCCESS"
export const CREATE_QUESTION_ERROR = "CREATE_QUESTION_ERROR"

export const GET_ALL_QUESTION_BEGIN = "GET_ALL_QUESTION_BEGIN"
export const GET_ALL_QUESTION_SUBJECTIVE_SUCCESS =
  "GET_ALL_QUESTION_SUBJECTIVE_SUCCESS"
export const GET_ALL_QUESTION_MULTIPLE_SUCCESS =
  "GET_ALL_QUESTION_MULTIPLE_SUCCESS"
export const GET_ALL_QUESTION_ERROR = "GET_ALL_QUESTION_ERROR"

export const GET_SINGLE_QUESTION_BEGIN = "GET_SINGLE_QUESTION_BEGIN"
export const GET_SINGLE_QUESTION_SUCCESS = "GET_SINGLE_QUESTION_SUCCESS"
export const GET_SINGLE_QUESTION_ERROR = "GET_SINGLE_QUESTION_ERROR"

export const UPDATE_QUESTION_BEGIN = "UPDATE_QUESTION_BEGIN"
export const UPDATE_SUBJECTIVE_QUESTION_SUCCESS =
  "UPDATE_SUBJECTIVE_QUESTION_SUCCESS"
export const UPDATE_MULTIPLE_QUESTION_SUCCESS =
  "UPDATE_MULTIPLE_QUESTION_SUCCESS"
export const UPDATE_QUESTION_ERROR = "UPDATE_QUESTION_ERROR"

export const DELETE_QUESTION_BEGIN = "DELETE_QUESTION_BEGIN"
export const DELETE_SUBJECTIVE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"
export const DELETE_MULTIPLE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"
export const DELETE_QUESTION_ERROR = "DELETE_QUESTION_ERROR"

export const OPEN_QUESTION_MODAL = "OPEN_QUESTION_MODAL"
export const CLOSE_QUESTION_MODAL = "CLOSE_QUESTION_MODAL"

export const CREATE_FEEDBACK_BEGIN = "CREATE_FEEDBACK_BEGIN"
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS"
export const CREATE_FEEDBACK_ERROR = "CREATE_FEEDBACK_ERROR"

export const GET_SUBJECTIVE_QUESTION_BEGIN = "GET_SUBJECTIVE_QUESTION_BEGIN"
export const GET_SUBJECTIVE_QUESTION_SUCCESS = "GET_SUBJECTIVE_QUESTION_SUCCESS"
export const GET_SUBJECTIVE_QUESTION_ERROR = "GET_SUBJECTIVE_QUESTION_ERROR"

export const GET_MULTIPLE_QUESTION_BEGIN = "GET_MULTIPLE_QUESTION_BEGIN"
export const GET_MULTIPLE_QUESTION_SUCCESS = "GET_MULTIPLE_QUESTION_SUCCESS"
export const GET_MULTIPLE_QUESTION_ERROR = "GET_MULTIPLE_QUESTION_ERROR"

export const GET_FEEDBACK_SUBJECTIVE_BEGIN = "GET_FEEDBACK_SUBJECTIVE_BEGIN"
export const GET_FEEDBACK_SUBJECTIVE_SUCCESS = "GET_FEEDBACK_SUBJECTIVE_SUCCESS"
export const GET_FEEDBACK_SUBJECTIVE_ERROR = "GET_FEEDBACK_SUBJECTIVE_ERROR"

export const GET_FEEDBACK_MULTIPLE_BEGIN = "GET_FEEDBACK_MULTIPLE_BEGIN"
export const GET_FEEDBACK_MULTIPLE_SUCCESS = "GET_FEEDBACK_MULTIPLE_SUCCESS"
export const GET_FEEDBACK_MULTIPLE_ERROR = "GET_FEEDBACK_MULTIPLE_ERROR"

export const SET_EDIT_SUBJECTIVE = "SET_EDIT_SUBJECTIVE"
export const SET_EDIT_MULTIPLE = "SET_EDIT_MULTIPLE"

export const OPEN_SUBJECTIVE_MODAL = "OPEN_SUBJECTIVE_MODAL"
export const OPEN_MULTIPLE_MODAL = "OPEN_MULTIPLE_MODAL"
