import React, { useState } from "react"
import SelectStatusModal from "../../components/SelectStatusModal"

import styled from "styled-components"
import { capitalizeFirstLetter } from "../../utils/getInitialName"
import MoreVertIcon from "@mui/icons-material/MoreVert"

import ArchiveFilter from "../../components/ArchiveFilter"

const ArchiveList = () => {
  const [tasks, setTasks] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null)

  // if (tasks.length < 1) {
  //   return <p>No tasks to display</p>
  // }

  return (
    <Wrapper>
      <h5 className="card-title tw-p-0 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">Archive Tasks</h5>
      <ArchiveFilter setTasks={setTasks} />
      <div>
        {tasks &&
          tasks.length > 0 &&
          tasks.map((t) => {
            return (
              <div className="box">
                <p>{capitalizeFirstLetter(t.title)}</p>

                <MoreVertIcon
                  color="disabled"
                  onClick={() => {
                    setOpenModal(true)
                    setSelectedTask(t)
                  }}
                />
              </div>
            )
          })}
      </div>
      <SelectStatusModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        selectedTask={selectedTask}
        setTasks={setTasks}
        tasks={tasks}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`

  .box {
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
    border: 1px solid lightgrey;
    border-left: 3px solid lightgrey;
    border-bottom: 3px solid lightgrey;
    width: 300px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
      align-items: center;
      display: flex;
      font-size: 12px;
    }
  }
  p {
    margin: 0;
    align-items: center;
    display: flex;
    font-size: 12px;
  }
`
export default ArchiveList
