import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { getAllTestCases, setDateRange } from "../../../redux";
import { FormRow } from "../../Common";
import { useSelector } from "react-redux";

const initialDateState = {
    startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
};

const TestCaseFilters = ({ testCaseId, }) => {

    const dispatch = useDispatch();
    const [dateRange, setDateRanges] = useState(initialDateState);

    useEffect(() => {
        const { startDate, endDate } = dateRange;
        dispatch(getAllTestCases(dateRange));
    }, [dispatch, testCaseId]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRanges((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const { startDate, endDate } = dateRange;
        dispatch(setDateRange(dateRange))
        dispatch(getAllTestCases(dateRange));
    }

    return (
        // <Wrapper>
        <form className="row" onSubmit={handleSubmit}>
            <div className="gap-3 mt-2" style={{ display: 'flex' }}>
                <div className="align-self-center pb-2">
                    <FormRow
                        name="startDate"
                        type="date"
                        value={dateRange.startDate}
                        handleChange={handleDateChange}
                        required={true}
                        labelText={"start Date"}
                    />
                </div>
                <div className="align-self-center pb-2">
                    <FormRow
                        name="endDate"
                        type="date"
                        value={dateRange.endDate}
                        handleChange={handleDateChange}
                        required={true}
                        labelText={"End Date"}
                    />
                </div>
                {/* <button className="btn btn-block">apply</button> */}
                <button className="btn add-btn align-self-center">apply</button>
            </div>
        </form>
        // </Wrapper>
    );
};

const Wrapper = styled.div`
  ${'' /* form {
    display: flex;
    gap: 10px;
  }

  label {
    margin-right: 5px;
  } */}

  button {
    background: var(--primary-500);
    color: white;
    ${'' /* padding: 5px 10px; */}
  }
`;

export default TestCaseFilters;
