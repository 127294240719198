import { Route, Routes, BrowserRouter } from "react-router-dom"
import { ProtectedRoute } from "./pages"
import { SignIn, Signup, ConfirmVerification, VerifyEmail } from "./components/Auth"
import { Clients, SharedLayout, Projects, Board, Profile, Reports, Dashboard, AdminReport, Settings, QuestionAnswer, Activity, ArchiveList, FeedbackReport, } from "./pages/Dashboard"
import Error from "./pages/Error"
import RaiseTicket from "./components/ticket/RaiseTicket"
import AllTicket from "./components/ticket/AllTicket"
import "./styles/home.css"
import "./styles/client.css"
import Testingg from "./pages/Testingg"
//test case
import TestCase from "./components/testcase/TestCase"
import Template from "./components/testcase/Template/Template"
import TestItem from "./components/testcase/TestItems/TestItem"
import TestCaseDetails from "./components/testcase/TestCaseDetails"
import EditTestCaseDetails from "./components/testcase/TestDetails/EditTestCaseDetails"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify_email/:token" element={<VerifyEmail />} />
        <Route path="/verify_email" element={<ConfirmVerification />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Projects />} />
          <Route path="clients" element={<Clients />} />
          <Route path="report" element={<Reports />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="board" element={<Board />} />
          <Route path="profile" element={<Profile />} />
          <Route path="admin_report" element={<AdminReport />} />
          <Route path="settings" element={<Settings />} />
          <Route path="question_answer" element={<QuestionAnswer />} />
          <Route path="activity" element={<Activity />} />
          <Route path="archive" element={<ArchiveList />} />
          <Route path="testing" element={<Testingg />} />
          <Route path="feedback_report" element={<FeedbackReport />} />
          <Route path="raise_ticket" element={<RaiseTicket />} />
          <Route path="all_ticket" element={<AllTicket />} />
          <Route path="test_case" element={<TestCase/>} />
          <Route path="/test_case/details" element={<TestCaseDetails/>} />
          <Route path="/test_case/details/edit" element={<EditTestCaseDetails/>} />
          <Route path="/test_case/template" element={<Template/>} />
          <Route path="/test_case/test_items" element={<TestItem/>} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
