import React from "react"
import { AllClients, ClientModal } from "../../components/Question"

const QuestionAnswer = () => {
  return (
    <>
      <ClientModal />

      <div className="all-clients lg:tw-pt-8  tw-pt-3">
        <AllClients />
      </div>
    </>
  )
}

export default QuestionAnswer
