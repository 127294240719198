import React from "react"
import { NavLink } from "react-router-dom"
import { useAppContext } from "../../context/context/appContext"
import { BreadCrumb, CustumTooltip, ProfileAvatar } from "../Common"
import {
  NavProjectName,
  NavWrapper,
  NaveBar,
} from "../../assets/styles/Layouts"
import {
  AccountCircleRoundedIcon,
  ExitToAppIcon,
  FiberManualRecordRoundedIcon,
  FormatIndentDecreaseIcon,
} from "../../assets/icons"
import { useSelector } from "react-redux"
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
const Navbar = ({ theme, toggleTheme }) => {
  const { toggleSidebar, logoutUser, user, showLogout, setShowLogout } =
    useAppContext()
  const { selectedProject } = useSelector((state) => state.project)

  return (
    <NaveBar className={` ${theme}`}>
      <NavWrapper className={` ${theme}`}>
        <div className={`nav-center ${theme}`}>
          <button className="toggle-btn" onClick={toggleSidebar}>
            <MenuOpenIcon />
          </button>
          <div className="nav-logo">
            {selectedProject && (
              <NavProjectName>
                <p>
                  <span>{selectedProject && selectedProject.title}</span>
                </p>
              </NavProjectName>
            )}
          </div>

          {/* {showLogout ? (
          <div className="pg-blur" onClick={() => setShowLogout(false)}></div>
        ) : null} */}
          <div className="right-txt">
            {/* <SearchBox /> */}
            <div
              className="btn-container"
              onClick={() => setShowLogout(!showLogout)}
            >
              <CustumTooltip
                text={user.name}
                children={<ProfileAvatar id={user._id} size="s" />}
                position={"bottom"}
                id={`profile-tooltip`}
              />

              <FiberManualRecordRoundedIcon
                className="isactive"
                style={{ color: user.is_active ? "rgb(1, 191, 1)" : "grey" }}
              />

              <div
                className={showLogout ? "dropdown show-dropdown" : "dropdown"}
                onClick={() => setShowLogout(!showLogout)}
              >
                <p className="acc">ACCOUNT</p>
                <div className="dflex  "  >
                  {user && <ProfileAvatar id={user._id} size="s" />}
                  <p className="textLeft">
                    {user ? user.name : "user"}
                    <br />
                    <b className="lowerCase">{user ? user.email : "user"}</b>
                  </p>
                </div>

                <NavLink to="/profile">
                  <button
                    className="dropdown-btn tw-p-0 jhk"
                    onClick={() => setShowLogout(!showLogout)}  
                  >
                    <AccountCircleRoundedIcon /> Profile
                  </button>
                </NavLink>
                <hr />
                <button
                  className="dropdown-btn jhk mb-2"
                  onClick={() => logoutUser()}
                >
                  <ExitToAppIcon /> Log out
                </button>
              </div>
            </div>
          </div>
        </div>

 
      </NavWrapper>
              {/* <BreadCrumb/> */}
    </NaveBar>
  )
}

export default Navbar
