import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FormRow } from "../Common"
import Modal from "react-bootstrap/Modal"
import { useDispatch, useSelector } from "react-redux"
import {
  getMultipleQuestion,
  createFeedback,
  updateQuestion,
} from "../../redux"
import { capitalizeFirstLetter } from "../../utils/getInitialName"

const initialState = {
  answer: "",
}
function MultipleModal() {
  const dispatch = useDispatch()
  const { isMultipleModalOpen, editingQuestion, isEditingQuestion } =
    useSelector((state) => state.feedback)
  const [values, setValues] = useState(initialState)
  const [question, setQuestion] = useState(null)
  const [options, setOptions] = useState([0])

  useEffect(() => {
    !editingQuestion &&
      dispatch(getMultipleQuestion()).then((data) => {
        console.log(data)
        setQuestion(data)
      })

    if (editingQuestion) {
      setQuestion(editingQuestion)
    }
  }, [editingQuestion])

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(values)
    dispatch(createFeedback({ answer: values.answer, question: question._id }))
  }
  const handleOptions = (e) => {
    console.log(e.target.value)
    setValues({ ...values, answer: e.target.value })
  }
  const handleAddOptions = () => {
    const length = question.options.length
    console.log(length)
    // setOptions([...options, last + 1])
    const arr = question.options
    arr[length] = ""
    setQuestion({ ...question, options: arr })
  }

  const handleChange = (i) => {
    const str = i.target.value.slice(0, -1)
    const position = question.options.indexOf(str)
    const arr = question.options
    arr[position] = i.target.value
    setQuestion({ ...question, options: arr })
  }

  const closeModal = () => {}
  const handleUpdate = () => {
    console.log(question)
    dispatch(updateQuestion(question))
  }
  return (
    <Wrapper>
      <Modal
        show={isMultipleModalOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditingQuestion ? "edit question" : "objective question"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!editingQuestion && (
            <Wrapper>
              {question && (
                <div>
                  <p>{capitalizeFirstLetter(question.question)}</p>

                  {question.options.map((q) => {
                    return (
                      <FormRow
                        name="option"
                        type="radio"
                        labelText={q}
                        handleChange={handleOptions}
                        value={q}
                      />
                    )
                  })}

                  <button className="btn" onClick={handleSubmit}>
                    submit
                  </button>
                </div>
              )}
            </Wrapper>
          )}
          {editingQuestion && (
            <div>
              {/* <h4>{editingQuestion.question}</h4> */}
              <FormRow
                name="question"
                value={question.question}
                labelText={"new question"}
              />
              {question.options.map((i, index) => {
                return (
                  <div>
                    <FormRow
                      name={index}
                      labelText={`option`}
                      value={question.options[index]}
                      handleChange={handleChange}
                    />
                  </div>
                )
              })}
              {question.type === "multiple" && (
                <button className="btn" onClick={handleAddOptions}>
                  add options
                </button>
              )}
              <button onClick={() => handleUpdate()}>submit</button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  .form-row {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: left;
    place-content: start;
  }

  .form-input {
    width: auto;
  }

  .form-label {
    margin: 2px 0 0 9px;
  }
`
export default MultipleModal
