import {
  GET_ALL_TASKS_BEGIN,
  GET_ALL_TASKS_SUCCESS,
  GET_ALL_TASKS_ERROR,
  GET_TASK_USER_BEGIN,
  GET_TASK_USER_SUCCESS,
  CREATE_TASK_BEGIN,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,
  UPDATE_TASKS_BEGIN,
  UPDATE_TASKS_SUCCESS,
  UPDATE_TASKS_ERROR,
  OPEN_TASK_MODAL,
  CLOSE_TASK_MODAL,
  SET_EDITING_TASK,
  DELETE_TASK_SUCCESS,
  GET_TASK_BY_GOAL,
  NEW_TASK_CREATED,
  FILTER_TASK_ON_STATUS_UPDATE,
  UPDATE_TASK_SOCKET,
  ARCHIVE_TASK,
  GET_TASK_WITHOUT_GOAL_BEGIN,
  GET_TASK_WITHOUT_GOAL_SUCCESS,
  GET_TASK_WITHOUT_GOAL_ERROR,
} from "./taskTypes"
import { SET_EDIT_TASK_FOR_CODE_REVIEW } from "../codeReview/codeReviewTypes"
import authFetch from "../../utils/interceptor"
import { toast } from "react-toastify"
import { socket } from "../../context/context/appContext"
import store from "../store"

export const createTask = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_TASK_BEGIN })
    try {
      const response = await authFetch.post("/task", values)
      dispatch({ type: CREATE_TASK_SUCCESS })
      toast.success("Task created successfully")
    } catch (error) {
      dispatch({ type: CREATE_TASK_ERROR, payload: { msg: error } })
      toast.error("Failed to create task")
    }
  }
}

export const newTaskCreated = (data) => {
  return (dispatch) => {
    const project = store.getState().project.selectedProject
    dispatch({ type: NEW_TASK_CREATED, payload: { task: data, project } })
  }
}

export const getAllTasks = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_TASKS_BEGIN })
    try {
      const response = await authFetch.get("/task")
      dispatch({
        type: GET_ALL_TASKS_SUCCESS,
        payload: { tasks: response.data.tasks },
      })
    } catch (error) {
      // add toast here
      dispatch({
        type: GET_ALL_TASKS_ERROR,
        payload: { err: error.response.data },
      })
    }
  }
}

export const getTasksByProject = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_TASKS_BEGIN })
    try {
      const response = await authFetch.post("/task/project", { id })
      dispatch({
        type: GET_ALL_TASKS_SUCCESS,
        payload: { tasks: response.data.tasks },
      })
      return response.data.tasks
    } catch (error) {}
  }
}

export const getSingleTask = (id) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.get(`task/${id}`)
      // console.log(response)
      return response.data.task
    } catch (error) {}
  }
}

export const getTasksUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_TASK_USER_BEGIN })
    try {
      const response = await authFetch.post("/task/user", { userId: id })

      dispatch({
        type: GET_TASK_USER_SUCCESS,
        payload: { tasks: response.data.tasks },
      })
    } catch (error) {}
  }
}

export const updateTask = (values) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TASKS_BEGIN })
    try {
      const response = await authFetch.patch(`/task/${values._id}`, values)
      dispatch({
        type: UPDATE_TASKS_SUCCESS,
        payload: { task: response.data.updatedTask },
      })
      toast.success("Task updated successfully")
    } catch (error) {
      dispatch({ type: UPDATE_TASKS_ERROR, payload: { msg: error.message } })
      toast.error("Task update failed")
    }
  }
}

export const deleteTask = (id) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.delete(`/task/${id}`)
      // console.log(response)
      toast.success("task deleted successfully")
      //   closeTaskModal()
      dispatch({ type: DELETE_TASK_SUCCESS, payload: { id: id } })
    } catch (error) {
      toast.error("you can not delete task as there are worklogs in it ")
    }
  }
}

export const openTaskModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_TASK_MODAL })
  }
}
export const closeTaskModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_TASK_MODAL })
  }
}

export const setEditingTask = (id) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.get(`task/${id}`)
      console.log(response)

      dispatch({
        type: SET_EDITING_TASK,
        payload: { task: response.data.task },
      })
      dispatch({
        type: SET_EDIT_TASK_FOR_CODE_REVIEW,
        payload: { codeReviewTask: response.data.task },
      })

    } catch (error) {
      toast.error("error getting task")
    }
  }
}

export const updateTaskStatus = (data) => {
  return async (dispatch) => {
    console.log(data)
    const response = await authFetch.patch("/task", data)
    if (response.data.updatedTask.status !== "todo") {
      if (!response.data.updatedTask.assignedTo) {
        data.destination.droppableId = "todo"
        const response = await authFetch.patch("/task", data)
        console.log(response)
        toast.error("please assign task else it will be moved back to todo")
      }
    }
  }
}

export const handleDragEnd = (data) => {
  return (dispatch) => {
    console.log(data)
    const { destination, source, draggableId } = data
    if (!destination) return
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    )
      return
    dispatch(updateTaskStatus(data))
    socket.emit("taskDragged", {
      draggableId,
      source,
      destination,
    })
  }
}

export const filterTaskOnStatusChange = (id, destination) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_TASK_ON_STATUS_UPDATE,
      payload: { id, destination },
    })
  }
}

export const updateTaskSocket = (id, updatedField) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_TASK_SOCKET, payload: { id, updatedField } })
  }
}

export const getTasksByGoal = (values) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.post("/task/goal", values)
      dispatch({
        type: GET_TASK_BY_GOAL,
        payload: { tasks: response.data.tasks },
      })
    } catch (error) {}
  }
}

export const archiveTask = (values) => {
  return async (dispatch) => {
    try {
      const { id } = values
      console.log(id)
      const response = await authFetch.post("/task/archive", values)
      dispatch({ type: ARCHIVE_TASK, payload: { id } })
      toast.success("task archived successfully")
    } catch (error) {
      toast.error("failed to update task")
    }
  }
}

export const getArchiveList = (values) => {
  return async (dispatch) => {
    try {
      const { project, startDate, endDate } = values
      const response = await authFetch.get(
        `/task/archive?project=${project}&startDate=${startDate}&endDate=${endDate}`
      )
      return response.data.tasks
    } catch (error) {
      toast.error("failed to get archived list")
    }
  }
}

export const unArchive = (values) => {
  return async (dispatch) => {
    try {
      console.log(values)
      const response = await authFetch.post("/task/unarchive", values)
    } catch (error) {
      console.log(error)
    }
  }
}

export const getTaskWithoutGoal = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_TASK_WITHOUT_GOAL_BEGIN })
    try {
      const response = await authFetch.get(`/task/withoutGoal/${id}`)
      console.log(response)
      dispatch({
        type: GET_TASK_WITHOUT_GOAL_SUCCESS,
        payload: { tasks: response.data.tasks },
      })
    } catch (error) {
      console.log(error)
      toast.error("error getting tasks")
      dispatch({ type: GET_TASK_WITHOUT_GOAL_ERROR })
    }
  }
}
