import React from "react";

const FormTextArea = ({
  name,
  value,
  handleChange,
  labelText,
  disabled,
  placeholder,
  onKeyUp,
  required,
  rows,
}) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText}
      </label>
      <textarea
        value={value}
        name={name}
        onChange={handleChange}
        className={disabled ? "form-input" : "form-input"}
        required={required}
        disabled={disabled ? true : false}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        rows={rows || 3} // Default to 3 rows if not specified
      />
    </div>
  );
};

export default FormTextArea;
