import authFetch from "./interceptor"
import { toast } from "react-toastify"
// get resource from aws s3 bucket
const getResource = async (url) => {
  // dispatch({ type: GET_RESOURCE_BEGIN })
  try {
    const response = await authFetch.post(
      "/resource/getFile",
      { Key: url },
      { responseType: "arraybuffer" }
    )
    // add payload if needed
    // dispatch({ type: GET_RESOURCE_SUCCESS })
    return response
  } catch (error) {
    // dispatch({ type: GET_RESOURCE_ERROR })
  }
}

const getProfilePic = async (id, size) => {
  //  console.log(id, size)
  try {
    const response = await authFetch.get(`/resource/profile/${size}/${id}`, {
      responseType: "arraybuffer",
    })
    //    console.log(response)
    return response
  } catch (error) {
    //    console.log(error)
    // toast.error("cannot get profile pic")
  }
}

// put resource to s3 bucket



const putticketResource = async (data) => {
  try {
   
    const res = await authFetch.post("/resource/submitticketFile", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  } catch (error) {
    // check error display in toast
  }
}
const putResource = async (data) => {
  try {
   
    const res = await authFetch.post("/resource/submitFile", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  } catch (error) {
    // check error display in toast
  }
}

const deleteResource = async (data) => {
  try {
    const res = await authFetch.post("/resource/deleteFile", data)
    toast.success(res.data.msg)
  } catch (error) {
    // show in toast
  }
}

const downloadResource = async (link) => {
  const name = link.split("/")
  const extension = link.split(".")
  try {
    const response = await authFetch.post(
      "/resource/getFile",
      { Key: link },
      { responseType: "arraybuffer" }
    )
    const blob = new Blob([response.data], { type: "" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = name[2]
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
    toast.success("File started to download")
  } catch (error) {}
}

export {
  downloadResource,
  deleteResource,
  putResource,
  getProfilePic,
  getResource,
  putticketResource,
}
