import React from "react"
import { useDropzone } from "react-dropzone"
import { RotatingLines } from "react-loader-spinner"
import { DropzoneWrapper } from "../../assets/styles/Common"
import { CloudUploadIcon, MoveToInboxIcon } from "../../assets/icons"

function Dropzone({ onDrop, accept, open, loading }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept,
      onDrop,
    })
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <DropzoneWrapper>
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" multiple {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">
              <MoveToInboxIcon />
            
              Release to drop the files here
            </p>
          ) : (
            <p className="dropzone-content">
              <CloudUploadIcon />
             Drag & drop file or upload here...
            </p>
          )}
          {/* <button type="button" onClick={open} className="btn">
            Click to select files
          </button> */}
        </div>
      </div>
      <aside>
        {loading && (
          <RotatingLines
            strokeColor="grey"
            strokeWidth="3"
            animationDuration="0.75"
            width="40"
            visible={true}
          />
        )}
      </aside>
    </DropzoneWrapper>
  )
}

export default Dropzone
