import React from "react"
import { CircleLoadingWrapper } from "../../assets/styles/Common"

const Loading = () => {
  return (
    <CircleLoadingWrapper>
      <div className="loader"></div>
    </CircleLoadingWrapper>
  )
}

export default Loading
