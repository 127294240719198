import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTestItem, getAllTestItems, openTestItemModal } from '../../../redux';
import { AddSharpIcon, DeleteIcon, DriveFileRenameOutlineSharpIcon } from '../../../assets/icons';
import TestItemModal from './TestItemsModal';
import DeleteConfirmationModal from '../../Common/DeleteConfirmationModal';
import { Loading, Loading2 } from '../../Common';

const AllTestsItem = ({ templateId, setId, id }) => {
    const dispatch = useDispatch();
    const { testItems, loading, isTestItemModalOpen  } = useSelector((state) => state.testItems);
    const[isModalOpen, setIsModalOpen] = useState(false)
    const[isEditModalOpen, setIsEditModalOpen] = useState(false)

    useEffect(() => {
        if (templateId) {
            dispatch(getAllTestItems(templateId));
        }
    }, [dispatch, templateId, isEditModalOpen]);

    useEffect(() => {
            dispatch(getAllTestItems(templateId));
    }, []);

    const handleTestItemEdit = (id) => {
        setId(id)
        dispatch(openTestItemModal())
        setIsEditModalOpen(true)
    }

    const handleDeleteOpen = (id) => {
        setId(id)
        setIsModalOpen(true)
    }
    const handleDeleteClose = () => {
        setId('')
        setIsModalOpen(false)
    }
    const handleDeleteConfirmation = () => {
        dispatch(deleteTestItem(templateId, id))
        setIsModalOpen(false)
        setId('')
    }

    if (loading) {
        // return <Loading2 center />
      }


    return (
        <div>
            <TestItemModal isEditModalOpen={isEditModalOpen}  setIsEditModalOpen={setIsEditModalOpen} templateId={templateId} id={id} setId={setId} />
            <DeleteConfirmationModal show={isModalOpen} handleClose={handleDeleteClose} handleConfirm={handleDeleteConfirmation} text={"Are you sure you want to delete this Test Item"}  />
            {loading ? <Loading2 center /> : <null></null>}
            <div className="tableresponse">
                <div className='table-wrapper'>
                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className='text-left' style={{textAlign: 'left'}}>Name</th>
                        <th style={{ textAlign: 'end', paddingRight: 30 }}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {testItems && testItems.length > 0 ? (
                        testItems.map((testItems, i) => (
                        <tr key={testItems._id}>
                            <td className="" style={{width: "30px"}}>{i+1}</td>
                            <td className="" style={{textAlign: 'left'}}>{testItems.title}</td>
                            <td style={{ textAlign: 'end' }}>
                            <button className="btn  add-btn mx-2" type="button" onClick={() => handleTestItemEdit(testItems._id)}>
                                <DriveFileRenameOutlineSharpIcon /> Edit
                            </button>
                            <button className="btn add-btn" type="button" onClick={() => handleDeleteOpen(testItems._id)}>
                                <DeleteIcon /> Delete
                            </button>
                            </td>
                        </tr>
                        ))
                    ) : (
                        <tr>
                        <td colSpan="3" className="text-center">No templates found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    );
};

export default AllTestsItem;
