import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FormRow, FormRowSelect, Dropzone, Loading } from "../../Common";
import FormTextArea from "../../Common/FormTextArea";
import { ArrowBackIcon, DeleteIcon } from "../../../assets/icons";
import { TestCaseWrapper } from "../../../assets/styles/TestCase";
import { getTestCaseDetailsById, updateTestCaseRemarks } from "../../../redux";


const EditTestCaseDetails = () => {
  const dispatch = useDispatch();
  const { testCaseDetailsItem, selectedTestCaseDetails, loading } = useSelector((state) => state.testCase);
  const navigate = useNavigate();
  const location = useLocation();
  const { testCaseDetailsId, testCaseId, templateId } = location.state || {};

  const [formValues, setFormValues] = useState({
    title: "",
    status: "Pending",
    notes: "",
    attachment: "",
    userId: "",
    tested_on: "",
    description: "",
    steps: "",
    sampleData: "",
    remarks: ""
  });

  const [id, setId] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [imageArray2, setImageArray2] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [testCaseIds, SetTestCaseId] = useState();
  const [templateIds, setTemplateId] = useState();

  useEffect(() => {
    if (testCaseDetailsId) {
      dispatch(getTestCaseDetailsById(testCaseDetailsId));
    }
  }, [testCaseDetailsId]);
  
  useEffect(() => {
    SetTestCaseId(testCaseId)
    setTemplateId(templateId)
  }, [testCaseId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (selectedTestCaseDetails) {

      setFormValues({
        title: selectedTestCaseDetails.title || "",
        status: selectedTestCaseDetails.status || "Pending",
        // notes: selectedTestCaseDetails.notes || "",
        attachment: selectedTestCaseDetails.attachment || "",
        userId: selectedTestCaseDetails.userId || "",
        tested_on: formatDate(selectedTestCaseDetails.startDate) || "", //formatDate(selectedTestCase.startDate)
        description: selectedTestCaseDetails.description || "",
        steps: selectedTestCaseDetails.steps || "",
        sampleData: selectedTestCaseDetails.sampleData || "",
        // remarks: selectedTestCaseDetails.remarks || ""
      });

      // Populate imageArray with URLs of existing attachments
      if (selectedTestCaseDetails?.attachments) {
        const attachmentURLs = selectedTestCaseDetails?.attachments?.map((attachment) => {
          // Convert base64 string to a Uint8Array
          const buffer = Uint8Array.from(atob(attachment.content), (c) => c.charCodeAt(0));
          const blob = new Blob([buffer], { type: attachment.contentType });  // Create a Blob
          const imageUrl = URL.createObjectURL(blob);  // Generate an object URL for the Blob
          return { key: attachment.key, filename: attachment.filename, url: imageUrl };
        });
        setImageArray(attachmentURLs);
      }
    }
  }, [selectedTestCaseDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Add other form fields
    // formData.append("title", formValues.title);
    // formData.append("description", formValues.description);
    // formData.append("steps", formValues.steps);
    // formData.append("sampleData", formValues.sampleData);
    // formData.append("tested_on", formValues.tested_on);
    const remarksArray = [{
      note: formValues.remarks,
      attachments: []  // If you have attachments, populate this array
    }];
    formData.append("remarks", JSON.stringify(remarksArray));
    formData.append("status_id", formValues.status === "Pass" ? 2 : formValues.status === "Fail" ? 3 : 1); //Pass, Fail
    formData.append("testCase_id", testCaseId);

    // Add files to formData
    // imageArray.forEach((file, index) => {
    //   formData.append(`attachment`, file); // This adds each file individually
    // });

    //image array for remark
    imageArray2.forEach((file, index) => {
      formData.append(`attachment`, file); // This adds each file individually
    });

    // Dispatch an action to update the test case
    dispatch(updateTestCaseRemarks(testCaseDetailsId, formData)); // Uncomment when using Redux for update
    setIsEditing(false);
    setFormValues({
      title: "",
      status: "Pending",
      notes: "",
      attachment: "",
      userId: "",
      tested_on: "",
      description: "",
      steps: "",
      sampleData: "",
      remarks: ""
    })
    setImageArray2([])
    navigate(`/test_case/details`, { state: {testCaseId: testCaseIds, templateId: templateIds} });
  };

  const onDrop = async (acceptedFiles) => {
    setImageArray([...imageArray, ...acceptedFiles]);
  };

  const onDropRemark = async (acceptedFiles) => {
    setImageArray2([...imageArray2, ...acceptedFiles]);
  };

  const removeImage = (indexToRemove) => {
    const updatedArray = imageArray.filter((_, index) => index !== indexToRemove);
    setImageArray(updatedArray);
  };

  const removeImageRemark = (indexToRemove) => {
    const updatedArray = imageArray2.filter((_, index) => index !== indexToRemove);
    setImageArray2(updatedArray);
  };

  const handleEditClick = (testCaseDetailsId) => {
    navigate(`/test_case/details/edit/${testCaseDetailsId}`, { state: { testCaseDetailsId, } });
    // setIsTestDetailsModalOpen(true);
    // setId("1");
  };

  const handleBack = () => {
    navigate(`/test_case/details`, { state: {testCaseId: testCaseIds, templateId: templateIds} });
  };

  return (
    <div className="card-title">
      <TestCaseWrapper>
        <h5 className="mt-2" style={{marginLeft: 10}}>
          <span onClick={handleBack}>
          <ArrowBackIcon style={{fontSize: 20}} />
          </span>{" "}
          Tests Details
        </h5>
        {loading ? <Loading /> : <></>}
        <div className="card-title container" style={{ width: "100%", maxHeight: '100vh', marginBottom: 100 }}>
          <form className="row" onSubmit={handleSubmit}>
            <div className="">
              <div className="d-flex">
                <div className="" style={{ width: "40%", marginRight: "12px" }}>
                  <FormRow
                    labelText="Title"
                    name="title"
                    value={formValues.title}
                    // handleChange={handleChange}
                  />
                </div>
                <div className="" style={{ width: "40%", marginRight: "12px" }}>
                  <FormRow
                    type="date"
                    name="tested_on"
                    labelText="Test On"
                    value={formValues.tested_on}
                    // handleChange={handleChange}
                    // disabled
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="" style={{ width: "40%", marginRight: "12px" }}>
                  <FormTextArea
                    labelText="Description"
                    name="description"
                    value={formValues.description}
                    // handleChange={handleChange}
                  />
                </div>
                <div className="" style={{ width: "40%", marginRight: "12px" }}>
                  <FormTextArea
                    labelText="Steps"
                    name="steps"
                    value={formValues.steps}
                    // handleChange={handleChange}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="" style={{ width: "40%", marginRight: "12px" }}>
                  <FormTextArea
                    labelText="Sample Data"
                    name="sampleData"
                    value={formValues.sampleData}
                    // handleChange={handleChange}
                    placeholder="Enter sample data..."
                  />
                </div>
                <div className="" style={{ width: "40%", marginRight: "12px", marginBottom: 10 }}>
                  <label htmlFor="attachment">Attachments</label>
                  <Dropzone  loading={false} />
                  {/* <Dropzone onDrop={(files) => onDrop(files)} loading={false} /> */}
                  <div
                    className="attachmentContainer"
                    style={{ display: "flex", overflowX: "auto", position: "relative" }}
                  >
                    {imageArray.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          margin: 8,
                          background: "#f9f9f9",
                          padding: 4,
                          borderRadius: 4,
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            image instanceof File
                              ? URL.createObjectURL(image)
                              : image.url // Use Base64 directly if not a File
                          }
                          style={{ height: 80, width: 80 }}
                          alt="attachment"
                          onClick={() =>
                            window.open(
                              image instanceof File ? URL.createObjectURL(image) : image.url,
                              "_blank"
                            )
                          }
                        />
                        {/* <DeleteIcon
                          style={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => removeImage(index)}
                        /> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <span className="textRight" style={{marginRight: '20%', }}>
                  <button className="btn add-btn mx-2" type="submit" disabled={loading}>
                    {id ? "Update" : "Submit"}
                  </button>
                  <button className="btn add-btn" disabled={loading} onClick={() => {
                    setId("")
                  }}>
                    {"Cancel"}
                  </button>
                </span>               */}

              <div className="d-flex">
                <div className="" style={{ width: "40%", marginRight: "12px" }}>
                  <FormRowSelect
                    labelText="Status"
                    name="status"
                    value={formValues.status}
                    handleChange={handleChange}
                    list={["Pending", "Pass", "Fail"]}
                    disabled={!isEditing}
                  />
                </div>
                {/* <div className="" style={{ width: "40%", marginRight: "12px" }}>
                 
                </div> */}
              </div>
              <div className="d-flex">
                <div className="" style={{ width: "40%", marginRight: "12px" }}>

                  <FormTextArea
                    labelText="Remarks"
                    name="remarks"
                    value={formValues.remarks}
                    handleChange={handleChange}
                    placeholder="Enter remarks..."
                  />
                </div>
                <div className="" style={{ width: "40%", marginRight: "12px", marginBottom: 10 }}>
                  <label htmlFor="attachment2">Attachments</label>
                  <Dropzone onDrop={(files) => onDropRemark(files)} loading={false} />
                  <div
                    className="attachmentContainer"
                    style={{ display: "flex", overflowX: "auto", position: "relative" }}
                  >
                    {imageArray2.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          margin: 8,
                          background: "#f9f9f9",
                          padding: 4,
                          borderRadius: 4,
                          position: "relative",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(image)}
                          style={{ height: 80, width: 80 }}
                          alt="attachment2"
                          onClick={() => window.open(URL.createObjectURL(image), "_blank")}
                        />
                        {/* <DeleteIcon
                          style={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => removeImageRemark(index)}
                        /> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="d-flex" style={{ textAlign: "right" }}> */}
              <span className="textRight">
                <button className="btn add-btn mx-2" type="submit" disabled={loading}>
                  {id ? "Update" : "Submit"}
                </button>
                <button className="btn add-btn" disabled={loading} onClick={() => {
                  setId("")
                  handleBack()
                }}>
                  {"Cancel"}
                </button>
              </span>
              {/* </div> */}
            </div>
          </form>
        </div>
      </TestCaseWrapper>
    </div>
  );
};

export default EditTestCaseDetails;
