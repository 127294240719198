import * as XLSX from "xlsx"

const convertToXlsx = async (data, name) => {
  const { projects, weeklyLogs } = data
  let initialArray = [["", "Name", "Task Name", "Time Spent"]]

  await projects.map((p) => {
    initialArray.push(["", "", ""])
    const temp = `Project:${p.title}`
    initialArray.push([temp, "", " "])
    weeklyLogs.map((log) => {
      if (log.p[0].title === p.title) {
        const name = log.name
        const task = log.result[0].title
        const tileSpent = log.timeSpent / 60
        initialArray.push(["", name, task, tileSpent])
      }
    })
  })

  console.log(initialArray)
  const worksheet = XLSX.utils.aoa_to_sheet(initialArray)

  const indexes = findIndicesOfArrays(initialArray)
  console.log(indexes)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const xlsxBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  })
  const data1 = new Blob([xlsxBuffer], { type: "application/octet-stream" })
  const url = window.URL.createObjectURL(data1)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${name}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
function findIndicesOfArrays(array) {
  return array.reduce((indices, currentArray, index) => {
    if (currentArray.length === 1) {
      indices.push(index)
    }
    return indices
  }, [])
}

export default convertToXlsx
