export const GET_PERMISSION_BY_ROLE_BEGIN = "GET_PERMISSION_BY_ROLE_BEGIN"
export const GET_PERMISSION_BY_ROLE_SUCCESS = "GET_PERMISSION_BY_ROLE_SUCCESS"
export const GET_PERMISSION_BY_ROLE_ERROR = "GET_PERMISSION_BY_ROLE_ERROR"

export const UPDATE_PERMISSION_ACTION_BEGIN = "UPDATE_PERMISSION_ACTION_BEGIN"
export const UPDATE_PERMISSION_ACTION_SUCCESS =
  "UPDATE_PERMISSION_ACTION_SUCCESS"
export const UPDATE_PERMISSION_ACTION_ERROR = "UPDATE_PERMISSION_ACTION_ERROR"

export const GET_ALL_ROLE_BEGIN = "GET_ALL_ROLE_BEGIN"
export const GET_ALL_ROLE_SUCCESS = "GET_ALL_ROLE_SUCCESS"
export const GET_ALL_ROLE_ERROR = "GET_ALL_ROLE_ERROR"

export const UPDATE_ROLE_BEGIN = "UPDATE_ROLE_BEGIN"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR"

export const UPDATE_ROLES_BEGIN = "UPDATE_ROLES_BEGIN"
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS"
export const UPDATE_ROLES_ERROR = "UPDATE_ROLES_ERROR"
