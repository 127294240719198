import {
  OPEN_WORKLOG_MODAL,
  CLOSE_WORKLOG_MODAL,
  CREATE_WORKLOG_BEGIN,
  CREATE_WORKLOG_SUCCESS,
  CREATE_WORKLOG_ERROR,
  GET_ALL_WORKLOG_TASK_BEGIN,
  GET_ALL_WORKLOG_TASK_SUCCESS,
  GET_SINGLE_WORKLOG_BEGIN,
  GET_SINGLE_WORKLOG_SUCCESS,
  GET_SINGLE_WORKLOG_ERROR,
  SET_EDITING_WORKLOG_BEGIN,
  SET_EDITING_WORKLOG_SUCCESS,
  SET_EDITING_WORKLOG_ERROR,
  UPDATE_WORKLOG_BEGIN,
  UPDATE_WORKLOG_SUCCESS,
  UPDATE_WORKLOG_ERROR,
  DELETE_WORKLOG_BEGIN,
  DELETE_WORKLOG_SUCCESS,
  DELETE_WORKLOG_ERROR,
  GET_ALL_WORKLOG_BEGIN,
  GET_ALL_WORKLOG_SUCCESS,
  GET_ALL_WORKLOG_ERROR,
  GET_DAILY_LOGS_BEGIN,
  GET_DAILY_LOGS_SUCCESS,
  GET_DAILY_LOGS_ERROR,
  GET_WEEKLY_LOGS_BEGIN,
  GET_WEEKLY_LOGS_SUCCESS,
  GET_ALL_WORKLOG_USER_BEGIN,
  GET_ALL_WORKLOG_USER_SUCCESS,
  GET_ALL_WORKLOG_USER_ERROR,
  GET_ATTENDANCE_REPORT,
} from "./worklogTypes"
import { toast } from "react-toastify"
import authFetch from "../../utils/interceptor"

export const openWorklogModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_WORKLOG_MODAL })
  }
}
export const closeWorklogModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_WORKLOG_MODAL })
  }
}

export const createWorklog = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_WORKLOG_BEGIN })

    try {
      const response = await authFetch.post("/worklog", values)

      toast.success("Worklog submitted")
      dispatch({
        type: CREATE_WORKLOG_SUCCESS,
        payload: { worklog: response.data.worklog },
      })
    } catch (error) {
      dispatch({ type: CREATE_WORKLOG_ERROR })
      toast.error("error crating worklog try later")
    }
  }
}

export const getWorklogTask = (values) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_WORKLOG_TASK_BEGIN })
    try {
      const response = await authFetch.get(`/worklog/task/${values}`)
      dispatch({
        type: GET_ALL_WORKLOG_TASK_SUCCESS,
        payload: { worklogs: response.data.worklogs },
      })
    } catch (error) {
      toast.error("Error getting work logs")
      // !why is here success
      dispatch({
        type: GET_ALL_WORKLOG_TASK_SUCCESS,
      })
    }
  }
}

export const getSingleWorklog = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_SINGLE_WORKLOG_BEGIN })
    try {
      const response = await authFetch.get(`/worklog/${id}`)
      dispatch({
        type: GET_SINGLE_WORKLOG_SUCCESS,
        payload: { worklog: response.data },
      })
    } catch (error) {
      dispatch({ type: GET_SINGLE_WORKLOG_ERROR })
    }
  }
}

export const setEditingWorklog = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_EDITING_WORKLOG_BEGIN })
    try {
      const response = await authFetch.get(`/worklog/${id}`)

      dispatch({
        type: SET_EDITING_WORKLOG_SUCCESS,
        payload: { worklog: response.data.log },
      })
    } catch (error) {
      dispatch({ type: SET_EDITING_WORKLOG_ERROR })
      toast.error("error editing worklog try later")
    }
  }
}

export const updateWorklog = (values) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_WORKLOG_BEGIN })
    try {
      const response = await authFetch.patch(`/worklog/${values._id}`, values)
      // console.log("response in update", response)
      dispatch({
        type: UPDATE_WORKLOG_SUCCESS,
        payload: { worklog: response.data.updatedWorklog },
      })
      toast.success("Work log updated successfully")
    } catch (error) {
      dispatch({ type: UPDATE_WORKLOG_ERROR })
      toast.error("Error updating work log try again later")
    }
  }
}

export const deleteWorklog = (values) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_WORKLOG_BEGIN })
    try {
      const response = await authFetch.delete(`/worklog/${values}`)

      toast.success("Worklog deleted successfully")
      dispatch({
        type: DELETE_WORKLOG_SUCCESS,
        payload: { id: response.data.deletedWorklog._id },
      })
    } catch (error) {
      dispatch({ type: DELETE_WORKLOG_ERROR })
    }
  }
}

export const getAllWorklogs = (values) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_WORKLOG_BEGIN })
    try {
      if (!values) {
        const response = await authFetch.get("/worklog")

        dispatch({
          type: GET_ALL_WORKLOG_SUCCESS,
          payload: { logs: response.data.worklogs },
        })
      } else {
        const { filters } = values

        const url = `/worklog?project=${filters.projectId}&task=${filters.taskId}&startDate=${filters.startDate}&toDate=${filters.toDate}&userId=${filters.userId}`

        const response = await authFetch.get(url)

        dispatch({
          type: GET_ALL_WORKLOG_SUCCESS,
          payload: { logs: response.data.worklogs },
        })
      }
    } catch (error) {
      dispatch({ type: GET_ALL_WORKLOG_ERROR })
      toast.error("Error fetching worklogs")
    }
  }
}

export const getDailyLogs = (values) => {
  return async (dispatch) => {
    dispatch({ type: GET_DAILY_LOGS_BEGIN })
    try {
      if (!values) {
        const response = await authFetch.get("/worklog/daily")

        dispatch({
          type: GET_DAILY_LOGS_SUCCESS,
          payload: {
            users: response.data.users,
            worklogs: response.data.worklogs,
          },
        })

        return response.data
      } else {
        //  console.log(values)
        const { filters } = values

        const url = `/worklog/daily?project=${filters.projectId}&task=${filters.taskId}&startDate=${filters.startDate}&toDate=${filters.toDate}&userId=${filters.userId}`

        const response = await authFetch.get(url)

        dispatch({
          type: GET_DAILY_LOGS_SUCCESS,
          payload: {
            users: response.data.users,
            worklogs: response.data.worklogs,
          },
        })
      }
    } catch (error) {
      dispatch({ type: GET_DAILY_LOGS_ERROR })
      toast.error("Error fetching worklogs")
    }
  }
}

export const getWeeklyLogs = (values) => {
  return async (dispatch) => {
    dispatch({ type: GET_WEEKLY_LOGS_BEGIN })
    try {
      if (!values) {
        const response = await authFetch.get("/worklog/weekly")
        // console.log(response)
        dispatch({
          type: GET_WEEKLY_LOGS_SUCCESS,
          payload: {
            users: response.data.users,
            worklogs: response.data.worklogs,
            projects: response.data.projects,
          },
        })

        return response.data
      } else {
        //console.log(values)
        const { filters } = values

        const url = `/worklog/weekly?project=${filters.projectId}&task=${filters.taskId}&startDate=${filters.startDate}&toDate=${filters.toDate}&userId=${filters.userId}`

        const response = await authFetch.get(url)

        dispatch({
          type: GET_WEEKLY_LOGS_SUCCESS,
          payload: {
            users: response.data.users,
            worklogs: response.data.worklogs,
            projects: response.data.projects,
          },
        })
      }
    } catch (error) {
      //  console.log(error)
      toast.error("Error fetching worklogs")
    }
  }
}

export const getWorklogUser = ({ id, filters }) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_WORKLOG_USER_BEGIN })
    try {
      if (!filters) {
        const response = await authFetch.post("/worklog/user", { userId: id })
        dispatch({
          type: GET_ALL_WORKLOG_USER_SUCCESS,
          payload: { logs: response.data.worklogs },
        })
        return response.data.worklogs
      } else {
        const url = `/worklog/user?project=${filters.projectId}&task=${filters.taskId}&startDate=${filters.startDate}&toDate=${filters.toDate}`
        const response = await authFetch.post(url, { userId: id })
        dispatch({
          type: GET_ALL_WORKLOG_USER_SUCCESS,
          payload: { logs: response.data.worklogs },
        })
        return response.data.worklogs
      }
    } catch (error) {
      toast.error("Error getting logs")
      dispatch({ type: GET_ALL_WORKLOG_USER_ERROR })
    }
  }
}

export const dailyWorklog = (userId) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.post("/worklog/daily/user", { userId })

      return response.data.worklogs
    } catch (error) {
      toast.error("Cannot get daily log")
    }
  }
}

export const weeklyWorklog = (userId) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.post("/worklog/weekly/user", { userId })

      return response.data.worklogs
    } catch (error) {
      toast.error("Cannot get daily log")
    }
  }
}

export const attendanceReport = (values, userId) => {
  return async (dispatch) => {
    try {
      let response
      if (userId) {
        response = await authFetch.get(
          `/worklog/attendance?monthyear=${values}&userId=${userId}`
        )
      } else {
        response = await authFetch.get(
          `/worklog/attendance?monthyear=${values}`
        )
      }

      dispatch({
        type: GET_ATTENDANCE_REPORT,
        payload: { workReport: response.data },
      })

      return response.data
    } catch (error) {
      toast.error("Cannot get daily log")
    }
  }
}
