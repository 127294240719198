import React, { useEffect } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import {
  getAllQuestion,
  setEditSubjective,
  deleteSingleQuestion,
} from "../../redux"
import ApiIcon from "@mui/icons-material/Api"
import { capitalizeFirstLetter } from "../../utils/getInitialName"
import { DeleteIcon, EditNoteOutlinedIcon } from "../../assets/icons"
import QuestionFilter from "./QuestionFilter"

import { DriveFileRenameOutlineSharpIcon } from "../../assets/icons"
const Subjective = () => {
  const dispatch = useDispatch()
  const { subjective } = useSelector((state) => state.feedback)

  if (subjective.length < 1) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2 lg:tw-px-8  tw-px-3 ">
        <span className="tw-bg-gray-100  tw-px-[5px] tw-py-3 tw-italic">  No questions to display add one by clicking on add question</span>
        <QuestionFilter subjective={true} />
      </div>
    )
  }
  return (
    <Wrapper>
      <QuestionFilter subjective={true} />
      {subjective.length > 0 &&
        subjective.map((i) => {
          return (
            <div>
              <p>
                <ApiIcon fontSize="15px" style={{ marginRight: "10px" }} />
                {capitalizeFirstLetter(i.question)}

                <DriveFileRenameOutlineSharpIcon
                  className="edit"
                  onClick={() => dispatch(setEditSubjective(i._id))}
                />

                <DeleteIcon
                  className="del"
                  onClick={() => dispatch(deleteSingleQuestion(i._id))}
                />
              </p>
            </div>
          )
        })}
      <section></section>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  p {
    font-size: 14px;
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 5px;
    padding: 11px 10px;
    box-shadow: var(--shadow-1);
  }

  .edit {
    margin-left: 20px;
    color: var(--blue);
    font-size: 18px;
  }

  .del {
    margin-left: 10px;
    color: rgb(203, 48, 69);
    font-size: 18px;
  }
`
export default Subjective
