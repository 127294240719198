import {
  CREATE_TEMPLATE_BEGIN,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_ERROR,
  GET_ALL_TEMPLATES_BEGIN,
  GET_ALL_TEMPLATES_SUCCESS,
  GET_ALL_TEMPLATES_ERROR,
  GET_TEMPLATE_BEGIN,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_ERROR,
  EDIT_TEMPLATE_BEGIN,
  EDIT_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_ERROR,
  DELETE_TEMPLATE_BEGIN,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_ERROR,
  OPEN_TEMPLATE_MODAL,
  CLOSE_TEMPLATE_MODAL,
  SET_EDIT_TEMPLATE,
  EDIT_MODAL_OPEN,
  EDIT_MODAL_CLOSE,
} from "./testcaseTypes";

const initialState = {
  templates: [],
  loading: false,
  error: null,
  editingTemplate: null,
  isEditingTemplate: false,
  selectedTemplate: null,
  isTemplateModalOpen: false,
  editTemplateModalOpen: false,
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_TEMPLATE_MODAL:
      return { ...state, isTemplateModalOpen: false };
    case OPEN_TEMPLATE_MODAL:
      return { ...state, isTemplateModalOpen: true };
    case GET_ALL_TEMPLATES_BEGIN:
      return { ...state, loading: true, error: null };
    case GET_TEMPLATE_BEGIN:
      return { ...state, loading: true, error: null };
    case CREATE_TEMPLATE_BEGIN:
      return { ...state, loading: true, error: null };
    case EDIT_TEMPLATE_BEGIN:
      return { ...state, loading: true, error: null };
    case DELETE_TEMPLATE_BEGIN:
      return { ...state, loading: true, error: null };
    case GET_ALL_TEMPLATES_SUCCESS:
      return { ...state, loading: false, templates: action.payload };
    case GET_TEMPLATE_SUCCESS:
      return { ...state, loading: false, selectedTemplate: action.payload };
    case CREATE_TEMPLATE_SUCCESS:
      return { ...state, loading: false, isTemplateModalOpen: false };
    case EDIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        editTemplateModalOpen: false,
        templates: state.templates.map((template) =>
          template._id === action.payload._id ? action.payload : template
        ),
      };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: state.templates.filter((template) => template._id !== action.payload.id),
      };
    case GET_ALL_TEMPLATES_ERROR:
    case GET_TEMPLATE_ERROR:
    case CREATE_TEMPLATE_ERROR:
    case EDIT_TEMPLATE_ERROR:
    case DELETE_TEMPLATE_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case SET_EDIT_TEMPLATE:
      const editingTemplate = state.templates.find(
        (template) => template._id === action.payload.id
      );
      return {
        ...state,
        editingTemplate: editingTemplate,
        isEditingTemplate: true,
      };
    case EDIT_MODAL_OPEN:
      return { ...state, editTemplateModalOpen: true };
    case EDIT_MODAL_CLOSE:
      return {
        ...state,
        editTemplateModalOpen: false,
        editingTemplate: null,
        isEditingTemplate: false,
      };
    default:
      return state;
  }
};

export default templateReducer;
