import React, { useState, useEffect } from "react"
import { useAppContext } from "../../context/context/appContext"
import Users from "./AddUser"
import { useDispatch, useSelector } from "react-redux"
import {
  openAddUserToClientModal,
  closeUserListModal,
  getClientUsers,
  setEditingClientUser,
} from "../../redux"
import styled from "styled-components"
import Modal from "react-bootstrap/Modal"
import { FiberManualRecordRoundedIcon } from "../../assets/icons"

const UserManagement = () => {
  const dispatch = useDispatch()

  const [actionOpen, setActionOpen] = useState(false)
  const [id, setId] = useState()
  const { socket } = useAppContext()

  const { isUserListModalOpen, clientUsers, client } = useSelector(
    (state) => state.client
  )
  const [isActive, setIsActive] = useState(false);


  useEffect(() => {
    client && dispatch(getClientUsers(client._id, isActive))
  }, [isUserListModalOpen, isActive])

  useEffect(() => {
    socket.on("newClientUser", (data) => {
      // if (data.updatedFields.clientId.includes(clientId)) {
      // getUsersByClient(clientId).then((data) => setUserList(data))
      // }
    })
    socket.on("newUser", (data) => {
      // getUsersByClient(clientId).then((data) => setUserList(data))
    })
    socket.on("editByAdmin", (data) => {
      // getUsersByClient(clientId).then((data) => setUserList(data))
    })
  }, [socket])

  const handleIsActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const handleAction = (e, value) => {
    setId(value)
    setActionOpen(!actionOpen)
  }

  return (
    <Wrapper>
      <Modal
        size="lg"
        show={isUserListModalOpen}
        onHide={() => dispatch(closeUserListModal())}
        dialogClassName="modal-60w"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>client Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Wrapper>
            <div>
              <div className="d-flex align-items-center  gap-4 lg:tw-px-8">
                <div className="">
                  <button
                    className="btn add-btn tw-self-center"
                    type="submit"
                    onClick={() => dispatch(openAddUserToClientModal())}
                  >
                    + Add New User
                  </button>
                </div>
                <div className="d-flex gap-2" style={{paddingBottom: 15}}>
                  <input
                    type='checkbox'
                    value={isActive}
                    name={'isActive'}
                    checked={isActive}  // Set checked to false, so checkbox is not checked by default
                    onChange={handleIsActiveChange}
                    className="tw-w-6"
                  />
                  <label htmlFor={'isActive'} className="tw-w-full" style={{ fontSize: 14, }}>
                    {"Include Inactive"}
                  </label>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-sm bg-white">
                  <thead className=" ">
                    <tr>
                      <th>User</th>

                      <th className="textLeft">Address</th>
                      <th>Contact Number</th>
                      <th>Active</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientUsers.length > 0 ? (
                      clientUsers.map((user, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="d-flex">
                                <span className="main-icon">
                                  <img src="https://secure.gravatar.com/avatar/28859fdf97e611b1434dec99bdb5554d?s=48&d=identicon" />
                                </span>
                                <div className="flexColumn">
                                  <span className="textC">{user.name}</span>
                                  <span className="lowerCase f-11">
                                    {user.email}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="upperCase textLeft">
                              {user.address}
                            </td>
                            <td>{user.contact_number}</td>
                            <td>
                          <span
                            className={user.is_active ? "actives" : "status"}
                          >
                            <FiberManualRecordRoundedIcon
                              style={{
                                color: user.is_active
                                  ? "rgb(79, 190, 121)"
                                  : "var(--grey-400)",
                              }}
                            />
                          </span>
                        </td>

                            <td className="textCenter">
                              <div className="dropdown">
                                <p onClick={(e) => handleAction(e, user._id)}>
                                  ...
                                </p>

                                {id === user._id && actionOpen ? (
                                  <div
                                    className="dropdown-menu"
                                    style={{
                                      display: "block",
                                      right: "25px",
                                      marginTop: "-13px",
                                    }}
                                    aria-labelledby="dropdownMenu2"
                                  >
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() =>{
                                        dispatch(setEditingClientUser(user._id))
                                        setIsActive(false);
                                      }
                                    }
                                    >
                                      <i className="bi bi-pencil-square"></i>{" "}
                                      Edit
                                    </button>
                                    {/* <button
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        <i className="bi bi-trash3-fill"></i>{" "}
                                        Delete
                                      </button> */}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Wrapper>
        </Modal.Body>
      </Modal>
      <Users />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .modal-content {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw !important;
  }
  .add-btn {
    margin-bottom: 20px;
  }

  .responsiveTable td .tdBefore {
    font-size: 14px;
  }

  .main-icon {
    width: 25px;
    height: 25px;
    display: grid;
    place-items: center;
    background: var(--primary-500);
    border-radius: var(--borderRadius);
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    margin-right: 1rem;
    margin-top: 8px;
  }
  .main-icon img {
    width: 25px;
    height: 25px;
  }
  h4 {
    padding: 18px 40px;
    text-align: center;
    cursor: pointer;
    background-color: var(--grey-50);
    border-bottom: 1px solid #d6dfde;
    border-radius: 5px;
    font-weight: 400 !important;
    svg {
      position: absolute;
      right: 50px;
    }
  }
  .modal-content {
    border-radius: var(--borderRadius);
  }

  .react-switch-bg {
    height: 22px !important;
    width: 52px !important;
  }

  .react-switch-handle {
    height: 20px !important;
    width: 20px !important;
  }

  tr td:first-child {
    text-align: left;
    font-size: 12px;
    display: table-cell;
  }
  p {
    margin: 0;
    text-align: center !important;
  }

  @media (max-width: 1120px) {
    .responsiveTable tbody tr {
      margin-bottom: 10px;
      border: 1px solid #dce8eb;
    }
  }
`

export default UserManagement
