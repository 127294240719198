import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import { FormRow, Dropzone, Loading } from "../../Common";
import FormTextArea from "../../Common/FormTextArea";
import {
  createTestItem,
  deleteTestItemAttachment,
  editTestItem,
  getAllTestItems,
  getTestItem,
} from "../../../redux";
import { DeleteIcon } from "../../../assets/icons";

const TestItemModal = ({ isEditModalOpen, setIsEditModalOpen, id, setId, templateId }) => {
  const dispatch = useDispatch();
  const { selectedTestItem, loading } = useSelector((state) => state.testItems);
  const [image, setImage] = useState(null)
  const [hoveredIndex, setHoveredIndex] = useState(null);



  const [values, setValues] = useState({
    title: "",
    description: "",
    steps: "",
    sampleData: "",
    attachment: "",
  });

  const [imageArray, setImageArray] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getTestItem(templateId, id));
    } else {
      setValues({
        title: "",
        description: "",
        steps: "",
        sampleData: "",
        attachment: "",
      });
      setImageArray([]);
      setId("");
    }
  }, [id, templateId, dispatch]);

  useEffect(() => {
    if (selectedTestItem && id) {
      setValues({
        title: selectedTestItem?.testItemWithAttachments?.title,
        description: selectedTestItem?.testItemWithAttachments?.description,
        steps: selectedTestItem?.testItemWithAttachments?.steps,
        sampleData: selectedTestItem?.testItemWithAttachments?.sampleData,
        attachment: selectedTestItem?.testItemWithAttachments?.attachments,
      });
      // Populate imageArray with URLs of existing attachments
      if (selectedTestItem?.testItemWithAttachments?.attachments) {
        const attachmentURLs = selectedTestItem?.testItemWithAttachments?.attachments?.map((attachment) => {
          // Convert base64 string to a Uint8Array
          const buffer = Uint8Array.from(atob(attachment.content), (c) => c.charCodeAt(0));
          const blob = new Blob([buffer], { type: attachment.contentType });  // Create a Blob
          const imageUrl = URL.createObjectURL(blob);  // Generate an object URL for the Blob
          return { key: attachment.key, filename: attachment.filename, url: imageUrl };
        });
        setImageArray(attachmentURLs);
      }
    }
  }, [selectedTestItem]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Add other form fields
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("steps", values.steps);
    formData.append("sampleData", values.sampleData);

    // Add files to formData
    imageArray.forEach((file, index) => {
      formData.append(`attachment`, file); // This adds each file individually
    });

    if (id) {
      dispatch(editTestItem(templateId, id, formData));
    } else {
      // console.log("Before submit formData: ", formData)
      dispatch(createTestItem(templateId, formData));
    }

    setValues({
      title: "",
      description: "",
      steps: "",
      sampleData: "",
      attachment: "",
    });
    setId("");
    setImageArray([]);
    setIsEditModalOpen(false)
  };


  const onDrop = async (acceptedFiles) => {
    for (let index = 0; index < acceptedFiles.length; index++) {
      setImageArray([...imageArray, acceptedFiles[index]])
    }
  }

  const handleRemoveAttachment = (index, attachmentKey) => {

    if (!attachmentKey) {
      const updatedArray = imageArray.filter((_, indx) => indx !== index);
      setImageArray(updatedArray);
    } else {
      const parts = attachmentKey.split('/');
      const keyId = parts[1];
      // Find the index of the attachment to remove
      const attachmentIndex = imageArray.findIndex((attachment) => attachment.key === attachmentKey);

      if (attachmentIndex !== -1) {
        // Update the local state to remove the attachment from the UI
        const updatedArray = imageArray.filter((_, i) => i !== attachmentIndex);
        setImageArray(updatedArray);

        // Dispatch the delete action to remove the attachment from the backend
        dispatch(deleteTestItemAttachment(templateId, id, keyId));
      }
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <Modal
      show={isEditModalOpen}
      onHide={() => {
        setIsEditModalOpen(false)
        setId("");
        setValues({
          title: "",
          description: "",
          steps: "",
          sampleData: "",
          attachment: "",
        });
        setImageArray([])
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading ? <Loading /> : <></>}

      <Modal.Header closeButton>
        <Modal.Title>{id ? "Edit Test Item" : "Create Test Item"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <FormRow
            name="title"
            type="text"
            labelText="Title"
            value={values.title}
            handleChange={handleChange}
            required={true}
            placeholder="Enter Title"
          />
          <FormTextArea
            name="description"
            labelText="Description"
            value={values.description}
            handleChange={handleChange}
            required={true}
            placeholder="Enter Description"
          />
          <FormTextArea
            name="steps"
            labelText="Steps"
            value={values.steps}
            handleChange={handleChange}
            placeholder="Enter Steps"
          />
          <FormTextArea
            name="sampleData"
            labelText="Sample Data"
            value={values.sampleData}
            handleChange={handleChange}
            placeholder="Enter Sample Data"
          />

          <div className="mt-2 w-100">
            <label htmlFor="attachment">Attachments</label>
            <Dropzone
              onDrop={(files) => onDrop(files)}
              loading={false}
            />
            <div className="" style={{ display: 'flex', overflowX: 'auto', position: "relative", }}
            >
              {imageArray.map((image, index) => (
                <div
                  key={index}
                  className=""
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: "center",
                    margin: 8,
                    background: "#EBF3F9",
                    borderRadius: 4,
                    position: "relative",
                    transition: "all 0.3s ease"
                  }}
                >
                  <span
                    className="tw-cursor-pointer "
                    onClick={() =>
                      window.open(
                        image instanceof File ? URL.createObjectURL(image) : image.url,
                        "_blank"
                      )
                    }
                    style={{ background: '#EBF3F9', marginLeft: 8, marginRight: 8 }}
                  >
                    {
                      image instanceof File
                        ? image.name
                        : image.filename // Use Base64 directly if not a File
                    }
                  </span>
                  <DeleteIcon
                    style={{
                      position: "absolute",
                      right: 4,
                      color: "rgb(203, 48, 69)",
                      cursor: "pointer",
                      opacity: hoveredIndex === index ? 1 : 0,
                      transition: "opacity 0.3s ease",
                    }}
                    onClick={() => handleRemoveAttachment(index, image.key)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="textRight">
            <button className="btn add-btn" type="submit" disabled={loading}>
              {id ? "Update" : "Submit"}
            </button>
            <button
              className="btn add-btn"
              disabled={loading}
              onClick={() => {
                setIsEditModalOpen(false)
                setId("");
                setValues({
                  title: "",
                  description: "",
                  steps: "",
                  sampleData: "",
                  attachment: "",
                });
                setImageArray([]);
                setImage(null);
              }}
            >
              {"Cancel"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TestItemModal;