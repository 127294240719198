import React, { useState, useEffect, useRef } from "react"
import moment from "moment"
import { toast } from "react-toastify"
import Modal from "react-bootstrap/Modal"
import {
  FormRow,
  Loading2,
  ProfileAvatar,
  CustumTooltip,
  ProjectIcon,
} from "../Common"
import Notification from "./Notification"
import { useAppContext } from "../../context/context/appContext"
import { capitalizeFirstLetter } from "../../utils/getInitialName"
import { MemberProjectTitle, MemberWrapper } from "../../assets/styles/Project"
import {
  CloseOutlinedIcon,
  DriveFileRenameOutlineSharpIcon,
} from "../../assets/icons"
import { useSelector, useDispatch } from "react-redux"
import {
  editProject,
  closeEditModal,
  updateProjectPic,
  getAllEmails,
} from "../../redux"

const initialState = {
  title: "",
  description: "",
  logoUrl: "",
  _id: "",
}
const AddMembers = ({ project }) => {
  const { socket, addMemberToTeam } = useAppContext()
  const { selectedProject, editingProject, editProjectModalOpen } = useSelector(
    (state) => state.project
  )
  const dispatch = useDispatch()

  const [members, setMembers] = useState([])
  const [email, setEmail] = useState("")
  const [emailList, setEmailList] = useState([])
  const [openTitle, setOpenTitle] = useState(false)
  const [openDescription, setOpenDescription] = useState(false)
  const [values, setValues] = useState(initialState)

  useEffect(() => {
    setMembers(editingProject.team)
    dispatch(getAllEmails()).then((data) => {
      const list = data.map((c) => c.email)

      setEmailList(list)
    })
    setOpenDescription(false)
    setOpenTitle(false)
  }, [])

  useEffect(() => {
    socket.on("teamMember", async (data) => {
      setMembers(data)
    })
  }, [socket])

  function handleAdd(e) {
    e.preventDefault()
    if (!email) {
      toast.error("Enter email")
      return
    }
    // Check if the member is already added to the team
    const memberAlreadyAdded = members.some((member) => member.email === email)
    if (memberAlreadyAdded) {
      toast.warning("Member already added to the team")
      setEmail("")
      return
    } else {
      addMemberToTeam({ email, projectId: selectedProject._id })
      setEmail("")
    }
  }

  const handleRemove = (obj) => {
    const list = editingProject.team.filter((item) => item.email !== obj.email)
    console.log(list)
    const p = { ...editingProject, team: list }
    dispatch(editProject(p))
  }

  useEffect(() => {
    if (editingProject !== null) {
      setValues({
        ...initialState,
        title: editingProject.title,
        description: editingProject.description,
        logoUrl: editingProject.logoUrl,
        createdBy: editingProject.createdBy,
        _id: editingProject._id,
      })
    } else {
      setValues(initialState)
    }
  }, [editingProject])

  const handleValueChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    await dispatch(editProject(values))
    setOpenTitle(false)
    setOpenDescription(false)
  }

  const fileInputRef = useRef(null)

  const handleImageClick = () => {
    fileInputRef.current.click()
  }

  const handleFileInputChange = async (event) => {
    const selectedFile = event.target.files[0]
    const form = new FormData()
    form.append("id", selectedProject._id)
    form.append("files", selectedFile)
    await dispatch(updateProjectPic(form))
  }

  return (
    // users && (
    <div className="modalWidth">
      {editingProject && (
        <Modal
          size="lg"
          show={editProjectModalOpen}
          onHide={() => dispatch(closeEditModal())}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <MemberProjectTitle>
                <p className="tw-flex tw-items-center">
                  <div className="img-bx" onClick={handleImageClick}>
                    <ProjectIcon size="s" id={editingProject._id} />
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      style={{ display: "none" }}
                    />
                  </div>

                  <span className="title"> {editingProject.title}</span>
                </p>
              </MemberProjectTitle>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MemberWrapper>
              <div className="row  ">
                <div className="col-md-12 col-xs-12">
                  <div className="displayDate">
                    <p>
                      Created At :{" "}
                      {moment(project.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </div>

                  <div className="">
                    {editingProject && (
                      <div>
                        <h5>
                          Title{" "}
                          {!openTitle && (
                            <CustumTooltip
                              text={"Edit title of project"}
                              children={
                                <DriveFileRenameOutlineSharpIcon
                                  onClick={() => setOpenTitle(true)}
                                />
                              }
                              position={"right"}
                              id={`title-tooltip`}
                            />
                          )}{" "}
                        </h5>

                        {openTitle ? (
                          <div className="description">
                            <FormRow
                              type="text"
                              labelText=""
                              name="title"
                              value={values.title}
                              handleChange={handleValueChange}
                              placeholder="Enter Title"
                            />
                            <div>
                              <button
                                type="cancel"
                                className="btn-blockn"
                                onClick={() => setOpenTitle(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn-blockn"
                                onClick={handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                            <br />
                          </div>
                        ) : (
                          <p className="ptitle">
                            {editingProject.title
                              ? capitalizeFirstLetter(editingProject.title)
                              : " Data is not available/No data "}
                          </p>
                        )}
                        <h5>
                          Description{" "}
                          {!openDescription && (
                            <>
                              <CustumTooltip
                                text={"Edit description of project"}
                                children={
                                  <DriveFileRenameOutlineSharpIcon
                                    onClick={() => setOpenDescription(true)}
                                  />
                                }
                                position={"right"}
                                id={`des-tooltip`}
                              />
                              {/* <Tooltip
                                id="my-tooltip"
                                place="bottom"
                                className="toolttp"
                              /> */}
                            </>
                          )}
                        </h5>
                        {openDescription ? (
                          <div className="description">
                            <FormRow
                              type="text"
                              labelText={""}
                              name="description"
                              value={values.description}
                              handleChange={handleValueChange}
                            />
                            <div>
                              <button
                                type="cancel"
                                className="btn-blockn"
                                onClick={() => setOpenDescription(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn-blockn"
                                onClick={handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        ) : (
                          <p className="ptitle">
                            {editingProject.description
                              ? capitalizeFirstLetter(
                                  editingProject.description
                                )
                              : " Data is not available/No data "}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 col-xs-12">
                  <hr />
                  <h5>Team Members</h5>

                  <form className="add-member">
                    <input
                      list="emailList"
                      placeholder="add member here..."
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      autoComplete="off"
                    />

                    <datalist id="emailList">
                      {emailList.map((item) => {
                        return <option value={item} />
                      })}
                    </datalist>

                    <button type="button" className="temp" onClick={handleAdd}>
                      Add
                    </button>
                  </form>
                  <div className="team-list">
                    {members && members.length === 0
                      ? "no members assigned"
                      : members.map((u, i) => {
                          return (
                            <h6 key={i}>
                              <p>
                                <ProfileAvatar id={u.id} size="s" />
                                <p
                                  className="d-flex"
                                  style={{ flexDirection: "column" }}
                                >
                                  <span className="emll">{u.email}</span>
                                </p>
                              </p>
                              <p className="delete">
                                <CloseOutlinedIcon
                                  className="bi bi-x"
                                  onClick={() => handleRemove(u)}
                                />
                              </p>
                            </h6>
                          )
                        })}
                  </div>
                </div>
                <Notification list={members} project={project} />
              </div>
            </MemberWrapper>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
  // )
}

export default AddMembers
