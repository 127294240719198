import authFetch from "../../utils/interceptor"
import { toast } from "react-toastify"
import { CLOSE_TEMPLATE_MODAL, CREATE_TEMPLATE, CREATE_TEMPLATE_BEGIN, CREATE_TEMPLATE_ERROR, CREATE_TEMPLATE_SUCCESS, DELETE_TEMPLATE_BEGIN, DELETE_TEMPLATE_ERROR, DELETE_TEMPLATE_SUCCESS, EDIT_TEMPLATE_BEGIN, EDIT_TEMPLATE_ERROR, EDIT_TEMPLATE_SUCCESS, GET_ALL_TEMPLATES_BEGIN, GET_ALL_TEMPLATES_ERROR, GET_ALL_TEMPLATES_SUCCESS, GET_TEMPLATE_BEGIN, GET_TEMPLATE_ERROR, GET_TEMPLATE_SUCCESS, OPEN_TEMPLATE_MODAL } from "./testcaseTypes";

export const openTemplateModal = () => ({
    type: OPEN_TEMPLATE_MODAL,
  });
  
  export const closeTemplateModal = () => ({
    type: CLOSE_TEMPLATE_MODAL,
  });
  
  // export const createTemplate = (template) => ({
  //   type: CREATE_TEMPLATE,
  //   payload: template,
  // });

  // Create Template
export const createTemplate = (data) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_TEMPLATE_BEGIN });
    try {
      const res = await authFetch.post('/testTemplate', data);
      dispatch({ type: CREATE_TEMPLATE_SUCCESS, payload: res.data });
      getAllTemplates()
      toast.success('Template created successfully');
    } catch (error) {
      dispatch({ type: CREATE_TEMPLATE_ERROR, payload: error.message });
      toast.error('Error creating template');
    }
  };
};

// Get All Templates
export const getAllTemplates = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_TEMPLATES_BEGIN });
    try {
      const res = await authFetch.get('/testTemplate');
      dispatch({ type: GET_ALL_TEMPLATES_SUCCESS, payload: res.data?.data });
    } catch (error) {
      dispatch({ type: GET_ALL_TEMPLATES_ERROR, payload: error.message });
    }
  };
};

// Get Template by ID
export const getTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_TEMPLATE_BEGIN });
    try {
      const res = await authFetch.get(`/testTemplate/${id}`);
      dispatch({ type: GET_TEMPLATE_SUCCESS, payload: res.data?.data });
    } catch (error) {
      dispatch({ type: GET_TEMPLATE_ERROR, payload: error.message });
    }
  };
};

// Edit Template
export const editTemplate = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_TEMPLATE_BEGIN });
    try {
      const res = await authFetch.patch(`/testTemplate/${id}`, data);
      dispatch({ type: EDIT_TEMPLATE_SUCCESS, payload: res.data?.data });
      getAllTemplates()
      toast.success('Template updated successfully');
    } catch (error) {
      dispatch({ type: EDIT_TEMPLATE_ERROR, payload: error.message });
      toast.error('Error updating template');
    }
  };
};

// Delete Template
export const deleteTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_TEMPLATE_BEGIN });
    try {
      await authFetch.delete(`/testTemplate/${id}`);
      dispatch({ type: DELETE_TEMPLATE_SUCCESS, payload: id });
      getAllTemplates()
      toast.success('Template deleted successfully');
    } catch (error) {
      dispatch({ type: DELETE_TEMPLATE_ERROR, payload: error.message });
      toast.error('Error deleting template');
    }
  };
};
  