import React, { useState, useEffect, useRef } from "react"
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"
import {
  FormRow,
  CustumTooltip,
  ProfileAvatar,
  Loading2,
} from "../../components/Common"
import {
  TaskModal,
  WorklogModal,
  NewListModal,
} from "../../components/Projects"
import { GoalContainer, GoalModal } from "../../components/Goals"
import { useAppContext } from "../../context/context/appContext"
// import { useParams } from "react-router-dom"
import Badge from "react-bootstrap/Badge"
import Modal from "react-bootstrap/Modal"
// import { Button } from "bootstrap"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"
import { capitalizeFirstLetter, getInitials } from "../../utils/getInitialName"
import {
  getGoalsProject,
  getTasksByProject,
  setEditingTask,
  openTaskModal,
  createTask,
  handleDragEnd,
  updateTaskStatus,
  getTaskWithoutGoal,
} from "../../redux"
import { useSelector, useDispatch } from "react-redux"
import PagesIcon from "@mui/icons-material/Pages"
import ManageList from "../../components/Projects/ManageList"
import { BoardWrapper, SearchInside } from "../../assets/styles/Dashboard"
import {
  AddSharpIcon,
  ArchiveOutlinedIcon,
  FiberManualRecordRoundedIcon,
  PostAddOutlinedIcon,
  QueryBuilderIcon,
  SearchIcon,
} from "../../assets/icons"
import { MoreHorizRoundedIcon } from "../../assets/icons"
import CodeReviewModal from "../../components/Projects/CodeReviewModal"

const Board = () => {
  const containerRef = useRef(null)
  const [overflowXX, setOverflowXX] = useState("hidden")
  const { socket, user, getUserName } = useAppContext()
  const { selectedProject, projects } = useSelector((state) => state.project)
  const { tasks, loading, editingTask, isTaskModalOpen } = useSelector(
    (state) => state.task
  )
  const { isCodeReviewModalOpen,  } = useSelector(
    (state) => state.codeReview
  )
  const [task, setTask] = useState({
    title: "",
    category: "",
  })
  const navigate = useNavigate()
  const [openTask, setOpenTask] = useState(false)
  const [openTaskList, setOpenTaskList] = useState(false)
  const [count, setCount] = useState([])
  const [openPopupGoal, setOpenPonupGoal] = useState(false)
  const dispatch = useDispatch()
  const [openList, setOpenList] = useState(false)
  const [openManageList, setOpenManageList] = useState(false)
  const [filterValue, setFilterValue] = useState("")
  const [usersList, setUsersList] = useState([])

  useEffect(() => {
    if (selectedProject) {
      dispatch(getTasksByProject(selectedProject._id))
    }
    if (!selectedProject) {
      toast.warning("Please select project ")
      navigate("/")
    }
    getUserName().then((data) => setUsersList(data))
    selectedProject &&
      dispatch(getGoalsProject({ project: selectedProject._id }))
  }, [])

  useEffect(() => {
    if (tasks.length > 0) {
      let temp = tasks.map((item) => item.status)
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index
      }
      let unique = temp.filter(onlyUnique)
      Array.prototype.count = function (item) {
        let appearance = 0 //This is the default value
        this.forEach((index) => {
          if (index === item) appearance++
        })
        return appearance
      }
      const c = unique.map((item) => {
        return { count: temp.count(`${item}`), item: item }
      })
      setCount(c)
    } else {
    }
  }, [tasks])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(
      createTask({
        title: task.title,
        status: task.status,
        project: selectedProject,
        createdBy: user._id,
      })
    )
    // socket.emit("taskCreated", task)
    setTask("")
    setOpenTask(false)
  }
  const handleDrag = (e) => {
    dispatch(handleDragEnd(e))
    // dispatch(updateTaskStatus(e))
  }

  const mappedTasks = tasks.map((task) => {
    const assignedToId = task.assignedTo
    const projectTeam = projects.flatMap((proj) => proj.team)
    const assignedUser = projectTeam.find(
      (teamMember) => teamMember.id === assignedToId
    )

    const assignedUserEmail = assignedUser ? assignedUser.email : ""
    const user = usersList.find((user) => user.email === assignedUserEmail)
    const name = user ? user.name : null
    return {
      ...task,
      assignedUserEmail,
      name,
    }
  })

  if (loading) {
    return <Loading2 />
  }
  if (!selectedProject) {
    return <p>select project to see its board</p>
  }
  const filterTasks = (item, selectedProjectTitle, filterValue) => {
    const normalizedFilterValue = filterValue.toLowerCase()
    const projectInitials = getInitials(selectedProjectTitle).toLowerCase()

    // Check if the item's title, the combination of selectedProject.title and item.key,
    // or the project initials contains the filter value
    return (
      item.title.toLowerCase().includes(normalizedFilterValue) ||
      `${selectedProjectTitle}-${item.key}`
        .toLowerCase()
        .includes(normalizedFilterValue) ||
      projectInitials.includes(normalizedFilterValue)
    )
  }

  const handleScrollRight = () => {
    const elem = document.getElementById("container")

    if (elem) {
      console.log(elem)
      elem.current.scrollBy(100, 100)
    }
  }
  //__reactProps$0hck7gluh6x
  return (
    <>
      <h5 className="card-title  lg:tw-p-8 lg:tw-pb-3 tw-p-3 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">
        Board
        <div className="task-heading d-flex ">
          <SearchInside>
            <div className="searches ">
              <span>
                <SearchIcon className=" " />
              </span>
              <FormRow
                type="text"
                name="searchquery"
                value={filterValue}
                handleChange={(e) => setFilterValue(e.target.value)}
                placeholder="Search by task name and key id"
              />
            </div>
          </SearchInside>
          <div className={`tw-ml-4 tw-bg-slate-100 tw-border tw-rounded tw-flex tw-justify-center tw-items-center tw-w-8 tw-h-8 tw-mt-2`}>
            <MoreHorizRoundedIcon onClick={() => setOpenList(!openList)} />
          </div>
          {openList && (
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenu2"
              style={{ display: "block", top: "130px", right: "20px" }}
            >
              <button
                type="button"
                className=" dropdown-item"
                onClick={() => {
                  setOpenTaskList(true)
                  setOpenList(false)
                }}
              >
                <AddSharpIcon className="svgIcon" />
                Create new list
              </button>
              <button
                type="button"
                className=" dropdown-item"
                onClick={(e) => {
                  setOpenPonupGoal(!openPopupGoal)
                  setOpenList(false)
                }}
              >
                <QueryBuilderIcon className="svgIcon" /> Goal
              </button>
              <button
                type="button"
                className=" dropdown-item"
                onClick={(e) => {
                  setOpenManageList(true)
                  setOpenList(false)
                }}
              >
                <PostAddOutlinedIcon className="svgIcon" /> Manage List
              </button>
              <button
                type="button"
                className=" dropdown-item"
                onClick={(e) => {
                  navigate("/archive")
                }}
              >
                <ArchiveOutlinedIcon className="svgIcon" /> Archive List
              </button>
            </div>
          )}
          {openManageList && (
            <ManageList
              openManageList={openManageList}
              setOpenManageList={setOpenManageList}
            />
          )}
          {openPopupGoal && (
            <GoalContainer setOpenPopupGoal={setOpenPonupGoal} />
          )}
        </div>
      </h5>
      <BoardWrapper>
        {/**for add new task start*/}
        <Modal
          show={openTask}
          onHide={() => setOpenTask(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <FormRow
                type="text"
                name="task"
                labelText="New Task"
                value={task.title}
                handleChange={(e) =>
                  setTask({ ...task, title: e.target.value })
                }
                required={true}
                placeholder="Enter New Task"
              />

              <div className="textRight">
                <button className="btn " type="submit">
                  Add
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/**for add new task start*/}
        {/**board start */}
        <div
          className="d-flex page-button-section tw-mb-5 lg:tw-px-8  tw-px-3"
          onClick={() => setOpenList(false)}
        >
          {
            // category === "todo" && (
            <button
              type="button"
              className="tw-text-[16px] tw-text-white tw-py-1 tw-rounded-lg tw-bg-[var(--blue)] tw-mr-2"
              onClick={() => {
                setOpenTask(true)
                setTask({ ...task, status: "todo" })
              }}
            >
              <AddSharpIcon color="#fff" fontSize="20px" /> Create Task
            </button>
            // )
          }

          <button
            type="button"
            className="tw-text-[16px] tw-text-white tw-py-3 tw-px-1 tw-rounded-lg tw-bg-[var(--blue)]"
            onClick={() => {
              dispatch(getTaskWithoutGoal(selectedProject._id))
            }}
          >
            Task Without Goal
          </button>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 board">
            <DragDropContext
              onDragEnd={handleDrag}
              onDragStart={(e) => console.log(e)}
            >
              <div
                className={`task-container tw-h-[72vh]`}
                onClick={() => setOpenList(false)}
                style={{
                  overflowX:
                    selectedProject.statusCategories.length > 3 && "scroll",
                }}
              >
                {selectedProject.statusCategories.map((category) => {
                  const name = selectedProject.title

                  return (
                    <div
                      key={category}
                      className="col-lg-3 col-md-3 col-sm-3 completed__wrapper"
                    >
                      <h3>
                        <p className="tw-text-[16px]"> {category} tasks: </p>
                        <div className="">
                          {tasks.length > 0 &&
                            count.map((c) => {
                              if (c.item === category) {
                                return (
                                  <p className="tw-text-[16px]">
                                    {" "}{c.count}
                                  </p>
                                )
                              }
                            })}
                        </div>
                        {/* <button type="button">
                        <AiFillDelete />
                      </button> */}
                      </h3>

                      <Droppable droppableId={category}>
                        {(provided, snapshot) => (
                          <div
                            className="box  md:tw-h-[80vh] tw-h-[70vh] "
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              background: snapshot.isDraggingOver
                                ? "rgb(247, 249, 252)"
                                : "#fff",
                              transition: "background 0.2s ease",
                            }}
                          //  isDraggingOver={snapshot.isDraggingOver}
                          >
                            {mappedTasks
                              .filter((item) =>
                                filterTasks(
                                  item,
                                  selectedProject.title,
                                  filterValue
                                )
                              )
                              .map((item, index) => {
                                if (item.status === category) {
                                  return (
                                    <Draggable
                                      key={item._id}
                                      draggableId={item._id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="pending__items"
                                            onClick={() => {
                                              dispatch(setEditingTask(item._id))
                                              // dispatch(openTaskModal())
                                            }}
                                            style={{
                                              background: snapshot.isDragging
                                                ? " rgba(48, 125, 175, 0.1)"
                                                : "#ebf3f9",
                                              ...provided.draggableProps.style,
                                            }}
                                          >
                                            <div className="d-flex">
                                              <p className="tasktitle">
                                                {capitalizeFirstLetter(
                                                  item.title
                                                )}
                                              </p>

                                              <div className="avatar-image">
                                                {item.assignedTo ? (
                                                  <CustumTooltip
                                                    text={
                                                      <p
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                          margin: 0,
                                                        }}
                                                      >
                                                        {item.name}
                                                      </p>
                                                    }
                                                    children={
                                                      <ProfileAvatar
                                                        id={item.assignedTo}
                                                        size={"s"}
                                                      />
                                                    }
                                                    position={"bottom"}
                                                    id={`assign-tooltip-${item.assignedTo}`}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                            <div className="tw-flex tw-mt-6 tw-justify-between">
                                              {item.key && (
                                                <>
                                                  <span
                                                    className="f-10 grey Upper"
                                                    style={{
                                                      textDecoration:
                                                        item.status === "done"
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    <PagesIcon fontSize="15" />
                                                    {getInitials(
                                                      selectedProject.title
                                                    )}
                                                    -{item.key}
                                                  </span>

                                                </>
                                              )}

                                              <p style={{ marginRight: "4px" }} className=""
                                              >
                                                <CustumTooltip
                                                  text={item.priority}
                                                  children={
                                                    <FiberManualRecordRoundedIcon
                                                      style={{
                                                        color:
                                                          item.priority ===
                                                            "lowest"
                                                            ? "blue"
                                                            : item.priority ===
                                                              "low"
                                                              ? "green"
                                                              : item.priority ===
                                                                "medium"
                                                                ? "orange"
                                                                : item.priority ===
                                                                  "high"
                                                                  ? "red"
                                                                  : item.priority ===
                                                                    "highest"
                                                                    ? "purple"
                                                                    : "#fff",
                                                      }}
                                                      fontSize="10px"
                                                    />
                                                  }
                                                  position={"bottom"}
                                                  id={`p-tooltip-${item.priority}`}
                                                />
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      }}
                                    </Draggable>
                                  )
                                }
                              })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )
                })}
              </div>
            </DragDropContext>
          </div>
        </div>
        {isTaskModalOpen && <TaskModal />}
        {editingTask && <WorklogModal />}
        {isCodeReviewModalOpen && <CodeReviewModal  />}
        {openTaskList && <NewListModal setTaskList={setOpenTaskList} />}

        <GoalModal />
      </BoardWrapper>
    </>
  )
}

export default Board
