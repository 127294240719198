import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { getAllTicket, closeTicket } from "../../redux"
import { useDispatch, useSelector } from "react-redux"
import { capitalizeFirstLetter } from "../../utils/getInitialName"

const AllTicket = () => {
  const dispatch = useDispatch()
  const { ticket } = useSelector((state) => state.ticket)

  useEffect(() => {
    dispatch(getAllTicket())
  }, [])
  return (
    <Wrapper>
      <section>
        <h5 className="card-title tw-p-0 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">Tickets</h5>
        {/* {console.log(ticket)} */}
        {ticket &&
          ticket.length > 0 &&
          ticket.map((i) => {
            return (
              <div >
                <div className="ticket">
                <p>{capitalizeFirstLetter(i.description)}</p>
                <div className="d-flex">
                  <p className="user">{i.userName[0].name}</p>
                  <button
                    className="btn add-btn"
                    onClick={() => dispatch(closeTicket(i._id))}
                  >
                    close ticket
                  </button>
                </div>
              </div>
              </div>
            )
          })}
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
 padding: 32px;
  .ticket {
    box-shadow: var(--shadow-1);
    padding: 20px;
    border-radius: 5px;
    margin: 0px 0 20px 0;
  }
  .d-flex {
    justify-content: space-between;
    align-items: baseline;
  }
  p {
    font-size: 12px;
  }

  .user {
    margin: 0;
    /* background: red; */
    padding: 2px 10px;
    border-radius: 5px;
    box-shadow: var(--shadow-1);
    text-transform: capitalize;
    font-size: 11px;

    background: linear-gradient(
      90deg,
      rgba(196, 246, 240, 0.5046393557422969) 0%,
      rgba(151, 219, 249, 0.37298669467787116) 100%
    );
  }
`
export default AllTicket
