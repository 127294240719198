const FormRow = ({
  type,
  name,
  value,
  handleChange,
  labelText,
  min,
  disabled,
  placeholder,
  onKeyUp,
  required,
}) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText}
      </label>
      <input
        type={type}
        value={value}
        name={name}
        onChange={handleChange}
        className={disabled ? "form-input" : "form-input"}
        min={min}
        required={required}
        disabled={disabled ? true : false}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
      />
    </div>
  )
}

export default FormRow
