import React, { useState } from "react"
import styled from "styled-components"
import { FormRow } from "../Common"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getAllQuestion } from "../../redux"

const initialState = {
  startDate: moment(Date.now()).format("YYYY-MM-DD"),
  endDate: moment(Date.now()).format("YYYY-MM-DD"),
}
const QuestionFilter = ({ subjective, multiple }) => {
  const [values, setValues] = useState(initialState)
  const dispatch = useDispatch()

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (subjective) {
      dispatch(
        getAllQuestion({
          subjective: true,
          startDate: values.startDate,
          endDate: values.endDate,
        })
      )
    }
    if (multiple) {
      dispatch(
        getAllQuestion({
          multiple: true,
          startDate: values.startDate,
          endDate: values.endDate,
        })
      )
    }
  }
  return (
    <Wrapper className="row">
      <div className="col-xs-12 col-sm-4 col-md-2">
        <FormRow
          type="date"
          name="startDate"
          value={values.startDate}
          handleChange={handleChange}
          labelText={"start date"}
        />
      </div>
      <div className="col-xs-12 col-sm-4 col-md-2">
        <FormRow
          type="date"
          name="endDate"
          value={values.endDate}
          handleChange={handleChange}
          labelText={"end date"}
        />
      </div>
      <button className="btn add-btn mt-4 col-xs-12 col-sm-4 col-md-2" onClick={handleSubmit}>
        Apply
      </button>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .form-row {
    margin: 10px 0px 0px 0;
  }
`
export default QuestionFilter
