import React, { useState, useEffect } from "react"
import moment from "moment"
import { FormRow } from "../Common"
import { useSelector, useDispatch } from "react-redux"
import { createGoal, updateGoal, closeGoalModal } from "../../redux"
import { ModalWrapper } from "../../assets/styles/Goals"
import { CloseOutlinedIcon } from "../../assets/icons"

const initialState = {
  // _id: "",
  title: "",
  description: "",
  startDate: moment(new Date()).format("YYYY-MM-DD"),
  endDate: moment(new Date()).format("YYYY-MM-DD"),
  completed: false,
  taskTitle: "",
}


const GoalModal = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState(initialState)
  const { isGoalModalOpen, isEditingGoal, editingGoal } = useSelector(
    (state) => state.goal
  )
  const { selectedProject } = useSelector((state) => state.project)

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isEditingGoal) {
      await setValues({ ...values, _id: editingGoal._id })
      dispatch(updateGoal(values))
    } else {
      dispatch(createGoal({ ...values, project: selectedProject._id }))
    }
    setValues(initialState)
    dispatch(closeGoalModal())
  }
  useEffect(() => {
    if (isEditingGoal) {
      const { title, description, startDate, endDate, completed, _id } =
        editingGoal
      setValues({
        ...values,
        title,
        description,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        completed,
        _id,
      })
    }
  }, [editingGoal])
  const closeModal = () => {
    dispatch(closeGoalModal())
    setValues(initialState)
  }

  return (
    isGoalModalOpen && (
      <ModalWrapper>
        <h5>
          {isEditingGoal ? "Edit Goal" : "Add Goal"}{" "}
          <CloseOutlinedIcon onClick={closeModal} />
        </h5>

        <form className="tw-flex tw-flex-col tw-gap-3" onSubmit={handleSubmit}>
          <FormRow
            type="text"
            name="title"
            labelText="Title"
            value={values.title}
            handleChange={handleChange}
            required={true}
            placeholder="Enter Title"
          />
          <FormRow
            type="text"
            name="description"
            labelText="description"
            value={values.description}
            handleChange={handleChange}
            placeholder="Enter Description"
          />
          <FormRow
            type="date"
            name="startDate"
            labelText="Start Date"
            value={values.startDate}
            handleChange={handleChange}
           
            placeholder="Start Date"
          />
          <FormRow
            type="date"
            name="endDate"
            labelText="End Date"
            value={values.endDate}
            handleChange={handleChange}
           
            placeholder="End Date"
          />

          <div className="textRight">
            <button className="btn" type="submit">
              submit
            </button>
          </div>
        </form>
      </ModalWrapper>
    )
  )
}

export default GoalModal
