export const CREATE_NOTIFICATION_BEGIN = "CREATE_NOTIFICATION_BEGIN"
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS"
export const CREATE_NOTIFICATION_ERROR = "CREATE_NOTIFICATION_ERROR"

export const DAILY_EMAIL_NOTIFICATION_BEGIN = "DAILY_EMAIL_NOTIFICATION_BEGIN"
export const DAILY_EMAIL_NOTIFICATION_SUCCESS =
  "DAILY_EMAIL_NOTIFICATION_SUCCESS"

export const GET_DAILY_EMAIL_NOTIFICATION_BEGIN =
  "GET_DAILY_EMAIL_NOTIFICATION_BEGIN"
export const GET_DAILY_EMAIL_NOTIFICATION_SUCCESS =
  "GET_DAILY_EMAIL_NOTIFICATION_SUCCESS"
