import authFetch from "../../utils/interceptor"
import { toast } from "react-toastify"
import {
  EDIT_USER_BEGIN,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
} from "./userTypes"

export const getAllEmails = () => {
  return async (dispatch) => {
    try {
      const response = await authFetch.get("/getAllEmails")
      // console.log(response)
      return response.data.emails
    } catch (error) {
      console.log(error)
    }
  }
}

export const updateUserActivation = (userId, isActive) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.put(`/user/activation/${userId}`, {
        isActive,
      })
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.response.data.error)
    }
  }
}

export const displayUserByEmail = (email) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.post("display_userby_email", {
        email,
      })

      return response
    } catch (error) {
      //console.log(error)
      toast.error("Error getting user")
    }
  }
}

export const editUser = (values) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_USER_BEGIN })
    console.log(values)
    try {
      const response = await authFetch.post(`/edit_user`, values)

      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: {
          user: response.data.updatedUser,
        },
      })
      toast.success("Profile updated successfully")

      // check the payload the add user to the appcontext
    } catch (error) {
      dispatch({
        type: EDIT_USER_ERROR,
        payload: { msg: error.response.data.msg },
      })
      toast.error(error.response.data.msg)
    }
  }
}

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      const response = await authFetch.get("/getAllUsers/admin")

      return response.data
    } catch (error) {
      toast.error("Error fetching users")
    }
  }
}
