import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FormRow } from "./Common"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getArchiveList } from "../redux"

const initialState = {
  startDate: moment(Date.now()).format("YYYY-MM-DD"),
  endDate: moment(Date.now()).format("YYYY-MM-DD"),
}

const ArchiveFilter = ({ setTasks }) => {
  const dispatch = useDispatch()
  const { selectedProject } = useSelector((state) => state.project)
  const [values, setValues] = useState(initialState)
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    selectedProject &&
      dispatch(
        getArchiveList({
          project: selectedProject._id,
          startDate: values.startDate,
          endDate: values.endDate,
        })
      ).then((data) => {
        setTasks(data)
      })
  }, [selectedProject])
  const handleSubmit = () => {
    dispatch(
      getArchiveList({
        project: selectedProject._id,
        startDate: values.startDate,
        endDate: values.endDate,
      })
    ).then((data) => {
      setTasks(data)
    })
  }
  return (
    <Wrapper>
      <h6>Select Date</h6>
      <div className="dateRow">
        <FormRow
          type="date"
          name="startDate"
          labelText={"Start Date"}
          value={values.startDate}
          handleChange={handleChange}
        />
        <FormRow
          type="date"
          name="endDate"
          labelText={"End Date"}
          value={values.endDate}
          handleChange={handleChange}
        />
        <button className="btn add-btn mx-2 mt-2" onClick={handleSubmit}>
          Apply
        </button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .dateRow {
    display: flex;
    align-items: center;
  }
  input {
    cursor: pointer;
  }
`
export default ArchiveFilter
