import { NavLink } from "react-router-dom"
import { useAppContext } from "../../context/context/appContext"
import { userLinks, adminLinks } from "../../utils/links"
import CustumTooltip from "../Common/CustumTooltip"
import { WMenu } from "../../assets/styles/Layouts"

const SidebarMenu = ({ toggleSidebar }) => {
  const { user } = useAppContext()
  if (!user) {
    return <p>loading</p>
  }

  return (
    <WMenu>
      <div className="nav-links">
        {user.role === "admin"
          ? adminLinks.map((link, i) => {
              const { text, path, id, icon } = link
              return (
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  key={id}
                  onClick={toggleSidebar}
                >
                  <CustumTooltip
                    text={text}
                    children={<span className="icon">{icon}</span>}
                    position={"right"}
                    id={`sidemenu-tooltip-${i}`}
                  />
                </NavLink>
              )
            })
          : userLinks.map((link, i) => {
              const { text, path, id, icon } = link
              return (
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  key={id}
                  onClick={toggleSidebar}
                >
                  <CustumTooltip
                    text={text}
                    children={<span className="icon">{icon}</span>}
                    position={"right"}
                    id={`sidemenu-tooltip-${i}`}
                  />
                </NavLink>
              )
            })}
      </div>
    </WMenu>
  )
}

export default SidebarMenu
