import React, { useState, useEffect } from "react"
import "react-datetime-picker/dist/DateTimePicker.css"
import "react-calendar/dist/Calendar.css"
import "react-clock/dist/Clock.css"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Modal from "react-bootstrap/Modal"
import { Dropzone, FormRow, FormRowSelect, Loading } from "../Common"
import { useDispatch, useSelector } from "react-redux"
import { closeCodeReviewModal, createCodeReview, getCodeReviewById, editCodeReview, deleteCodeReviewAttachment } from "../../redux"
import { WorklogModalWrapper } from "../../assets/styles/Project"
import { DeleteIcon } from "../../assets/icons"

const initialState = {
  taskId: "",
  status: "Pending",
  textReview: "",
  comments: "",
  attachments: ""
}

const CodeReviewModal = () => {
  const [values, setValues] = useState(initialState)

  const { isCodeReviewModalOpen, isReviewId, loading2, editingTaskForCodeReview, selectedCodeReview } = useSelector((state) => state.codeReview)

  const dispatch = useDispatch()
  const minDate = new Date(values.dateStarted);
  const [imageArray, setImageArray] = useState([]);

  minDate.setDate(minDate.getDate() - 7);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    if (isReviewId) {
      dispatch(getCodeReviewById(isReviewId))
      setValues({
        taskId: "",
        status: "Pending",
        textReview: "",
        comments: ""
      })
    } else {
      setValues(initialState)
    }
  }, [isReviewId])

  useEffect(() => {
    if (isReviewId) {
      setValues({
        taskId: selectedCodeReview?.taskId?._id,
        status: selectedCodeReview?.status == 'pass' ? "Pass" : selectedCodeReview?.status == "fail" ? "Fail" : "Pending",
        textReview: selectedCodeReview?.textReview,
        comments: selectedCodeReview?.comments,
      })

      // Populate imageArray with URLs of existing attachments
      if (selectedCodeReview?.attachments) {
        const attachmentURLs = selectedCodeReview?.attachments?.map((attachment) => {
          // Convert base64 string to a Uint8Array
          const buffer = Uint8Array.from(atob(attachment.content), (c) => c.charCodeAt(0));
          const blob = new Blob([buffer], { type: attachment.contentType });  // Create a Blob
          const imageUrl = URL.createObjectURL(blob);  // Generate an object URL for the Blob
          return { key: attachment.key, filename: attachment.filename, url: imageUrl };
        });
        setImageArray(attachmentURLs);
      }

    } else {
      setValues(initialState)
    }
  }, [selectedCodeReview])

  const onDrop = (acceptedFiles) => {
    setImageArray([...imageArray, ...acceptedFiles]);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()


    const formData = new FormData();

    // Append the text review to the form data
    formData.append("textReview", values.textReview);
    formData.append("comments", values.comments);
    formData.append("status", values.status === "Pass" ? "pass" : values.status == "Fail" ? 'fail' : 'pending');

    imageArray.forEach((file, index) => {
      formData.append(`attachment`, file);
    });

    if (isReviewId) {
      if (values.textReview !== "") {
        dispatch(editCodeReview(editingTaskForCodeReview._id, isReviewId, formData))
      }

    } else {
      dispatch(createCodeReview(editingTaskForCodeReview._id, formData))
      setValues(initialState)
    }

    dispatch(closeCodeReviewModal())
    setValues(initialState)
    setImageArray([])

  }


  const closeModal = () => {
    dispatch(closeCodeReviewModal())
    setValues(initialState)
    setImageArray([])
  }

  const handleRemoveAttachment = (index, attachmentKey) => {

    if (!attachmentKey) {
      const updatedArray = imageArray.filter((_, indx) => indx !== index);
      setImageArray(updatedArray);
    } else {
      const parts = attachmentKey.split('/');
      const keyId = parts[1];
      // Find the index of the attachment to remove
      const attachmentIndex = imageArray.findIndex((attachment) => attachment.key === attachmentKey);

      if (attachmentIndex !== -1) {
        // Update the local state to remove the attachment from the UI
        const updatedArray = imageArray.filter((_, i) => i !== attachmentIndex);
        setImageArray(updatedArray);

        // Dispatch the delete action to remove the attachment from the backend
        dispatch(deleteCodeReviewAttachment(keyId));

      }
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <WorklogModalWrapper>
      <Modal
        show={isCodeReviewModalOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {loading2 ? <Loading /> : <></>}
        <Modal.Header closeButton>
          <Modal.Title>
            {isReviewId ? "edit review" : "create review"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorklogModalWrapper>
            <form onSubmit={handleSubmit} className="">
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              ></div>
              <FormRowSelect
                labelText="Status"
                name="status"
                value={values.status}
                handleChange={handleChange}
                list={["Pending", "Pass", "Fail"]}
              />
              <div className="mt-3"></div>
              <label htmlFor="textReview" className="form-label">
                Review Text
              </label>
              <ReactQuill
                theme="snow"
                name="textReview"
                // placeholder="Write description "
                modules={CodeReviewModal.modules}
                formats={CodeReviewModal.formats}
                value={values.textReview}
                onChange={(e) => setValues({ ...values, textReview: e })}
                required={true}
              />
              <div className="mt-3"></div>

              <FormRow
                labelText="Comment"
                name="comments"
                value={values.comments}
                handleChange={handleChange}
                required={true}
              />

              <label htmlFor="attachment" className="form-label">
                Attachment
              </label>
              <Dropzone onDrop={(files) => onDrop(files)} />
              <div
                className="attachmentContainer"
                style={{ display: "flex", overflowX: "auto", position: "relative" }}
              >
                {imageArray.map((image, index) => (
                  <div
                    key={index}
                    className=""
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      display: 'flex',
                      justifyItems: 'center',
                      alignItems: "center",
                      margin: 8,
                      background: "#EBF3F9",
                      borderRadius: 4,
                      position: "relative",
                      transition: "all 0.3s ease"
                    }}
                  >
                    <span
                      className="tw-cursor-pointer "
                      onClick={() =>
                        window.open(
                          image instanceof File ? URL.createObjectURL(image) : image.url,
                          "_blank"
                        )
                      }
                      style={{ background: '#EBF3F9', marginLeft: 8, marginRight: 8}}
                    >
                      {
                        image instanceof File
                          ? image.name
                          : image.filename // Use Base64 directly if not a File
                      }
                    </span>
                    <DeleteIcon
                      style={{
                        position: "absolute",
                        right: 4,
                        color: "rgb(203, 48, 69)",
                        cursor: "pointer",
                        opacity: hoveredIndex === index ? 1 : 0,
                        transition: "opacity 0.3s ease",
                      }}
                      onClick={() => handleRemoveAttachment(index, image.key)}
                    />
                  </div>
                ))}
              </div>

              <div style={{ textAlign: "right", marginTop: 8 }}>
                <button className="btn">save</button>
                <button
                  className="btn"
                  type="button"
                  onClick={() => {
                    closeModal()
                  }}
                >
                  cancel
                </button>
              </div>
            </form>
          </WorklogModalWrapper>
        </Modal.Body>
      </Modal>
    </WorklogModalWrapper>
  )
}

export default CodeReviewModal

CodeReviewModal.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    [{ font: [] }],
    [{ size: ["small", "large"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
    ["code-block"],
  ],
}

CodeReviewModal.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "code-block",
]
