import {
  CREATE_NOTIFICATION_BEGIN,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_ERROR,
  DAILY_EMAIL_NOTIFICATION_BEGIN,
  DAILY_EMAIL_NOTIFICATION_SUCCESS,
  GET_DAILY_EMAIL_NOTIFICATION_BEGIN,
  GET_DAILY_EMAIL_NOTIFICATION_SUCCESS,
} from "./notificationTypes"

const initialState = {
  notifications: [],
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION_BEGIN:
      return { ...state }
    case CREATE_NOTIFICATION_SUCCESS:
      return { ...state }
    case CREATE_NOTIFICATION_ERROR:
      return { ...state }
    case DAILY_EMAIL_NOTIFICATION_BEGIN:
      return { ...state }
    case DAILY_EMAIL_NOTIFICATION_SUCCESS:
      return { ...state }
    case GET_DAILY_EMAIL_NOTIFICATION_BEGIN:
      return { ...state }
    case GET_DAILY_EMAIL_NOTIFICATION_SUCCESS:
      return { ...state }
    default:
      return { ...state }
  }
}

export default notificationReducer
