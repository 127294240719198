import styled from "styled-components"

export const WorkReportWrapper = styled.div`
  .heading {
    display: flex;
    justify-content: space-between;
  }
  .progress {
    width: 20px;
    height: 4px;
    background-color: var(--grey-200);
    --bs-progress-bar-bg: var(--primary-500-active);
  }
`
