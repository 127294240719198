import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { FormRowSelect } from "./Common"
import moment from "moment"
import parse from "html-react-parser"
import {
  capitalizeDescription,
  capitalizeFirstLetter,
} from "../utils/getInitialName"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { unArchive } from "../redux"

const SelectStatusModal = ({
  openModal,
  setOpenModal,
  selectedTask,
  setTasks,
  tasks,
}) => {
  const dispatch = useDispatch()
  const { selectedProject } = useSelector((state) => state.project)
  const [status, setStatus] = useState("todo")

  const onSubmit = () => {
    dispatch(unArchive({ taskId: selectedTask._id, status: status }))
    setOpenModal(false)
    const filteredTask = tasks.filter((i) => i._id !== selectedTask._id)
    setTasks(filteredTask)
  }
  if (!selectedTask) {
    return
  }
  return (
    <Modal
      show={openModal}
      onHide={() => {
        setOpenModal(false)
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedProject.title && selectedProject.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" mt-0">
        <Wrapper>
          <div>
            <label>Task :</label>
            <p>
              {selectedTask.title && capitalizeFirstLetter(selectedTask.title)}
            </p>
          </div>
          {selectedTask.requirement && (
            <div>
              <label>Requirement :</label>

              {parse(capitalizeDescription(selectedTask.requirement))}
            </div>
          )}
          <div>
            <label> Start Date :</label>

            <p> {moment(selectedTask.createdAt).format("YYYY-MM-DD")}</p>
          </div>
          <div>
            <label> Priority :</label>

            <p> {selectedTask.priority}</p>
          </div>
          <div>
            <label>Select the list</label>
            <FormRowSelect
              list={selectedProject.statusCategories}
              value={status}
              handleChange={(e) => setStatus(e.target.value)}
              name="status"
            />
          </div>
          <button
            className="btn add-btn"
            type="button"
            onClick={() => onSubmit()}
          >
            submit
          </button>
          <button className="btn add-btn" onClick={() => setOpenModal(false)}>
            cancel
          </button>
        </Wrapper>
      </Modal.Body>
    </Modal>
  )
}

export default SelectStatusModal

const Wrapper = styled.div`
  p {
    margin: 0;
    align-items: center;

    font-size: 12px;
  }
`
