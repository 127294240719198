import React, { useEffect, useState } from "react"
import { LogFilters } from "./index"
import styled from "styled-components"
import moment from "moment"
import parse from "html-react-parser"
import { Loading, ProfileAvatar } from "../../Common"
import { useAppContext } from "../../../context/context/appContext"
import {
  capitalizeDescription,
  capitalizeFirstLetter,
  getInitials,
} from "../../../utils/getInitialName"
import PagesIcon from "@mui/icons-material/Pages"
import { useSelector, useDispatch } from "react-redux"
import { getAllWorklogs, getAllProjects } from "../../../redux"

const LogContainer = () => {
  const { getUsersList, user } = useAppContext()
  const dispatch = useDispatch()
  const { worklogs } = useSelector((state) => state.worklog)
  useEffect(() => {
    dispatch(getAllProjects(user.role, user._id))
    dispatch(
      getAllWorklogs({
        filters: {
          project: [],
          projectId: [],
          taskId: [],
          task: [],
          user: [],
          userId: [],
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      })
    )
    getUsersList()
  }, [])
  const [viewWorklog, setViewWorklog] = useState(false)
  const [worklogId, setWorklogId] = useState(null)

  if (!worklogs) {
    return <Loading />
  }

  let totalHours = 0
  let totalMinutes = 0

  if (worklogs) {
    for (let i = 0; i < worklogs.length; i++) {
      const { timeSpent } = worklogs[i]
      totalHours += Math.floor(timeSpent / 60)
      totalMinutes += timeSpent % 60
    }
  }

  totalHours += Math.floor(totalMinutes / 60)
  totalMinutes = totalMinutes % 60

  return (
    <Wrapper>
      <LogFilters />

      {worklogs && (
        <div className="total-time   ">
          Total Time: {totalHours}h {totalMinutes}m
        </div>
      )}
      <div style={{ marginTop: "65px" }}></div>
       <div className="   tw-h-[55vh] tw-overflow-y-scroll">
      <div className="tableresponse">
        <table>
          <thead>
            <tr>
              <th className="users">User</th>
              <th className="project textLeft">Project</th>
              <th className="task textLeft ">Task</th>

              <th className="description textLeft">Description</th>
              <th className="date">Date</th>

              <th className="timespent">Time Spent</th>
            </tr>
          </thead>
          <tbody>
            {worklogs &&
              worklogs.map((log) => {
                return (
                  <>
                    <tr
                      key={log._id}
                      onClick={() => {
                        setWorklogId(log._id)
                        setViewWorklog(true)
                      }}
                    >
                      <td className="img-content">
                        <div className="d-flex">
                          <ProfileAvatar id={log.userId} size="s" />
                          <span>{log.name}</span>
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "justify",
                          textTransform: "capitalize",
                        }}
                      >
                        {log.p && log.p[0].title}
                      </td>
                      <td className="textLeft">
                        <p>
                          {log.result &&
                            capitalizeFirstLetter(log.result[0].title)}
                        </p>
                        <span className="f-12 grey">
                          {log.result && log.result[0].key && (
                            <>
                              <PagesIcon fontSize="15" />
                              <span className="Upper">
                                {log.p && getInitials(log.p[0].title)}-
                                {log.result[0].key}
                              </span>
                            </>
                          )}
                        </span>
                      </td>
                      <td
                        style={{ textALign: "left" }}
                        className="description word-break textLeft"
                      >
                        {parse(capitalizeDescription(log.workDescription))}
                      </td>

                      <td>{moment(log.dateStarted).format("DD/MM/YYYY")}</td>

                      <td>
                        <span className="time-spent">
                          {log.timeSpent > 0
                            ? `${Math.floor(log.timeSpent / 60)}h ${
                                log.timeSpent % 60
                              }m`
                            : "0h"}
                        </span>
                      </td>
                    </tr>

                    {/* <div className="mb-view">
                    <div className="row" key={log._id}>
                      <div className="col-lg-2 col-sm-12">
                        <div className="img-content">
                           <span>{log.name}</span>
                        </div>

                        <div className="date-format">
                          {moment(log.dateStarted).format("DD-MM-YYYY")}

                          <span className="time-spent">
                            {convertTime(log.timeSpent)}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-7 col-sm-12 my-2 rhgtc">
                        <Description>{parse(log.workDescription)}</Description>
                      </div>
                    </div>
                  </div> */}
                  </>
                )
              })}
          </tbody>
        </table>
      </div>
      {/* {viewWorklog && (
        <WorklogDetails id={worklogId} setViewWorklog={setViewWorklog} />
      )} */}</div>
    </Wrapper>
  )
}
const Description = styled.div`
  margin-left: 20px;
  @media (max-width: 992px) {
    margin-top: 15px !important;
    margin-left: 0;
  }
`

const Wrapper = styled.div`
  p {
    text-align: left !important;
  }
  .row {
    box-shadow: var(--shadow-1) !important;
    // margin: 15px 0;
    // padding: 15px 12px;
    border-left: 4px solid green !important;
    p {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .total-time {
    background: #edf5fd;
    font-size: var(--font-12);
    right: 30px;
    position: absolute;
    padding: 5px;
  }

  .right-content {
    text-align: end;
    span {
      margin-right: 10px;
    }
  }
  .right-content img,
  .img-content img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid lightgrey;
    margin-left: 2px;
  }
  .img-content {
    display: table-cell;
    align-items: center;
    text-transform: capitalize;
    div {
      display: contents;
    }
  }
  .img-content span {
    margin-left: 10px;
  }
  .dsk-view {
    display: block;
  }
  .mb-view {
    display: none;
  }
  // tr {
  //   &:nth-child(even) {
  //     background-color: #f9f9f9 !important;
  //   }
  //   background-color: #fff !important;
  // }
  tr td {
    padding: 5px 8px;
    //border: 1px solid lightgrey;
    //  text-align: center;
    // background-color: #f9f9f9;

    // word-break: break-all;

    p {
      margin: 0;
    }
  }

  tr td:first-child,
  tr td:nth-child(2) {
    text-align: left;
  }
  .img-content {
    display: table-cell;
  }
  select[name="task"] {
    text-transform: capitalize;
  }
  select[name="project"] {
    text-transform: capitalize;
  }
  // select[name="user"] {
  //   text-transform: capitalize;
  // }
  @media (max-width: 992px) {
    .dsk-view {
      display: none;
    }
    .mb-view {
      display: block;
    }

    .date-format {
      display: flex;
      margin-top: var(--font-12) !important;
      font-size: var(--font-12) !important;
      .time-spent {
        margin-left: 5px;
      }
    }
    .img-content {
      // display: flex;
      span {
        font-size: var(--font-12);
      }
    }
    .status {
      margin-left: 20px;
      color: orange;
    }
    .rhgtc {
      border-top: 1px solid var(--grey-100);
    }
  }
  .d-flex {
    align-items: center;
  }
  table tr {
    .users {
      width: 200px;
    }
    .project {
      width: 150px;
    }
    .task {
      width: 200px;
    }
    .description {
      width: 300px;
    }
    .date {
      width: 150px;
      //  text-align: center;
    }
    .timespent {
      width: 150px;
      //  text-align: center;
    }
  }

  .description {
    img {
      height: 60px;
      width: 60px;
    }
  }
`
export default LogContainer
