import { toast } from "react-toastify"
import {
  GET_PERMISSION_BY_ROLE_BEGIN,
  GET_PERMISSION_BY_ROLE_SUCCESS,
  GET_PERMISSION_BY_ROLE_ERROR,
  UPDATE_PERMISSION_ACTION_BEGIN,
  UPDATE_PERMISSION_ACTION_SUCCESS,
  UPDATE_PERMISSION_ACTION_ERROR,
  GET_ALL_ROLE_BEGIN,
  GET_ALL_ROLE_SUCCESS,
  GET_ALL_ROLE_ERROR,
  UPDATE_ROLES_BEGIN,
  UPDATE_ROLES_ERROR,
  UPDATE_ROLES_SUCCESS,
  UPDATE_ROLE_BEGIN,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
} from "./permissionTypes"
import authFetch from "../../utils/interceptor"

export const createPermission = (role) => {
  return async (dispatch) => {
    const response = await authFetch.post("/permission", { name: role })
    if (response.data === "already exist") {
      toast.success("Role already exist!")
    } else {
      toast.success("Role updated successfully")
    }
    return response.data
  }
}

export const getPermissionByRole = (role) => {
  return async (dispatch) => {
    // dispatch({ type: GET_PERMISSION_BY_ROLE_BEGIN })
    try {
      const response = await authFetch.post("permission/role", { role: role })
      // dispatch({ type: GET_PERMISSION_BY_ROLE_SUCCESS })
      return response.data.permission
    } catch (error) {
      // console.log(error)
      // dispatch({ type: GET_PERMISSION_BY_ROLE_ERROR })
      toast.error("Cannot find permissions")
    }
  }
}
export const getAllPermissionName = () => {
  return async (dispatch) => {
    try {
      const response = await authFetch.get("/permission/name")
      return response.data.permissions
    } catch (error) {
      // console.log(error)
      toast.error("Error getting permissions")
    }
  }
}

export const updatePermissionAction = (values) => {
  return async (dispatch) => {
    // dispatch({ type: UPDATE_PERMISSION_ACTION_BEGIN })
    try {
      const response = await authFetch.patch("/permission/action", values)
      // console.log(response)
      // dispatch({ type: UPDATE_PERMISSION_ACTION_SUCCESS })
      return response.data.updatedPermission
    } catch (error) {
      // console.log(error)
      // dispatch({ type: UPDATE_PERMISSION_ACTION_ERROR })
      // toast.error("error updating permission")
    }
  }
}

export const getAllRole = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_ROLE_BEGIN })
    try {
      const response = await authFetch.get("/permission/name")

      // console.log(response)
      // dispatch({{}
      //   type: GET_ALL_ROLE_SUCCESS,
      //   payload: { roles: response.data },
      // })
      //console.log(response.data)
      return response.data
    } catch (error) {
      //toast.error("error fetching roles")
    }
  }
}

export const editRole = (value, id) => {
  return async (dispatch) => {
    if (!value) {
      toast.error("Select role")
      return
    }
    // dispatch({ type: UPDATE_ROLE_BEGIN })
    try {
      const response = await authFetch.post("/permission/edit", {
        id: id,
        role: value,
      })

      // dispatch({ type: UPDATE_ROLE_SUCCESS })
      toast.success("Role edited successfully")
    } catch (error) {
      //console.log(error)
      // dispatch({ type: UPDATE_ROLE_ERROR })
      //   toast.error("error deleting role")
    }
  }
}

export const editUserPermission = (values) => {
  return async (dispatch) => {
    // dispatch({ type: UPDATE_PERMISSION_ACTION_BEGIN })

    try {
      const response = await authFetch.post(
        `/permission/edituser_permission`,
        values
      )

      toast.success("User role updated successfully")
    } catch (error) {
      toast.error(error.response.data.msg)
    }
  }
}

export const editRoles = (value, id) => {
  return async (dispatch) => {
    if (!value) {
      toast.error("Please select a role")
      return
    }
    // dispatch({ type: UPDATE_ROLES_BEGIN })
    try {
      const response = await authFetch.patch(`/users/${id}`, {
        role: value,
      })

      // console.log(response.data, value);
      //   dispatch({ type: UPDATE_ROLES_SUCCESS })
      toast.success("Role edited successfully")
    } catch (error) {
      //console.log(error);
      //   dispatch({ type: UPDATE_ROLES_ERROR })
      toast.error("Failed to edit role")
    }
  }
}

export const deleteRole = (value) => {
  return async (dispatch) => {
    if (!value) {
      toast.error("Select role")
      return
    }
    // dispatch({ type: DELETE_ROLE_BEGIN })
    try {
      const response = await authFetch.post("/permission/delete", {
        role: value,
      })
      // dispatch({ type: DELETE_ROLE_SUCCESS })
      toast.success("Role deleted successfully")
    } catch (error) {
      //console.log(error)
      // dispatch({ type: DELETE_ROLE_ERROR })
      toast.error("Error deleting role")
    }
  }
}

export const getUsersByRole = (value) => {
  return async (dispatch) => {
    try {
      const response = await authFetch.post("/permission/user", value)
      return response.data.users
    } catch (error) {
      toast.error("Error getting users By role")
    }
  }
}
