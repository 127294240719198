import React, { useState } from "react"
import validator from "validator"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { PhoneNumberUtil } from "google-libphonenumber"
import { Logo, FormRow } from "../Common"
import { useAppContext } from "../../context/context/appContext"
import { SignUpWrapper } from "../../assets/styles/Auth"

const initialState = {
  name: "",
  email: "",
  contact_number: "",
  country: "",
  country: "",
  address: "",
  // role: "",
  education_qualification: "",
  work_experience: "",
  skills: "",
  job_title: "",
  department: "",
  reporting_manager: "",
  joining_date: "",
  salary_details: "",
  emergency_contact_details: "",
}

const Signup = () => {
  const { signupUser } = useAppContext()
  const navigate = useNavigate()

  const [values, setValues] = useState(initialState)

  const [isEmail, setIsEmail] = useState(true)
  const [message, setMessage] = useState("")
  const [isValid, setIsValid] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState("US")
  const handleSubmit = (e) => {
    e.preventDefault()

    if (isEmail) {
      try {
        const result = signupUser(values)

        if (result) {
          navigate("/verify_email")
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }

  const validateEmail = () => {
    if (validator.isEmail(values.email)) {
      setIsEmail(true)
    } else {
      setIsEmail(false)
    }
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleContactChange = (e) => {
    setValues({ ...values, ["contact_number"]: e })
    validatePhoneNumber(e, selectedCountry)
  }

  const handleCountryChange = (country) => {
    setSelectedCountry(country)
    setValues({ ...values, ["country"]: country })
    validatePhoneNumber(values.contact_number, country)
  }

  const validatePhoneNumber = (input, country = selectedCountry) => {
    try {
      // Create an instance of PhoneNumberUtil
      const phoneUtil = PhoneNumberUtil.getInstance()

      // Parse the phone number
      const parsedNumber = phoneUtil.parseAndKeepRawInput(input, country)

      // Check if the phone number is valid for the selected country
      const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(
        parsedNumber,
        country
      )

      setIsValid(isValidPhoneNumber)
    } catch (error) {
      // Handle any errors during phone number validation
      setIsValid(false)
    }
  }

  return (
    <SignUpWrapper>
      <form className="form" onSubmit={handleSubmit}>
        <div className="text-center">
          <Logo />
        </div>
        <h3>sign up</h3>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="name"
              type="text"
              labelText="Name*"
              value={values.name}
              handleChange={handleChange}
              required={true}
              placeholder="Enter name"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="email"
              type="email"
              labelText="Email*"
              value={values.email}
              handleChange={handleChange}
              onKeyUp={validateEmail}
              required={true}
              placeholder="Enter Email"
            />
            {isEmail ? null : (
              <>
                <span className="error-msg">please enter valid email</span>
              </>
            )}
          </div>
          <div className="col-md-6 col-sm-12">
            <label>Contact Number</label>

            <PhoneInput
              international
              defaultCountry={values.country || selectedCountry}
              value={values.contact_number}
              onChange={handleContactChange}
              country={values.country}
              onCountryChange={handleCountryChange}
            />
            {isValid ? null : (
              <span className="validatePError">invalid phone number.</span>
            )}
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="address"
              type="text"
              labelText="Address"
              value={values.address}
              handleChange={handleChange}
              placeholder="Enter Address"
            />
          </div>
          {/* <div className="col-md-6 col-sm-12">
            <FormRow
              name="role"
              type="text"
              labelText="Role*"
              value={values.role}
              handleChange={handleChange}
            />
          </div> */}
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="education_qualification"
              type="text"
              labelText="Education Qualification"
              value={values.education_qualification}
              handleChange={handleChange}
              placeholder="Enter Education Qualification"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="work_experience"
              type="text"
              labelText="Work Experience"
              value={values.work_experience}
              handleChange={handleChange}
              placeholder="Enter Work Experience"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="skills"
              type="text"
              labelText="Skills"
              value={values.skills}
              handleChange={handleChange}
              placeholder="Enter Skills"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="job_title"
              type="text"
              labelText="Job Title"
              value={values.job_title}
              handleChange={handleChange}
              placeholder="Enter Job Title"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="department"
              type="text"
              labelText="Department"
              value={values.department}
              handleChange={handleChange}
              placeholder="Enter Department"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="reporting_manager"
              type="text"
              labelText="Reporting Manager"
              value={values.reporting_manager}
              handleChange={handleChange}
              placeholder="Enter Reporting Manager"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="joining_date"
              type="date"
              labelText="Joining Date"
              value={values.joining_date}
              handleChange={handleChange}
              placeholder="Enter Joining Date"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="salary_details"
              type="text"
              labelText="Salary Details"
              value={values.salary_details}
              handleChange={handleChange}
              placeholder="Enter Salary Details"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <FormRow
              name="emergency_contact_details"
              type="text"
              labelText="Emergency Contact Details"
              value={values.emergency_contact_details}
              handleChange={handleChange}
              placeholder="Enter Emergency Contact Details"
            />
          </div>
        </div>
        <div className="bttn">
          <button
            type="submit"
            className="btn btn-block"
            // onClick={(e) => handleRegistration(e)}
          >
            Submit
          </button>
        </div>
        {message ? message : null}
        <div className="btmq">
          {message ? message : null}
          Already have an account? <Link to="/signin" className="--primary-500">Sign in</Link>
        </div>
      </form>
    </SignUpWrapper>
  )
}

export default Signup
