export const GET_GOALS_PROJECT_BEGIN = "GET_GOALS_PROJECT_BEGIN"
export const GET_GOALS_PROJECT_SUCCESS = "GET_GOALS_PROJECT_SUCCESS"
export const GET_GOALS_PROJECT_ERROR = "GET_GOALS_PROJECT_ERROR"

export const CREATE_GOAL_BEGIN = "CREATE_GOAL_BEGIN"
export const CREATE_GOAL_SUCCESS = "CREATE_GOAL_SUCCESS"
export const CREATE_GOAL_ERROR = "CREATE_GOAL_ERROR"

export const UPDATE_GOAL_BEGIN = "UPDATE_GOAL_BEGIN"
export const UPDATE_GOAL_SUCCESS = "UPDATE_GOAL_SUCCESS"
export const UPDATE_GOAL_ERROR = "UPDATE_GOAL_ERROR"

export const GET_SINGLE_GOAL_BEGIN = "GET_SINGLE_GOAL_BEGIN"
export const GET_SINGLE_GOAL_SUCCESS = "GET_SINGLE_GOAL_SUCCESS"
export const GET_SINGLE_GOAL_ERROR = "GET_SINGLE_GOAL_ERROR"

export const OPEN_GOAL_MODAL = "OPEN_GOAL_MODAL"
export const CLOSE_GOAL_MODAL = "CLOSE_GOAL_MODAL"

export const SET_EDITING_GOAL_BEGIN = "SET_EDITING_GOAL_BEGIN"
export const SET_EDITING_GOAL_SUCCESS = "SET_EDITING_GOAL_SUCCESS"
export const SET_EDITING_GOAL_ERROR = "SET_EDITING_GOAL_ERROR"

export const DELETE_GOAL_BEGIN = "DELETE_GOAL_BEGIN"
export const DELETE_GOAL_SUCCESS = "DELETE_GOAL_SUCCESS"
export const DELETE_GOAL_ERROR = "DELETE_GOAL_ERROR"

export const OPEN_MANAGE_GOAL_MODAL = "OPEN_MANAGE_GOAL_MODAL"
export const CLOSE_MANAGE_GOAL_MODAL = "CLOSE_MANAGE_GOAL_MODAL"

export const GET_GOALS_REPORT_BEGIN = "GET_GOALS_REPORT_BEGIN"
export const GET_GOALS_REPORT_SUCCESS = "GET_GOALS_REPORT_SUCCESS"
export const GET_GOALS_REPORT_ERROR = "GET_GOALS_REPORT_ERROR"
