import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeTemplateModal, deleteTemplate, getAllTemplates, openTemplateModal } from '../../../redux';
import TemplatesModal from './TemplatesModal';
import { AddSharpIcon, DeleteIcon, DriveFileRenameOutlineSharpIcon, MoreHorizRoundedIcon } from '../../../assets/icons';
import DeleteConfirmationModal from '../../Common/DeleteConfirmationModal';
import styled from 'styled-components';

const AllTemplates = ({ setId, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isTemplateModalOpen, templates,  } = useSelector((state) => state.template);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAction, setIsAction] = useState(false)

  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch, isTemplateModalOpen, isModalOpen]);

  useEffect(() => {
    dispatch(getAllTemplates());
  }, [isModalOpen, id]);

  const handleEdit = (id) => {
    setId(id);
    dispatch(openTemplateModal());
  };

  const handleDelete = (id) => {
    setIsModalOpen(true)
    setId(id);
  };
  const handleDeleteClose = () => {
    setIsModalOpen(false)
    setId("")
  };
  const handleDeleteConfirmation = () => {
    dispatch(deleteTemplate(id));
    setId("");
    setIsModalOpen(false)
  };

  const handleAction = (actionId) => {
    setIsAction(!isAction);
    setId(actionId);
  };

  const handelTestItem = (templateId) => {
    navigate('/test_case/test_items', { state: { templateId } });
  };

  return (
    <div>
      <TemplatesModal isTemplateModalOpen={isTemplateModalOpen} id={id} setId={setId} />
      <DeleteConfirmationModal show={isModalOpen} handleClose={handleDeleteClose} handleConfirm={handleDeleteConfirmation} text={'Are you sure you want to delete this Template'}/>
      <div className="tableresponse">
        <div className='table-wrapper'>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th className='text-left' style={{textAlign: 'left'}}>Name</th>
                <th style={{ textAlign: 'end', paddingRight: 30 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {templates && templates.length > 0 ? (
                templates.map((template, i) => (
                  <tr key={template._id}>
                    <td className="" style={{width: "30px"}}>{i + 1}</td>
                    {/* <td className="" style={{textAlign: 'left'}}>{template.name}</td> */}
                    <td className="" style={{ textAlign: "left" }}>
                    <span onClick={() =>handelTestItem(template._id)} className="testcase-link">{template.name}</span>
                    </td>
                    <td style={{ textAlign: 'end', paddingRight: 30 }}>
                    <h4 onClick={(e) => handleAction(template._id)}>
                            <MoreHorizRoundedIcon
                              style={{ fontSize: "25px" }}
                            />
                          </h4>
                          {id === template._id && isAction ? (
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu2"
                            >
                              {/* <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handelTestItem(template._id)}
                              >
                                <AddSharpIcon />
                                <span className="text"> Test Item </span>
                              </button> */}
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleEdit(template._id)}
                              >
                                <DriveFileRenameOutlineSharpIcon />
                                <span className="text"> Edit </span>
                              </button>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => { handleDelete(template._id) }}
                              >
                                <DeleteIcon />
                                <span className="text"> Delete </span>
                              </button>
                            </div>
                          ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">No templates found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const tableWrapper = styled.div`
  .table-wrapper {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
  background-color: red;
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper th,
.table-wrapper td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.table-wrapper th {
  background-color: #f2f2f2;
}
`

export default AllTemplates;


