import {
  CLOSE_PROJECT_MODAL,
  OPEN_PROJECT_MODAL,
  GET_ALL_PROJECTS_BEGIN,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_ERROR,
  CREATE_PROJECT_BEGIN,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  SELECT_PROJECT,
  SET_EDIT_PROJECT,
  EDIT_MODAL_OPEN,
  EDIT_MODAL_CLOSE,
  UPDATE_PROJECT,
  DRAG_CATEGORY,
  GET_ALL_DEVPRO_BEGIN,
  GET_ALL_DEVPRO_SUCCESS,
  GET_ALL_DEVPRO_ERROR,
} from "./projectTypes"

const initialState = {
  projects: [],
  loading: false,
  reports:[],
  editingProject: null,
  isEditingProject: false,
  selectedProject: null,
  isProjectModalOpen: false,
  editProjectModalOpen: false,
}

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_PROJECT_MODAL:
      return { ...state, isProjectModalOpen: false }
    case OPEN_PROJECT_MODAL:
      return { ...state, isProjectModalOpen: true }
    case GET_ALL_PROJECTS_BEGIN:
      return { ...state, loading: true }
    case GET_ALL_PROJECTS_SUCCESS:
      return { ...state, loading: false, projects: action.payload.projects }
    case GET_ALL_PROJECTS_ERROR:
      return { ...state, loading: false }
    case CREATE_PROJECT_BEGIN:
      return { ...state, loading: true }
    case CREATE_PROJECT_SUCCESS:
      return { ...state, loading: false, isProjectModalOpen: false }
    case CREATE_PROJECT_ERROR:
      return { ...state, loading: false }

      case GET_ALL_DEVPRO_BEGIN:
        return { ...state, loading: true }
      case GET_ALL_DEVPRO_SUCCESS:       
  
        return { ...state, loading: false,     
          
          reports: action.payload.report,      
        }
      case GET_ALL_DEVPRO_ERROR:
        return { ...state, loading: false }  
    case SELECT_PROJECT:
      const project = state.projects.find((i) => i._id === action.payload.id)
      return { ...state, selectedProject: project }
    case SET_EDIT_PROJECT:
      const editingProject = state.projects.find(
        (i) => i._id === action.payload.id
      )
      return {
        ...state,
        editingProject: editingProject,
        isEditingProject: true,
      }
    case EDIT_MODAL_OPEN:
      return { ...state, editProjectModalOpen: true }
    case EDIT_MODAL_CLOSE:
      return {
        ...state,
        editProjectModalOpen: false,
        editingProject: null,
        isEditingProject: false,
      }
    case UPDATE_PROJECT:
      if (state.selectedProject) {
        if (state.selectedProject._id === action.payload.id) {
          const key = Object.keys(action.payload.data)

          const b = key[0]
          // console.log(action.payload.updatedField[b])
          const a = {
            ...state.selectedProject,
            [b]: action.payload.data[b],
          }

          return { ...state, editingProject: a, selectedProject: a }
        }
      }
      return { ...state }
    case DRAG_CATEGORY:
      // console.log(action.payload)
      const { sourceIndex, destinationIndex } = action.payload
      const { selectedProject } = state

      // console.log(selectedProject.statusCategories)
      const reorderedStatusCategories = Array.from(
        selectedProject.statusCategories
      )
      const [removed] = reorderedStatusCategories.splice(sourceIndex, 1)
      reorderedStatusCategories.splice(destinationIndex, 0, removed)

      return {
        ...state,
        selectedProject: {
          ...selectedProject,
          statusCategories: reorderedStatusCategories,
        },
      }
    default:
      return { ...state }
  }
}

export default projectReducer
