import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddSharpIcon, ArrowBackIcon } from "../../assets/icons";
import { TestCaseWrapper } from "../../assets/styles/TestCase";
import AllTestCases from "./AllTestCases";
import TestCaseModal from "./TestCaseModal";
import { openTestCaseModal } from "../../redux";
import TestCaseFilters from "./TestDetails/TestCaseFilters";
import styled from "styled-components";

const CreateTestCases = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTestCaseModalOpen, } = useSelector((state) => state.testCase);
  const [id, setId] = useState("");
  
  return (
    <div className="">
    <TestCaseModal id={id} setId={setId} isTestCaseModalOpen={isTestCaseModalOpen}/>
      <TestCaseWrapper>
      <Wrapper>
        <div className="testCaseHeader">
        <div className="testCaseHeader" style={{display: 'flex',  marginLeft: 8}}>
              <div>
                <TestCaseFilters testCaseId={id} />
              </div>
          <button
            className="btn add-btn mt-2"
            type="button"
            onClick={() => {
              setId("");
              dispatch(openTestCaseModal())
            }}
          >
            <AddSharpIcon style={{ color: "#fff", fontSize: "20px",  }} /> New
          </button>
        </div>
        </div>
        <div className="" >
          <AllTestCases setId={setId} id={id} />
        </div>
        </Wrapper>
      </TestCaseWrapper>
    </div>
  );
};

const Wrapper = styled.div`
  .testCaseHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    padding-right: 30px;  
    gap: 10px;  
  }
  .rangeContainer {
    display: flex;
    justify-content: justify-center;
    align-items: center; 
    gap: 10px;
  }
`;

export default CreateTestCases;
