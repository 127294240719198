import React, { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import ProgressBar from "react-bootstrap/ProgressBar"
import { attendanceReport } from "../../../redux"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { getInitials } from "../../../utils/getInitialName"
import attendanceDownload from "../../../utils/attendanceDownload"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
const initialState = {
  user: [],
}

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const dispatch = useDispatch()
  const { attendance } = useSelector((state) => state.worklog)
  const [userList] = useState([])
  const [values, setValues] = useState(initialState)

  function goToPreviousMonth() {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() - 1,
        1
      )
      return newDate
    })
    setValues(initialState)
  }

  function goToNextMonth() {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() + 1,
        1
      )
      return newDate
    })
    setValues(initialState)
  }

  useEffect(() => {
    const month = moment(currentDate).format("MM")
    const year = moment(currentDate).format("YYYY")
    month !== undefined &&
      year !== undefined &&
      dispatch(attendanceReport(monthYear))
  }, [currentDate])

  useEffect(() => {
    if (attendance && attendance.users !== undefined) {
      const newUsers = attendance.users.map((item) => ({
        value: item._id,
        label: item.name,
      }))

      const uniqueUserList = [
        ...new Set(newUsers.map((user) => user.value)),
      ].map((value) => {
        return newUsers.find((user) => user.value === value)
      })

      userList.push(uniqueUserList)
    }
  }, [attendance])

  function generateCalendarDays() {
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()
    const daysInMonth = new Date(year, month + 1, 0).getDate()

    const calendarDays = []

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i)
      const day = date.getDate()
      const week = date.toLocaleString("default", { weekday: "short" })
      calendarDays.push({ day, week })
    }

    return calendarDays
  }
  const calendarDays = generateCalendarDays()
  const monthYear = currentDate.toLocaleString("default", {
    month: "long",
    year: "numeric",
  })

  const handleUserSelect = (e) => {
    setValues({ ...values, user: e })

    const userLists = e.map((i) => i.value)

    dispatch(attendanceReport(monthYear, userLists))
  }

  console.log(attendance)

  return (
    <Wrapper>
      <div className="calendar">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-5    lg:tw-px-8    tw-px-3 ">
        <Select
          value={values.user}
          options={userList[0]}
          onChange={handleUserSelect}
          isMulti={true}
          style={{ fontSize: "12px", textTransform: "capitalize" }}
          placeholder="Select user"
        />
        <div className="download-btn">
          <div className="navigation-buttons">
            <span onClick={goToPreviousMonth}>
              {"<"}
            </span>
            <h2>{monthYear}</h2>
            <span onClick={goToNextMonth}>
                {">"}
            </span>
          </div>
          <button
            className="btn add-btn"
            onClick={() => attendanceDownload("calender", "attendance")}
          >
            Download
          </button>
        </div>
        </div>
        <Paper sx={{ width: "100%", overflow: "auto",margin:"0 32px" }} className="table   ">
          <TableContainer sx={{ maxHeight: 540 }}>
            <Table stickyHeader aria-label="sticky table" id="calender">
              <TableHead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 0,
                  background: "#fff",
                }}
              >
                <TableRow>
                  <TableCell className="brht">User</TableCell>
                  {calendarDays.map((month, index) => {
                    return (
                      <th
                        key={index}
                        style={{
                          color:
                            month.week === "Sat" || month.week === "Sun"
                              ? "cadetblue"
                              : "",
                        }}
                      >
                        {month.day}
                        <br />
                        {month.week}
                      </th>
                    )
                  })}
                  <TableCell className="brht">
                    Working
                    <br /> Hours
                  </TableCell>
                  <TableCell className="brht">
                    Actual
                    <br /> Hours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendance &&
                  attendance.users !== undefined &&
                  attendance.users.map((user, i) => {
                    const total = attendance.worklogs.reduce((acc, log) => {
                      if (user._id === log.log.userId) {
                        acc += log.log.timeSpent
                      }
                      return acc
                    }, 0)
                    /**find week off */
                    const [month, year] = monthYear.split(" ")
                    const monthNumber =
                      new Date(Date.parse(`${month} 1, ${year}`)).getMonth() + 1

                    const lastDayOfMonth = new Date(
                      year,
                      monthNumber,
                      0
                    ).getDate()

                    let count = 0

                    for (let day = 1; day <= lastDayOfMonth; day++) {
                      const currentDate = new Date(year, monthNumber - 1, day)
                      const dayOfWeek = currentDate.getDay()

                      // Sunday is 0, Saturday is 6
                      if (dayOfWeek === 0 || dayOfWeek === 6) {
                        count++
                      }
                    }
                    //find total working hour
                    const workingHour = (lastDayOfMonth - count) * 8

                    if (total !== 0) {
                      return (
                        <TableRow>
                          <TableCell className="upperCase color brht">
                            <p>
                              <span> {getInitials(user.name)}</span> {user.name}
                            </p>
                          </TableCell>

                          {calendarDays.map((month, index) => {
                            const worklog = attendance.worklogs.filter((log) => {
                              if (
                                month.day === log.days &&
                                user._id === log.log.userId
                              ) {
                                return log
                              }
                            })

                            let progress = 0,
                              label = 0
                            if (worklog.length > 0) {
                              let count = 0
                              for (let i = 0; i < worklog.length; i++) {
                                count += worklog[i].log.timeSpent
                              }
                              label = Math.floor(count / 60)
                              progress = (count / (8 * 60)) * 100
                            }

                            return (
                              <TableCell
                                style={{
                                  backgroundColor:
                                    month.week === "Sat" || month.week === "Sun"
                                      ? "#f1fbfb"
                                      : "#fff",
                                }}
                              >
                                {progress !== 0 ? (
                                  <div className="proBar">
                                    {label}
                                    <ProgressBar now={progress} />
                                  </div>
                                ) : (
                                  <div className="proBars">
                                    <span style={{ visibility: "hidden" }}>
                                      0
                                    </span>
                                    <ProgressBar now={0} />
                                  </div>
                                )}
                              </TableCell>
                            )
                          })}

                          <TableCell
                            className={`${total / 60 >= workingHour ? "" : "red"
                              }`}
                          >
                            {Math.floor(total / 60)}
                          </TableCell>
                          <TableCell>{workingHour}</TableCell>
                        </TableRow>
                      )
                    } else {
                      return null
                    }
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .red {
    color: red;
  }
  .blue {
    color: blue;
  }
  /* Base styles */
  .navigation-buttons {
    text-align: right;
    float: right;
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-left: 10px;
   height:38px;
  }
  .navigation-buttons h2 {
    font-size: 12px;
    margin: 0 10px;
  }
  .navigation-buttons span {
    padding: 4px 8px;
    background: #f9f9f9;
    cursor:pointer;
    &:hover {
      color: var(--blue);
    }
  }
  table{
    width:100%;
  }
  .table {
  
     max-width: 1640px; 
      @media(max-width: 1600px){
        max-width: 1140px;
      }
     @media(max-width: 1440px){
        max-width: 1040px;
      }
       @media(max-width: 1240px){
        max-width: 865px;
      }
        @media(max-width: 1140px){
        max-width:980px;
      }
          @media(max-width: 1024px){
        max-width:950px;
      }
          @media(max-width: 992px){
        max-width:870px;
      }
     @media(max-width: 880px){
        max-width:800px;
      }
           @media(max-width: 769px){
        max-width:735px;
      }
  }
  tr {
    background: #fff;
    background-color: #fff;
  }
  th,
  td {
    font-size: 10px;
  }
  .color {
    background: #f9f9f9;
  }
    .css-y8ay40-MuiTableCell-root {
    padding: 0px !important;
    text-align:center;
    line-height:unset !important;
  }

  .css-1ex1afd-MuiTableCell-root {
    padding: 6px !important;
      text-align:center;
  } 
  .css-8coetn {
    padding: 0px !important;
      text-align:center;
  }

  .brht {
    border-right: 1px solid #cdc4c4;
    display: table-cell;
   padding: 0 15px !important;
    span {
      border: 1px solid var(--blue);
      border-radius: 50%;
      padding: 5px;
      height: 25px;
      font-size: 10px;
      display: flex;
      width: 25px;
      align-items: center;
      background: var(--blue);
      justify-content: center;
      color: rgb(255, 255, 255);
      margin-right: 5px;
    }
    p {
      display: flex;
      align-items: center;
      margin: 0;
      word-break: normal !important;
    }
  }

  // .calendar {
  //   width: 90%;
  // }

  .proBar {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .css-b62m3t-container {
    width: 30%;
    text-transform: capitalize;
    font-size: 12px;
  }
  .css-3w2yfm-ValueContainer {
    flex-wrap: nowrap;
  }

  .css-qbdosj-Input {
    input {
      &:focus {
        outline: 0px solid blue !important;
        border: 0px solid red !important;
      }
    }
  }
  select {
    text-transform: capitalize;
    font-size: 12px;
  }
  option {
    text-transform: capitalize;
  }
  /* Media queries for responsiveness */
  @media only screen and (max-width: 768px) {
    /* Adjust the navigation-buttons for smaller screens */
    .navigation-buttons {
      justify-content: space-between;
    }
    .navigation-buttons h2 {
      font-size: 10px;
      margin: 0 5px;
    }
    .navigation-buttons button {
      padding: 6px;
    }

    /* Make the table responsive */
    table {
      font-size: 8px;
    }
    th,
    td {
      padding: 4px;
    }
  }

  /* Additional media queries for other screen sizes if needed */

  /* For example, to handle very small screens or mobile devices */
  @media only screen and (max-width: 480px) {
    .navigation-buttons h2 {
      font-size: 8px;
      margin: 0 3px;
    }
    .navigation-buttons button {
      padding: 4px;
    }

    table {
      font-size: 6px;
    }
    th,
    td {
      padding: 2px;
    }
  }
`
export default Calendar
