import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { PhoneNumberUtil } from "google-libphonenumber"
import Modal from "react-bootstrap/Modal"
import { FormRow } from "../Common"
import { useDispatch, useSelector } from "react-redux"
import Form from "react-bootstrap/Form"
import {
  addUserToClient,
  closeAddUserToClientModal,
  getClientUsers,
  displayUserByEmail,
  editUser,
} from "../../redux"
import { UserTableCell } from "../../assets/styles/Settings"
import { updateClientUserActivation } from "../../redux/client/clientActions"

const initialState = {
  name: "",
  email: "",
  contactNumber: "",
  country: "",
  address: "",
  clientId: "",
}
const Users = () => {
  const dispatch = useDispatch()
  const {
    isAddClientUserModalOpen,
    client,
    isEditingClientUser,
    editingClientUser,
    isUserListModalOpen
  } = useSelector((state) => state.client)
  const [values, setValues] = useState(initialState)

  const [isValid, setIsValid] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState("US")

  useEffect(() => {
    client && dispatch(getClientUsers(editingClientUser?._id, editingClientUser?.is_active))
  }, [isUserListModalOpen, editingClientUser?.is_active])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isEditingClientUser) {
      // console.log(values)
      await dispatch(editUser({ ...values, _id: editingClientUser._id }))
      dispatch(closeAddUserToClientModal())
      dispatch(getClientUsers(client._id, editingClientUser?.is_active))
    } else {
      await dispatch(addUserToClient({ ...values, clientId: client._id }))
    }
    setValues(initialState)
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const func = async () => {
    const res = await dispatch(displayUserByEmail(values.email))

    if (res.data.message !== "not exists") {
      const { name, address, contact_number } = res.data.data[0]
      setValues({ ...values, name, address, contactNumber: contact_number })
    } else {
    }
  }
  useEffect(() => {
    if (values.email.slice(-4) === ".com") {
      func()
    }
  }, [values.email])

  useEffect(() => {
    editingClientUser &&
      setValues({
        ...values,
        name: editingClientUser.name,
        email: editingClientUser.email,
        contactNumber: editingClientUser.contact_number,
        country: editingClientUser.country,
        address: editingClientUser.address,
        clientId: editingClientUser.clientId,
      })
  }, [isEditingClientUser])

  const handleContactChange = (e) => {
    setValues({ ...values, ["contact_number"]: e })
    validatePhoneNumber(e, selectedCountry)
  }

  const handleCountryChange = (country) => {
    setSelectedCountry(country)
    setValues({ ...values, ["country"]: country })

    validatePhoneNumber(values.contact_number, country)
  }

  const validatePhoneNumber = (input, country = selectedCountry) => {
    try {
      // Create an instance of PhoneNumberUtil
      const phoneUtil = PhoneNumberUtil.getInstance()

      // Parse the phone number
      const parsedNumber = phoneUtil.parseAndKeepRawInput(input, country)

      // Check if the phone number is valid for the selected country
      const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(
        parsedNumber,
        country
      )

      setIsValid(isValidPhoneNumber)
    } catch (error) {
      // Handle any errors during phone number validation
      setIsValid(false)
    }
  }

  const handleToggleActivation = async (user) => {
    try {
      const newActiveStatus = !user.is_active
      await dispatch(updateClientUserActivation(user._id, newActiveStatus))
      user.is_active = newActiveStatus
      dispatch(getClientUsers(user?._id, user?.is_active))
    } catch (error) {
      //   console.log(error);
    }
  }

  return (
    <>
      <Wrapper>
        <Modal
          show={isAddClientUserModalOpen}
          onHide={() => {
            dispatch(closeAddUserToClientModal())
            setValues(initialState)
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditingClientUser ? "edit user" : "Add User"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit} className="tw-flex tw-flex-col tw-gap-3">
              <div className="form-group  ">
                <FormRow
                  name="email"
                  value={values.email}
                  handleChange={handleChange}
                  type="text"
                  labelText="email*"
                  required={true}
                  placeholder="Enter Email"
                />
              </div>

              <div className="form-group  ">
                <FormRow
                  name="name"
                  value={values.name}
                  handleChange={handleChange}
                  type="text"
                  labelText="name"
                  required={true}
                  placeholder="Enter Name"
                />
              </div>

              <div className="form-group  ">
                <FormRow
                  name="address"
                  value={values.address}
                  handleChange={handleChange}
                  type="text"
                  labelText="Address"
                  placeholder="Enter Address"
                />
              </div>
              <div className="form-group  ">
                <label>Contact Number</label>
                <br />

                <PhoneInput
                  international
                  defaultCountry={values.country || selectedCountry}
                  value={values.contactNumber}
                  onChange={handleContactChange}
                  country={values.country}
                  onCountryChange={handleCountryChange}
                />
                {isValid ? null : (
                  <span className="validatePError">invalid phone number.</span>
                )}
              </div>

              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="">
                  <UserTableCell style={{ textAlign: "left" }}>
                  {isEditingClientUser && <div className="tw-flex tw-gap-4">
                    <span className="" style={{ fontSize: 14 }}>Active</span>
                    <Form.Switch
                      id={`switch-${editingClientUser?._id}`}
                      checked={editingClientUser?.is_active}
                      onChange={() => handleToggleActivation(editingClientUser)}
                      style={{ paddingTop: 2 }}
                    />
                  </div>}
                  </UserTableCell>
                </div> 
                <div className="btn-container">
                  <button
                    className="btn btn-block clear-btn"
                    type="button"
                    onClick={() => {
                      dispatch(closeAddUserToClientModal())
                      setValues(initialState)
                    }}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-block submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Wrapper>
    </>
  )
}

export default Users

const Wrapper = styled.div`
  h4 {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #d6dfde;
    background-color: aliceblue;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .modal-content {
    border-radius: 1rem;
  }

  button {
    align-self: end;
    height: 40px;
  }

  .btn {
    border-radius: 50px;
    color: var(--primary-500);
    font-size: 18px;
    font-weight: 600 !important;

    text-align: center;
    color: #fff;
    margin: 10px;
  }

  .btn:hover {
    color: inherit;
    background: inherit;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-self: flex-end;
    margin-top: 0.5rem;
  }
  .clear-btn {
    background: var(--primary-500);
    color: var(--white);
  }
  .clear-btn:hover {
    background: var(--primary-500);
    color: var(--white);
  }
  .submit-btn {
    background: var(--primary-500);
    color: var(--white);
  }

  .PhoneInput input {
    height: 35px;
    min-width: 0;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--borderRadius);
    background: var(--white);
    border: 1px solid var(--grey-200);
  }
  .PhoneInput .PhoneInputCountry {
    border: 1px solid grey;
    padding: 3px;
    border-radius: var(--borderRadius);
    background: var(--white);
    border: 1px solid var(--grey-200);
  }
`
