function setCookie(name, value, expires, path, httpOnly = false) {
  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  if (expires) {
    const expirationDate = new Date(Date.now() + expires * 1000)
    cookie += `; expires=${expirationDate.toUTCString()}`
  }

  if (path) {
    cookie += `; path=${path}`
  }

  if (httpOnly) {
    cookie += "; HttpOnly"
  }

  document.cookie = cookie
}
export default setCookie
