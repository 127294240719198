function attendanceDownload(tableID, logType) {
  var csv_data = []

  var rows = document.getElementById(tableID).getElementsByTagName("tr")

  for (var i = 0; i < rows.length; i++) {
    var cols = rows[i].querySelectorAll("td,th")

    var csvrow = []

    var userCol = cols[0].querySelector("p")

    if (userCol) {
      const userName = userCol.textContent.trim().slice(2).trim()
      csvrow.push(`"${userName}"`)
    }

    for (var j = 0; j < cols.length - 3; j++) {
      if (i === 0 && j === 0) {
        csvrow.push(`Username`)
      }

      // Remove HTML tags from cell content using regex
      var cellContent = cols[j + 1].innerHTML.replace(/<[^>]+>/g, "")
      cellContent = cellContent === "0" ? "-" : cellContent

      csvrow.push(cellContent)
    }

    csvrow.push(`"${cols[cols.length - 2].innerText.trim()}"`)
    csvrow.push(`"${cols[cols.length - 1].innerText.trim()}"`)
    csv_data.push(csvrow.join(","))
  }

  csv_data = csv_data.join("\n")

  downloadCSVFile(csv_data, logType)
}

function downloadCSVFile(csv_data, logType) {
  const CSVFile = new Blob([csv_data], {
    type: "text/csv",
  })

  var temp_link = document.createElement("a")

  temp_link.download = logType
  var url = window.URL.createObjectURL(CSVFile)
  temp_link.href = url

  temp_link.style.display = "none"
  document.body.appendChild(temp_link)

  temp_link.click()
  document.body.removeChild(temp_link)
}

export default attendanceDownload
