import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AddSharpIcon, DeleteIcon, DriveFileRenameOutlineSharpIcon, MoreHorizRoundedIcon } from '../../assets/icons';
import { copyTemplateItemsToTestCaseItems, deleteTestCase, getAllTestCases, openTestCaseModal, setDateRange } from "../../redux";
import TestCaseModal from "./TestCaseModal";
import DeleteConfirmationModal from "../Common/DeleteConfirmationModal";
import moment from "moment";
import { formatDate } from "../../utils/dateUtils";

const initialDateState = {
  startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

const AllTestCases = ({ setTestCaseModal, setId, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { testCases, dateRange, isTestCaseModalOpen, loading } = useSelector((state) => state.testCase);
  const [isTestCaseDelete, setIsTestCaseDelete] = useState(false);
  const [isAction, setIsAction] = useState(false);

  const [dateRanges, setDateRanges] = useState(initialDateState);

  useEffect(() => {
    if(!dateRange) {
      dispatch(setDateRange(dateRanges))
    } else {
      dispatch(setDateRange(dateRange))
    }
    dispatch(getAllTestCases(dateRange));
  }, [dispatch, isTestCaseDelete, isTestCaseModalOpen, id]);

  const handleEditClick = (testCaseId) => {
    setId(testCaseId);
    dispatch(openTestCaseModal()); // Load test case data into the modal
    dispatch(getAllTestCases(dateRange));
  };
  
  //All about delete functions
  const handleDeleteClick = (testCaseId) => {
    setId(testCaseId);
    setIsTestCaseDelete(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteTestCase(id, dateRange));
    setIsTestCaseDelete(false);
    setId("");
  };
  const handleDeleteClose = () => {
    setId("");
    setIsTestCaseDelete(false);
  };

  const handleItemsClick = (testCaseId, templateId, testCaseName) => {
    dispatch(copyTemplateItemsToTestCaseItems(templateId, testCaseId))
    dispatch(getAllTestCases(dateRange));
    navigate(`/test_case/details`, { state: { testCaseId,  templateId, testCaseName} });
  };

  const handleAction = (actionId) => {
    setIsAction(!isAction);
    setId(actionId);
  };


  return (
    <div>
    <TestCaseModal isTestCaseModalOpen={isTestCaseModalOpen}  id={id} setId={setId} setDateRanges={setDateRanges}  />
    <DeleteConfirmationModal show={isTestCaseDelete} handleClose={handleDeleteClose} handleConfirm={handleDeleteConfirm} text={'Are you sure you want to delete this Test Case'} />
    {/* {loading ? <Loading /> : <></>} */}

      <div className="tableresponse">
      <div className='table-wrapper'>
      <table>
            <thead>
              <tr>
                <th>#</th>
                <th className="text-left" style={{ textAlign: "left" }}>Test Case</th>
                <th className="text-left" style={{ textAlign: "left" }}>Start Date</th>
                <th style={{ textAlign: "end", paddingRight: 30 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {testCases && testCases.length > 0 ? (
                testCases?.map((testCase, i) => (
                  <tr key={testCase._id}>
                    <td className="" style={{ width: "30px" }}>{i + 1}</td>
                    <td className="" style={{ textAlign: "left" }}>
                    <span onClick={() => handleItemsClick(testCase._id, testCase.template_id, testCase.name)} className="testcase-link">{testCase.name}</span>
                    </td>
                    <td className="" style={{ textAlign: "left" }}>{moment(testCase.startDate).format("DD/MM/YYYY")}</td>

                    <td style={{ textAlign: "end", paddingRight: 30 }}>
                    <h4 onClick={(e) => handleAction(testCase._id)}>
                            <MoreHorizRoundedIcon
                              style={{ fontSize: "25px" }}
                            />
                          </h4>
                          {id === testCase._id && isAction ? (
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu2"
                            >
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleEditClick(testCase._id)}
                              >
                                <DriveFileRenameOutlineSharpIcon />
                                <span className="text"> Edit </span>
                              </button>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => { handleDeleteClick(testCase._id) }}
                              >
                                <DeleteIcon />
                                <span className="text"> Delete </span>
                              </button>
                            </div>
                          ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">No test cases found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllTestCases;
