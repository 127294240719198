import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import Select from "react-select"
import { useAppContext } from "../../../context/context/appContext"
import { FormRow } from "../../Common"
import "../../../styles/filters.css"

import { useDispatch, useSelector } from "react-redux"
import {
  getAllWorklogs,
  getDailyLogs,
  getWeeklyLogs,
  getTasksByProject,
} from "../../../redux"
const initialState = {
  project: [],
  projectId: [],
  taskId: [],
  task: [],
  user: [],
  userId: [],
  startDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: moment(new Date()).format("YYYY-MM-DD"),
}

const LogFilters = ({ weekly, daily }) => {
  const [values, setValues] = useState(initialState)
  const [projectList, setProjectList] = useState([])
  const [taskList, setTaskList] = useState([])
  const [userList, setUserList] = useState([])
  const [taskOptions, setTaskOptions] = useState([])
  const { user, users } = useAppContext()
  const dispatch = useDispatch()
  const { projects } = useSelector((state) => state.project)

  useEffect(() => {
    setValues({
      ...values,
      projectId: projectList,
      taskId: taskList,
      userId: userList,
    })
  }, [projectList, taskList, userList])

  useEffect(() => {
    if (weekly) {
      const previousMonday = getPreviousMonday()
      setValues({
        ...values,
        startDate: moment(previousMonday).format("YYYY-MM-DD"),
      })
    }
  }, [weekly])

  function getPreviousMonday() {
    const currentDate = new Date()
    const currentDay = currentDate.getDay() // 0 (Sunday) to 6 (Saturday)
    const daysToSubtract = currentDay === 0 ? 6 : currentDay - 1
    const previousMonday = new Date(
      currentDate.setDate(currentDate.getDate() - daysToSubtract)
    )
    return previousMonday
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  // const handleProjectSelect = async (e) => {
  //   await setValues({ ...values, project: e })
  //   const projectlist = await e.map((i) => i.id)

  //   await setProjectList(projectlist)
  //   //   console.log(e)
  //   if (e.length > 0) {
  //     getTasksByProject(e.slice(-1)[0].id).then((data) => {
  //       //    console.log(data)
  //       const options = data.map((i) => {
  //         return { label: i.title, value: i.title, id: i._id }
  //       })
  //       setTaskOptions([...taskOptions, ...options])
  //     })
  //   }
  // }

  const handleProjectSelect = async (e) => {
    await setValues({ ...values, project: e })
    const projectlist = await e.map((i) => i.id)
    await setProjectList(projectlist)

    if (e.length > 0) {
      dispatch(getTasksByProject(e.slice(-1)[0].id)).then((data) => {
        const options = data.map((i) => {
          return { label: i.title, value: i.title, id: i._id }
        })
        setTaskOptions(options)
      })
    } else {
      setTaskOptions([]) // Clear task options when no project is selected
    }
  }

  const handleTaskSelect = (e) => {
    //    console.log(e)
    setValues({ ...values, task: e })
    const taskList = e.map((i) => i.id)
    setTaskList(taskList)
  }

  const handleUserSelect = (e) => {
    setValues({ ...values, user: e })
    const userList = e.map((i) => i.value)
    setUserList(userList)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(weeklyLogs, values)
    if (weekly) {
      dispatch(getWeeklyLogs({ filters: values }))
    } else if (daily) {
      dispatch(getDailyLogs({ filters: values }))
    } else {
      dispatch(getAllWorklogs({ filters: values }))
    }
  }

  const clearOptions = () => {
    setValues(initialState)
    setProjectList([])
    setTaskList([])
    setUserList([])
    setTaskOptions([])
    dispatch(getWeeklyLogs({ filters: values }))
    dispatch(getDailyLogs({ filters: values }))
    dispatch(getAllWorklogs({ filters: values }))
  }

  const projectOptions =
    projects &&
    projects.map((i) => {
      return { label: i.title, value: i.title, id: i._id }
    })

  // const DropdownCheckbox = ({ options, selectedOptions, handleSelect }) => {
  //   const [isOpen, setIsOpen] = useState(false)

  //   const toggleDropdown = () => {
  //     setIsOpen(!isOpen)
  //   }

  //   const handleOptionClick = (option) => {
  //     const selected = [...selectedOptions]
  //     const selectedIndex = selected.findIndex((item) => item.id === option.id)
  //     if (selectedIndex > -1) {
  //       selected.splice(selectedIndex, 1)
  //       setSelectedProjectLists()
  //     } else {
  //       selected.push(option)
  //     }
  //     handleSelect(selected)
  //   }

  //   return (
  //     <div className={`dropdown `}>
  //       <button
  //         className="dropdown-toggle"
  //         type="button"
  //         onClick={() => toggleDropdown()}
  //       >
  //         Select
  //       </button>
  //       {isOpen && options.length > 0 && (
  //         <ul
  //           className="dropdown-menu"
  //           style={{
  //             display: "block",
  //             height: "auto",

  //             width: "100%",
  //           }}
  //         >
  //           {options.map((option) => (
  //             <li key={option.id} style={{ margin: "2px" }}>
  //               <label style={{ cursor: "pointer" }}>
  //                 <input
  //                   style={{ height: "15px", width: "15px" }}
  //                   type="checkbox"
  //                   checked={selectedOptions.some(
  //                     (item) => item.id === option.id
  //                   )}
  //                   onChange={() => handleOptionClick(option)}
  //                 />
  //                 <span style={{ marginLeft: "15px" }}>
  //                   {capitalizeFirstLetter(option.label)}
  //                 </span>
  //               </label>
  //             </li>
  //           ))}
  //         </ul>
  //       )}
  //     </div>
  //   )
  // }

  // const CheckboxOption = ({ children, ...props }) => (
  //   <components.Option {...props} className="d-flex">
  //     <input type="checkbox" checked={props.isSelected} onChange={() => null} />
  //     {children}
  //   </components.Option>
  // )

  return (
    <Wrapper>
      <form onSubmit={handleSubmit} className="log-container  lg:tw-px-8   tw-px-3 ">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-xs-6">
            <label htmlFor="project">Project</label>
            {/* <DropdownCheckbox
              options={projectOptions}
              selectedOptions={values.project}
              handleSelect={handleProjectSelect}
            /> */}
            <Select
              value={values.project}
              options={projectOptions}
              //  components={{ Option: CheckboxOption }}
              onChange={handleProjectSelect}
              isMulti={true}
            />
          </div>
          <div className="col-lg-3 col-md-6 col-xs-6">
            <label htmlFor="task">Task</label>

            {/* <DropdownCheckbox
              options={taskOptions}
              selectedOptions={values.task}
              handleSelect={handleTaskSelect}
            /> */}
            <Select
              value={values.task}
              options={taskOptions}
              onChange={handleTaskSelect}
              isMulti={true}
            />
          </div>
          <div className="col-lg-2 col-md-6 col-xs-6">
            {user.role === "admin" && (
              <>
                <label htmlFor="user">Users</label>
                {/* <DropdownCheckbox
                  options={users}
                  selectedOptions={values.user}
                  handleSelect={handleUserSelect}
                /> */}
                <Select
                  value={values.user}
                  options={users}
                  onChange={handleUserSelect}
                  isMulti={true}
                />
              </>
            )}
          </div>

          <div className="col-lg-2 col-md-6 col-xs-6 sdf">
            <FormRow
              labelText="from"
              type="date"
              name="startDate"
              value={values.startDate}
              handleChange={handleChange}
            />
          </div>
          <div className="col-lg-2 col-md-6 col-xs-6 sdf">
            <FormRow
              labelText="to"
              type="date"
              name="toDate"
              value={values.toDate}
              handleChange={handleChange}
            />
          </div>
          {/* {weekly && (
            <div className="col-lg-3 col-xs-12">
              <FormRowSelect
                labelText="Group By"
                name="groupBy"
                value={groupBy}
                handleChange={(e) => setGroupBy(e.target.value)}
                list={["Project", "User"]}
              />
            </div>
          )} */}

          <div className="col-lg-8 col-md-6  col-xs-6 tw-gap-5 tw-flex tw-flex-row">
            <button className="btdf">Apply</button>
            <button  className="btdf" onClick={() => clearOptions()}>Clear</button>
          </div>
        </div>
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 10px;
  margin: 20px 0;

  .css-13cymwt-control {
    border: 1px solid var(--grey-200);
  }
  .css-b62m3t-container {
    font-size: 12px;
    text-transform: capitalize;
  }
  .log-container {
    display: flex;
  }
  .log-container {
    .prjtxt {
      display: flex;
    }
    .row {
      box-shadow: none !important;
      border-left: none !important;
      width: 100%;

      label {
        font-size: var(--font-12);
      }
    }

   
    button {
      background: var(--primary-500-active);
      color: #fff;
      border-radius: 5px;
      // height: 35px;
      width: 25%;
      font-size: var(--font-12);
      margin-top: 10px;
      margin-right: 3px;
      font-weight: 500 !important;
      padding: 8px 12px;
      @media (max-width: 769px) {
        margin-top:28px;
        width: 100%;
      }
    }
    select,
    input {
      cursor: pointer;
      width: 100%;
      font-size: var(--font-12) !important;
    }
    select[name="project"] {
      text-transform: capitalize;
      font-size: var(--font-12);
    }
    select[name="users"] {
      text-transform: capitalize;
      font-size: var(--font-12);
    }
    select[name="task"] {
      text-transform: capitalize;
      font-size: var(--font-12);
    }

    input:focus {
      outline: none !important;
      border: none !important;
    }
    .sdf input:focus {
      outline: 1px solid #2684ff !important;
      border: 1px solid #2684ff !important;
    }
  }

  .css-3w2yfm-ValueContainer {
    flex-wrap: nowrap !important;
  }
`
export default LogFilters
