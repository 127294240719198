import React from "react"
import ReactDOM from "react-dom/client"
import "normalize.css"
import "./index.css"
import { AppProvider } from "./context/context/appContext"
import "./styles/home.css"
import "./styles/client.css"
import "./styles/testcase.css"

import App from "./App"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap-icons/font/bootstrap-icons.css"
import "react-best-tabs/dist/index.css"
import { Provider } from "react-redux"
import store from "./redux/store"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AppProvider>
    <Provider store={store}>
      <App />
    </Provider>

    <ToastContainer position="bottom-right" theme="dark" autoClose={2500} />
  </AppProvider>
)
