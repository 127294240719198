import {
  GET_GOALS_PROJECT_BEGIN,
  GET_GOALS_PROJECT_SUCCESS,
  GET_GOALS_PROJECT_ERROR,
  CREATE_GOAL_BEGIN,
  CREATE_GOAL_SUCCESS,
  CREATE_GOAL_ERROR,
  UPDATE_GOAL_BEGIN,
  UPDATE_GOAL_SUCCESS,
  UPDATE_GOAL_ERROR,
  GET_SINGLE_GOAL_BEGIN,
  GET_SINGLE_GOAL_SUCCESS,
  GET_SINGLE_GOAL_ERROR,
  OPEN_GOAL_MODAL,
  CLOSE_GOAL_MODAL,
  SET_EDITING_GOAL_BEGIN,
  SET_EDITING_GOAL_SUCCESS,
  SET_EDITING_GOAL_ERROR,
  DELETE_GOAL_BEGIN,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_ERROR,
  OPEN_MANAGE_GOAL_MODAL,
  CLOSE_MANAGE_GOAL_MODAL,
  GET_GOALS_REPORT_BEGIN,
  GET_GOALS_REPORT_SUCCESS,
  GET_GOALS_REPORT_ERROR,
} from "./goalTypes"

const initialState = {
  loading: false,
  goals: [],
  isGoalModalOpen: false,
  editingGoal: null,
  isEditingGoal: false,
  isManageGoalModalOpen: false,
}

const goalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_GOAL_MODAL:
      return { ...state, isGoalModalOpen: true }
    case CLOSE_GOAL_MODAL:
      return {
        ...state,
        editingGoal: null,
        isEditingGoal: false,
        isGoalModalOpen: false,
      }
    case GET_GOALS_PROJECT_BEGIN:
      return { ...state, loading: true }
    case GET_GOALS_PROJECT_SUCCESS:
      return { ...state, loading: false, goals: action.payload.goals }
    case GET_GOALS_PROJECT_ERROR:
      return { ...state, loading: false }
    case CREATE_GOAL_BEGIN:
      return { ...state, loading: false }
    case CREATE_GOAL_SUCCESS:
      const goals = [...state.goals, action.payload.goal]
      return { ...state, loading: false, goals }
    case CREATE_GOAL_ERROR:
      return { ...state, loading: false }
    case UPDATE_GOAL_BEGIN:
      return { ...state, loading: false }
    case UPDATE_GOAL_SUCCESS:
      const goal = state.goals.map((i) => {
        if (i._id === action.payload.updatedGoal._id) {
          return action.payload.updatedGoal
        } else {
          return i
        }
      })
      return {
        ...state,
        loading: false,
        goals: goal,
        editingGoal: null,
        isEditingGoal: false,
        isGoalModalOpen: false,
      }
    case UPDATE_GOAL_ERROR:
      return { ...state, loading: false }
    case GET_SINGLE_GOAL_BEGIN:
      return { ...state, loading: true }
    case GET_SINGLE_GOAL_SUCCESS:
      return { ...state, loading: false }
    case GET_SINGLE_GOAL_ERROR:
      return { ...state, loading: false }
    case SET_EDITING_GOAL_BEGIN:
      return { ...state }
    case SET_EDITING_GOAL_SUCCESS:
      return {
        ...state,
        isEditingGoal: true,
        isGoalModalOpen: true,
        editingGoal: action.payload.goal,
      }
    case SET_EDITING_GOAL_ERROR:
      return { ...state }
    case DELETE_GOAL_BEGIN:
      return { ...state }
    case DELETE_GOAL_SUCCESS:
      // console.log(action.payload)
      const filteredGoals = state.goals.filter(
        (i) => i._id !== action.payload.deletedGoal._id
      )
      return {
        ...state,
        editingGoal: null,
        isEditingGoal: false,
        isGoalModalOpen: false,
        goals: filteredGoals,
      }
    case DELETE_GOAL_ERROR:
      return {
        ...state,
        editingGoal: null,
        isEditingGoal: false,
        isGoalModalOpen: false,
      }
    case OPEN_MANAGE_GOAL_MODAL:
      return { ...state, isManageGoalModalOpen: true }
    case CLOSE_MANAGE_GOAL_MODAL:
      return { ...state, isManageGoalModalOpen: false }
    case GET_GOALS_REPORT_BEGIN:
      return { ...state }
    case GET_GOALS_REPORT_SUCCESS:
      return { ...state }
    case GET_GOALS_REPORT_ERROR:
      return { ...state }
    default:
      return { ...state }
  }
}

export default goalReducer
