import React, { useContext, useReducer, useState } from "react"
import reducer from "./reducer"
import axios from "axios"
import Cookies from "js-cookie"
import { toast } from "react-toastify"
import io from "socket.io-client"
import authFetch from "../../utils/interceptor"

import {
  SIGNUP_USER_BEGIN,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  SETUP_USER,
  LOGOUT_USER_BEGIN,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  SET_USERS_LIST,
  EDIT_PROFILE_BEGIN,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  GET_ALL_USERS_BEGIN,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  DELETE_SINGLE_USER_ROLE,
  GET_ACCESS_LIST_BEGIN,
  GET_ACCESS_LIST_SUCCESS,
  GET_ACCESS_LIST_ERROR,
} from "./actions"
import setCookie from "../../utils/setCookie"

const socket = io.connect(process.env.REACT_APP_SOCKET_URL)

const baseURL = process.env.REACT_APP_API_URL
// /api/v1

const user = localStorage.getItem("User")

const initialState = {
  user: user ? JSON.parse(user) : null,
  token: null,
  showSidebar: false,
  loading: false,
  error: "",
  usersSearchByName: [],
  profilepic: true,
  users: [],
  accessList: [],
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [refreshToken, setRefreshToken] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [profilePic, setProfilePic] = useState(null)
  const [showLogout, setShowLogout] = useState(false)

  // this is the function to get the list to which the user has access to
  const getAccessList = async () => {
    try {
      console.log("reac")
      dispatch({ type: GET_ACCESS_LIST_BEGIN })
      const response = await authFetch.post(`/getAccessList`, {
        roles: state.user.assignedRoles,
      })
      dispatch({
        type: GET_ACCESS_LIST_SUCCESS,
        payload: { accessList: response.data.accessList },
      })
    } catch (error) {
      dispatch({ type: GET_ACCESS_LIST_ERROR })
    }
  }

  // get new access token whenever it expires
  const getNewAccessToken = async () => {
    try {
      const response = await axios.post(`${baseURL}/refreshToken`, {
        refreshToken: Cookies.get("refreshToken"),
      })
      if (response.data.message === "refresh token invalid") {
        logoutUser()
      }
      const newAccessToken = response.data.accessToken
      // console.log(response)
      await setAccessToken(newAccessToken)
      await setCookie("accessToken", response.data.accessToken, 8000, "/")
    } catch (error) {}
  }
  const token = Cookies.get("accessToken")

  const signupUser = async (values) => {
    dispatch({ type: SIGNUP_USER_BEGIN })

    try {
      const response = await axios.post(`${baseURL}/signup`, values)
      if (response.data.status === true) {
        dispatch({
          type: SIGNUP_USER_SUCCESS,
          payload: {
            user: response.data.user,
          },
        })
        toast.success("Sign up successful check your email")
      }
      // check the payload the add user to the appcontext
    } catch (error) {
      dispatch({
        type: SIGNUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      })
      toast.error(error.response.data.msg)
    }
  }

  const editProfile = async (values) => {
    dispatch({ type: EDIT_PROFILE_BEGIN })

    try {
      const response = await authFetch.post(`/edit_user`, values)

      dispatch({
        type: EDIT_PROFILE_SUCCESS,
        payload: {
          user: response.data.updatedUser,
        },
      })
      toast.success("Profile updated successfully")
    } catch (error) {
      dispatch({
        type: EDIT_PROFILE_ERROR,
        payload: { msg: error.response.data.msg },
      })
      toast.error(error.response.data.msg)
    }
  }

  const logoutUser = async () => {
    dispatch({ type: LOGOUT_USER_BEGIN })
    try {
      const response = await axios.delete(`${baseURL}/refreshToken`, {
        refreshToken,
      })
      if (response.data.error === false) {
        await Cookies.remove("refreshToken")
        await Cookies.remove("accessToken")
        await localStorage.clear()
        dispatch({ type: LOGOUT_USER_SUCCESS })
        toast.success("Logged out successfully")
      }
    } catch (error) {
      dispatch({ type: LOGOUT_USER_ERROR, payload: { error } })
    }
  }

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR })
  }

  const setupUser = (values) => {
    dispatch({ type: SETUP_USER, payload: { user: values } })
  }

  const updateProfilePic = async (data) => {
    //  console.log(data)
    try {
      const res = await authFetch.post("/resource/updateProfilePic", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      dispatch({ type: "PROFILE_PIC_CHANGES" })
      toast.success("profile pic updated successfully")
    } catch (error) {
      toast.error("error updating profile pic")
    }
  }

  /*get user list in setting old code*/
  const getUsersList = async () => {
    const response = await authFetch.get("/getAllUsers")
    const users = response.data.users.map(
      ({ _id: value, name: label, ...rest }) => ({
        value,
        label,
        ...rest,
      })
    )
    dispatch({ type: SET_USERS_LIST, payload: { users } })
    return users
  }

  const getAdminList = async () => {
    const response = await authFetch.get("/admins")
    return response.data.users
  }

  const getClientList = async () => {
    const response = await authFetch.get("/clients")
    return response.data.data
  }

  /* commenting this code to test the code below by faiyaz*/
  const addMemberToTeam = async (values) => {
    const response = await authFetch.post("/project/team", values)
  }

  const getAllUsers = async (page, limit, search, isActive) => {
    try {
      //console.log(page, limit, search)
      dispatch({ type: GET_ALL_USERS_BEGIN })

      const response = await authFetch.get(
        `/getUserListsFind?page=${page}&limit=${limit}&search=${search}&includeInactive=${isActive}`
      )

      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: { usersSearchByName: response.data },
      })
      return response.data
    } catch (error) {
      // Handle error case
      dispatch({
        type: GET_ALL_USERS_ERROR,
        payload: {
          err: error,
        },
      })
    }
  }
  const getSingleTask = async (id) => {
    try {
      const response = await authFetch.get(`task/${id}`)
      return response.data
    } catch (error) {}
  }
  const deleteSingleRole = async (value) => {
    try {
      const response = await authFetch.post("/permission/deleteSingleRole", {
        role: value,
      })

      if (response) {
        toast.success("User role delete")

        dispatch({
          type: DELETE_SINGLE_USER_ROLE,
          payload: { userole: response.data.user },
        })
      }
    } catch (error) {
      //console.log(error)
    }
  }

  const getUserName = async () => {
    try {
      const response = await authFetch.get("/getUserName")
      return response.data.users
    } catch (error) {
      //console.log(error)
    }
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        authFetch,
        setAccessToken,
        setRefreshToken,
        socket,
        signupUser,
        profilePic,
        setProfilePic,
        toggleSidebar,
        setupUser,
        logoutUser,
        getNewAccessToken,
        refreshToken,
        getUsersList,
        getAdminList,
        addMemberToTeam,
        editProfile,
        updateProfilePic,
        showLogout,
        setShowLogout,
        getClientList,
        getAllUsers,
        deleteSingleRole,
        getUserName,
        getSingleTask,
        getAccessList,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppContext = () => {
  return useContext(AppContext)
}

export { AppProvider, initialState, useAppContext, socket, user }
