import {
  SIGNUP_USER_BEGIN,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  SET_PROFILE_PIC,
  TOGGLE_SIDEBAR,
  SETUP_USER,
  // LOGOUT_USER,
  LOGOUT_USER_BEGIN,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  FILTER_ATTACHMENTS,
  FILTER_WORKLOG,
  SET_USERS_LIST,
  UPDATE_STATUS_CHANGE,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
  FILTER_TASK_BY_GOAL,
  EDIT_PROFILE_BEGIN,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  PROFILE_PIC_UPDATE,
  GET_USERS_LIST_SUCCESS,
  GET_ALL_USERS_BEGIN,
  GET_ALL_USERS_SUCCESS,
  DELETE_SINGLE_USER_ROLE,
  GET_ACCESS_LIST_BEGIN,
  GET_ACCESS_LIST_SUCCESS,
  GET_ACCESS_LIST_ERROR,
} from "./actions"

// import { initialState } from "./appContext"

const reducer = (state, action) => {
  if (action.type === "setProfilePic") {
    return { ...state }
  }
  if (action.type === "PROFILE_PIC_CHANGES") {
    console.log("profile pic")
    const profilePic = !state.profilepic
    return { ...state, profilepic: profilePic }
  }
  if (action.type === SIGNUP_USER_BEGIN) {
    return { ...state, loading: true }
  }
  if (action.type === SIGNUP_USER_SUCCESS) {
    return { ...state, loading: false, user: action.payload.user }
  }
  if (action.type === SIGNUP_USER_ERROR) {
    return { ...state, loading: false, error: action.payload.msg }
  }
  if (action.type === EDIT_PROFILE_BEGIN) {
    return { ...state, loading: false }
  }
  if (action.type === EDIT_PROFILE_SUCCESS) {
    localStorage.setItem("User", JSON.stringify(action.payload.user))
    return { ...state, loading: false, user: action.payload.user }
  }
  if (action.type === EDIT_PROFILE_ERROR) {
    return { ...state, loading: false, error: action.payload.msg }
  }
  if (action.type === SET_PROFILE_PIC) {
    //
    return { ...state }
  }
  if (action.type === LOGOUT_USER_BEGIN) {
    return { ...state, loading: true }
  }
  if (action.type === LOGOUT_USER_SUCCESS) {
    return { ...state, loading: false }
  }
  if (action.type === LOGOUT_USER_ERROR) {
    return { ...state, loading: false }
  }
  if (action.type === TOGGLE_SIDEBAR) {
    return { ...state, showSidebar: !state.showSidebar }
  }

  if (action.type === SETUP_USER) {
    return { ...state, user: action.payload.user }
  }

  if (action.type === FILTER_ATTACHMENTS) {
    // const tasks = state.tasks.filter((task) => {
    //   if (task._id === action.payload.id) {
    //     task.attachments = action.payload.Keys
    //   }
    //   return task
    // })

    return { ...state }
  }

  if (action.type === FILTER_WORKLOG) {
    return { ...state }
  }

  if (action.type === SET_USERS_LIST) {
    return { ...state, users: action.payload.users }
  }

  if (action.type === UPDATE_STATUS_CHANGE) {
    return { ...state, loading: false }
  }
  if (action.type === UPDATE_STATUS_SUCCESS) {
    return { ...state, loading: false }
  }
  if (action.type === UPDATE_STATUS_ERROR) {
    return { ...state, loading: false }
  }

  if (action.type === FILTER_TASK_BY_GOAL) {
    const tasks = state.tasks.filter((item) => {
      if (item.goal === action.payload.goal) {
        return item
      }
    })
    return { ...state, tasks }
  }

  if (action.type === PROFILE_PIC_UPDATE) {
    const user = state.user
    user.profileUrl = action.payload.pic
    localStorage.setItem("User", JSON.stringify(user))
    return { ...state, user }
  }

  // if (action.type === NEW_LOG) {
  //   if (action.payload.log.taskId === state.editingTask._id) {
  //     const worklog = [...state.worklog, action.payload.log]
  //     return { ...state, worklog: worklog }
  //   } else {
  //     return { ...state }
  //   }
  // }

  // if (action.type === NEW_LOG) {
  //   if (
  //     state.editingTask &&
  //     action.payload.log.taskId === state.editingTask._id
  //   ) {
  //     const worklog = [...state.worklog, action.payload.log]
  //     return { ...state, worklog: worklog }
  //   } else {
  //     return { ...state }
  //   }
  // }

  if (action.type === GET_ALL_USERS_SUCCESS) {
    //console.log(action.payload.usersSearchByName);
    return {
      ...state,
      usersSearchByName: action.payload.usersSearchByName,
    }
  }
  if (action.type === GET_ALL_USERS_BEGIN) {
    return {
      ...state,
    }
  }

  if (action.type === DELETE_SINGLE_USER_ROLE) {
    const userUpdate = state.usersSearchByName.data.users.find(
      (obj) => obj._id === action.payload.userole._id
    )

    if (userUpdate) {
      // Update the assignedRoles property of objToUpdate
      userUpdate.assignedRoles = action.payload.userole.assignedRoles
    }

    return { ...state }
  }
  if (action.type === GET_ACCESS_LIST_BEGIN) {
    return { ...state }
  }
  if (action.type === GET_ACCESS_LIST_SUCCESS) {
    return { ...state, accessList: action.payload.accessList }
  }
  if (action.type === GET_ACCESS_LIST_ERROR) {
    return { ...state }
  }

  throw new Error(`no such action : ${action}`)
}

export default reducer
