import {
  CREATE_QUESTION_BEGIN,
  CREATE_QUESTION_SUBJECTIVE_SUCCESS,
  CREATE_QUESTION_MULTIPLE_SUCCESS,
  CREATE_QUESTION_ERROR,
  GET_ALL_QUESTION_BEGIN,
  GET_ALL_QUESTION_SUBJECTIVE_SUCCESS,
  GET_ALL_QUESTION_MULTIPLE_SUCCESS,
  GET_ALL_QUESTION_ERROR,
  UPDATE_QUESTION_BEGIN,
  UPDATE_SUBJECTIVE_QUESTION_SUCCESS,
  UPDATE_MULTIPLE_QUESTION_SUCCESS,
  UPDATE_QUESTION_ERROR,
  GET_SINGLE_QUESTION_BEGIN,
  GET_SINGLE_QUESTION_SUCCESS,
  GET_SINGLE_QUESTION_ERROR,
  DELETE_QUESTION_BEGIN,
  DELETE_SUBJECTIVE_QUESTION_SUCCESS,
  DELETE_MULTIPLE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,
  OPEN_QUESTION_MODAL,
  CLOSE_QUESTION_MODAL,
  CREATE_FEEDBACK_BEGIN,
  CREATE_FEEDBACK_SUCCESS,
  CREATE_FEEDBACK_ERROR,
  GET_SUBJECTIVE_QUESTION_BEGIN,
  GET_SUBJECTIVE_QUESTION_SUCCESS,
  GET_SUBJECTIVE_QUESTION_ERROR,
  GET_MULTIPLE_QUESTION_BEGIN,
  GET_MULTIPLE_QUESTION_SUCCESS,
  GET_MULTIPLE_QUESTION_ERROR,
  GET_FEEDBACK_SUBJECTIVE_BEGIN,
  GET_FEEDBACK_SUBJECTIVE_SUCCESS,
  GET_FEEDBACK_SUBJECTIVE_ERROR,
  GET_FEEDBACK_MULTIPLE_BEGIN,
  GET_FEEDBACK_MULTIPLE_SUCCESS,
  GET_FEEDBACK_MULTIPLE_ERROR,
  SET_EDIT_MULTIPLE,
  SET_EDIT_SUBJECTIVE,
} from "./feedbackTypes"
import authFetch from "../../utils/interceptor"
import { toast } from "react-toastify"

export const openQuestionModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_QUESTION_MODAL })
  }
}
export const closeQuestionModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_QUESTION_MODAL })
  }
}

export const createQuestion = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_QUESTION_BEGIN })
    try {
      const response = await authFetch.post("/question", values)
      if (response.data.newQuestion.type === "subjective") {
        dispatch({
          type: CREATE_QUESTION_SUBJECTIVE_SUCCESS,
          payload: { question: response.data.newQuestion },
        })
      }
      if (response.data.newQuestion.type === "multiple") {
        dispatch({
          type: CREATE_QUESTION_MULTIPLE_SUCCESS,
          payload: { question: response.data.newQuestion },
        })
      }
      toast.success("question created successfully")
    } catch (error) {
      dispatch({ type: CREATE_QUESTION_ERROR })
      console.log(error)
      toast.error("something went wrong while creating question")
    }
  }
}

export const getAllQuestion = (values) => {
  return async (dispatch) => {
    const { subjective, multiple } = values

    dispatch({ type: GET_ALL_QUESTION_BEGIN })
    try {
      let response
      if (subjective) {
        response = await authFetch.get(`/question?subjective=true`)
        dispatch({
          type: GET_ALL_QUESTION_SUBJECTIVE_SUCCESS,
          payload: { questions: response.data.questions },
        })
      }
      if (multiple) {
        response = await authFetch.get(`/question?multiple=true`)
        dispatch({
          type: GET_ALL_QUESTION_MULTIPLE_SUCCESS,
          payload: { questions: response.data.questions },
        })
      }
    } catch (error) {
      dispatch({ type: GET_ALL_QUESTION_ERROR })
      toast.error("error getting questions")
      console.log(error)
    }
  }
}

export const getSingleQuestion = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_SINGLE_QUESTION_BEGIN })
    try {
      const response = await authFetch.get(`/question/${id}`)
      dispatch({ type: GET_SINGLE_QUESTION_SUCCESS })
      return response.data.question
    } catch (error) {
      console.log(error)
      dispatch({ type: GET_SINGLE_QUESTION_ERROR })
      toast.error("error getting question")
    }
  }
}

export const deleteSingleQuestion = (id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_QUESTION_BEGIN })
    try {
      const response = await authFetch.delete(`/question/${id}`)
      if (response.data.deletedQuestion.type === "subjective") {
        dispatch({
          type: DELETE_SUBJECTIVE_QUESTION_SUCCESS,
          payload: { deletedQuestion: response.data.deletedQuestion },
        })
      } else {
        dispatch({
          type: DELETE_MULTIPLE_QUESTION_SUCCESS,
          payload: { deletedQuestion: response.data.deletedQuestion },
        })
      }

      toast.success("question delete successfully")
    } catch (error) {
      console.log(error)
      dispatch({ type: DELETE_QUESTION_ERROR })
      toast.error("error deleting question")
    }
  }
}

export const updateQuestion = (values) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_QUESTION_BEGIN })
    try {
      const { _id } = values

      const response = await authFetch.patch(`/question/${_id}`, values)

      if (response.data.updatedQuestion.type === "subjective") {
        dispatch({
          type: UPDATE_SUBJECTIVE_QUESTION_SUCCESS,
          payload: { updatedQuestion: response.data.updatedQuestion },
        })
      } else {
        dispatch({
          type: UPDATE_MULTIPLE_QUESTION_SUCCESS,
          payload: { updatedQuestion: response.data.updatedQuestion },
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: UPDATE_QUESTION_ERROR })
    }
  }
}

export const createFeedback = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_FEEDBACK_BEGIN })
    try {
      const response = await authFetch.post("/feedback", values)

      dispatch({ type: CREATE_FEEDBACK_SUCCESS })
    } catch (error) {
      console.log(error)
      dispatch({ type: CREATE_FEEDBACK_ERROR })
      toast.error("error submitting feedback")
    }
  }
}

export const getSubjectiveQuestion = () => {
  return async (dispatch) => {
    dispatch({ type: GET_SUBJECTIVE_QUESTION_BEGIN })
    try {
      const response = await authFetch.get("/feedback/subjective")

      dispatch({ type: GET_SUBJECTIVE_QUESTION_SUCCESS })
      return response.data.question
    } catch (error) {
      console.log(error)
      dispatch({ type: GET_SUBJECTIVE_QUESTION_ERROR })
    }
  }
}
export const getMultipleQuestion = () => {
  return async (dispatch) => {
    dispatch({ type: GET_SUBJECTIVE_QUESTION_BEGIN })
    try {
      const response = await authFetch.get("/feedback/multiple")

      dispatch({ type: GET_SUBJECTIVE_QUESTION_SUCCESS })
      return response.data.question
    } catch (error) {
      console.log(error)
      dispatch({ type: GET_SUBJECTIVE_QUESTION_ERROR })
    }
  }
}

export const getFeedbackSubjective = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_FEEDBACK_SUBJECTIVE_BEGIN })
    try {
      const response = await authFetch.get(`/feedback/subjective/${id}`)

      dispatch({ type: GET_FEEDBACK_SUBJECTIVE_SUCCESS })
      return response.data.feedbacks
    } catch (error) {
      console.log(error)
      dispatch({ type: GET_FEEDBACK_SUBJECTIVE_ERROR })
      toast.error("error getting feedbacks")
    }
  }
}
export const getFeedbackMultiple = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_FEEDBACK_MULTIPLE_BEGIN })
    try {
      const response = await authFetch.get(`/feedback/multiple/${id}`)

      dispatch({ type: GET_FEEDBACK_MULTIPLE_SUCCESS })
      return response.data.feedbacks
    } catch (error) {
      console.log(error)
      dispatch({ type: GET_FEEDBACK_MULTIPLE_ERROR })
      toast.error("error getting feedbacks")
    }
  }
}

export const setEditSubjective = (id) => {
  return (dispatch) => {
    dispatch({ type: SET_EDIT_SUBJECTIVE, payload: { id } })
  }
}

export const setEditMultiple = (id) => {
  return (dispatch) => {
    dispatch({ type: SET_EDIT_MULTIPLE, payload: { id } })
  }
}

export const openFeedbackModal = () => {
  return (dispatch) => {
    const myArray = ["OPEN_MULTIPLE_MODAL", "OPEN_SUBJECTIVE_MODAL"]
    const randomIndex = Math.floor(Math.random() * myArray.length)
    const randomString = myArray[randomIndex]
    dispatch({ type: randomString })
  }
}
