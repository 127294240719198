import React, { useState, useEffect } from "react"
import DateTimePicker from "react-datetime-picker"
import "react-datetime-picker/dist/DateTimePicker.css"
import "react-calendar/dist/Calendar.css"
import "react-clock/dist/Clock.css"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import getMinutes from "../../utils/getMinutes"
import { toast } from "react-toastify"
import Modal from "react-bootstrap/Modal"
import { Attachments, Dropzone, FormRow } from "../Common"
import { useAppContext } from "../../context/context/appContext"
import { useDispatch, useSelector } from "react-redux"
import { closeWorklogModal, updateWorklog, createWorklog } from "../../redux"
import { WorklogModalWrapper } from "../../assets/styles/Project"
import { putResource } from "../../utils/s3Resources"

const initialState = {
  // _id: "",
  userId: "",
  userRole: "",
  taskId: "",
  projectId: "",
  profilePic: "",
  name: "",
  timeSpent: "",
  //timeRemaining: "",
  dateStarted: new Date(),
  workDescription: "",
}

const WorklogModal = () => {
  const { user } = useAppContext()
  const [values, setValues] = useState(initialState)

 
  const { isWorklogModalOpen, isEditingWorklog, editingWorklog } = useSelector(
    (state) => state.worklog
  )
  const { editingTask } = useSelector((state) => state.task)
  const { selectedProject } = useSelector((state) => state.project)
  const dispatch = useDispatch()
  const minDate = new Date(values.dateStarted);
  minDate.setDate(minDate.getDate() - 7);
  useEffect(() => {
    setValues({
      ...values,
      name: user.name,
      userId: user._id,
      userRole: user.role,
      taskId: editingTask._id,
      projectId: selectedProject,
      profilePic: user.profileUrl,
    })
  }, [isWorklogModalOpen])

  function convertMinutesToHoursAndMinutes(minutes) {
    const days = Math.floor(minutes / (60 * 24));
    const remainingHours = Math.floor((minutes % (60 * 24)) / 60);
    const remainingMinutes = minutes % 60;
  
    if (days > 0) {
      return `${days}d ${remainingHours}h ${remainingMinutes}m`;
    } else {
      return `${remainingHours}h ${remainingMinutes}m`;
    }
  }
  
  useEffect(() => {
    if (editingWorklog) {
      setValues({
        ...values,
        _id: editingWorklog._id,
      
        timeSpent:convertMinutesToHoursAndMinutes(editingWorklog.timeSpent),
        timeRemaining: editingWorklog.timeRemaining,
        dateStarted: new Date(editingWorklog.dateStarted),
        workDescription: editingWorklog.workDescription,        
        projectId: selectedProject,
        profilePic: user.profileUrl,
        
      })
    }
  }, [isEditingWorklog])
  const onDrop = async (acceptedFiles) => {
    for (let index = 0; index < acceptedFiles.length; index++) {
      const form = new FormData()
      form.append("id", user._id)
      form.append("files", acceptedFiles[index])
      form.append("worklog", editingWorklog._id)
      await putResource(form)
    }
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleDateChange = (e) => {
    setValues({ ...values, dateStarted: e })
  }
  const handleSubmit = (e) => {
    e.preventDefault()

   
    if (isEditingWorklog) {
      if (values.workDescription !== "") {
        const regex = /^(\d+)(m|h|d|w)(\s(\d+)(m|h|d|w))*$/g
        if (!regex.test(values.timeSpent)) {
          toast.error("enter value in m h d w")
        }
        else
        {
        console.log("Values before dispatch:", values);
        const { name, ...filteredValues } = values;
        console.log("Values after dispatch:", filteredValues);
        dispatch(
          updateWorklog({
            ...filteredValues,
            _id: editingWorklog._id,
            timeSpent: getMinutes(values.timeSpent)
          })
        )
        setValues(initialState)
        dispatch(closeWorklogModal())
      }
      } 
      else {
        toast.error("Add description")
      }
    } else {
      const regex = /^(\d+)(m|h|d|w)(\s(\d+)(m|h|d|w))*$/g
      if (!regex.test(values.timeSpent)) {
        toast.error("enter value in m h d w")
      } else {
        dispatch(
          createWorklog({ ...values, timeSpent: getMinutes(values.timeSpent) })
        )

        setValues(initialState)
        dispatch(closeWorklogModal())
      }
    }
  }


  const closeModal = () => {
    dispatch(closeWorklogModal())
    setValues(initialState)
  }
  return (
    <WorklogModalWrapper>
      <Modal
        show={isWorklogModalOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditingWorklog ? "edit work log" : "create worklog"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WorklogModalWrapper>
            <form onSubmit={handleSubmit} className="">
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              ></div>
              <FormRow
                type="text"
                name="timeSpent"
                labelText="time spent"
                value={
                  isEditingWorklog
                    ? values.timeSpent
                    : values.timeSpent
                }
                handleChange={handleChange}
                disabled={user.role === "developer" ? false : false}
                placeholder="Enter Time Spent"
              />
              {!isEditingWorklog && (
                <>
                  
                  <span className="expl">
                    hints: 1m, 2h, 1d, 2w, 1h 20m{" "}
                  </span> 
                </>
              )}
              {!isEditingWorklog &&   <div style={{marginTop:"2rem"}}></div>}
              <DateTimePicker
                onChange={handleDateChange}
                value={values.dateStarted}
                minDate={user.role === "developer" ? minDate : null}
                maxDate={values.dateStarted}
                disabled={user.role === "developer" ? false : false}          
              />
              <div className="mt-3"></div>
              <label htmlFor="description" className="form-label">
                Work Description
              </label>
              <ReactQuill
                theme="snow"
                name="description"
                // placeholder="Write description "
                modules={WorklogModal.modules}
                formats={WorklogModal.formats}
                value={values.workDescription}
                onChange={(e) => setValues({ ...values, workDescription: e })}
              />
              <div className="mt-3"></div>
              {isEditingWorklog && (
                <>
                  <label htmlFor="attachment" className="form-label">
                    Attachment
                  </label>
                  <Dropzone onDrop={(files) => onDrop(files)} />
                </>
              )}
              {editingWorklog && (
                <Attachments
                  attachments={editingWorklog.attachments}
                  worklog={true}
                />
              )}
              <div style={{ textAlign: "right" }}>
                <button
                  className="btn"
                  type="button"
                  onClick={() => {
                    closeModal()
                  }}
                >
                  cancel
                </button>
                <button className="btn">save</button>
              </div>
            </form>
          </WorklogModalWrapper>
        </Modal.Body>
      </Modal>
    </WorklogModalWrapper>
  )
}

export default WorklogModal

WorklogModal.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6] }],
    [{ font: [] }],
    [{ size: ["small", "large"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
    ["code-block"],
  ],
}

WorklogModal.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "code-block",
]
