import {
  GET_ACTIVITY_BEGIN,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_ERROR,
} from "./activityTypes"

import authFetch from "../../utils/interceptor"

export const getActivities = (values) => {
  const { startDate, endDate, resourceType, action } = values
  return async (dispatch) => {
    dispatch({ type: GET_ACTIVITY_BEGIN })
    try {
      const response = await authFetch.get(
        `/activity?startDate=${startDate}&endDate=${endDate}&resourceType=${resourceType}&action=${action}`
      )
      console.log(response)
      dispatch({
        type: GET_ACTIVITY_SUCCESS,
        payload: { activity: response.data.activities },
      })
    } catch (error) {
      dispatch({ type: GET_ACTIVITY_ERROR })
    }
  }
}

// export { getActivities }
