import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TestCaseWrapper } from '../../../assets/styles/TestCase';
import { AddSharpIcon, ArrowBackIcon } from '../../../assets/icons';
import AllTestsItem from './AllTestsItem';
import { useDispatch } from 'react-redux';
import { openTestItemModal } from '../../../redux';
import { useSelector } from 'react-redux';
import TestItemModal from './TestItemsModal';


const TestItem = () => {
    const location = useLocation();
    const templateId = location.state?.templateId;
    const navigate = useNavigate();
    const dispatch =  useDispatch()
    const { isTestItemModalOpen } = useSelector((state) => state.testItems);
    const [id, setId] = useState(templateId);
    const[isEditModalOpen, setIsEditModalOpen] = useState(false)


    return (
        <div>
            <TestCaseWrapper>
                <h5 className="card-title mt-2" style={{marginLeft: 10}}>
                    <span 
                        className='mr-2' 
                        onClick={() => navigate('/test_case/template')}
                    >
                        <ArrowBackIcon style={{fontSize: 22}} />
                    </span>
                    Tests Item
                </h5>
                <TestItemModal isEditModalOpen={isEditModalOpen} setIsEditModalOpen={setIsEditModalOpen} id={id} setId={setId} templateId={templateId} />
                <div className="card-title" style={{marginLeft: 30}}>
                    <button
                        className="btn btn-block add-btn"
                        type="submit"
                        onClick={() => {
                            // dispatch(openTestItemModal());
                            setIsEditModalOpen(true)
                            setId("");
                        }}
                    >
                        <AddSharpIcon style={{ color: "#fff", fontSize: "20px" }} /> Add
                    </button>
                </div>

                {/* All created test items will be displayed here */}
                <div className="card-title">
                    <AllTestsItem templateId={templateId} id={id} setId={setId} />
                </div>
            </TestCaseWrapper>
        </div>
    );
};

export default TestItem;
