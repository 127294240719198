import {
  GET_ACTIVITY_BEGIN,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_ERROR,
} from "./activityTypes"

const initialState = {
  loading: false,
  activity: [],
}

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_BEGIN:
      return { ...state, loading: true }

    case GET_ACTIVITY_SUCCESS:
      return { ...state, loading: false, activity: action.payload.activity }

    case GET_ACTIVITY_ERROR:
      return { ...state, loading: false }

    default:
      return { ...state }
  }
}

export default activityReducer
