import axios from "axios"
import Cookies from "js-cookie"
import { toast } from "react-toastify"
const baseURL = process.env.REACT_APP_API_URL

// get new access token whenever it expires
const getNewAccessToken = async () => {
  try {
    const response = await axios.post(`${baseURL}/refreshToken`, {
      refreshToken: Cookies.get("refreshToken"),
    })

    const newAccessToken = response.data.accessToken

    await Cookies.set("accessToken", newAccessToken)
  } catch (error) {}
}

const authFetch = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${Cookies.get("accessToken")}`,
  },
})
// request interceptor
authFetch.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${Cookies.get("accessToken")}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// response interceptor
authFetch.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      getNewAccessToken()
    }
    if (error.response.status === 403) {
      toast.error("Not authorized to access this route")
    }
    return Promise.reject(error)
  }
)

export default authFetch
