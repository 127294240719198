import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AllTestCases from "./AllTestCases";
import CreateTestCases from "./CreateTestCases";

const TestCase = () => {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    navigate('/test_case/template')
  };
  
  return (
    <>
      <Wrapper>
          <div className="template">
          <div className="template-container">
            <div className="" style={{display: 'flex', width: "100%", padding: '10px'}}>
              <h5 className="card-title">Select Tests</h5>
              {/* <div style={{width: '30%'}}>
                <TestCaseDropdown />
              </div> */}
            </div>
            <div className="card-title" style={{paddingRight: 30}}>
                <button
                  className="btn add-btn mt-2"
                  type="submit"
                  disabled={false}
                  onClick={handleSubmit}
                >
                  Template
                </button>
            </div>
          </div>
          </div>
          <div className="card-title">
          {/* adding new test case  */}
            <CreateTestCases />
          </div>
      </Wrapper>
    </>
  );
};

export default TestCase;

const Wrapper = styled.div`
   .template {
    width: 100%;
  }
   .template-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;