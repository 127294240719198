import React from "react"
import { ConfirmWrapper } from "../../assets/styles/Auth"

const ConfirmVerification = (props) => {
  return (
    <ConfirmWrapper>
      <div className="container main signin verify">
        <p className="textCenter">
          <img
            src="https://media.giphy.com/media/YlSR3n9yZrxfgVzagm/giphy.gif"
            alt=""
          />
          <br />
          Verify your email
        </p>
      </div>
    </ConfirmWrapper>
  )
}

export default ConfirmVerification
