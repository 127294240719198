import React from "react"
import { useAppContext } from "../../context/context/appContext"
import {
  LogContainer,
  DailyLog,
  WeeklyLog,
} from "../../components/Reports/AdminReport"
import { GoalsReport } from "../../components/Goals"
import Tabs, { Tab } from "react-best-tabs"
import { Loading } from "../../components/Common"
import Attendance from "../../components/Reports/AdminReport/Attendance"
import { TabsWrapper } from "../../assets/styles/Dashboard"
import TaskReport from "../../components/Reports/AdminReport/TaskReport"



const AdminReport = () => {
  const { user } = useAppContext()
  if (!user) {
    return <Loading />
  }
  return (
    <TabsWrapper>
      <h5 className="card-title  lg:tw-p-8 lg:tw-pb-4  tw-p-3 tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">Reports</h5>
      <Tabs
        activeTab="1"
        className="tabss page-button-section"
        ulClassName=""
        activityClassName="bg-success"
      >
        <Tab title="Work Logs" className="mr-3">
          <LogContainer />
        </Tab>
        <Tab title="Daily Logs" className="mr-3">
          <DailyLog />
        </Tab>
        <Tab title="Weekly Logs" className="mr-3">
          <WeeklyLog />
        </Tab>
        <Tab title="Goals Report" className="mr-3">
          <GoalsReport />
        </Tab>

        <Tab title="Attendance" className="mr-3">
          <Attendance />
        </Tab>
        <Tab title="Current Work Status" className="mr-3">
          <TaskReport />
        </Tab>
      </Tabs>
    </TabsWrapper>
  )
}

export default AdminReport
