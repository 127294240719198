import React, { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import parse from "html-react-parser"
import { useAppContext } from "../../../context/context/appContext"
import { Loading } from "../../Common"

const WorklogDetails = ({ id, setViewWorklog }) => {
  const { getSingleWorklog } = useAppContext()
  const [log, setLog] = useState(null)

  useEffect(() => {
    getSingleWorklog(id).then((data) => setLog(data))
  }, [])
  if (!log) {
    return <Loading />
  }

  const user = log.log
  // console.log(log, user)

  /*function for timeSpent in hours/min/both */
  const formatTimeSpent = (timeSpent) => {
    if (timeSpent >= 60) {
      const hours = Math.floor(timeSpent / 60)
      const minutes = timeSpent % 60
      return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${
        minutes > 1 ? "s" : ""
      }`
    } else {
      return `${timeSpent} minute${timeSpent > 1 ? "s" : ""}`
    }
  }

  return (
    <Wrapper>
      <div className="bg-blur"></div>
      <div className="container">
        <div className="content">
          <div className="modall-header">
            <button onClick={() => setViewWorklog(false)}>X</button>

            <p className="userss"></p>
            <h5> {user.name}</h5>
          </div>
          <div className="modall-content">
            <p className="project">
              <h6>Project:</h6>
              {log.project}
            </p>

            <p className="project">
              <h6>Task:</h6> {log.task}
            </p>
            <p>
              <h6>Description:</h6> {parse(user.workDescription)}
            </p>
            <div className="footer">
              <p>{moment(user.dateStarted).format("DD-MM-yyyy")}</p>
              <p>
                <span className="times">{formatTimeSpent(user.timeSpent)}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .container {
    position: absolute;
    z-index: 999;
  }
  .content {
    box-shadow: var(--shadow-1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 30vw;
    background: #fff;
    color: #193362;
    /* padding: 50px; */
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    button {
      right: 0px;
      position: absolute;
      margin: -9px 8px;
      background: none;
      color: #fff;
      svg {
        font-size: 30px;
      }
    }

    .modall-header {
      background: radial-gradient(
        circle,
        rgba(189, 238, 195, 1) 0%,
        rgba(115, 187, 173, 1) 0%,
        rgba(175, 215, 228, 1) 100%
      );
      padding: 40px 50px 1px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .modall-content {
      padding: 20px 50px 50px;
    }
    h5 {
      font-family: "Didact Gothic", sans-serif !important;
      margin-bottom: 2rem;
      margin-top: 0px;
      font-weight: 600;
    }
    p {
      text-transform: capitalize;
      margin: 0;
      padding: 0;
      font-size: 14px;
      margin-bottom: 20px;
      text-align: left;
    }
    h6 {
      font-weight: 600;
      font-size: 17px;
      margin: 0;
      margin-bottom: 0.2rem;
      color: #374151;
    }
    .times {
      font-size: 15px;
      text-transform: lowercase;
    }
  }

  .userss {
    display: flex;
    justify-content: center;
    margin: 0;
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      /* border: 1px solid lightgrey; */
      margin-right: 15px;
      margin-top: -6px;
      box-shadow: var(--shadow-5);
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin: 0;
    p {
      margin: 0;
      padding: 0;
    }
  }
`
export default WorklogDetails
