import React, { useEffect } from "react"
import moment from "moment"
import parse from "html-react-parser"
import { CustumTooltip, ProfileAvatar, Loading2 } from "../Common"
import { capitalizeDescription } from "../../utils/getInitialName"
import { getCodeReviews, setIsReviewId, openCodeReviewModal, deleteCodeReview, checkStatus } from "../../redux"
import { useSelector, useDispatch } from "react-redux"
import { WorklogWrapper } from "../../assets/styles/Project"
import { DeleteIcon, DriveFileRenameOutlineSharpIcon, FiberManualRecordRoundedIcon } from "../../assets/icons"

const AllCodeReview = () => {
  const { isCodeReviewModalOpen, isReviewId, editingTaskForCodeReview, codeReviews, loading } = useSelector((state) => state.codeReview)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCodeReviews(editingTaskForCodeReview._id))
    dispatch(checkStatus(editingTaskForCodeReview._id))

  }, [dispatch, isCodeReviewModalOpen, isReviewId])

  if (!codeReviews || codeReviews.length === 0) {
    return <p>No code review to display. Please create one.</p>
  }

  return (
    <WorklogWrapper>
      { loading ? <Loading2 /> : null}
      <br />
      <div className={codeReviews.length > 4 && "table-sc"}>
        <table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th className="textLeft">Comments</th>
              <th className="date-w">Date</th>
              <th className="date-w">Status</th>
              <th className="textCenter">Action</th>
            </tr>
          </thead>
          <tbody>
            {codeReviews && codeReviews.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="profilepics upperCase">
                    <p style={{ display: "flex", alignItems: "center" }}>
                      <ProfileAvatar id={item?.userId?._id} size={"s"} />
                      {item?.userId?.name}
                    </p>
                  </td>
                  <td className="textLeft">
                    <div className="worklog-description">
                      {/* {parse(capitalizeDescription(item?.comments)) || ""} */}
                      {item?.comments}
                    </div>
                  </td>
                  <td className="date-w">
                    {moment(item.date).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    <span>
                      <FiberManualRecordRoundedIcon
                        style={{ color: item.status == 'pass' ? "#0b9f5c" : item.status == 'fail' ? "red" : "gray" }}
                      />
                    </span>
                  </td>
                  <td className="action textCenter">
                    <div className="d-flex">
                      <CustumTooltip
                        text={"Edit Code Review"}
                        children={
                          <DriveFileRenameOutlineSharpIcon
                            onClick={() => {
                              dispatch(setIsReviewId(item?.id))
                              dispatch(openCodeReviewModal())
                            }}
                          />
                        }
                        position={"bottom"}
                        id={`edit-tooltip`}
                      />
                        <CustumTooltip
                          text={"Delete Code Review"}
                          children={
                            <DeleteIcon
                              style={{
                                fontSize: "18px",
                                color: "rgb(203, 48, 69)",
                                marginTop: "3px",
                              }}
                              onClick={() => dispatch(deleteCodeReview(editingTaskForCodeReview._id, item.id))}
                            />
                          }
                          position={"bottom"}
                          id={`delete-tooltip`}
                        />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </WorklogWrapper>
  )
}

export default AllCodeReview
