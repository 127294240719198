import React from "react"
import logo from "../../assets/trace1.svg"

const Logo = () => {
  return (
    <div className="companylogo">
     <a href="/"> <img src={logo} alt=""  /></a>
    </div>
  )
}

export default Logo
