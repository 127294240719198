import React from "react"
import { useAppContext } from "../../context/context/appContext"
import { AddMembers } from "./index"
import { DetailWrapper } from "../../assets/styles/Project"

const ProjectDetails = ({ project }) => {
  const { user } = useAppContext()

  if (!project) {
    return <p></p>
  }
  return (
    <DetailWrapper>
      <div className="project-details">
        <AddMembers project={project} />
      </div>
    </DetailWrapper>
  )
}

export default ProjectDetails
