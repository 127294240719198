import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { FormRow, FormRowSelect } from "../Common";
import FormTextArea from "../Common/FormTextArea";
import { getAllTemplates } from "../../redux";
import { closeTestCaseModal, createTestCase, editTestCase, getTestCase, setTemplateId } from "../../redux/testcase/tests/testCaseAction";
import moment from "moment";

const initialDateState = {
  startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

const TestCaseModal = ({ isTestCaseModalOpen, id, setId, setDateRanges }) => {
  const dispatch = useDispatch();

  const { loading, templates } = useSelector((state) => state.template);
  const { selectedTestCase, dateRange } = useSelector((state) => state.testCase);

  // State to manage form values
  const [formValues, setFormValues] = useState({
    selectedTemplates: "",
    name: "",
    description: "",
    startDate: moment().format("YYYY-MM-DD"), // Today's date in YYYY-MM-DD format
    template_id: ""
  })

  const [showTemplate, setShowTemplateItem] = useState(false);
  const [templateID, setTemplateID] = useState("");

  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch, isTestCaseModalOpen]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // for auto populated test case
  useEffect(() => {
    if (id) {
      setFormValues({
        selectedTemplates: selectedTestCase?.name,
        name: selectedTestCase?.name,
        description: selectedTestCase.description,
        startDate: formatDate(selectedTestCase.startDate) ,
      })
      setShowTemplateItem(true)
    }
  }, [selectedTestCase]);

  //for calling test by id
  useEffect(() => {
    if (id) {
      dispatch(getTestCase(id)); //templateId, caseId, values
    } else {
      setFormValues({
        selectedTemplates: "",
        name: "",
        description: "",
        startDate: moment().format("YYYY-MM-DD"), // Today's date in YYYY-MM-DD format
        template_id: ""
      })
    }
  }, [dispatch, id, isTestCaseModalOpen]);

  // Handle change in form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle template selection and populate form fields
  const handleTemplateSelect = (e) => {
    const selectedTemplateId = e.target.value;  //pms, gopher, inteli
    const selectedTemplate = templates.find(template => template.name === selectedTemplateId);

    setTemplateID(selectedTemplate._id)
    dispatch(setTemplateId(selectedTemplate?._id))

    if (selectedTemplate) {
      setFormValues({
        ...formValues,
        selectedTemplates: selectedTemplateId,
        name: selectedTemplate.name,
        description: selectedTemplate.description,
        template_id: selectedTemplate._id
      });
      setShowTemplateItem(true)
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Form Submitted:", formValues);

    if (id) {
      dispatch(editTestCase(id, formValues, dateRange)); //templateId, caseId, values
    } else {

      dispatch(createTestCase(formValues, dateRange)); // templateId, values
    }

    // setDateRanges(initialDateState)
    setId("")
    setFormValues({
      selectedTemplates: "",
      name: "",
      description: "",
      startDate: "",
      template_id: ""
    })
    setShowTemplateItem(false)
    dispatch(closeTestCaseModal())
  };

  return (
    <Modal
      show={isTestCaseModalOpen}
      onHide={() => {
        setFormValues({
          selectedTemplates: "",
          name: "",
          description: "",
          startDate: moment().format("YYYY-MM-DD"), // Today's date in YYYY-MM-DD format
          template_id: ""
        })
        setId("")
        // setShowTemplateItem(false)
        dispatch(closeTestCaseModal())
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{id ? "Edit Tests" : "Create New Tests"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <FormRowSelect
            labelText="Select Template"
            name="selectedTemplates"
            value={formValues.selectedTemplates}
            handleChange={handleTemplateSelect}
            list={[...templates.map((template) => template.name)]}
            pla
          />
          {
            showTemplate ? (
              <>
                <FormRow
                  className="col-2"
                  type="text"
                  name="name"
                  labelText={"Name"}
                  required={true}
                  value={formValues.name}
                  handleChange={handleChange}
                />

                <FormTextArea
                  name="description"
                  labelText="Description"
                  value={formValues.description}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Description"
                />
              </>
            ) : (<></>)
          }

          <FormRow
            className="col-2"
            type="date"
            name="startDate"
            labelText={"Start Date"}
            required={true}
            value={formValues.startDate}
            handleChange={handleChange}
          />
          <div className="textRight">
            <button className="btn add-btn" type="submit" disabled={loading}>
              {id ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              className="btn add-btn"
              disabled={loading}
              onClick={() => {
                setFormValues({
                  selectedTemplates: "",
                  name: "",
                  description: "",
                  startDate: moment().format("YYYY-MM-DD"), // Today's date in YYYY-MM-DD format
                  template_id: ""
                })
                setShowTemplateItem(false)
                setId("")
                dispatch(closeTestCaseModal())
              }}
            >
              {"Cancel"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TestCaseModal;
