import { combineReducers } from "redux"
// import calendarReducer from "./calendar/calendarReducer"
// import callReducer from "./call/callReducer"
import activityReducer from "./activity/activityReducer"
import goalReducer from "./goal/goalReducer"
import worklogReducer from "./worklog/worklogReducer"
import clientReducer from "./client/clientReducer"
import taskReducer from "./task/taskReducer"
import projectReducer from "./project/projectReducer"
import notificationReducer from "./notification/notificationReducer"
import feedbackReducer from "./feedback/feedbackReducer"
import ticketReducer from "./ticket/ticketReducer"
import templateReducer from "./testcase/testcaseReducer"
import testItemsReducer from "./testcase/testItems/testItemReducer"
import testCaseReducer from "./testcase/tests/testCaseReducer"
import codeReviewReducer from "./codeReview/codeReviewReducer"

const rootReducer = combineReducers({
  project: projectReducer,
  //   calendar: calendarReducer,
  //   call: callReducer,
  template: templateReducer, 
  testItems: testItemsReducer,
  testCase: testCaseReducer,
  codeReview: codeReviewReducer,
  activity: activityReducer,
  goal: goalReducer,
  worklog: worklogReducer,
  client: clientReducer,
  task: taskReducer,
  notification: notificationReducer,
  feedback: feedbackReducer,
  ticket: ticketReducer,
})

export default rootReducer
