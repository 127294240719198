import styled from "styled-components"

export const GoalWrapper = styled.div`
  position: absolute;
  background: #fff;
  right: 0;
  width: 350px;
  top: 0px;
  z-index: 1000;
  padding: 20px;
  box-shadow: var(--shadow-1);
  height: 100%;
  //background: #97ed26;
  .goal-content {
    height: 540px;
    overflow-y: scroll;
  }
  button {
    background: var(--primary-500);
    color: white;
  }
  h6 {
    cursor: pointer;
    text-transform: capitalize;
    font-size: 10px;
    text-align: right;
  }
  .goal-heading {
    p {
      margin: 0;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      .date-group {
        time {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    svg {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
    }
  }
  .textRight {
    margin: 0 0 10px 0;
  }
  .borde {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }
  .date-group {
    font-size: 12px;
    display: flex;
    margin-top: 4px;
    p {
      margin-top: 0px;
      font-size: 12px;
    }
    time {
      font-size: 11px;
      font-weight: 400;
    }
  }
  .single-goal {
    margin: 10px 0;
    padding: 10px;
    background: #fdfdfd;
    /* border: 1px solid aliceblue; */
    border-radius: 3px;
    box-shadow: var(--shadow-1);
    p {
      font-size: 13px;
      margin: 0;
    }

    .title {
      display: flex;
      justify-content: space-between;
      input {
        height: 15px;
        width: 15px;
      }
      input:focus {
        outline: none !important;
        border: none !important;
      }
      p {
        // text-transform: capitalize;
        font-weight: 500;
      }
    }
  }

  .progress-bar-rect-wrap-container h2 {
    font-size: 12px !important;
  }

  .dflex {
    display: flex;
    justify-content: space-between;
  }
`
export const ModalWrapper = styled.div`
  border: 1px solid var(--grey-50);
  position: relative;
  border-radius: 5px;
  margin-bottom: 10px;
  form {
    padding: 20px;
  }
`
export const ReportWrapper = styled.div`
  padding: 0 32px;
  overflow-y:scroll;
  height:70vh;
  form {
    display: flex;
    .form-row {
      width: 20%;
      margin-right: 15px;
    }
    button {
      margin-top: 25px;
    }
  }

  .proj {
    background: #fbfbfb;
    cursor: pointer;
    color: rgb(51, 51, 51);
    padding: 8px 10px 7px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border: 1px solid var(--grey-50);
  }
  p {
    font-size: 12px !important;
  }
  .w-30 {
    width: 300px;
  }
  .textLeft {
    margin: 0;
  }
`
export const ManageWrapper = styled.div`
  overflow: auto;
  .textLeft {
    margin: 0;
  }
  .textCenter {
    margin: 0;
  }
  .cent {
    text-align: center !important;
  }

  h5 {
    display: flex;
    justify-content: center;
    background-color: var(--grey-50);
    padding: 20px;
    margin: 0;
    svg {
      position: absolute;
      right: 20px;
    }
  }
`
