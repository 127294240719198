export const GET_ALL_CLIENTS_BEGIN = "GET_ALL_CLIENTS_BEGIN"
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS"
export const GET_ALL_CLIENTS_ERROR = "GET_ALL_CLIENTS_ERROR"

export const ADD_CLIENT_BEGIN = "ADD_CLIENT_BEGIN"
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS"
export const ADD_CLIENT_ERROR = "ADD_CLIENT_ERROR"

export const OPEN_CLIENT_MODAL = "OPEN_CLIENT_MODAL"
export const CLOSE_CLIENT_MODAL = "CLOSE_CLIENT_MODAL"

export const SET_EDITING_CLIENT = "SET_EDITING_CLIENT"

export const EDIT_CLIENT_BEGIN = "EDIT_CLIENT_BEGIN"
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS"
export const EDIT_CLIENT_ERROR = "EDIT_CLIENT_ERROR"

export const ADD_USER_TO_CLIENT_BEGIN = "ADD_USER_TO_CLIENT_BEGIN"
export const ADD_USER_TO_CLIENT_SUCCESS = "ADD_USER_TO_CLIENT_SUCCESS"
export const ADD_USER_TO_CLIENT_ERROR = "ADD_USER_TO_CLIENT_ERROR"

export const OPEN_ADD_USER_TO_CLIENT = "OPEN_ADD_USER_TO_CLIENT"
export const CLOSE_ADD_USER_TO_CLIENT = "CLOSE_ADD_USER_TO_CLIENT"

export const OPEN_USER_LIST_MODAL = "OPEN_USER_LIST_MODAL"
export const CLOSE_USER_LIST_MODAL = "CLOSE_USER_LIST_MODAL"

export const GET_CLIENT_USERS_BEGIN = "GET_CLIENT_USERS_BEGIN"
export const GET_CLIENT_USERS_SUCCESS = "GET_CLIENT_USERS_SUCCESS"
export const GET_CLIENT_USERS_ERROR = "GET_CLIENT_USERS_ERROR"

export const SET_EDITING_CLIENT_USER = "SET_EDITING_CLIENT_USER"

export const EDIT_CLIENT_USER_BEGIN = "EDIT_CLIENT_USER_BEGIN"
export const EDIT_CLIENT_USER_SUCCESS = "EDIT_CLIENT_USER_SUCCESS"
export const EDIT_CLIENT_USER_ERROR = "EDIT_CLIENT_USER_ERROR"
