import axios from "axios"
import { apiUrl, headers } from "./helpers"
import { toast } from "react-toastify"

export const sendEmailOTP = async (email) => {
  const response = await axios.post(
    `${apiUrl}/send_otp`,
    { email: email },
    {
      headers: headers,
    }
  )
  console.log(response)
  return response
}

export const verifyEmailOTP = async (email, otp) => {
  try {
    const response = await axios.post(`${apiUrl}/verify_otp`, {
      email: email,
      otp: otp,
    })

    // Display a success toast notification
    toast.success(response.data.message)

    return response
  } catch (error) {
    // Display an error toast notification
    toast.error(error.response.data.message)

    throw error
  }
}

export const verifyEmail = async (token) => {
  const response = await axios.post(
    `${apiUrl}/verify_email`,
    { token: token },
    {
      headers: headers,
    }
  )

  return response
}

export const displayUser = async (client_id) => {
  const response = await axios.post(
    `${apiUrl}/display_user`,
    { client_id: client_id },
    {
      headers: headers,
    }
  )

  return response
}
