import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { FormRowSelect, Loading, Loading2 } from "../../Common";
import FormTextArea from "../../Common/FormTextArea";
import { Dropzone } from "../../Common";
import { getTestCaseDetailsById, updateTestCaseRemarks } from "../../../redux";
import { deleteRemarkAttachment, getAllTestCasesStatus } from "../../../redux/testcase/tests/testCaseAction";
import { useSelector } from "react-redux";
import { DeleteIcon } from "../../../assets/icons";

const TestsRemarkModal = ({ isTestDetailsModalOpen, setIsTestDetailsModalOpen, testCaseId, id, setId, getAllUser }) => {
    const dispatch = useDispatch();
    const { selectedSingleTestCase, loading2 } = useSelector((state) => state.testCase);
    const [formValues, setFormValues] = useState({
        status: "Pending",
        userId: "",
        remarks: "",
        remarkId: ""
    });

    const [imageArray2, setImageArray2] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);


    useEffect(() => {
        if (id) {
            dispatch(getTestCaseDetailsById(id));
        } else {
            setFormValues({
                status: "Pending",
                userId: "",
                remarks: "",
                remarkId: ""
            });
            setImageArray2([]);
            setId("");
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (selectedSingleTestCase && id) {
            setFormValues({
                status: selectedSingleTestCase?.status_id == 2 ? "Pass" : selectedSingleTestCase?.status_id == 3 ? "Fail" : "Pending",
                remarks: selectedSingleTestCase?.remarks && selectedSingleTestCase?.remarks.length > 0
                    ? selectedSingleTestCase?.remarks[0]?.note
                    : "",
                remarkId: selectedSingleTestCase?.remarks && selectedSingleTestCase?.remarks.length > 0
                    ? selectedSingleTestCase.remarks[0]?._id
                    : "",
            });
            // Populate imageArray with URLs of existing attachments
            if (selectedSingleTestCase?.remarksAttachments) {
                const attachmentURLs = selectedSingleTestCase?.remarksAttachments.map((attachment) => {
                    // Convert base64 string to a Uint8Array
                    const buffer = Uint8Array.from(atob(attachment.content), (c) => c.charCodeAt(0));
                    const blob = new Blob([buffer], { type: attachment.contentType });  // Create a Blob
                    const imageUrl = URL.createObjectURL(blob);  // Generate an object URL for the Blob
                    return { key: attachment.key, filename: attachment.filename, url: imageUrl };
                });
                setImageArray2(attachmentURLs);
            }
        }
    }, [selectedSingleTestCase]);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        const remarksArray = [{
            note: formValues.remarks,
            attachments: []  // If you have attachments, populate this array
        }];
        formData.append("remarks", JSON.stringify(remarksArray));
        formData.append("status_id", formValues.status === "Pass" ? 2 : formValues.status === "Fail" ? 3 : 1); //Pass, Fail
        formData.append("testCase_id", testCaseId);

        //image array for remark
        imageArray2.forEach((file, index) => {
            formData.append(`attachment`, file); // This adds each file individually
        });

        // Dispatch an action to update the test case
        dispatch(updateTestCaseRemarks(id, formData, testCaseId)); // Uncomment when using Redux for update
        dispatch(getAllTestCasesStatus(testCaseId)); // Uncomment when using Redux for update
        getAllUser()


        setIsEditing(false);
        setFormValues({
            status: "Pending",
            userId: "",
            remarks: "",
            remarkId: "",
        })
        setImageArray2([])
        setId("")
        setIsTestDetailsModalOpen(false)
    };

    const onDropRemark = async (acceptedFiles) => {
        setImageArray2([...imageArray2, ...acceptedFiles]);
    };

    const handleRemoveAttachment = (index, attachmentKey) => {

        if (!attachmentKey) {
            const updatedArray = imageArray2.filter((_, indx) => indx !== index);
            setImageArray2(updatedArray);
        } else {
            const parts = attachmentKey.split('/');
            const keyId = parts[1];
            // Find the index of the attachment to remove
            const attachmentIndex = imageArray2.findIndex((attachment) => attachment.key === attachmentKey);

            if (attachmentIndex !== -1) {
                // Update the local state to remove the attachment from the UI
                const updatedArray = imageArray2.filter((_, i) => i !== attachmentIndex);
                setImageArray2(updatedArray);

                // Dispatch the delete action to remove the attachment from the backend
                dispatch(deleteRemarkAttachment(formValues.remarkId, keyId, id));

            }
        }
    };

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };


    return (
        <Modal
            show={isTestDetailsModalOpen}
            onHide={() => {
                // dispatch(closeTestItemModal());
                setId("");
                setIsTestDetailsModalOpen(false)
                setFormValues({
                    status: "Pending",
                    userId: "",
                    remarks: "",
                    remarkId: ""
                });
                setImageArray2([]);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {loading2 ? <Loading /> : <></>}
            <Modal.Header closeButton>
                <Modal.Title>{id ? "Edit Status" : "Create Test Item"}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="">
                    <form className="row" onSubmit={handleSubmit}>
                        <FormRowSelect
                            labelText="Status"
                            name="status"
                            value={formValues.status}
                            handleChange={handleChange}
                            list={["Pending", "Pass", "Fail"]}
                            disabled={!isEditing}
                        />
                        <FormTextArea
                            labelText="Remarks"
                            name="remarks"
                            value={formValues.remarks}
                            handleChange={handleChange}
                            placeholder="Enter remarks..."
                        />
                        <label htmlFor="attachment2">Attachments</label>
                        <Dropzone onDrop={(files) => onDropRemark(files)} loading={false} />
                        <div
                            className="attachmentContainer"
                            style={{ display: "flex", overflowX: "auto", position: "relative" }}
                        >
                            {imageArray2.map((image, index) => (
                                <div
                                    key={index}
                                    className=""
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        display: 'flex',
                                        justifyItems: 'center',
                                        alignItems: "center",
                                        margin: 8,
                                        background: "#EBF3F9",
                                        borderRadius: 4,
                                        position: "relative",
                                        transition: "all 0.3s ease"
                                    }}
                                >
                                    <span
                                        className="tw-cursor-pointer "
                                        onClick={() =>
                                            window.open(
                                                image instanceof File ? URL.createObjectURL(image) : image.url,
                                                "_blank"
                                            )
                                        }
                                        style={{ background: '#EBF3F9', marginLeft: 8, marginRight: 8 }}
                                    >
                                        {
                                            image instanceof File
                                                ? image.name
                                                : image.filename // Use Base64 directly if not a File
                                        }
                                    </span>
                                    <DeleteIcon
                                        style={{
                                            position: "absolute",
                                            right: 4,
                                            color: "rgb(203, 48, 69)",
                                            cursor: "pointer",
                                            opacity: hoveredIndex === index ? 1 : 0,
                                            transition: "opacity 0.3s ease",
                                        }}
                                        onClick={() => handleRemoveAttachment(index, image.key)}
                                    />
                                </div>
                            ))}

                        </div>
                        <div className="textRight mt-2">
                            <button className="btn add-btn" type="submit" disabled={loading2}>
                                {id ? "Update" : "Submit"}
                            </button>
                            <button
                                type="button"
                                className="btn add-btn"
                                onClick={() => {
                                    setId("");
                                    setIsTestDetailsModalOpen(false)
                                    setFormValues({
                                        status: "Pending",
                                        userId: "",
                                        remarks: "",
                                        remarkId: "",
                                    });
                                    setImageArray2([]);
                                }}
                                disabled={loading2}
                            >
                                {"Cancel"}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TestsRemarkModal;
