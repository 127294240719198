import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import { ConfirmationBox, FormRow } from "../Common"
import { RoleWrapper } from "../../assets/styles/Settings"
import { DeleteIcon, DriveFileRenameOutlineSharpIcon } from "../../assets/icons"
import { createPermission, getAllRole, deleteRole, editRole } from "../../redux"
import { useDispatch } from "react-redux"

const Roles = () => {
  // const { getAllRole, deleteRole, editRole } = useAdminContext()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [role, setRole] = useState()
  const [roleList, setRoleList] = useState([])
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  const [name, setName] = useState()
  const [confirm, setConfirm] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  useEffect(() => {
    dispatch(getAllRole()).then((data) => setRoleList(data.permissions))

    setEdit(false)
    setOpen(false)
    setConfirm(false)
    setOpenConfirmation(false)
  }, [])

  const handleChange = (e) => {
    setRole(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (open === true) {
      await dispatch(createPermission(role)).then((data) => {
        //  console.log(data);
        if (data === "already exist") {
          // toast.success(data)
        } else {
          setOpen(false)
        }
      })
      await dispatch(getAllRole()).then((data) => setRoleList(data.permissions))
      setRole()
    } else if (edit === true) {
      await dispatch(editRole(role, id))
      await dispatch(getAllRole()).then((data) => setRoleList(data.permissions))
      setEdit(false)
      setOpen(false)
    } else {
    }
    dispatch(getAllRole()).then((data) => setRoleList(data.permissions))
  }
  const handleDelete = async (role) => {
    await dispatch(deleteRole(role))
    await dispatch(getAllRole()).then((data) => setRoleList(data.permissions))
    setOpenConfirmation(false)
    setConfirm(false)
  }

  const handleEdit = (name, id) => {
    setRole(name)
    setId(id)
    setEdit(true)
  }

  const handleOpenConfirm = (role) => {
    setName(role)
    setOpenConfirmation(true)
  }

  return (
    <RoleWrapper>
      <span
        className="btn btn-add-card "
        onClick={() => {
          setOpen(true)
          setRole("")
        }}
      >
        + Add a role
      </span>

      <Modal
        show={open || edit}
        onHide={() => {
          setOpen(false)
          setEdit(false)
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> {open ? "Add a Role" : "Edit Role"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="" onSubmit={handleSubmit}>
            <FormRow
              name="role"
              type="text"
              labelText={open ? "Add" : "Edit"}
              value={role}
              handleChange={handleChange}
              placeholder={"Add role for user"}
            />
            <div className="textRight">
              <button className="btn add-btn  " type="submit">
                {open ? "Add" : "Edit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="role-list">
        <table>
          <thead>
            <tr>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {roleList && roleList.length > 0 ? (
              roleList.map((role, i) => {
                return (
                  <tr key={role._id}>
                    <td className="textC">{role.name}</td>
                    <td>
                      <DriveFileRenameOutlineSharpIcon
                        style={{ color: "var(--blue)" }}
                        onClick={() => handleEdit(role.name, role._id)}
                      />
                      <DeleteIcon
                        style={{ color: "rgb(203 48 69)" }}
                        onClick={(e) => handleOpenConfirm(role.name)}
                      />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td>-</td>
                <td>-</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {openConfirmation && (
        <ConfirmationBox
          open={openConfirmation}
          setConfirm={setConfirm}
          setOpenConfirmation={setOpenConfirmation}
          handleDelete={handleDelete}
          name={name}
        />
      )}
    </RoleWrapper>
  )
}

export default Roles
