import styled from "styled-components"

export const FeedbackFormWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  .feedform {
    padding: 20px;
    width: 40vw;
    border-radius: 5px;
    background-color: rgb(255, 255, 255, 0.8);
    border: 3px solid var(--primary-500);
  }

  .modal-content {
    border-radius: 5px;
  }
  .labels {
  }

  .selectOption {
    display: flex;
    align-items: center;
  }

  .fetching {
    margin: 0;
    padding: 10px;
  }

  input {
    background: none !important;
  }
`