const PROJECT = {
  GET_ALL_PROJECTS: "GET_ALL_PROJECTS",
  CREATE_PROJECT: "CREATE_PROJECT",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  // DELETE_PROJECT: "DELETE_PROJECT",
  ADD_MEMBERS_TO_TEAM: "ADD_MEMBERS_TO_TEAM",
  ARCHIVE_PROJECT: "ARCHIVE_PROJECT",
}

const QUESTION = {
  ADD_QUESTION: "ADD_QUESTION",
  GET_ALL_QUESTIONS: "GET_ALL_QUESTIONS",
}

const FEEDBACK = {
  GET_ALL_FEEDBACK: "GET_ALL_FEEDBACK",
}

const TASK = {
  CREATE_TASK: "CREATE_TASK",
  UPDATE_TASK: "UPDATE_TASK",
  DELETE_TASK: "DELETE_TASK",
  GET_ALL_TASK: "GET_ALL_TASK",
  ARCHIVE_TASK: "ARCHIVE_TASK",
}

const WORKLOG = {
  CREATE_WORKLOG: "CREATE_WORKLOG",
  GET_ALL_WORKLOG: "GET_ALL_WORKLOG",
  DELETE_WORKLOG: "DELETE_WORKLOG",
  UPDATE_WORKLOG: "UPDATE_WORKLOG",
}

const GOAL = {
  CREATE_GOAL: "CREATE_GOAL",
  GET_ALL_GOAL: "GET_ALL_GOAL",
  DELETE_GOAL: "DELETE_GOAL",
  UPDATE_GOAL: "UPDATE_GOAL",
}
const CLIENT = {
  CREATE_CLIENT: "CREATE_CLIENT",
  GET_ALL_CLIENT: "GET_ALL_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  // DELETE_CLIENT: "DELETE_CLIENT",
}

const USERS = {
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ADMINS: "GET_ADMINS",
  DAILY_EMAIL_NOTIFICATION: "DAILY_EMAIL_NOTIFICATION",
}

const TEMPLATE = {
  CREATE_TEMPLATE: "CREATE_TEMPLATE",
  GET_ALL_TEMPLATES: "GET_ALL_TEMPLATES",
  UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
  DELETE_TEMPLATE: "DELETE_TEMPLATE"
} 

const TEST_ITEM = {
  CREATE_TEST_ITEM: "CREATE_TEST_ITEM",
  GET_ALL_TEST_ITEMS: "GET_ALL_TEST_ITEMS",
  UPDATE_TEST_ITEM: "UPDATE_TEST_ITEM",
  DELETE_TEST_ITEM: "DELETE_TEST_ITEM",
};

const TEST_CASE = {
  CREATE_TEST_CASE: "CREATE_TEST_CASE",
  GET_ALL_TEST_CASES: "GET_ALL_TEST_CASES",
  UPDATE_TEST_CASE: "UPDATE_TEST_CASE",
  DELETE_TEST_CASE: "DELETE_TEST_CASE",
};

const CODE_REVIEW = {
  CREATE_CODE_REVIEW: "CREATE_CODE_REVIEW",
  GET_ALL_CODE_REVIEWS: "GET_ALL_CODE_REVIEWS",
  UPDATE_CODE_REVIEW: "UPDATE_CODE_REVIEW",
  DELETE_CODE_REVIEW: "DELETE_CODE_REVIEW",
};

export { PROJECT, TASK, WORKLOG, GOAL, CLIENT, USERS, QUESTION, FEEDBACK, TEMPLATE, TEST_ITEM, TEST_CASE, CODE_REVIEW }
