import React from "react"
import Tabs, { Tab } from "react-best-tabs"
import "react-best-tabs/dist/index.css"
import {
  UsersContainer,
  Permissions,
  Roles,
} from "../../components/Permissions"
import { TabsWrapper } from "../../assets/styles/Dashboard"

const Settings = () => {
  return (
    <TabsWrapper>
      <h5 className="card-title lg:tw-p-8 lg:tw-pb-4  tw-p-3  tw-font-semibold lg:tw-text-2xl sm:tw-text-[22px] tw-text-sm tw-flex tw-justify-between tw-items-center">Settings</h5>
      <Tabs
        activeTab="1"
        className="tabss page-button-section "
        ulClassName=""
        activityClassName="bg-success"
      // onClick={(event, tab) => console.log(event, tab)}
      >
        <Tab title="Users" className="mr-3">
          <UsersContainer />
        </Tab>
        <Tab title="Roles" className="mr-3">
          <Roles />
        </Tab>
        <Tab title="Permissions" className="mr-3">
          <Permissions />
        </Tab>
      </Tabs>
    </TabsWrapper>
  )
}

export default Settings
