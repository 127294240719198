import React from "react"
import { Loading2Wrapper } from "../../assets/styles/Common"

const Loading2 = () => {
  return (
    <Loading2Wrapper>
      <div className="loader"></div>
    </Loading2Wrapper>
  )
}

export default Loading2
