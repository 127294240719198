import authFetch from "../../utils/interceptor"
import { toast } from "react-toastify"
import {
  CLOSE_TICKET_BEGIN,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_ERROR,
  CREATE_TICKET_BEGIN,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_ERROR,
  GET_ALL_TICKET_BEGIN,
  GET_ALL_TICKET_SUCCESS,
  GET_ALL_TICKET_ERROR,
} from "./ticketTypes"

export const raiseTicket = (values) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_TICKET_BEGIN })
    try {
      console.log("ticket is here")
      const response = await authFetch.post("/ticket", values)
      dispatch({ type: CREATE_TICKET_SUCCESS })
      toast.success(
        "Ticket raised successfully we will get to it soon be patient"
      )
    } catch (error) {
      console.log(error)
      dispatch({ type: CREATE_TICKET_ERROR })
    }
  }
}

export const getAllTicket = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_TICKET_BEGIN })
    try {
      const response = await authFetch.get("/ticket")
      console.log(response)
      dispatch({
        type: GET_ALL_TICKET_SUCCESS,
        payload: { tickets: response.data.tickets },
      })
    } catch (error) {
      console.log(error)
      dispatch({ type: GET_ALL_TICKET_ERROR })
    }
  }
}

export const closeTicket = (id) => {
  return async (dispatch) => {
    dispatch({ type: CLOSE_TICKET_BEGIN })
    try {
      const response = await authFetch.patch(`/ticket`, { id })
      console.log(response)
      dispatch({
        type: CLOSE_TICKET_SUCCESS,
        payload: { updatedTicket: response.data.updatedTicket },
      })
    } catch (error) {
      console.log(error)
      dispatch({ type: CLOSE_TICKET_ERROR })
    }
  }
}
