import React from "react"

const FormRowSelect = ({
  labelText,
  name,
  value,
  handleChange,
  list,
  required,
}) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText}
      </label>
      <select
        name={name}
        value={value}
        onChange={handleChange}
        className="form-select"
        required={required}
      >
      { list.length < 2 && <option value="">--Select--</option>}

        {list.map((item, index) => {
          return (
            <option key={index} value={item}>
              {item}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default FormRowSelect
