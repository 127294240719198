import React, { useState, useEffect, useRef } from "react"
import validator from "validator"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { PhoneNumberUtil } from "google-libphonenumber"
import Modal from "react-bootstrap/Modal"
import { FormRow } from "../Common"
import Form from "react-bootstrap/Form"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { closeClientModal, addClient, editClient } from "../../redux"
import { UserTableCell } from "../../assets/styles/Settings"
import { getAllClients, updateClientActivation } from "../../redux/client/clientActions"

const initialState = {
  companyName: "",
  website: "",
  address: "",
  contactNumber: "",
  email: "",
  country: "",
}

const ClientModal = () => {
  const dispatch = useDispatch()
  const { isClientModalOpen, clients, isEditingClient, editingClient } = useSelector(
    (state) => state.client
  )
  const [values, setValues] = useState(initialState)
  const [isEmail, setIsEmail] = useState(true)
  const [isValid, setIsValid] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState("US")

  const [limit, setLimit] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")

  const currentPage = useRef(1)

  const [isActive, setIsActive] = useState(false);


  const getPagination = async () => {
    await dispatch(getAllClients(currentPage.current, limit, searchQuery, editingClient?.is_active))
    setPageCount(clients?.data?.pageCount) // Update pageCount from API response
  }

  useEffect(() => {
    currentPage.current = 1
    //getAllClients(currentPage.current, limit, searchQuery);
    getPagination()
  }, [limit, searchQuery, editingClient?.is_active])


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!values.companyName || !values.email) {
      toast.error("provide all fields")
      return
    }
    if (!validator.isEmail(values.email)) {
      toast.error("enter valid email")
      return
    }

    if (isEditingClient) {
      await dispatch(editClient({ ...values, _id: editingClient._id }))
    } else {
      dispatch(addClient(values))
    }

    setValues(initialState)
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const clearValues = () => {
    setValues(initialState)
    setIsActive(false);
  }

  useEffect(() => {
    if (editingClient) {
      setValues({
        ...initialState,
        companyName: editingClient.company_name,
        website: editingClient.website,
        address: editingClient.address,
        contactNumber: editingClient.contact_number,
        email: editingClient.email,
        country: editingClient.country,
      })
      setIsActive(editingClient.is_active);
    } else {
      setValues(initialState)
      setIsActive(false);
    }
  }, [isEditingClient])

  const validateEmail = () => {
    if (validator.isEmail(values.email)) {
      setIsEmail(true)
    } else {
      setIsEmail(false)
    }
  }
  const handleContactChange = (e) => {
    setValues({ ...values, ["contactNumber"]: e })
    validatePhoneNumber(e, selectedCountry)
  }

  const handleCountryChange = (country) => {
    setSelectedCountry(country)
    setValues({ ...values, ["country"]: country })

    validatePhoneNumber(values.contactNumber, country)
  }

  const validatePhoneNumber = (input, country = selectedCountry) => {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance()

      const parsedNumber = phoneUtil.parseAndKeepRawInput(input, country)

      const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(
        parsedNumber,
        country
      )

      setIsValid(isValidPhoneNumber)
    } catch (error) {
      setIsValid(false)
    }
  }

  const closeModal = () => {
    dispatch(closeClientModal())
  }

  const handleToggleActivation = async (client) => {
    if (!client || !client._id) {
      // console.error("Client data is missing or invalid.");
      // return;
      setIsActive(!isActive)
    }
    
    // console.log("toggle user", client?._id, client?.is_active);
    setIsActive(!client?.is_active || !isActive);
  
    try {
      const newActiveStatus = !client?.is_active || !isActive;
      await dispatch(updateClientActivation(client?._id, newActiveStatus));
      client.is_active = newActiveStatus; // No optional chaining for assignment here
      getPagination();
    } catch (error) {
      console.error("Error updating client activation status", error);
    }
  };
  

  return (
    <>
      {isClientModalOpen && (
        <div
          className={`${isClientModalOpen ? "modal-container isOpen" : "modal-container"
            }`}
        >
          {isClientModalOpen && (
            <>
              <Modal
                show={isClientModalOpen}
                onHide={closeModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isEditingClient ? "Edit Client" : "Add Client"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="tw-flex tw-flex-col tw-gap-3">
                      {/* company */}
                      <FormRow
                        type="text"
                        name="companyName"
                        labelText="Company Name*"
                        value={values.companyName}
                        handleChange={handleChange}
                        disabled={isEditingClient}
                        required={true}
                        placeholder="Enter Company Name"
                      />
                      {/* email */}
                      <FormRow
                        type="email"
                        name="email"
                        labelText="Email*"
                        value={values.email}
                        handleChange={handleChange}
                        disabled={isEditingClient}
                        onKeyUp={validateEmail}
                        required={true}
                        placeholder="Enter Email"
                      />
                      {isEmail ? null : (
                        <>
                          <span className="errordef">
                            please enter valid email
                          </span>
                        </>
                      )}
                      {/* website*/}
                      <FormRow
                        type="text"
                        name="website"
                        labelText="Website"
                        value={values.website}
                        handleChange={handleChange}
                        placeholder="Enter Website"
                      />
                      {/* address */}
                      <FormRow
                        type="text"
                        labelText="Address"
                        name="address"
                        value={values.address}
                        handleChange={handleChange}
                        placeholder="Enter Address"
                      />
                      <div className="tw-flex tw-flex-col  ">
                        <label>Contact Number</label>
                        <PhoneInput
                          international
                          defaultCountry={values.country || selectedCountry}
                          value={values.contactNumber}
                          onChange={handleContactChange}
                          country={values.country}
                          onCountryChange={handleCountryChange}
                        />
                        {isValid ? null : (
                          <span className="validatePError">
                            invalid phone number.
                          </span>
                        )}
                      </div>

                      <div className="tw-flex tw-justify-between tw-items-center">
                        <div className="">
                          <UserTableCell style={{ textAlign: "left" }}>
                          {isEditingClient && <div className="tw-flex tw-gap-4">
                          <span className="" style={{fontSize: 14}}>Active</span>
                            <Form.Switch
                              id={`switch-${editingClient?._id}`}
                              checked={isActive}
                            onChange={() => handleToggleActivation(editingClient)}
                            style={{paddingTop: 2}}
                            />
                          </div>}
                          </UserTableCell>
                        </div>
                        <div>
                          <button
                            className="btn btn-block clear-btn"
                            onClick={(e) => {
                              e.preventDefault()
                              closeModal()
                              clearValues()
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-block submit-btn"
                            type="submit"
                            disabled={false}
                            onClick={handleSubmit}
                          >
                            {isEditingClient ? "Save" : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </>
          )}
        </div>
      )}{" "}
    </>
  )
}

export default ClientModal
