// testCaseTypes.js
export const CREATE_TEST_CASE_BEGIN = 'CREATE_TEST_CASE_BEGIN';
export const CREATE_TEST_CASE_SUCCESS = 'CREATE_TEST_CASE_SUCCESS';
export const CREATE_TEST_CASE_ERROR = 'CREATE_TEST_CASE_ERROR';

export const GET_ALL_TEST_CASES_BEGIN = 'GET_ALL_TEST_CASES_BEGIN';
export const GET_ALL_TEST_CASES_SUCCESS = 'GET_ALL_TEST_CASES_SUCCESS';
export const GET_ALL_TEST_CASES_ERROR = 'GET_ALL_TEST_CASES_ERROR';

export const GET_TEST_CASE_BEGIN = 'GET_TEST_CASE_BEGIN';
export const GET_TEST_CASE_SUCCESS = 'GET_TEST_CASE_SUCCESS';
export const GET_TEST_CASE_ERROR = 'GET_TEST_CASE_ERROR';

export const EDIT_TEST_CASE_BEGIN = 'EDIT_TEST_CASE_BEGIN';
export const EDIT_TEST_CASE_SUCCESS = 'EDIT_TEST_CASE_SUCCESS';
export const EDIT_TEST_CASE_ERROR = 'EDIT_TEST_CASE_ERROR';

export const DELETE_TEST_CASE_BEGIN = 'DELETE_TEST_CASE_BEGIN';
export const DELETE_TEST_CASE_SUCCESS = 'DELETE_TEST_CASE_SUCCESS';
export const DELETE_TEST_CASE_ERROR = 'DELETE_TEST_CASE_ERROR';

export const OPEN_TEST_CASE_MODAL = 'OPEN_TEST_CASE_MODAL';
export const CLOSE_TEST_CASE_MODAL = 'CLOSE_TEST_CASE_MODAL';

export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID';
export const CLEAR_TEMPLATE_ID = 'CLEAR_TEMPLATE_ID';

export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const CLEAR_DATE_RANGE = 'CLEAR_DATE_RANGE';

export const SET_EDIT_TEST_CASE = 'SET_EDIT_TEST_CASE';
export const EDIT_TEST_CASE_MODAL_OPEN = 'EDIT_TEST_CASE_MODAL_OPEN';
export const EDIT_TEST_CASE_MODAL_CLOSE = 'EDIT_TEST_CASE_MODAL_CLOSE';

//Details Tests Cases
export const COUNT_TEST_CASE_ITEMS_BEGIN = 'COUNT_TEST_CASE_ITEMS_BEGIN';
export const COUNT_TEST_CASE_ITEMS_SUCCESS = 'COUNT_TEST_CASE_ITEMS_SUCCESS';
export const COUNT_TEST_CASE_ITEMS_ERROR = 'COUNT_TEST_CASE_ITEMS_ERROR';

export const CREATE_TEST_CASE_DETAILS_BEGIN = 'CREATE_TEST_CASE_DETAILS_BEGIN';
export const CREATE_TEST_CASE_DETAILS_SUCCESS = 'CREATE_TEST_CASE_DETAILS_SUCCESS';
export const CREATE_TEST_CASE_DETAILS_ERROR = 'CREATE_TEST_CASE_DETAILS_ERROR';

export const CREATE_COPY_TEMPLATE_ITEM_BEGIN = 'CREATE_TEST_CASE_DETAILS_BEGIN';
export const CREATE_COPY_TEMPLATE_ITEM_SUCCESS = 'CREATE_COPY_TEMPLATE_ITEM_SUCCESS';
export const CREATE_COPY_TEMPLATE_ITEM_ERROR = 'CREATE_COPY_TEMPLATE_ITEM_ERROR';

export const GET_TEST_CASE_BY_ID_BEGIN = 'GET_TEST_CASE_BY_ID_BEGIN';
export const GET_TEST_CASE_BY_ID_SUCCESS = 'GET_TEST_CASE_BY_ID_SUCCESS';
export const GET_TEST_CASE_BY_ID_ERROR = 'GET_TEST_CASE_BY_ID_ERROR';

export const GET_ALL_TEST_CASE_DETAILS_ITEMS_BEGIN = 'GET_ALL_TEST_CASE_DETAILS_ITEMS_BEGIN';
export const GET_ALL_TEST_CASE_DETAILS_ITEMS_SUCCESS = 'GET_ALL_TEST_CASE_DETAILS_ITEMS_SUCCESS';
export const GET_ALL_TEST_CASE_DETAILS_ITEMS_ERROR = 'GET_ALL_TEST_CASE_DETAILS_ITEMS_ERROR';

export const GET_TEST_CASE_DETAILS_BEGIN = 'GET_TEST_CASE_DETAILS_BEGIN';
export const GET_TEST_CASE_DETAILS_SUCCESS = 'GET_TEST_CASE_DETAILS_SUCCESS';
export const GET_TEST_CASE_DETAILS_ERROR = 'GET_TEST_CASE_DETAILS_ERROR';

export const UPDATE_TEST_CASE_DETAILS_REQUEST = 'UPDATE_TEST_CASE_DETAILS_REQUEST';
export const UPDATE_TEST_CASE_DETAILS_SUCCESS = 'UPDATE_TEST_CASE_DETAILS_SUCCESS';
export const UPDATE_TEST_CASE_DETAILS_FAILURE = 'UPDATE_TEST_CASE_DETAILS_FAILURE';

export const DELETE_TEST_CASE_DETAILS_BEGIN = 'DELETE_TEST_CASE_DETAILS_BEGIN';
export const DELETE_TEST_CASE_DETAILS_SUCCESS = 'DELETE_TEST_CASE_DETAILS_SUCCESS';
export const DELETE_TEST_CASE_DETAILS_ERROR = 'DELETE_TEST_CASE_DETAILS_ERROR';

export const UPDATE_TEST_CASE_REMARKS_REQUEST = 'UPDATE_TEST_CASE_REMARKS_REQUEST';
export const UPDATE_TEST_CASE_REMARKS_SUCCESS = 'UPDATE_TEST_CASE_REMARKS_SUCCESS';
export const UPDATE_TEST_CASE_REMARKS_FAILURE = 'UPDATE_TEST_CASE_REMARKS_FAILURE';

export const DELETE_REMARK_ATTACHMENT_BEGIN = 'DELETE_REMARK_ATTACHMENT_BEGIN';
export const DELETE_REMARK_ATTACHMENT_SUCCESS = 'DELETE_REMARK_ATTACHMENT_SUCCESS';
export const DELETE_REMARK_ATTACHMENT_ERROR = 'DELETE_REMARK_ATTACHMENT_ERROR';

export const DELETE_TEST_CASE_ATTACHMENT_BEGIN = 'DELETE_TEST_CASE_ATTACHMENT_BEGIN';
export const DELETE_TEST_CASE_ATTACHMENT_SUCCESS = 'DELETE_TEST_CASE_ATTACHMENT_SUCCESS';
export const DELETE_TEST_CASE_ATTACHMENT_ERROR = 'DELETE_TEST_CASE_ATTACHMENT_ERROR';




