  import React, { useState, useEffect, useRef } from "react"

  import Form from "react-bootstrap/Form"
  import { toast } from "react-toastify"
  import Modal from "react-bootstrap/Modal"
  import {
    FormRow,
    FormRowSelect,
    ProfileAvatar,
    Pagination,
    CustumTooltip,
  } from "../Common"

  import { useAppContext } from "../../context/context/appContext"

  import {
    UserTable,
    UserTableCell,
    UserTableHeader,
    UserTableRow,
    UserWrapper,
  } from "../../assets/styles/Settings"
  import {
    DeleteIcon,
    DriveFileRenameOutlineSharpIcon,
    FiberManualRecordRoundedIcon,
    MoreHorizRoundedIcon,
    SearchIcon,
  } from "../../assets/icons"
  import { useDispatch } from "react-redux"
  import {
    getDailyEmailNotification,
    dailyEmailNotification,
    editUserPermission,
    getAllRole,
    updateUserActivation,
  } from "../../redux"
  import { SearchInside } from "../../assets/styles/Dashboard"

  const UsersContainer = () => {
    const {
      getUsersList,
      getAllUsers,
      loading,
      usersSearchByName,
      deleteSingleRole,
    } = useAppContext()
    const dispatch = useDispatch()

    const [selectedUser, setSelectedUser] = useState(null)
    const [role, setRole] = useState()
    const [open, setOpen] = useState(false)
    const [roles, setRoles] = useState([])
    const [showEdit, setShowEdit] = useState(false)

    const [limit, setLimit] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [searchQuery, setSearchQuery] = useState("")
    const [isDaily, setIsDaily] = useState([])
    const currentPage = useRef(1)
    const [isActive, setIsActive] = useState(false);

    const getPagination = async () => {
      await getAllUsers(currentPage.current, limit, searchQuery, isActive)
      setPageCount(usersSearchByName?.data?.pageCount) // Update pageCount from API response
    }
    useEffect(() => {
      currentPage.current = 1
      getPagination()
      // getAllUsers(currentPage.current, limit);
    }, [limit, searchQuery, isActive])

    const handleChange = (e) => {
      setRole(e.target.value)
    }
    const handleIsActiveChange = (e) => {
      setIsActive(e.target.checked);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault()
      if (selectedUser) {
        const isRoleAlreadyAssigned =
          selectedUser.assignedRoles && selectedUser.assignedRoles.includes(role)
        if (isRoleAlreadyAssigned) {
          // Role is already assigned, no need to push
          toast.success("Role is already assigned to the user.")
          return
        } else {
          selectedUser.assignedRoles = selectedUser.assignedRoles || []
          selectedUser.assignedRoles.push(role)
          await dispatch(editUserPermission(selectedUser))
        }
      }
      setOpen(false)
    }
    const handleEdit = async (user) => {
      //console.log("edit users",user);
      setSelectedUser(user)

      setShowEdit(false)
      setOpen(true) // Open the modal
      setRole(user.role) // Reset the role state
    }
    const handleModalCancel = () => {
      setOpen(false)
    }
    const handleToggleOptions = (user) => {
      setShowEdit(true)
      setSelectedUser(user === selectedUser ? null : user)
    }
    const handleToggleActivation = async (user) => {
      //console.log("toggle user",user )
      try {
        const newActiveStatus = !user.is_active
        await dispatch(updateUserActivation(user._id, newActiveStatus))
        user.is_active = newActiveStatus
        getUsersList()
      } catch (error) {
        //   console.log(error);
      }
    }
    useEffect(() => {
      dispatch(getAllRole()).then((data) => setRoles(data.permissions))
      dispatch(getDailyEmailNotification()).then((data) => {
        //console.log(data)
        setIsDaily(data)
      })
    }, [])
    const handleToggleNotification = (userId, email, checked) => {
      dispatch(
        dailyEmailNotification({
          userId,
          email,
          is_daily: checked,
        })
      )
      const modifiedObject = isDaily.find((i) => {
        if (i.email === email) {
          return i
        }
      })
      if (!modifiedObject) {
        const obj = { email: email, is_daily: checked }
        const newArray = [...isDaily, obj]
        setIsDaily(newArray)
      } else {
        modifiedObject.is_daily = checked
        const newArray = isDaily.map((i) => {
          if (i.email === email) {
            return modifiedObject
          } else return i
        })
        setIsDaily(newArray)
      }
    }
    const handlePageClick = (e) => {
      currentPage.current = e.selected + 1
      getPagination() // Fetch data for the selected page
    }
    const changeLimit = () => {
      currentPage.current = 1
      getPagination()
    }

    const deleteSingleUserRole = async (id, role) => {
      const value = { id, role }
      await deleteSingleRole(value)
    }

    return (
      <>
        <div className="d-flex align-items-center gap-4 lg:tw-px-8 tw-px-3 ">
          {/**search box start */}
          <SearchInside>
            <div className="searches tw-py-[10px]">
            
                <SearchIcon className=" " />
          
              <FormRow
                type="text"
                name="searchquery"
                value={searchQuery}
                handleChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by user's name"
              />
            </div>
          </SearchInside>
          {/**search box end */}
          <div className="d-flex gap-2 tw-w-full">
            <input
              type='checkbox'
              value={isActive}
              name={'isActive'}
              checked={isActive}  // Set checked to false, so checkbox is not checked by default
              onChange={handleIsActiveChange}
              className="tw-w-6"
            />
            <label htmlFor={'isActive'} className="tw-w-full" style={{fontSize: 14,}}>
              {"Include Inactive"}
            </label>
          </div>
        </div>
        <UserWrapper>
          <div className=" tableresponse  ">
            <UserTable>
              <thead>
                <tr>
                  <UserTableHeader>User</UserTableHeader>
                  <UserTableHeader className="textLeft">Roles</UserTableHeader>
                  <UserTableHeader className="textCenter">
                    {/* <input type="checkbox" /> */}
                    Notification
                  </UserTableHeader>
                  <UserTableHeader className="textCenter">Active</UserTableHeader>
                  <UserTableHeader className="textCenter">Action</UserTableHeader>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  usersSearchByName &&
                  usersSearchByName.data &&
                  usersSearchByName.data.users &&
                  usersSearchByName.data.users.length > 0 &&
                  usersSearchByName.data.users
                    .filter((user) =>
                      user.name.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((user) => {
                      const userNotification = isDaily.find(
                        (notif) => notif.email === user.email
                      )

                      return (
                        <UserTableRow key={user._id}>
                          <UserTableCell>
                            <ProfileAvatar id={user._id} size={"s"} />
                            <span
                              className={user.is_active ? "actives" : "status"}
                            >
                              <FiberManualRecordRoundedIcon />
                            </span>
                            <p>
                              {user.name}
                              <span className="lowerCase">{user.email}</span>
                            </p>
                          </UserTableCell>

                          <UserTableCell className="textLeft listst">
                            <ul>
                              {user.assignedRoles &&
                                user.assignedRoles.map((a, i) => {
                                  return (
                                    <li key={i}>
                                      <span className="textLeft my-0"> {a}</span>
                                      <CustumTooltip
                                        text={"Delete this user role"}
                                        children={
                                          <DeleteIcon
                                            color="#7D8282"
                                            onClick={() =>
                                              deleteSingleUserRole(user._id, a)
                                            }
                                          />
                                        }
                                        position={"left"}
                                        id={`delete-tooltip`}
                                      />
                                    </li>
                                  )
                                })}
                            </ul>
                          </UserTableCell>
                          <UserTableCell style={{ textAlign: "center" }}>
                            <input
                              type="checkbox"
                              checked={
                                userNotification && userNotification.is_daily
                              }
                              onChange={(e) =>
                                handleToggleNotification(
                                  user._id,
                                  user.email,
                                  e.target.checked
                                )
                              }
                            />
                          </UserTableCell>
                          <UserTableCell style={{ textAlign: "center" }}>
                            <Form.Switch
                              id={`switch-${user._id}`}
                              checked={user.is_active}
                              onChange={() => handleToggleActivation(user)}
                            />
                          </UserTableCell>
                          <UserTableCell>
                            <MoreHorizRoundedIcon
                              onClick={() => handleToggleOptions(user)}
                            />
                            {selectedUser === user && showEdit ? (
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenu2"
                              >
                                <span
                                  className="text f-12"
                                  onClick={() => handleEdit(user)}
                                >
                                  <DriveFileRenameOutlineSharpIcon /> Edit
                                </span>
                              </div>
                            ) : null}
                          </UserTableCell>
                        </UserTableRow>
                      )
                    })}
              </tbody>
            </UserTable>
          </div>
          {/** pagination start */}
          {!loading &&
            usersSearchByName &&
            usersSearchByName.data &&
            usersSearchByName.data.totalUser > 7 &&
            usersSearchByName.data.pageCount && (
              <div className="paginationed">
                <div>
                  <input
                    type="number"
                    placeholder="Limit"
                    onChange={(e) => setLimit(e.target.value)}
                  />
                  <button onClick={changeLimit}>set row</button>
                </div>
                <Pagination
                  handlePageClick={handlePageClick}
                  pageCount={usersSearchByName.data.pageCount}
                  forcePage={currentPage.current - 1}
                />
              </div>
            )}
          {/** pagination end */}
          {/**Edit assigned role modal start */}
          {open && (
            <>
              <Modal
                show={open}
                onHide={() => setOpen(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form className="" onSubmit={handleSubmit}>
                    <FormRowSelect
                      handleChange={handleChange}
                      labelText={"select role"}
                      value={role}
                      name="role"
                      list={["select", ...roles.map((p) => p.name)]}
                    />
                    <div className="button-container textRight">
                      <button className="btn add-btn" onClick={handleModalCancel}>
                        Cancel
                      </button>
                      <button className="btn add-btn" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </>
          )}
          {/**Edit assigned role modal end */}
        </UserWrapper>
      </>
    )
  }

  export default UsersContainer
