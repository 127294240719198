import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"

import {
  DeleteIcon,
  DoneRoundedIcon,
  DriveFileRenameOutlineSharpIcon,
  KeyboardDoubleArrowLeftIcon,
} from "../../assets/icons"

import { useDispatch, useSelector } from "react-redux"
import { updateCategory, dragCategory } from "../../redux"
const initialState = {
  category: "",
}
const ManageList = ({ openManageList, setOpenManageList }) => {
  const dispatch = useDispatch()
  const { selectedProject } = useSelector((state) => state.project)
  const [categoryList, setCategoryList] = useState()
  const [values, setValues] = useState(initialState)
  const [categoryId, setCategoryId] = useState()

  const handleCategoryDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    // Check if the category was dropped in a different position
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return
    }

    // Get the source and destination indices
    const sourceIndex = source.index
    const destinationIndex = destination.index
    // Dispatch the action to update the category order in the state
    dispatch(
      dragCategory(
        selectedProject._id,
        sourceIndex,
        destinationIndex,
        selectedProject
      )
    )
  }

  const handleChange = (e, c) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSubmit = (categorys) => {
    dispatch(
      updateCategory(selectedProject, categorys, values.category, "edit")
    )

    let indexToUpdates = selectedProject.statusCategories.findIndex(
      (category) => category.toLowerCase() === categorys.toLowerCase()
    )

    if (indexToUpdates !== -1) {
      selectedProject.statusCategories[indexToUpdates] = values.category
    }

    setValues(initialState)
  }
  return (
    <Modal
      show={openManageList}
      onHide={() => setOpenManageList(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Manage List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DragDropContext
          onDragEnd={handleCategoryDragEnd}
          onDragStart={(e) => console.log(e)}
        >
          <div>
            {selectedProject.statusCategories.map((category, i) => {
              return (
                <div key={category} className="col-12 completed__wrapper">
                  <Droppable droppableId={category}>
                    {(provided, snapshot) => (
                      <div
                        className="box"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "#f5f5f5"
                            : "#fff",
                          transition: "background 0.2s ease",
                        }}
                      >
                        <Draggable
                          key={category}
                          draggableId={category}
                          index={i}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  background: snapshot.isDragging
                                    ? " rgba(48, 125, 175, 0.1)"
                                    : "#ebf3f9",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <h4 className="categort">
                                  {categoryList && categoryId === category ? (
                                    <>
                                      <input
                                        type="text"
                                        name="category"
                                        value={values.category}
                                        onChange={handleChange}
                                        placeholder="Enter Title"
                                      />
                                      <span>
                                        <button
                                          style={{ background: "none" }}
                                          onClick={() => handleSubmit(category)}
                                        >
                                          <DoneRoundedIcon
                                            style={{
                                              fontSize: "16px",
                                              color: "green",
                                            }}
                                          />
                                        </button>
                                        <button
                                          style={{ background: "none" }}
                                          onClick={() => setCategoryList(false)}
                                        >
                                          <KeyboardDoubleArrowLeftIcon
                                            style={{
                                              fontSize: "16px",
                                              color: "rgb(203 48 69)",
                                            }}
                                          />
                                        </button>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {category}{" "}
                                      <span>
                                        <DriveFileRenameOutlineSharpIcon
                                          style={{
                                            fontSize: "15px",
                                            color: "#58a3d3",
                                          }}
                                          className="mx-2"
                                          onClick={() => {
                                            setCategoryList(true)
                                            setValues({ category: category })
                                            setCategoryId(category)
                                          }}
                                        />
                                        <DeleteIcon
                                          style={{
                                            fontSize: "15px",
                                            color: "rgb(203 48 69)",
                                          }}
                                          onClick={() => {
                                            setCategoryId(category)
                                            dispatch(
                                              updateCategory(
                                                selectedProject,
                                                category,
                                                category,
                                                "delete"
                                              )
                                            )
                                            setCategoryList(false)
                                          }}
                                        />
                                      </span>{" "}
                                    </>
                                  )}
                                </h4>
                              </div>
                            )
                          }}
                        </Draggable>

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )
            })}
          </div>
        </DragDropContext>
      </Modal.Body>
    </Modal>
  )
}

export default ManageList
