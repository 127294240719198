import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { verifyEmail } from "../../services/repository"
import { useNavigate } from "react-router"
import { useAppContext } from "../../context/context/appContext"
import setCookie from "../../utils/setCookie"
const VerifyEmail = (props) => {
  const { setupUser } = useAppContext()
  const [loading, setLoading] = useState(false)
  let params = useParams()
  const navigate = useNavigate()

  const verifyUserEmail = async () => {
    setLoading(true)
    const result = await verifyEmail(params.token)
    if (result.data.status === true) {
      // 24*60*60*10
      // here set the tokens in the cookies
      const { accessToken, refreshToken } = result.data
      setCookie("accessToken", accessToken, 8000, "/")
      setCookie("refreshToken", refreshToken, 864000, "/")
      // Cookies.set("accessToken", accessToken)
      // Cookies.set("refreshToken", refreshToken)
      await setupUser(result.data.user)
      localStorage.setItem("User", JSON.stringify(result.data.user))
      setLoading(false)
      setTimeout(() => {
        navigate("/")
      }, 3000)
    }
  }
  useEffect(() => {
    try {
      verifyUserEmail()
    } catch (err) {
      return err
    }
  }, [params.token])

  return (
    <div className="wrapper">
      <div className="container main signin verify">
        {loading ? (
          <p>Please wait verifing your email...</p>
        ) : (
          <p>
            <i className="bi bi-check2-circle"></i>
            <br />
            Email verified successfully!
          </p>
        )}
      </div>
    </div>
  )
}

export default VerifyEmail
