import React, { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import { FormRow, FormRowSelect } from "../Common"
import { useSelector, useDispatch } from "react-redux"
import { createQuestion, closeQuestionModal } from "../../redux"

const initialState = {
  question: "",
  type: "subjective",
  options: [],
}

const QuestionModal = () => {
  const [values, setValues] = useState(initialState)
  const [options, setOptions] = useState([0])
  const dispatch = useDispatch()
  const { isAddQuestionModalOpen, isEditingQuestion } = useSelector(
    (state) => state.feedback
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(values)
    dispatch(createQuestion(values))
    setValues(initialState)
  }
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const hanndleAddOptions = () => {
    const last = options[options.length - 1]
    setOptions([...options, last + 1])
  }
  const handleOptionChange = (e) => {
    const position = parseInt(e.target.name)
    const str = e.target.value
    const arr = values.options
    arr[position] = str
    setValues({ ...values, options: arr })
  }

  return (
    <>
      <Modal
        show={isAddQuestionModalOpen}
        onHide={() => dispatch(closeQuestionModal())}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditingQuestion ? "edit question" : "add question"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="tw-flex tw-flex-col tw-gap-3"> 
          <FormRow
            type={"text"}
            value={values.question}
            name="question"
            handleChange={handleChange}
            labelText={"question"}
            required={true}
          />
          <FormRowSelect
            labelText={"type"}
            name="type"
            value={values.type}
            handleChange={handleChange}
            list={["subjective", "multiple"]}
          />

          {values.type === "multiple" && (
          <div>  <button className="btn" onClick={hanndleAddOptions}>
              add options
            </button>
            </div>
          )}
          {values.type === "multiple" &&
            options.map((i) => {
              return (
                <div>
                  <FormRow
                    name={`${i}`}
                    value={values.options[i]}
                    handleChange={handleOptionChange}
                    labelText={`option ${i + 1}`}
                  />
                </div>
              )
            })}
            <div className="tw-flex tw-gap-3">
          <button className="btn" onClick={handleSubmit}>
            submit
          </button>
          <button
            className="btn"
            onClick={() => dispatch(closeQuestionModal())}
          >
            cancel
          </button>
          </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default QuestionModal

